import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { useEntity } from '@property-folders/components/hooks/useEntity';

export function usePropertyGnafLocation() {
  const { value } = useLightweightTransaction<{gnaf: string}[]>({ myPath: 'saleAddrs' });
  const { value: meta } = useLightweightTransaction<{entity: {id: number}}>({ myPath: '', bindToMetaKey: true });
  const entity = useEntity(meta?.entity?.id);

  if (value && value.length > 0) {
    for (const property of value) {
      if (property.gnaf) {
        return property.gnaf;
      }
    }
  }

  if (entity && entity.gnafId) {
    return entity.gnafId;
  }

  return undefined;
}
