import short from 'short-uuid';

export class UuidFormatError extends Error {
  constructor() {
    super('Value could not be parsed as a UUID');
  }
}

export class ShortIdFormatError extends Error {
  constructor() {
    super('Value could not be parsed as a short id');
  }
}

type Nullable<T> = T | null | undefined;

class WrappedTranslator {
  private _regexp?: RegExp;

  constructor(private translator: short.Translator) { }

  public get alphabet() {
    return this.translator.alphabet;
  }

  public get regexp() {
    if (this._regexp) {
      return this._regexp;
    }
    this._regexp = new RegExp(`\\b[${this.translator.alphabet}]{${this.translator.maxLength}}\\b`, 'g');
    return this._regexp;
  }

  public toUuid(value: Nullable<string>): string | undefined {
    if (!value) {
      return undefined;
    }

    try {
      const match = [...value.matchAll(this.regexp)].splice(-1)[0];

      return match
        ? this.translator.toUUID(match[0])
        : undefined;
    } catch (err: unknown) {
      console.error(err);
      return undefined;
    }
  }

  public fromUuid(value: string): string {
    return this.translator.fromUUID(value);
  }
}

export enum ShortIdMode {
  /**
   * i.e. Lowercase alphanumeric
   */
  EmailFriendly,
  /**
   * i.e. Flickr 58 alphabet (unambiguous upper/lower alphanumeric)
   */
  FlickrBase58,
}

const flickrTranslator = new WrappedTranslator(short(short.constants.flickrBase58, { consistentLength: true }));
const emailFriendlyTranslator = new WrappedTranslator(short('0123456789abcdefghijklmnopqrstuvwxyz', { consistentLength: true }));
const allTranslators = [emailFriendlyTranslator, flickrTranslator];

function getTranslator(mode?: ShortIdMode) {
  switch (mode) {
    case ShortIdMode.FlickrBase58:
      return flickrTranslator;
    default:
      return emailFriendlyTranslator;
  }
}

export class ShortId {
  public static get alphabets(): string[] {
    return allTranslators.map(t => t.alphabet);
  }

  public static toUuid(value: Nullable<string>) {
    return this.tryConvertShortIdToUuid(value)
      || this.extractUuid(value)
      || this.shortIdAssertionFailed();
  }

  public static fromUuid(value: Nullable<string>, mode?: ShortIdMode) {
    return this.tryConvertUuidToShortId(value, mode)
      || this.extractUuid(value)
      || this.uuidAssertionFailed();
  }

  private static extractUuid(value: Nullable<string>) {
    if (!value) {
      return undefined;
    }
    const uuidMatch = value.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
    return uuidMatch
      ? uuidMatch[0]
      : undefined;
  }

  private static tryConvertShortIdToUuid(value: Nullable<string>) {
    for (const translator of allTranslators) {
      const uuid = translator.toUuid(value);
      if (uuid) {
        return uuid;
      }
    }

    return undefined;
  }

  private static tryConvertUuidToShortId(value: Nullable<string>, mode?: ShortIdMode) {
    const uuidMatch = this.extractUuid(value);

    if (!uuidMatch) {
      return undefined;
    }

    return getTranslator(mode).fromUuid(uuidMatch);
  }

  private static shortIdAssertionFailed(): string {
    throw new ShortIdFormatError();
  }

  private static uuidAssertionFailed(): string {
    throw new UuidFormatError();
  }
}
