import { z } from 'zod';

export interface PropertyList {
  id: number;
  subscriptionDocumentId?: number;
  subscriptionFolderId?: number;
  subscriptionFormId?: number;
  propertyId: string;
  propertyFormId: string;
  name: string;
  status: AllDocumentStatus;
  statusDetail: Record<string, string | number | boolean>;
  signingDetail?: GetDocumentSigningDetailsItem[],
  ownerId: number;
  ownerName: string;
  entityId: number;
  createdStamp: Date;
  updateStamp: Date;
  formName: string;
  formType?: string;
  isArchived: boolean;
  formCode: string;
  locked?: { agent: { name: string, id: number }, expiresAt: Date; };
  summary?: string;
  signingPortalEnabled: boolean;
}

export const ListDocumentQuerySchema = z.object({
  limit: z.coerce.number().default(10),
  pageNumber: z.coerce.number().optional().default(1),
  searchTerm: z.string().optional(),
  propertyId: z.string().optional(),
  productId: z.coerce.number().optional(),
  moduleId: z.coerce.number().optional(),
  formId: z.coerce.number().optional(),
  formCode: z.string().optional(),
  folderId: z.coerce.number().optional(),
  entityUuid: z.string().optional(),
  showArchived: z.optional(z.string().transform(x => x === 'true'))
});

export type ListDocumentQuery = z.infer<typeof ListDocumentQuerySchema>;

export interface ListDocumentResponse {
  results: PropertyList[];
  pageNumber: number;
}

export enum AllDocumentStatus {
  Draft = 'Draft',
  Configuring = 'Configuring',
  PendingRemoteCompletion = 'Pending completion',
  OutForSigning = 'Out for signing',
  Signed = 'Signed',
  Distributed = 'Distributed',
  Terminated = 'Terminated'
}

export interface GetDocumentSigningDetailsItem {
  type: 'sign' | 'fill';
  status: 'pending' | 'done' | 'declined';
  method: 'email' | 'sms' | 'hosted' | 'wet';
  timestampMs?: number;
  name: string; // could be AGENT1 or "Joe Bloggs"
  recipient?: string; // email, phone, or blank (hosted/wet)
}

export interface GetDocumentSigningDetailsResponse {
  items: GetDocumentSigningDetailsItem[];
  timeZone: string;
}
