import { FeeCalcMechanism, FeeScaleItemType, MaterialisedPropertyData } from '@property-folders/contract';
import { ComplexValidationContext, ComplexValidationFunction, ComplexValidationReturnErrors } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { PathSegments } from '@property-folders/contract/yjs-schema/model';
import { getValueByPath, mergePaths, mergePathsAsStr, normalisePathToStr } from '../../../../util/pathHandling';
import { Predicate } from '../../../../predicate';

function checkIndividualScale(nodeTree: MaterialisedPropertyData, modePath: PathSegments, scalePath: PathSegments, updatedPath: PathSegments|undefined) {
  if (updatedPath) {
    const updated = normalisePathToStr(updatedPath);
    if (
      updated !== normalisePathToStr(modePath)
      && !updated.startsWith(normalisePathToStr(scalePath))
    ) {
      return;
    }

  }
  const mode = getValueByPath(modePath, nodeTree, true);
  if (mode !== FeeCalcMechanism.Scale) return;

  const levels = getValueByPath(mergePaths(scalePath, 'levels'), nodeTree, true) as FeeScaleItemType[] | undefined;
  const levelsPath = mergePathsAsStr(scalePath, 'levels');
  if (!Array.isArray(levels)) {
    return {
      forceUpdatesOn: [] as string[],
      errorObj: { [levelsPath]: ['missingScaleLevelsArray'] }
    };
  }
  if (levels.length < 2) {
    return {
      forceUpdatesOn: [] as string[],
      errorObj: { [levelsPath]: ['insufficientRowsForScale'] }
    };
  }
  const errorObj: ComplexValidationReturnErrors  = {};
  for (const lis in levels) {
    const li = parseInt(lis);
    if (li >= levels.length-1) continue;
    if (li === 0) continue;
    const item = levels[li];
    const itemPath = mergePathsAsStr(levelsPath, `[${item.id??li}]`);
    const limitPath = mergePathsAsStr(itemPath, 'limit');
    if (typeof item.limit !== 'number') {
      errorObj[limitPath] = ['scaleLimitNotNumber'];
      continue;
    }
    const itemPrev = levels[li-1];
    if (typeof itemPrev.limit !== 'number') continue; // Not our fault, that's the next fields problem

    if (item.limit > itemPrev.limit) continue;

    errorObj[limitPath] = ['scaleLimitNotGreaterThanPrev'];
  }

  return {
    forceUpdatesOn: [] as string[],
    errorObj: Object.keys(errorObj).length > 0 ? errorObj : undefined
  };
}

export const feeScalesShouldBeInOrder: ComplexValidationFunction = (
  nodeTree: MaterialisedPropertyData,
  updatedPath?: PathSegments,
  context?: ComplexValidationContext
) => {
  if (updatedPath && updatedPath[0] !== 'professFee') return { errorObj: undefined, forceUpdatesOn: [] };

  const results = [
    checkIndividualScale(nodeTree, ['professFee', 'fixedMode'], ['professFee', 'fixedScale'], updatedPath),
    checkIndividualScale(nodeTree, ['professFee', 'commissionMode'], ['professFee', 'commisScale'], updatedPath)
  ];

  return {
    forceUpdatesOn: results.filter(Predicate.isNotNullish).flatMap(r=>r?.forceUpdatesOn??[]),
    errorObj: results.map(r=>r?.errorObj).some(Predicate.isNotNullish) ? Object.assign({}, ...results.map(r=>r?.errorObj??{})) : undefined
  };

};
