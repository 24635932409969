import React, { useContext } from 'react';
import { DefaultPageLayout } from '~/components/default-page-layout';
import {
  Await,
  defer,
  LoaderFunctionArgs,
  redirect,
  useAsyncValue,
  useLoaderData, useMatches, useNavigate, useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';
import { GetPurchaserPortalRegistrationResult, GetPurchaserPortalResult, YDocContentType } from '@property-folders/contract';
import { FormContext } from '@property-folders/components/context/FormContext';
import { ShortId } from '@property-folders/common/util/url';
import { LoaderResult } from '~/types';
import { DefaultError } from '~/components/default-error';
import { PurchaserPortalRootKey } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { SetupYManagerContext, WaitForLoadedYDoc } from '@property-folders/components/context/YManagerContext';
import * as Y from 'yjs';
import { mergeLoaderResults } from '~/util';
import { FullScreenLoader } from '~/components/loaders';
import { PurchaserRegistrationComponent } from '~/components/purchaser-registration';
import { registrationFormContext } from '~/form-context-definitions';
import { BasicYjsContext } from '~/context/basic-yjs-context';
import { PurchaserPortalApi } from '~/api';
import { LinkBuilderContext } from '@property-folders/components/context/link-builder-context';

class InvalidInvitationError extends Error {
  constructor() {
    super('Invitation is not valid');
  }
}

interface LoaderData {
  portalId: string;
  portalUserId: string;
  yDocId: string;
  skipToVerification?: {
    phone?: boolean;
    email?: boolean;
  }
}

type ResolvedSuspenseData = GetPurchaserPortalResult & LoaderData;

export async function purchaserUnauthenticatedRegistrationLoader(opts: LoaderFunctionArgs & {
  invalidateSession: () => void
}) {
  const { params, invalidateSession, request } = opts;
  const { portalIdRaw, inviteToken } = params as { portalIdRaw: string, inviteToken?: string };
  const portalId = ShortId.toUuid(portalIdRaw);

  let registration: GetPurchaserPortalRegistrationResult | undefined;
  try {
    registration = await PurchaserPortalApi.getRegistration(portalId, inviteToken);
  } catch {
    console.warn('Got error response from API');
  }

  if (inviteToken && !registration) {
    invalidateSession();

    const searchObj = new URL(request.url).searchParams;
    const searchStr = searchObj.size > 0 ? `?${searchObj.toString()}` : '';
    // This function is literally called purchaser...registration, so we should
    // be able to safely assume the redirect path this should take
    return redirect(`/purchaser/${portalIdRaw}/register${searchStr}`, 308);
  }

  // This is a slight pattern change, we need to possibly redirect, so we'll await before returning
  // defer. The defer example on remix-run shows we can await before returning defer, as it is
  // intended for streaming, which we're not particularly doing. However I am not sure we can
  // dispense with the defer call entirely, due to the use of merge loaders?

  if (!registration) {
    throw new Error('No Registration details');
  }

  if (registration.type === 'login_redirect') {
    throw new Error('Already logged in');
  }

  // trigger the auth provider to refresh now that we've been assigned a session
  invalidateSession();

  const deferData = {
    portalId,
    portalUserId: registration.portalUserId,
    yDocId: registration.ydocId,
    skipToVerification: registration.type === 'verification_required'
      ? { phone: registration.phone, email: registration.email }
      : undefined
  };

  return defer({
    data: deferData
  });
}

export function PurchaserUnauthenticatedRegistrationRoute() {
  const matches = useMatches();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const linkBuilder = useContext(LinkBuilderContext);
  const resolve = mergeLoaderResults<ResolvedSuspenseData>([
    useLoaderData() as LoaderResult,
    useRouteLoaderData('unauthenticated-root') as LoaderResult
  ]);

  return <React.Suspense fallback={<FullScreenLoader/>}>
    <Await
      resolve={resolve}
      errorElement={<DefaultError/>}
    >
      {(resolved: ResolvedSuspenseData) => <SetupYManagerContext
        user={{ portalUserId: resolved.portalUserId }}
        enabled={true}
        offlineEnabled={false}
        websocketEndpoint={linkBuilder.wsApi()}
        impersonator={false/* No impersonators in portal */}
      >
        <WaitForLoadedYDoc yDocId={resolved.yDocId} yDocType={YDocContentType.PurchaserPortal}
          loadingElement={<FullScreenLoader/>}>
          {(yDoc: Y.Doc) => <PurchaserUnauthenticatedRegistrationRouteInner data={resolved} yDoc={yDoc}/>}
        </WaitForLoadedYDoc>
      </SetupYManagerContext>}
    </Await>
  </React.Suspense>;
}

export function PurchaserUnauthenticatedRegistrationRouteInner({ data, yDoc }: {
  data: ResolvedSuspenseData,
  yDoc: Y.Doc
}) {
  const { skipToVerification, settings, gnaf } = useAsyncValue() as ResolvedSuspenseData;
  const { inviteToken } = useParams<{ inviteToken?: string }>();

  return <DefaultPageLayout
    title='Purchaser portal'
    subtitle={[<h5 key='subtitle'>{data.headline}</h5>]}
    agencyCssOverrides={data.cssOverrides}
  >
    <BasicYjsContext
      yDoc={yDoc}
      yDocId={data.yDocId}
      rootKey={PurchaserPortalRootKey.Main}
      metaKey={PurchaserPortalRootKey.Main}
    >
      <FormContext.Provider value={registrationFormContext}>
        <PurchaserRegistrationComponent
          portalId={data.portalId}
          inviteToken={inviteToken}
          verifyPhone={skipToVerification?.phone}
          settings={settings}
          gnaf={gnaf}
        />
      </FormContext.Provider>
    </BasicYjsContext>
  </DefaultPageLayout>;
}

