import Dexie from 'dexie';
import { AgentSessionInfoResult } from '@property-folders/contract';
import { IOfflineProperty } from './offlineProperties';
import { ISessionPointer } from './offlineSession';
import { IPendingUpdate } from './pendingUpdates';
import { ITaskLastExecution, TaskType } from './tasks';
import { IFileData, IFileMeta } from './fileStorage';

export class StorageDexie extends Dexie {
  agentOfflineProperties!: Dexie.Table<IOfflineProperty, [string, number]>;
  pendingUpdates!: Dexie.Table<IPendingUpdate, [string, number]>;
  // note: only agent sessions can be stored offline, so the contents are agent session details.
  sessionInfo!: Dexie.Table<AgentSessionInfoResult, number>;
  sessionPointers!: Dexie.Table<ISessionPointer, string>;
  taskLastExecution!: Dexie.Table<ITaskLastExecution, TaskType>;
  fileData!: Dexie.Table<IFileData, string>;
  fileMeta!: Dexie.Table<IFileMeta, string>;

  constructor() {
    super('_storage');
    this.version(2).stores({
      offlineProperties: '&id, *keywords',
      sessions: '&id'
    });
    this.version(3).stores({
      agentOfflineProperties: '&[id+agentId], *keywords',
      sessions: '&id'
    });
    this.version(4).stores({
      offlineProperties: null
    });
    this.version(5).stores({
      pendingUpdates: '&[id+agentId]'
    });
    this.version(6).stores({
      sessionInfo: '&agentId',
      sessionPointers: '&id'
    });
    this.version(7).stores({
      sessions: null
    });
    this.version(8).stores({
      pendingUpdates: '&[id+agentId], agentId'
    });
    this.version(9).stores({
      agentOfflineProperties: '&[id+agentId], *keywords, agentId'
    });
    this.version(10).stores({
      taskLastExecution: '&id'
    });
    this.version(11).stores({
      fileData: '&id',
      fileMeta: '&id, fileStatus, syncStatus'
    });
    this.version(12).stores({
      fileMeta: '&id, fileStatus, syncStatus, addedTimeMs'
    }).upgrade(transact => {
      return transact.table('fileMeta').toCollection().modify (fm => {
        fm.addedTimeMs = 0;
      });
    });
  }
}

export const db = new StorageDexie();
