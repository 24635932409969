import { FormInstance, SignedStates } from '@property-folders/contract';

export const formCompleted = (formInstance: FormInstance) => {
  if (!(formInstance.signing?.state && SignedStates.has(formInstance.signing.state))) {
    return;
  }

  const max = Math.max(...(formInstance.signing.session?.fields || []).map(f => f.timestamp || 0));
  if (!max) {
    return;
  }

  return new Date(max);
};

export const formCompletedWithTimezone = (formInstance: FormInstance) => {
  if (!(formInstance.signing?.state && SignedStates.has(formInstance.signing.state))) {
    return;
  }

  const max = Math.max(...(formInstance.signing.session?.fields || []).map(f => f.timestamp || 0));
  if (!max) {
    return;
  }

  return {
    date: new Date(max),
    timezone: formInstance.signing.session?.initiator.timeZone || 'Australia/Adelaide'
  };
};
