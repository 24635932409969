import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm } from '@property-folders/components/hooks/useForm';
import { useSelector } from 'react-redux';
import { useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { PurchaserPortalData, PurchaserPortalRootKey, PurchaserPortalSettings } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { v4 } from 'uuid';
import { FullScreenLoader } from '~/components/loaders';
import { ContentCard } from '~/components/content-card';
import { Alert, Button, Card, FloatingLabel, Form } from 'react-bootstrap';
import { ProgressSteps } from '~/components/progress-steps';
import { PartyAuthorityInput } from '@property-folders/components/dragged-components/form/PartyAuthorityInput';
import { purchaserTypeOptions } from '@property-folders/common/util/pdfgen/constants';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { noBubble, swallow } from '~/util';
import { PurchaserPortalApi } from '~/api';
import { GetPurchaserPortalResult } from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import { InvalidPasswordRule, checkPasswordRules } from '@property-folders/common/util/check-password-rules';
import { useAuth } from '~/context/auth-context';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { ResendButton } from './resend-button';

enum PageSteps {
  AboutYou = 1,
  //Password = 2,
  VerificationSms = 2, // was 3
  //VerificationEmail = 4
}

type InvalidPasswordReason =
  | InvalidPasswordRule
  | 'common';

export function PurchaserRegistrationComponent({ portalId, inviteToken, verifyPhone, verifyEmail, settings, gnaf }: {
  portalId: string,
  inviteToken?: string,
  verifyPhone?: boolean,
  verifyEmail?: boolean,
  settings?: PurchaserPortalSettings
  gnaf: GetPurchaserPortalResult['gnaf']
}) {
  const navigate = useNavigate();
  const { invalidate: reloadSession } = useAuth();
  const { search } = useLocation();
  const previewPassthrough = new URLSearchParams(search).get('doctype');
  const moreQs = previewPassthrough ? `&doctype=${previewPassthrough}` : '';
  const [step, setStep] = useState<PageSteps>(verifyPhone
    ? PageSteps.VerificationSms
    : PageSteps.AboutYou
  );
  const { ydoc, docName, transactionRootKey, formName } = useForm();
  const focusErrList = useSelector<any, string[] | undefined>((state: any) => state?.validation?.focusErrList?.[docName ?? '']?.[transactionRootKey ?? '']?.[formName]);
  const { value: root } = useTransactionField<PurchaserPortalData>({ myPath: '' });
  const {
    binder
  } = useImmerYjs<PurchaserPortalData>(ydoc, PurchaserPortalRootKey.Main);

  // Verification steps
  const [phoneVerificationRequired, setPhoneVerificationRequired] = useState(!!verifyPhone);
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('');

  // general
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);

  // initialise ydoc
  useEffect(() => {
    if (!root) return;
    if (!binder) return;
    if (root.id) return;

    const docId = v4();
    const primaryPurchaserId = v4();
    binder.update(state => {
      state.id = docId;
      state.portalId = portalId;
      state.purchasers = [{
        id: primaryPurchaserId
      }];
      state.primaryPurchaser = primaryPurchaserId;
      state.settings = settings ?? {};
    });
  }, [!!root, !!binder]);

  const purchaser = root?.primaryPurchaser && root.purchasers?.length
    ? root.purchasers.find(p => p.id === root.primaryPurchaser)
    : undefined;
  const [purchaserEmail, purchaserPhone] = (() => {
    switch (purchaser?.primarySubcontact) {
      case 1:
        return [purchaser?.email2, purchaser?.phone2];
      default:
        return [purchaser?.email1, purchaser?.phone1];
    }
  })();

  const backHandler = () => {
    if (step > 1) {
      setStep(step - 1);
      setErrorMessage(undefined);
      return;
    }

    navigate(-1);
  };

  const [phoneVerificationReady, setPhoneVerificationReady] = useState(false);

  const submitRegistrationDetails = async () => {
    if (!purchaserPhone) return;
    setErrorMessage(undefined);
    setSubmitting(true);
    const result = await PurchaserPortalApi.postRegistration(portalId, {
      username: purchaserPhone!,
      inviteToken
    });
    setSubmitting(false);

    switch (result?.type) {
      case 'verification_required':
        if (result.phone) {
          setPhoneVerificationRequired(true);
          setStep(PageSteps.VerificationSms);
          setPhoneVerificationReady(true);
          break;
        }

        // what?
        break;
      case 'completed':
        await reloadSession();
        navigate(`/purchaser/${portalId}/view${moreQs ? `?${moreQs}` : ''}`);
        break;
      case 'invalid_username':
        setErrorMessage(() => <span>
          Your phone number has already registered with this portal. Please select a new phone number or <Link to='../login'>try logging in</Link>.
        </span>);
        break;
    }
  };

  const submitCodesHandler = async () => {
    if (phoneVerificationRequired && !phoneVerificationCode) return;
    setSubmitting(true);
    setErrorMessage(undefined);
    const result = await PurchaserPortalApi.postRegistrationVerification(portalId, {
      phoneCode: phoneVerificationRequired ? phoneVerificationCode : undefined
    });
    setSubmitting(false);

    switch (result?.type) {
      case 'verification_required': {
        if (result.phone) {
          if (step === PageSteps.VerificationSms) {
            setErrorMessage('The phone code entered was incorrect. Please enter the correct code and try again.');
          }
          setPhoneVerificationRequired(true);
          setStep(PageSteps.VerificationSms);
          break;
        }

        if (result.email) {
          throw new Error('Email should no longer be a step');
        }

        break;
      }
      case 'completed':
        await reloadSession();
        navigate(`/purchaser/${portalId}/view${moreQs ? `?${moreQs}` : ''}`);
        break;
    }
  };

  if (!(root?.id && root.primaryPurchaser)) {
    return <FullScreenLoader/>;
  }

  return <ContentCard agencyHighlights={true}>
    <Card.Header className='d-flex flex-column-reverse flex-sm-row justify-content-between gap-2'>
      {step === PageSteps.AboutYou && <h4>Tell us about you</h4>}
      {step === PageSteps.VerificationSms && <h4>SMS Verification</h4>}
      <ProgressSteps steps={2} current={step}/>
    </Card.Header>
    {step === PageSteps.AboutYou && <>
      <Card.Body>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        <PartyAuthorityInput
          primaryId={root.primaryPurchaser }
          primaryIdAbsPath={'primaryPurchaser'}
          thisLevel={1}
          hideOnTitleField={true}
          partyLabel='Purchaser'
          typeOptions={purchaserTypeOptions}
          myPath={`purchasers.[${root.primaryPurchaser}]`}
          purchaserPortalRegistration={true}
          hideRootPrimaryContactCheckbox={true}
          hideDelete={true}
          gnaf={gnaf}
          setAutoComplete={true}
        />
      </Card.Body>
      <Card.Footer className='d-flex flex-row justify-content-end gap-2'>
        <Button variant='outline-secondary' onClick={backHandler}>Back</Button>
        <Button disabled={!!focusErrList?.length} onClick={noBubble(swallow(submitRegistrationDetails))}>Next</Button>
      </Card.Footer>
    </>}
    {step === PageSteps.VerificationSms && <form onSubmit={noBubble(swallow(submitCodesHandler))}>
      <Card.Body>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        <div className='d-flex flex-column gap-5'>
          {phoneVerificationRequired && <div>
            <p>We have sent a verification code to your phone number <b>{canonicalisers.phone(purchaserPhone).display}</b>.</p>
            <p>Enter the code below to verify this phone number:</p>
            <FloatingLabel label='Phone verification code'>
              <Form.Control
                type='text'
                autoComplete='one-time-code'
                inputMode='numeric'
                onChange={e => {
                  const beforeNotEmpty = e.target.value.length > 0;
                  e.target.value = e.target.value.replace(/[^\d]/, '');
                  if (beforeNotEmpty && e.target.value.length === 0) {
                    e.preventDefault();
                    return false;
                  }
                  setPhoneVerificationCode(e.target.value);
                }}
                isInvalid={!phoneVerificationCode}
                pattern='\d*'
                tabIndex={1}
              />
            </FloatingLabel>
          </div>}
        </div>
      </Card.Body>
      <Card.Footer className='d-flex flex-row justify-content-end gap-2'>
        <ResendButton
          initialSent={phoneVerificationReady}
          phone={purchaserPhone}
          portalId={portalId}
          resendSource='registration'
          tabIndex={3}
        />
        <SpinnerButton
          type='submit'
          processing={submitting}
          disabled={(phoneVerificationRequired && !phoneVerificationCode)}
          onClick={noBubble(swallow(submitCodesHandler))}
          tabIndex={2}
        >Next</SpinnerButton>
      </Card.Footer>
    </form>}
  </ContentCard>;
}

export async function checkPassword(password: string): Promise<InvalidPasswordReason | undefined> {
  const rulesResult = checkPasswordRules(password);
  if (rulesResult) return rulesResult;

  const commonPasswords = (await import('./common-pw.txt?raw'))
    .default
    .split('\n')
    .map(x => x.trim())
    .filter(Predicate.isTruthy);
  for (const line of commonPasswords) {
    if (line === password) {
      return 'common';
    }
  }

  return undefined;
}

