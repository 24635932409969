import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { Container, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import { SigningPartyMessageConfig } from '@property-folders/contract';
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

const TextAreaRows = 6;
const TextAreaMaxLength = 1000;

export function CustomiseMessage({ path }: { path: string }) {
  return <Container fluid>
    <Row>
      <Col lg={12}>
        <WrField.TextArea
          parentPath={path}
          myPath={'content'}
          name={'content'}
          bindToMetaKey={true}
          label={'Message'}
          maxLength={TextAreaMaxLength}
          showLengthRemaining={true}
          rows={TextAreaRows}
          minHeight={'105px'}
        />
      </Col>
    </Row>
  </Container>;
}

export function CustomiseMessageStandalone({
  config,
  onConfigChange
}: {
  config: SigningPartyMessageConfig,
  onConfigChange: (value: SigningPartyMessageConfig) => void
}) {
  const [content, setContent] = useState<string>(config.content || '');
  const remaining = TextAreaMaxLength - content.length;
  // debounce it a little, because otherwise the recipient is processing a state change on every keystroke
  const debouncedOnConfigChange = useCallback(debounce(onConfigChange, 300), []);

  return <Container fluid>
    <Row>
      <Col lg={12}>
        <FloatingLabel label={'Message'}>
          <Form.Control
            as={'textarea'}
            rows={TextAreaRows}
            maxLength={TextAreaMaxLength}
            value={content}
            onChange={e => {
              const value = e.target.value;
              setContent(value);
              debouncedOnConfigChange({ content: value });
            }}/>
        </FloatingLabel>
        <p>Characters remaining: {remaining}</p>
      </Col>
    </Row>
  </Container>;
}

export function LimitedCharacterTextField({ label, value, onChange, maxLength }: {
  label: string,
  value: string,
  onChange: (value: string) => void,
  maxLength: number
}) {
  const remaining = maxLength - value.length;

  return <Container fluid>
    <Row>
      <Col lg={12}>
        <FloatingLabel label={label}>
          <Form.Control
            maxLength={TextAreaMaxLength}
            value={value}
            onChange={e => {
              onChange(e.target.value);
            }}/>
        </FloatingLabel>
        <p>Characters remaining: {remaining}</p>
      </Col>
    </Row>
  </Container>;
}

