import { PurchaserPortalSharedDocument } from '@property-folders/contract';

type DocTypes = PurchaserPortalSharedDocument['documentType'];

export type DocumentDescriptionItem = {

  title: string
  summary: string
  mobileTitle?: string
  mobileSummary?: string
};

export const DocumentDescriptions: {[x in DocTypes]: DocumentDescriptionItem} = {
  'form-1': {
    title: 'Form 1 - Vendor’s Statement',
    summary: 'View the vendor’s statutory disclosure about this property'
  },
  'brochure': {
    title: 'Property Brochure',
    summary: 'View the agent’s brochure for this property'
  },
  'contract': {
    title: 'Template Contract',
    summary: 'Familiarise yourself with the contract to be used for this property'
  },
  'prescribed-notice': {
    title: 'Prescribed Notice – Form R3 – Buyers Information Notice',
    summary: 'Learn about what you should investigate and consider as a purchaser of residential property',
    mobileTitle: 'Prescribed Notice'
  }
};
