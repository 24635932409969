import { RecursiveObject } from './IRecursiveObject';

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result as string);
  });
}

export async function SaveObjectContainingBlobs<T extends RecursiveObject | Blob>(obj: T): Promise<T> {
  if (obj instanceof Blob) {
    return {
      _type: 'blob',
      _contentType: obj.type,
      _blob: await blobToBase64(obj)
    } as any;
  }

  if (typeof obj !== 'object' || obj == null) {
    return obj;
  }

  for (const key of Object.keys(obj)) {
    const data = obj[key];

    if (data instanceof Blob) {
      obj[key] = {
        _type: 'blob',
        _contentType: data.type,
        _blob: await blobToBase64(data)
      };
    }

    if (typeof data !== 'object' || data == null) {
      continue;
    }

    if (typeof data === 'object') {
      obj[key] = await SaveObjectContainingBlobs(obj[key]);
    }
  }

  return obj;
}
