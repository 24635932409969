export class StatusCodeError extends Error {
  errorCode = 0;
  errorMessage = '';
  constructor(public status: number, message?: string) {
    super(`Received error status code: ${status}`);
    this.errorCode = status;
    if (message) this.errorMessage = message;
  }

  public setMessage(message: string) {
    this.errorMessage = message??'';
  }
}
