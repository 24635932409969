import './authenticated-view.scss';
import { useAsyncValue, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { DefaultPageLayout } from '~/components/default-page-layout';
import { ContentCard } from '~/components/content-card';
import { Card, Dropdown, Table } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import {
  FileRef,
  GetPurchaserPortalAuthenticatedResult,
  PurchaserPortalSharedDocument
} from '@property-folders/contract';
import {
  PurchaserEditableDocument,
  PurchaserEditableDocumentStatus,
  PurchaserSubmittedDocument,
  PurchaserSubmittedDocumentStatus,
  SettingsOfferType
} from '@property-folders/contract/yjs-schema/purchaser-portal';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { byNumericValueDesc } from '@property-folders/common/util/sortComparison';
import clsJn from '@property-folders/common/util/classNameJoin';
import { AsyncButton } from '~/../../components/dragged-components/AsyncButton';
import { ShortId } from '@property-folders/common/util/url';
import { PurchaserPortalApi } from '~/api';
import { getSubmittedDocumentFileId, getSubmittedDocumentFileName } from '~/routes/purchaser/authenticated-offer';
import { Hero } from '@property-folders/components/display/embellishments/Hero';
import { ConfirmWithdrawModal } from '~/components/confirm-withdraw-modal';
import { DocumentDescriptions } from '@property-folders/common/data-and-text/descriptions';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';

type ResolvedSuspenseData = GetPurchaserPortalAuthenticatedResult;

export function PurchaserAuthenticatedViewRoute() {
  const navigate = useNavigate();

  const data = useAsyncValue() as ResolvedSuspenseData;

  const { value: editableDocuments } = useLightweightTransaction<PurchaserEditableDocument[]>({
    myPath: 'editableDocuments'
  });
  const { value: submittedDocuments } = useLightweightTransaction<PurchaserSubmittedDocument[]>({
    myPath: 'submittedDocuments'
  });

  const userDocumentsCount = (editableDocuments?.length??0) + (submittedDocuments?.length??0);

  const newOfferHandler = async () => {
    const response =await PurchaserPortalApi.postCreateOffer(data.portalId);
    if (!response) return;
    navigate(`offer/${ShortId.fromUuid(response.yDocId)}`);
  };

  const documents = useMemo(() => {
    return data.sharedDocuments.filter(d => !d.old);
  }, [data]);
  const { offerType } = data.settings;

  const noOffers = userDocumentsCount === 0;
  const newOfferButton = <AsyncButton key='new-offer' onClick={newOfferHandler}>{noOffers ? 'Make an offer' : 'New offer'}</AsyncButton>;

  return <DefaultPageLayout
    title='Purchaser portal'
    subtitle={<div className='d-flex'><div>{data.headline}</div><div className='ms-2'></div></div>}
    agencyCssOverrides={data.cssOverrides}
    heroElement={offerType !== 'none' && noOffers && <Hero>
      <h2 className='mb-4'>Make an offer on {data.headline}</h2>
      <div className='px-2'>
        <p>You can prepare and submit an offer for this property right now in your browser.</p>

        <p>We will ask you for details about all of the purchasers, the amount being offered, and any conditions that the offer may have. Afterwards, the purchasers will sign the offer and it will be submitted to the agency for presentation to the vendor.</p>

        {(offerType === SettingsOfferType.offer) && (<p>You will be able to withdraw your submitted offer at any time. If your offer is accepted then it will form the basis of the contract that all of the parties will sign.</p>)}

        {(offerType === SettingsOfferType.contract) && (<p>You will be able to withdraw your submitted offer at any time, prior to it being accepted by the vendor. If accepted by the vendor this contract will be legally binding.</p>)}

        <div className='w-100 d-flex justify-content-center'>{newOfferButton}</div>
      </div>

    </Hero>}
  >

    <PurchaserView
      documents={documents}
      timeZone={data.settings.timeZone || 'Australia/Adelaide'}
      portalId={data.portalId}
      headline={data.headline}
      tableTitleEndButtons={offerType && offerType !== 'none' ? newOfferButton : undefined}
    />
  </DefaultPageLayout>;
}

export interface SortedOfferDocument {
  viewMode: 'view' | 'edit'
  name: string,
  id: string,
  documentId: string;
  status: string,
  statusAtMs: number,
  muted: boolean,
  file?: FileRef
  download?: {
    src: string,
    fileName: string
  }
}

function download(url: string, name: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function PurchaserView({
  documents,
  timeZone,
  portalId,
  headline,
  tableTitleEndButtons
}: {
  documents: PurchaserPortalSharedDocument[],
  timeZone: string,
  portalId: string,
  headline: string,
  tableTitleEndButtons?: React.ReactNode
}) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const previewOnLogin = new URLSearchParams(search).get('doctype');
  const { value: editableDocuments } = useLightweightTransaction<PurchaserEditableDocument[]>({
    myPath: 'editableDocuments'
  });
  const { value: submittedDocuments } = useLightweightTransaction<PurchaserSubmittedDocument[]>({
    myPath: 'submittedDocuments'
  });
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useState<undefined | {offerId: string}>(undefined);

  const offers = useMemo<SortedOfferDocument[]>(() => {
    return (editableDocuments || []).map<SortedOfferDocument>(doc => ({
      viewMode: 'edit',
      status: doc.status,
      statusAtMs: doc.statusAtMs,
      name: doc.name,
      id: doc.id,
      documentId: doc.documentId,
      muted: doc.status === PurchaserEditableDocumentStatus.Cancelled
    }))
      .concat((submittedDocuments || []).map<SortedOfferDocument>(doc => {
        const fileName = getSubmittedDocumentFileName(headline, doc);
        return ({
          viewMode: 'view',
          status: doc.status,
          statusAtMs: doc.statusAtMs,
          name: doc.name,
          id: doc.id,
          documentId: doc.documentId,
          muted: doc.status === PurchaserSubmittedDocumentStatus.Withdrawn,
          file: getSubmittedDocumentFileId(doc),
          download: {
            src: PurchaserPortalApi.getPurchaserPortalSubmittedDocumentUrl({
              portalId,
              documentId: doc.documentId,
              fileName
            }),
            fileName
          }
        });
      }))
      .sort((a, b) => byNumericValueDesc(a.statusAtMs, b.statusAtMs));
  }, [editableDocuments, submittedDocuments]);

  useEffect(()=>{
    if (!(previewOnLogin && Array.isArray(documents))) return;
    const previewTarget = documents.find(d=>d.documentType === previewOnLogin);
    if (previewTarget) {
      navigate(`document/${ShortId.fromUuid(previewTarget.documentId)}`);
    }
  }, [Array.isArray(documents) && documents.length > 0, !!previewOnLogin]);

  const isXs = useMediaQuery({ maxWidth: BP_MINIMA.sm });

  return <ContentCard agencyHighlights={true}>
    <Card.Header className='d-flex flex-row justify-content-between align-items-center'>
      <h4>Documents</h4>
      {tableTitleEndButtons && <div className='d-flex flex-row justify-content-between align-items-center children-spacing-rem'>{tableTitleEndButtons}</div>}
    </Card.Header>
    <Card.Body>
      {showConfirmWithdraw && <ConfirmWithdrawModal
        portalId={portalId}
        offerId={showConfirmWithdraw.offerId}
        onClose={() => setShowConfirmWithdraw(undefined)} />}
      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th style={{ width: '16px', maxWidth: '16px' }}></th>
          </tr>
        </thead>
        <tbody>
          {offers.map(item => {
            return (
              <tr key={`offer-${item.id}`} className={clsJn(item.muted && 'text-light', 'cursor-pointer')}>
                <td style={{ borderBottom: '0' }}
                  onClick={() => navigate(`offer/${ShortId.fromUuid(item.documentId)}`)}>
                  <b>{item.name}</b>
                </td>
                <td className='border-0 d-flex flex-column'
                  onClick={() => navigate(`offer/${ShortId.fromUuid(item.documentId)}`)}>
                  <b>{item.status}</b>
                  <small>{formatTimestamp(item.statusAtMs, timeZone, false)}</small>
                </td>
                <td style={{ borderBottom: '0' }}>
                  <Dropdown align='end'>
                    <Dropdown.Toggle className='clear-after' variant='white'>
                      <Icon name='more_vert' icoClass={clsJn(item.muted && 'text-light')}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate(`offer/${ShortId.fromUuid(item.documentId)}`)}>
                        {item.viewMode === 'view' ? 'View' : 'Edit'}
                      </Dropdown.Item>
                      {item.viewMode === 'view' && <>
                        {item.download && <Dropdown.Item
                          key='download'
                          as='a'
                          href={item.download.src}
                          download={item.download.fileName}
                        >Download</Dropdown.Item>}
                        {item.status === PurchaserSubmittedDocumentStatus.Submitted &&
                          <Dropdown.Item
                            onClick={() => setShowConfirmWithdraw({ offerId: item.documentId })}>Withdraw</Dropdown.Item>}
                        {item.status === PurchaserSubmittedDocumentStatus.Withdrawn &&
                          <Dropdown.Item
                            onClick={() => PurchaserPortalApi.postPurchaserPortalResubmitOffer(portalId, item.documentId)}>Resubmit</Dropdown.Item>}
                      </>}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
          {documents.map((item, idx) => {
            const desc = DocumentDescriptions[item.documentType];
            const name = (isXs ? desc?.mobileTitle : null) ?? desc?.title ?? item.name;
            const summary = (isXs ? desc?.mobileSummary : null) ?? desc?.summary;
            return <tr key={`doc-${idx}`} className='cursor-pointer'>
              <td className='border-0' onClick={() => navigate(`document/${ShortId.fromUuid(item.documentId)}`)}>
                <b>{name}</b>
                <div className='text-secondary'><small>{summary}</small></div>
              </td>
              <td className='border-0' onClick={() => window.open(item.url, '_blank')}>
                <b>{item.status}</b>
                <div><small>{formatTimestamp(item.timestamp, timeZone, false)}</small></div>
              </td>
              <td style={{ borderBottom: '0' }}>
                <Dropdown align='end'>
                  <Dropdown.Toggle className='clear-after' variant='white'>
                    <Icon name='more_vert'/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate(`document/${ShortId.fromUuid(item.documentId)}`)}>View</Dropdown.Item>
                    <Dropdown.Item onClick={() => download(item.url, item.fileName)}>Download</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>;
          })}
        </tbody>
      </Table>
    </Card.Body>
  </ContentCard>;
}
