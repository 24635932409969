import { bind, Binder, Snapshot } from 'immer-yjs';
import { useEffect, useMemo } from 'react';
import * as Y from 'yjs';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';
import { Maybe } from '@property-folders/contract';
import { MaybeUpdateFn } from '@property-folders/common/types/MaybeUpdateFn';

export type BinderFn<T extends Snapshot> = Maybe<Binder<T>>;
export type SelectorFn<TRoot extends Snapshot, TSelection extends Snapshot> = (state: TRoot) => Maybe<TSelection>;

interface BindStateResult<TRoot extends Snapshot, TSelection extends Snapshot> {
  data?: TSelection;
  update: MaybeUpdateFn<TRoot>;
}

interface ImmerYjsResult<TRoot extends Snapshot> {
  status: 'pending' | 'success';
  bindState: <TSelection extends Snapshot,>(selector: SelectorFn<TRoot, TSelection>) => BindStateResult<TRoot, TSelection>
  binder: BinderFn<TRoot>;
}

/**
 * Build an immer-yjs binding to a root property on a yjs doc.
 * The binding can then be used to extract child information from that root property,
 * and perform updates to that root property.
 * @param yDoc the yjs doc to bind to
 * @param key the name of the root property on the yjs doc
 */
export function useImmerYjs<TRoot extends Snapshot>(yDoc: Y.Doc | undefined, key: string): ImmerYjsResult<TRoot> {
  const result = useMemo((): ImmerYjsResult<TRoot> => {
    if (!yDoc) {
      return {
        status: 'pending',
        binder: undefined,
        // returning a void func just improves the ergonomics a little.
        // useImmerYjs(...).bindState?.(...) || {}
        // becomes
        // useImmerYjs(...).bindState(...)
        bindState: () => ({ update: undefined })
      };
    }

    const binder = bind<TRoot>(yDoc.getMap(key));

    return {
      status: 'success',
      binder,
      bindState: <TSelection extends Snapshot,>(selector: SelectorFn<TRoot, TSelection>): { data?: TSelection, update: MaybeUpdateFn<TRoot>} => {
        const data = useSyncExternalStoreWithSelector(
          binder.subscribe,
          binder.get,
          binder.get,
          selector,
        );

        return {
          data,
          update: binder.update
        };
      }
    };
  }, [yDoc, key]);

  // clean up the binder when the calling component unmounts
  useEffect(() =>{
    return () => {
      if (!result.binder) {
        return;
      }

      result.binder.unbind();
    };
  }, [result.bindState]);

  return result;
}
