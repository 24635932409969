import { MaterialisedPropertyData } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { uuidv4 } from 'lib0/random';

/**Sets tenant data in the old location based on primary tenant in new list
 *
 */
export const tenantBackporting: FieldGroupFn = (fieldId, updatedPath, immerProxy, variationsSnapshot, history) => {
  const draft = immerProxy as MaterialisedPropertyData;
  if (!draft) return;

  // Is there any data to copy?
  if (!Array.isArray(draft.tenantsCollect)) return;
  if (draft.tenantsCollect.length === 0) return;

  if (!draft.saaTenant) draft.saaTenant = {};
  if (!draft.saaTenant?.migrationLinkingTenantId) {
    if (!draft.tenantsCollect[0].id) {
      draft.tenantsCollect[0].id = uuidv4();
    }
    draft.saaTenant.migrationLinkingTenantId = draft.tenantsCollect[0].id;
  }

  const primaryId = draft.saaTenant.migrationLinkingTenantId;
  if (!primaryId) return;

  const matchedPorter = draft.tenantsCollect?.find(t=>t.id === primaryId) ?? {};
  if (!matchedPorter) return;
  const { id: _id, ...toClone } =  matchedPorter;

  Object.assign(draft.saaTenant, toClone);
};
