import * as Y from 'yjs';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { v4 } from 'uuid';
import { useTimeout } from '../hooks/useTimeout';
import { UPDATED_PERSIST_TIME, UPDATING_FLASH_TIME } from '@property-folders/common/data-and-text/constants';

export type NetState = 'saving' | 'saved' | 'updating' | 'updated';

export interface INetStateReadContext {
  netStateIndicators: NetState[],
}

export interface INetStateWriteContext {
  addPath: (path: string) => void,
  removePath: (path: string) => void
}

export const NetStateReadContext = createContext<INetStateReadContext>({
  netStateIndicators: []
});

export const NetStateWriteContext = createContext<INetStateWriteContext>({
  addPath: () => { /**/ },
  removePath: () => { /**/ }
});

export function SetupNetStateContext(
  {
    children,
    ydoc,
    transactionRootKey
  }: {
    children: ReactNode,
    ydoc?: Y.Doc,
    transactionRootKey?: string
  }
) {
  const [pathDebounceSet, setPathDebounceSet] = useState<Set<string>>(new Set);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updatingTimeoutId, setUpdatingTimeoutId] = useState<string | null>(null);

  useTimeout(()=>{
    setUpdating(false);
    setUpdated(false);
    setUpdatingTimeoutId(null);
  }, UPDATING_FLASH_TIME + UPDATED_PERSIST_TIME, updatingTimeoutId);

  useEffect(() => {
    if (!ydoc) return;
    if (!transactionRootKey) return;

    const currentDocMap = ydoc.getMap(transactionRootKey);
    const changeMonitoringFunction = (evts: Y.YEvent<any>[]) => {
      const remoteEvent = evts.map(e=>!e.transaction?.local).reduce((acc,cv)=>acc&&cv);
      if (remoteEvent) {
        setUpdated(false);
        setUpdating(true);
        setUpdatingTimeoutId(v4());
      }
    };
    currentDocMap.observeDeep(changeMonitoringFunction);

    return () => currentDocMap.unobserveDeep(changeMonitoringFunction);
  }, [!!ydoc, transactionRootKey]);

  useEffect(() => {
    if (pathDebounceSet.size === 0) {
      setSaving(false);
    } else {
      setSaving(true);
    }
  }, [pathDebounceSet]);

  const writeContext = useMemo<INetStateWriteContext>(() => {
    return {
      addPath: (path: string) => setPathDebounceSet(ps=>{
        if (ps.has(path)) return ps;

        const r=new Set(ps);
        r.add(path);
        return r;
      }),
      removePath: (path: string) => setPathDebounceSet(ps=>{
        if (!ps.has(path)) return ps;

        const r=new Set(ps);
        r.delete(path);
        return r;
      })
    };
  }, []);

  const readContext = useMemo<INetStateReadContext>(() => {
    return {
      netStateIndicators: [
        saving ? 'saving' : 'saved',
        updated ? 'updated' : undefined,
        updating ? 'updating' : undefined
      ].filter(Predicate.isNotNull) as NetState[]
    };
  }, [saving, updated, updating]);

  return (
    <NetStateWriteContext.Provider value={writeContext}>
      <NetStateReadContext.Provider value={readContext}>
        {children}
      </NetStateReadContext.Provider>
    </NetStateWriteContext.Provider>
  );
}

export function ShowNetStateIndicators() {
  const { netStateIndicators } = useContext(NetStateReadContext);

  return <>
    {(netStateIndicators.includes('updated') || netStateIndicators.includes('updating')) && <div>
      <div><i className="bi bi-cloud-download">&nbsp;</i>{netStateIndicators.includes('updating') ? 'Updating' : 'Updated!'}</div>
    </div>}
    {(!netStateIndicators.includes('updated') && !netStateIndicators.includes('updating') && (netStateIndicators.includes('saved') || netStateIndicators.includes('saving'))) && <div>
      {netStateIndicators.includes('saving') ? <div><i className="bi bi-cloud-upload">&nbsp;</i>Saving</div> : 'Saved'}
    </div>}
  </>;
}
