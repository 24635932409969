import { Predicate } from '../predicate';
import { db } from './db';
import { TransactionMetaData } from '@property-folders/contract';
import { PropertyDoc } from './propertyDoc';

export interface IOfflineProperty {
  id: string,
  agentId: number,
  lastAccessed?: number,
  created?: number,
  keywords: string[],
  headline: string,
  address: string,
  agents?: string[],
  vendors?: string[],
  meta?: TransactionMetaData;
  sublineageMeta?: {[sublineageId: string]: TransactionMetaData}
}

export interface ISearchScoredOfflineProperty extends IOfflineProperty {
  score?: number
}

export class OfflineProperties {
  static async write(id: string, agentId: number, entry: IOfflineProperty) {
    if (!id) {
      return;
    }

    await db.transaction('rw', db.agentOfflineProperties, async () => {
      const existing = await db.agentOfflineProperties.get([id, agentId]);
      if (existing) {
        entry.lastAccessed = existing.lastAccessed;
        entry.created = existing.created;
      }

      await db.agentOfflineProperties.put(entry);
    });
  }

  static async setLastAccessed(id: string, agentId: number, timestamp: number) {
    // will do nothing if a record doesn't exist, which we want.
    await db.agentOfflineProperties.update([id, agentId], {
      lastAccessed: timestamp
    });
  }

  static async delete(id: string, agentId: number) {
    const count = await db.transaction('rw', db.agentOfflineProperties, async () => {
      await db.agentOfflineProperties.delete([id, agentId]);
      return await db.agentOfflineProperties.where('id').equals(id).count();
    });

    // indexeddb transactions are at the db level.
    // this is a different db, ergo it cannot be deleted within the above transaction
    if (count === 0) {
      await PropertyDoc.deleteForce(id);
    }
  }

  static async read(id: string, agentId: number) {
    return db.agentOfflineProperties.get([id, agentId]);
  }

  static async all(agentId: number) {
    return db.agentOfflineProperties.filter(p => p.agentId === agentId).toArray();
  }
  static async search(searchTerm: string): Promise<ISearchScoredOfflineProperty[]> {
    if (!searchTerm) {
      return db.agentOfflineProperties.toArray();
    }

    const terms = (searchTerm
      .match(/(\w+)/g)??[])
      .map(s => s.trim())
      .filter(Predicate.isNotNull);

    // Start by performing a dexie OR search
    let results = await db.agentOfflineProperties
      .where('keywords').startsWithAnyOfIgnoreCase(terms)
      .toArray();

    // filter it further
    results = results.filter(result => {
      for (let term of terms) {
        term = term.toLowerCase();
        if (isNaN(Number(term))) {
          let found = false;
          for (const keyword of result.keywords) {
            if (keyword.startsWith(term)) {
              found = true;
              break;
            }
          }
          if (!found) {
            return false;
          }
        } else {
          if (!result.keywords.includes(term)) return false;
        }
      }
      // Having not exited, we expect this means all terms have been found. A more defensive method
      // would be to do a terms.map() and expect all to be true, but this would be more performant,
      // as this is pretty n^2 (maybe also *m as well)... We rely on this already being prefiltered
      // to reduce n and thus the search space, so that this doesn't get too crazy. Hmm.
      // In terms of complexity, we could probably define a rough limit of complexity, as the 3
      // terms are, count of keywords in property file, count of terms in search box, and number of
      // properties stored offline. Rough upper limits of each would be, 15 key words per file,
      // 5 search terms, and 50 properties stored offline. So 3,750 fairly simple compare
      // operations for a search, which I think is fairly acceptable.

      return true;
    });

    const resultScoreMap = new Map<string, { count: number, value: IOfflineProperty }>();
    for (const result of results) {
      const match = resultScoreMap.get(result.id);
      if (match) {
        match.count++;
      } else {
        resultScoreMap.set(result.id, { count: 1, value: result });
      }
    }

    return [...resultScoreMap.values()].map(v => ({
      ...v.value,
      score: v.count
    }));
  }

  static async count(agentId: number) {
    return await db.agentOfflineProperties.where('agentId').equals(agentId).count();
  }
}
