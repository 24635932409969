export function tryParseInt(value: string | undefined,  def: undefined): number | undefined;
export function tryParseInt(value: string | undefined,  def: number): number;
export function tryParseInt(value: string | undefined,  def: number | undefined): number | undefined {
  if (!value) {
    return def;
  }

  const parsed = parseInt(value, 10);

  if (parsed == null || isNaN(parsed)) {
    return def;
  }

  return parsed;
}

export function setMutateIfAdd<T>(set: Set<T>, item: T): Set<T> {
  if (set.has(item)) return set;

  const result = new Set(set);
  result.add(item);
  return result;
}

export function setMutateIfDelete<T>(set: Set<T>, item: T): Set<T> {
  if (!set.has(item)) return set;

  const result = new Set(set);
  result.delete(item);
  return result;
}

export function mapMutateIfSet<TKey, TValue>(map: Map<TKey,TValue>, key: TKey, value: TValue, eq?: (a: TValue, b: TValue) => boolean): Map<TKey,TValue> {
  const current = map.get(key);
  if (current === value) return map;
  if (eq && current && eq(current, value)) return map;

  const result = new Map(map);
  result.set(key, value);
  return result;
}

export function mapMutateIfDelete<TKey, TValue>(map: Map<TKey,TValue>, key: TKey): Map<TKey,TValue> {
  if (!map.has(key)) return map;

  const result = new Map(map);
  result.delete(key);
  return result;
}
