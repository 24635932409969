import { globalFieldGroups } from '../field';
import { ValidationDefnType } from '../validation-field';

export const signingValidationRules: ValidationDefnType = {
  _complexValidators: ['validateSigningRequirements']
};

export const fieldGroups = {
  ...globalFieldGroups,
  'otherContacts.[].partyType': 'partyResetActivity',
  'otherContacts.[].authority': 'partyResetActivity',
  'otherContacts.[].inTrust': 'partyResetActivity',
  'otherContacts.[].registeredOnTitle': 'partyResetActivity'
};
