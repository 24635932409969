import { StatusCodeError } from './statusCodeError';

const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export class WrappedFetch {
  public static async json<T>(input: RequestInfo | URL, init?: RequestInit): Promise<T | undefined> {
    const response = await this.bare(input, init);

    return <T>(await response.json());
  }

  public static async jsonWithDate<T>(input: RequestInfo | URL, init?: RequestInit): Promise<T | undefined> {
    const response = await this.bare(input, init);

    const responseText = (await response.text());
    if (!responseText) {
      return;
    }

    return JSON.parse(responseText, (key: string, value: any) => {
      if (typeof value === 'string') {
        if (value.match(dateRegex)) {
          return new Date(value);
        }
      }

      return value;
    });
  }

  public static async bytes(input: RequestInfo | URL, init?: RequestInit) {
    const response = await this.bare(input, init);

    return await response.arrayBuffer();
  }

  public static async bare(input: RequestInfo | URL, init?: RequestInit) {
    const response = await fetch(input, init);

    if (response.status > 399) {
      const err = new StatusCodeError(response.status);

      try {
        err.setMessage(JSON.parse(await response.text()).message);
      } catch {
        // Generic handler, we don't want an error on an error, just construct a generic error with
        // the status code, as it was before.
      }

      throw err;
    }

    return response;
  }
}
