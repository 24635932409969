import {
  AccompanyingObjects,
  AddressParts,
  Agent,
  Annexure,
  AuthorityParty,
  AgentSessionInfoResult,
  CustomFieldConfiguration,
  FileRef,
  FormCode,
  FormCodeUnion,
  FormFamilyState,
  FormInstance,
  FormInstanceSigning,
  FormOrderState,
  FormSigningState,
  FormStates,
  PortalSessionInfoResult,
  InstanceHistory,
  MaterialisedPropertyData,
  PartyType,
  SessionInfo,
  SignedForm,
  SigningAuthorityType,
  SigningInitiator,
  SigningOrderSettingsItem,
  SigningParty,
  SigningPartySnapshot,
  SigningPartySource,
  SigningPartySourceType,
  SigningPartyType,
  SigningSessionField,
  SigningSessionFieldType,
  SigningSessionOrderItem,
  SigningSessionSubType,
  TransactionMetaData,
  VendorParty,
  FormOrderType,
  SigningSessionOrderParty, OtherContactParty
} from '@property-folders/contract';
import { customFieldMetas } from '@property-folders/contract/property/meta';
import { Maybe } from '../types/Utility';
import { Binder, Snapshot } from 'immer-yjs/src/immer-yjs';
import { v4 } from 'uuid';
import {
  fieldIsSigned,
  FormTypes,
  getCompletedFiles,
  getFirstOrderedParty,
  getInvolvedSigningParties,
  mapSigningPartySourceTypeToCategory,
  PartyCategory
} from '../yjs-schema/property/form';
import {
  emailSubject,
  FormDescriptorParty,
  generateHeadlineFromMaterialisedData,
  getDocumentName
} from '../yjs-schema/property';
import {
  EntitySettingsEntity,
  EntitySettingsSigningOptions
} from '@property-folders/contract/yjs-schema/entity-settings';
import {
  canonicalisers,
  companyTradingAs,
  companyTradingWithFallback,
  formatTimestamp
} from './formatting';
import { populateSigningPhrase } from './formatting/string-composites';
import { forEach, omit } from 'lodash';
import { FileStorage } from '../offline/fileStorage';
import { formCompleted } from './form/formCompleted';
import { getLeastUsedColour, userColours } from './UserColours';
import { AnyAction, Store } from 'redux';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '../redux-reducers/entityMeta';
import { AgentWithAgency } from './pdfgen/sections/signatureSection';
import { CommunicationPreference, jointTypes, PropertyRootKey, SourceRepresentationLevel } from '@property-folders/contract/yjs-schema/property';
import { applyMigrationsV2, MigrationV2_1 } from '../yjs-schema';
import * as Y from 'yjs';
import { byMapperFn } from './sortComparison';
import { Predicate } from '../predicate';
import { uuidv4 } from 'lib0/random';
import { compareFormInstances } from './compareFormInstances';
import { AllDocumentStatus } from '@property-folders/contract/rest/document';
import { PathSegments } from '@property-folders/contract/yjs-schema/model';
import { UserPreferencesMain } from '@property-folders/contract/yjs-schema/user-preferences';
import { formatAct } from './pdfgen/formatters/clauses';
import { LegalJurisdiction } from './pdfgen/constants';

export interface IOutForSigningData {
  sessionId: string;
  baseFile: FileRef;
  accompanyingFileIds: {[Property in keyof AccompanyingObjects]: FileRef};
  initiator: SigningInitiator;
  /**
   * If set, then the signing session is scoped only to those types specified.
   */
  partySourceTypeRestriction?: SigningPartySourceType[]
  portalSource?: {
    portalUserId: string;
    commsPref: CommunicationPreference
  }
  /**
   * Used for mapping custom field ids to newly generated signing field ids
   */
  customFieldsIdMap?: Map<string, string>
  /**
   * Must be set for agent/admin initiated signing. Optional because of portal
   */
  userPrefs?: UserPreferencesMain
  /**
   * Set in concert with userPrefs. If not set, assuming true
   */
  hasRemoteSigning?: boolean,
  memberEntities: BelongingEntityMeta
}

export interface IConfiguringData {
  skipToCustomFields?: boolean,
  forceParties?: FormDescriptorParty[],
  // probably just for testing
  usePartyCategoryOrder?: boolean
}

export type ITransitionSigningStateOpts = {
  to: FormSigningState,
  outForSigningData?: IOutForSigningData
  configuringData?: IConfiguringData
} | {
  to: FormSigningState.Configuring,
  outForSigningData?: Partial<IOutForSigningData>
  configuringData?: IConfiguringData
};

type ExpectedSources = {currentSigningSources: SigningPartySource[], previousSigningSources?: SigningPartySource[]};

function allPartyFieldsSigned(partyId?: string, form?: FormInstance): boolean {
  if (!partyId) {
    return false;
  }

  if (!form?.signing?.session?.fields?.length) {
    return false;
  }

  return form.signing.session.fields
    .filter(f => f.partyId === partyId)
    .every(f => fieldIsSigned(f));
}

export function generateInitiator (
  meta?: TransactionMetaData,
  sessionInfo?: PortalSessionInfoResult | Pick<AgentSessionInfoResult, 'name' | 'email' | 'agentId' | 'type' | 'timeZone' | 'entities'>,
  ese?: EntitySettingsEntity | null
): SigningInitiator {
  switch (sessionInfo?.type) {
    case 'portal':
      return {
        id: 0,
        name: '',
        timeZone: 'Australia/Adelaide',
        email: '',
        entity: {
          id: meta?.entity?.id ||ese?.entityId || 0,
          uuid: undefined,
          name: meta?.entity?.name || ese?.name || ''
        },
        portalUser: {
          name: sessionInfo.name,
          email: sessionInfo.email,
          portalId: sessionInfo.portalId,
          portalUserId: sessionInfo.portalUserId,
          portalType: 'purchaser'
        }
      };
    default: {
      const entityId = meta?.entity?.id;
      const entityUuid = (sessionInfo?.entities || []).find(e => e.entityId === meta?.entity?.id)?.entityUuid;
      return {
        id: sessionInfo?.agentId || 0,
        name: sessionInfo?.name || 'Unknown',
        timeZone: sessionInfo?.timeZone || 'Australia/Adelaide',
        email: sessionInfo?.email || 'misconfigured@email',
        entity: {
          id: entityId || 0,
          uuid: entityUuid,
          name: companyTradingWithFallback(ese?.name, ese?.tradeName) || meta?.entity?.name || 'Unknown'
        }
      };
    }
  }
}

export type FormRestriction =
  | { type: 'existing', formId: string, formCode: string }
  | { type: 'unsigned', formId: string, formCode: string }
  | { type: 'invalid' };

// todo: migrate some stuff to common/yjs-schema/property/form.ts
export class FormUtil {
  public static canAddForm(formStates: FormStates, formCode: string) {
    return FormUtil.getFormRestriction(formStates, formCode) === undefined;
  }

  public static getCreateableFormCodes(formStates: FormStates, formCode: string) {
    return Object.entries(FormTypes)
      .filter(([_, defn]) => defn.formFamily === formCode)
      .map(([formCode]) => formCode)
      .filter(code => (
        FormUtil.canAddForm(formStates ?? {}, code)
      ));
  }

  /**
   * Returns the reason why a form can't be created.
   * If the response is undefined, then the form can be created.
   */
  public static getFormRestriction(formStates: FormStates, formCode: string, sublineageIntent?: boolean): FormRestriction | undefined {
    // todo-future: take into account suggested prior conditions (e.g. primary is signed)
    const formDefn = FormTypes[formCode];
    if (!formDefn) {
      return { type: 'invalid' };
    }

    if (formDefn.subscription || sublineageIntent) {
      // at least for now, we can always create subscription forms and sublineage forms
      return undefined;
    }
    const fam = formDefn?.formFamily;
    const familyInstances = formStates?.[fam]?.instances?.filter(i => !i.archived);
    const familyInstancesExist = Array.isArray(familyInstances) && familyInstances.length > 0;

    if (formDefn.primary) {
      if (familyInstancesExist) {
        // prefer an exact match on code first.
        const existing = (familyInstances.find(x => x.formCode === formCode) || familyInstances[0]);
        return {
          type: 'existing',
          formCode: existing.formCode,
          formId: existing.id
        };
      }

      return undefined;
    }

    if (familyInstancesExist) {
      const incomplete = familyInstances
        .find((i, index) => (index === 0 || i.formCode === formCode) && !formCompleted(i));

      if (incomplete) {
        return { type: 'unsigned', formId: incomplete.id, formCode: incomplete.formCode };
      }

      return undefined;
    }

    // this definition isn't a primary, and there isn't already a primary to evaluate.
    // since under normal circumstances you can't create a non-primary without a primary already there,
    // this situation is invalid.
    return { type: 'invalid' };
  }

  public static getFormPath(formCode: string, formId: string) {
    const formDefn = FormTypes[formCode];
    const fam = formDefn?.formFamily;

    if (!fam) {
      return undefined;
    }

    return `formStates.${fam}.instances.[${formId}]`;
  }

  public static getFamilyInstancesPath(formCode: FormCodeUnion) {
    const formDefn = FormTypes[formCode];
    const fam = formDefn?.formFamily;

    if (!fam) {
      return undefined;
    }

    return `formStates.${fam}.instances`;
  }

  public static getCodeFromFormId(formId: string, propertyMeta: Maybe<TransactionMetaData>) {
    if (!propertyMeta?.formStates) return;
    const formStates = propertyMeta.formStates;

    for (const familyCode of Object.keys(formStates)) {
      const instances = formStates[familyCode].instances || [];
      for (const instance of instances) {
        if (instance.id === formId) {
          return { formFamilyCode: familyCode, instance };
        }
      }
    }

    return undefined;
  }

  public static getFormInstanceFromSigningSession(signingSessionId: string, propertyMeta: Maybe<TransactionMetaData>) {
    if (!propertyMeta?.formStates) return;
    const formStates = propertyMeta.formStates;

    for (const familyCode of Object.keys(formStates)) {
      const instances = formStates[familyCode].instances || [];
      for (const instance of instances) {
        if (instance.signing?.session?.id === signingSessionId) {
          return { formFamilyCode: familyCode, instance };
        }
      }
    }

    return undefined;
  }

  public static getFormStateFromIdAlone(formId: string, propertyMeta: Maybe<TransactionMetaData>): Maybe<FormInstance> {
    return this.getCodeFromFormId(formId, propertyMeta)?.instance;
  }

  public static getFormFamilyState(formCode: string, propertyMeta: Maybe<TransactionMetaData>): Maybe<FormFamilyState> {
    const formDefn = FormTypes[formCode];
    const fam = formDefn?.formFamily;

    if (!fam) {
      return undefined;
    }

    return propertyMeta?.formStates?.[fam];
  }

  public static getForm1Instances(propertyMeta: Maybe<TransactionMetaData>): FormInstance[] {
    const family = this.getFormFamilyState(FormCode.Form1, propertyMeta);
    if (!family?.instances?.length) return [];

    return family.instances.filter(i => {
      if (i.archived) return false;
      return !(i.order && [FormOrderState.None, FormOrderState.ClientOrdering].includes(i.order.state));
    });
  }

  public static getFormInstanceFromFamilyState(familyState: FormFamilyState|undefined, formId: string) {
    if (!familyState) {
      return undefined;
    }

    const instances = familyState.instances;
    if (!instances?.length) {
      return undefined;
    }

    return instances.filter(i => i.id === formId)[0];
  }

  /**
   *
   * @param formCode
   * @param formId
   * @param propertyMeta
   * @param allowUndefinedSubscription allow searching for subscription forms without definitions
   */
  public static getFormState(
    formCode: string,
    formId: string,
    propertyMeta: Maybe<TransactionMetaData>,
    allowUndefinedSubscription?: boolean
  ): Maybe<FormInstance> {
    const familyState = this.getFormFamilyState(formCode, propertyMeta);
    return familyState || !allowUndefinedSubscription
      ? this.getFormInstanceFromFamilyState(familyState, formId)
      : propertyMeta?.formStates?.[formCode].instances?.find(i => i.id === formId);
  }

  public static getAnnexures(formCode: string, formId: string, propertyMeta: Maybe<TransactionMetaData>, opts: { includeRestored?: boolean, includeRemoved?: boolean } = {}): Maybe<Annexure[]> {
    return this.getAnnexuresFromFormInstance(this.getFormState(formCode, formId, propertyMeta), opts);
  }

  public static getAnnexuresFromFormInstance(instance: Maybe<FormInstance>, opts: { includeRestored?: boolean, includeRemoved?: boolean } = {}): Annexure[] {
    return (instance?.annexures || []).filter(annex => {
      if ('_removedMarker' in annex && annex._removedMarker && !opts.includeRemoved) return false;
      if ('_restoredMarker' in annex && annex._restoredMarker && !opts.includeRestored) return false;
      return true;
    });
  }

  public static generateFormInstancePath(formCode: string, formId: string): PathSegments {
    const formDefn = FormTypes[formCode];
    const fam = formDefn?.formFamily;
    return ['formStates', fam, 'instances', `[${formId}]`];
  }

  /**
   * Returns all signed forms for the property
   */
  public static getSignedForms(propertyMeta: Maybe<TransactionMetaData>): SignedForm[] {
    const docs: (SignedForm[]|undefined)[] = [];
    forEach(propertyMeta?.formStates, (v)=> {
      docs.push(v.instances?.filter?.(i=> i.signing?.state === FormSigningState.Signed)
        ?.map<SignedForm>(i=> ({
          name: getDocumentName(i.formCode, i),
          timestamp: formatTimestamp(i.signing?.session?.completedTime??0, i.signing?.session?.initiator.timeZone ?? 'Australia/Adelaide', false),
          id: getCompletedFiles(i.signing?.session).map(c => c.id)
        })));
    });
    return docs.flat().filter(f=> f) as SignedForm[];
  }

  public static getSigning(formCode: string, formId: string, propertyMeta: Maybe<TransactionMetaData>): Maybe<FormInstanceSigning> {
    const instance = this.getFormState(formCode, formId, propertyMeta);

    if (!instance) {
      return undefined;
    }

    return instance.signing;
  }

  public static getSigningState(formCode: string, formId: string, propertyMeta: Maybe<TransactionMetaData>): FormSigningState {
    return this.getSigning(formCode, formId, propertyMeta)?.state || FormSigningState.None;
  }

  public static getSalespersonSigningSources(property: MaterialisedPropertyData, optimiseForDisplay?: boolean): SigningPartySource[] {
    const useAuthRep = !!property.authRep?.length;
    const sourceAgentArray = useAuthRep
      ? property.authRep
      : property.agent;

    const result: SigningPartySource[] = [];

    for (const agent of sourceAgentArray || []) {
      for (const salesperson of agent.salesp || []) {
        result.push(optimiseForDisplay
          ? {
            id: salesperson.id,
            agencySalesPersonId: salesperson.linkedSalespersonId,
            agencyId: agent.linkedEntityId,
            type: SigningPartySourceType.Salesperson,
            isPopulated: !!salesperson.linkedSalespersonId,
            isAuthRep: useAuthRep ? true : undefined,
            _optimisation: {
              name: salesperson.name,
              email: salesperson.email,
              phone: salesperson.phone,
              searchable: optimisationSearchableText('agent', salesperson.name, salesperson.email, salesperson.phone)
            }
          }
          : {
            id: salesperson.id,
            agencySalesPersonId: salesperson.linkedSalespersonId,
            agencyId: agent.linkedEntityId,
            type: SigningPartySourceType.Salesperson,
            isPopulated: !!salesperson.linkedSalespersonId,
            isAuthRep: useAuthRep ? true : undefined
          });
      }
    }

    return result;
  }

  public static evaluatePartySources(
    parties: AuthorityParty[],
    { formTypeParty, optimiseForDisplay, sublineageId, mainType, primaryId, currentRepresentationArr = [], accessKey, overrideTopLvlId, topLevelPrimaryID }: {
      formTypeParty: FormDescriptorParty,
      optimiseForDisplay?: boolean,
      sublineageId?: string,
      mainType: SigningPartySourceType,
      accessKey: string
      primaryId?: string,
      currentRepresentationArr?: SourceRepresentationLevel[],
      overrideTopLvlId?: string
      topLevelPrimaryID?: string
    }
  ): SigningPartySource[] {
    const result: SigningPartySource[] = [];
    const shouldInclude = (isPrimaryContact: boolean) => {
      switch (formTypeParty.mode) {
        case 'primary-only':
          return isPrimaryContact;
        default:
          return true;
      }
    };

    const category = formTypeParty.type;
    for (const partyIndex in parties) {

      const party = parties[partyIndex];
      const representation: SourceRepresentationLevel[] = [...currentRepresentationArr, { accessKey, position: parseInt(partyIndex), itemId: party.id }];

      if (!shouldInclude(party.id === primaryId)) continue;
      if (jointTypes.includes(party.partyType)) {
        const subExecutorResults = this.evaluatePartySources(party.namedExecutors, {
          accessKey: 'namedExecutors',
          formTypeParty,
          mainType,
          currentRepresentationArr: representation,
          optimiseForDisplay,
          primaryId: party.primaryNamedExecutor,
          sublineageId,
          overrideTopLvlId: overrideTopLvlId??party.id,
          topLevelPrimaryID: primaryId
        });
        result.push(...subExecutorResults);
        continue;
      }

      switch (party.authority) {
        case SigningAuthorityType.directors2:
        case SigningAuthorityType.directorSecretary: {
          if (shouldInclude(party.primarySubcontact !== 1)) {
            const representationHierarchy = [...representation, { accessKey: 'dualPartyPseudoLevel', position: 0 }];
            result.push(optimiseForDisplay
              ? { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!party.fullLegalName, sublineageId, _optimisation: {
                name: party.personName1 || '',
                email: party.email1,
                phone: party.phone1,
                searchable: optimisationSearchableText(category, party.personName1, party.email1, party.phone1)
              } }
              : { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!party.fullLegalName, sublineageId }
            );
          }
          if (shouldInclude(party.primarySubcontact === 1)) {
            const representationHierarchy = [...representation, { accessKey: 'dualPartyPseudoLevel', position: 1 }];
            result.push(optimiseForDisplay
              ? { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!party.fullLegalName, sublineageId, _optimisation: {
                name: party.personName2 || '',
                email: party.email2,
                phone: party.phone2,
                searchable: optimisationSearchableText(category, party.personName2, party.email2, party.phone2)
              } }
              : { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!party.fullLegalName, sublineageId });
          }
          break;
        }
        case SigningAuthorityType.sole:
        case SigningAuthorityType.attorney:
        case SigningAuthorityType.guardian:
        case SigningAuthorityType.authRep: {
          const representationHierarchy = [...representation, { accessKey: 'singlePartyRep', position: 0 }];
          result.push(optimiseForDisplay
            ? { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!party.fullLegalName, sublineageId, _optimisation: {
              name: party.personName1 || '',
              email: party.email1,
              phone: party.phone1,
              searchable: optimisationSearchableText(category, party.personName1, party.email1, party.phone1)
            } }
            : { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!party.fullLegalName, sublineageId }
          );
          break;
        }

        case SigningAuthorityType.attorneyJoint:
        case SigningAuthorityType.guardianJoint:{
          const primaryRep = party.primarySubcontactId;
          for (const repIndex in party.legalRepresentatives ?? []) {
            const rep = (party.legalRepresentatives??[])[repIndex];
            if (!shouldInclude(rep.id === primaryRep)) continue;
            const thisRepLvl: SourceRepresentationLevel = { accessKey: 'legalRepresentatives', position: parseInt(repIndex), itemId: rep.id };
            const representationHierarchy: SourceRepresentationLevel[] = [...representation, thisRepLvl];

            result.push(optimiseForDisplay
              ? {
                id: overrideTopLvlId??party.id,
                type: mainType,
                representationHierarchy,
                isPopulated: !!rep.fullLegalName,
                sublineageId,
                _optimisation: {
                  name: rep.fullLegalName || '',
                  email: rep.email,
                  phone: rep.phone,
                  searchable: optimisationSearchableText(category, rep.fullLegalName, rep.email, rep.phone)
                }
              }
              : { id: overrideTopLvlId??party.id, type: mainType, representationHierarchy, isPopulated: !!rep.fullLegalName, sublineageId }
            );
          }
          break;
        }
        default:
          result.push(optimiseForDisplay
            ? {
              id: overrideTopLvlId??party.id,
              type: mainType,
              overrideType: (party as OtherContactParty).overridePartyCategory,
              originalType: (party as OtherContactParty).originalType,
              representationHierarchy: representation,
              isPopulated: !!party.fullLegalName,
              sublineageId,
              _optimisation: {
                name: party.fullLegalName || '',
                email: party.email1,
                phone: party.phone1,
                searchable: optimisationSearchableText(category, party.fullLegalName, party.email1, party.phone1)
              }
            }
            : {
              id: overrideTopLvlId??party.id,
              type: mainType,
              overrideType: (party as OtherContactParty).overridePartyCategory,
              originalType: (party as OtherContactParty).originalType,
              representationHierarchy: representation,
              isPopulated: !!party.fullLegalName,
              sublineageId
            });
      }
    }

    return result;
  }

  public static getPartySigningSources(
    property: MaterialisedPropertyData,
    modelKey: Extract<keyof MaterialisedPropertyData, 'vendors' | 'purchasers' | 'otherContacts'>,
    formTypeParty: FormDescriptorParty,
    optimiseForDisplay?: boolean,
    sublineageId?: string
  ): SigningPartySource[] {
    let selfType= SigningPartySourceType.Error;
    let primaryId: string | undefined = undefined;
    switch (modelKey) {
      case 'vendors':
        selfType = SigningPartySourceType.Vendor;
        primaryId = property.primaryVendor;
        break;
      case 'purchasers':
        selfType = SigningPartySourceType.Purchaser;
        primaryId = property.primaryPurchaser;
        break;
      case 'otherContacts':
        selfType = SigningPartySourceType.Other;
        break;
      default: break;
    }
    if (selfType === SigningPartySourceType.Error) {
      console.error('Party key not handled', modelKey);
      return [];
    }
    return this.evaluatePartySources(property?.[modelKey]|| [], {
      formTypeParty,
      optimiseForDisplay,
      sublineageId,
      mainType: selfType,
      accessKey: modelKey,
      primaryId
    });
  }

  static getSourceCategories(parties: FormDescriptorParty[], property: MaterialisedPropertyData) {
    const result = [];
    for (const party of parties) {
      switch (party.type) {
        case 'agent':
          result.push(...this.getSalespersonSigningSources(property));
          break;
        case 'vendor':
          result.push(...this.getPartySigningSources(property, 'vendors', party));
          break;
        case 'purchaser':
          result.push(...this.getPartySigningSources(property, 'purchasers', party));
          break;
        case 'other':
          result.push(...this.getPartySigningSources(property, 'otherContacts', party));
          break;
      }
    }
    return result;
  }

  static getCustomSourceCategories(parties: SigningParty[] | undefined, property: MaterialisedPropertyData) {
    if (!parties?.length) return [];
    const categories = new Set<PartyCategory>((parties || [])
      .map(p => mapSigningPartySourceTypeToCategory(p.source.type))
      .filter(Predicate.isNotNull));
    const availableSources = this.getSourceCategories(
      [...categories.values()].map(c => ({ type: c })),
      property);

    const result = availableSources.filter(source => {
      return !!parties.find(p => FormUtil.sourcesMatch(p.source, source));
    });

    return result;
  }

  public static getExpectedSigningSources(
    formCode: string,
    property: MaterialisedPropertyData,
    history?: InstanceHistory,
    signing?: FormInstanceSigning,
    forceParties?: FormDescriptorParty[],

  ): ExpectedSources {

    const performSignerDiff = !!FormTypes[formCode].isVariation;

    const instList = history?.instanceList;
    const latestInstance = instList && instList[instList.length-1];
    const latestSnapshot = instList && history?.data?.[latestInstance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id??''];
    const doDiffing = performSignerDiff && latestSnapshot;

    const currentSigningSources: SigningPartySource[] = [];

    let workingCurrentSources = !forceParties && FormTypes[formCode].isCustomisable
      ? this.getCustomSourceCategories(
        signing?.parties,
        property)
      : this.getSourceCategories(
        forceParties || FormTypes[formCode].parties || [],
        property);
    let previousSigningSources: SigningPartySource[]|undefined;

    if (doDiffing) {
      previousSigningSources = latestInstance?.signing?.parties?.map(party => party.source);
      workingCurrentSources = workingCurrentSources
        .map(current=>{
          let found = !!previousSigningSources?.find(oldParty=> current.id===oldParty.id);
          if (current.type === SigningPartySourceType.Salesperson) {
            found = !!previousSigningSources?.find(oldParty=> oldParty.type === SigningPartySourceType.Salesperson && current.agencyId===oldParty.agencyId);
          }

          const result = ({
            added: !found,
            ...current
          });
          return result;
        });
    }
    currentSigningSources.push(...workingCurrentSources);
    const result: ExpectedSources = { currentSigningSources };

    if (!(FormTypes[formCode].isVariation && instList && instList.length > 0) || !previousSigningSources) {
      return result;
    }
    // Current result list should include added and carrying over parties. So let's review the
    // previous snapshot for any parties which do not exist

    if (!doDiffing) {
      return result;
    }

    const removedSigningSources = previousSigningSources
      .filter(prevParty=>{
        if (prevParty.isRemoving) {
          // If it already has isRemoving, that means it was already removed in the previous signing
          // we shan't remove it again
          return false;
        }

        let found = currentSigningSources.find(newParty=>newParty.id===prevParty.id);
        if (prevParty.type === SigningPartySourceType.Salesperson) {
          found = currentSigningSources?.find(newParty=> newParty.type === SigningPartySourceType.Salesperson && prevParty.agencyId===newParty.agencyId);
        }
        return !found;
      })
      .map(prevParty=>({ ...prevParty, isRemoving: true }));

    result.previousSigningSources = removedSigningSources;
    return result;
  }

  public static getAuthorityPartySnapshot(signer: Pick<SigningParty, 'source'>, party: AuthorityParty, propertyData: MaterialisedPropertyData, topLevelPrimaryId: string | undefined): SigningPartySnapshot | undefined {
    if (!(signer.source.representationHierarchy?.length > 0)) {
      // Legacy mode
      return {
        name: party.fullLegalName || '',
        signingPartyIdentifier: party.fullLegalName || '',
        partyIdentifier: party.fullLegalName || '',
        originalPartySourceId: party.id,
        filledSigningPhrase: populateSigningPhrase(party),
        phone: party.phone1 || '',
        email: party.email1 || '',
        isPrimary: topLevelPrimaryId === party.id,
        ...this.getAuthorityPartyAddress(party, propertyData),
        ...this.getAuthorityPartyCompany(party)
      };
    }
    const repList = signer.source.representationHierarchy;
    if (party.id !== repList[0].itemId) {
      console.warn('Top level signer doesn\'t match');
      return;
    }
    let effectiveParty = party;
    let indexer = 1;

    const overrides: {[fieldName: string]: string} = {};
    let isPrimary = topLevelPrimaryId === party.id;
    // We only expect one level of re-embedding in the same sort of structure
    if (repList[indexer]?.accessKey === 'namedExecutors') {
      effectiveParty = party.namedExecutors.find(i=>i.id === repList[indexer].itemId);
      if (!effectiveParty) {
        console.warn('Could not find executor sub party');
        return;
      }
      if (repList[indexer].itemId !== party.primaryNamedExecutor) isPrimary = false;
      indexer++;
      overrides.onBehalfOf = party.onBehalfOf ?? '';
    }

    const rVal = {
      name: effectiveParty.fullLegalName || '',
      signingPartyIdentifier: effectiveParty.fullLegalName || '',
      partyIdentifier: effectiveParty.fullLegalName || '',
      originalPartySourceId: party.id,
      filledSigningPhrase: populateSigningPhrase(effectiveParty),
      phone: effectiveParty.phone1 || '',
      email: effectiveParty.email1 || '',
      isPrimary,
      ...this.getAuthorityPartyAddress(party, propertyData),
      ...this.getAuthorityPartyCompany(effectiveParty)
    };

    if (repList[indexer]?.accessKey === 'legalRepresentatives') {
      const rep = effectiveParty.legalRepresentatives?.find(lr => lr.id === repList[indexer].itemId);
      if (!rep) {
        console.warn('Couldn\'t find representing party');
        return;
      }
      rVal.name = rep.name ?? '';
      rVal.signingPartyIdentifier = rep.name ?? '';
      rVal.phone = rep.phone ?? '';
      rVal.email = rep.email ?? '';
      if (repList[indexer].itemId !== party.primarySubcontactId) isPrimary = false;
      overrides.personName1 = rVal.name;
      indexer++;
    }

    if (
      repList[indexer]?.accessKey === 'dualPartyPseudoLevel'
      && repList[indexer].position !== party.primarySubcontact
    ) {
      isPrimary = false;
    }

    if (
      (repList[indexer]?.accessKey === 'dualPartyPseudoLevel' && repList[indexer].position === 0)
      || repList[indexer]?.accessKey === 'singlePartyRep'
    ) {
      rVal.name = effectiveParty.personName1 ?? '';
      rVal.signingPartyIdentifier = effectiveParty.personName1 ?? '';
      rVal.phone = effectiveParty.phone1 ?? '';
      rVal.email = effectiveParty.email1 ?? '';
      indexer++;
    }

    if (repList[indexer]?.accessKey === 'dualPartyPseudoLevel' && repList[indexer].position === 1) {
      rVal.name = effectiveParty.personName2 ?? '';
      rVal.signingPartyIdentifier = effectiveParty.personName2 ?? '';
      rVal.phone = effectiveParty.phone2 ?? '';
      rVal.email = effectiveParty.email2 ?? '';
      indexer++;
    }

    rVal.isPrimary = isPrimary;

    rVal.filledSigningPhrase =  populateSigningPhrase(effectiveParty, { overrides });
    if (indexer === repList.length) { // ie successfully parsed all segments
      return rVal;
    }
    console.warn('We apparently haven\'t parsed the whole list', { repList, party });
  }

  public static getIndividualSnapshot(party: SigningParty, data: MaterialisedPropertyData, salespersons: AgentWithAgency[], { memberEntities }: {memberEntities: BelongingEntityMeta | null}) {

    let result: SigningPartySnapshot | undefined;
    const effectivePartyId = party.source.representationHierarchy?.length > 0 ? party.source.representationHierarchy[0].itemId : party.source.id;
    switch (party.source.type) {
      case SigningPartySourceType.Salesperson: {
        const salesperson = salespersons.filter(sp => sp.id === effectivePartyId).at(0);
        if (!salesperson) break;
        if (!salesperson.linkedSalespersonId) {
          console.error('salesperson missing linkedSalespersonId!', salesperson);
        }
        const mainAgent = salesperson.agency;
        const linkedAgency = memberEntities && memberEntities[`${mainAgent?.linkedEntityId}`??''];
        const reaName = linkedAgency ? companyTradingAs(linkedAgency.name, linkedAgency.tradeName, { long: true }) : salesperson.agency.company;
        const reaAbnAcnCanon = canonicalisers.abnacn((linkedAgency ? (linkedAgency.abn ?? salesperson.agency.abn) : salesperson.agency.abn)??'');
        const aXnStr = reaAbnAcnCanon.components?.type === 'abn'
          ? 'ABN'
          : reaAbnAcnCanon.components?.type === 'acn'
            ? 'ACN'
            : 'ABN/ACN';
        const partyIdentifier = `${reaName} ${aXnStr} ${reaAbnAcnCanon.display}`;
        result = {
          name: salesperson.name || '',
          signingPartyIdentifier: salesperson.name || '',
          partyIdentifier,
          originalPartySourceId: `${salesperson.agency.linkedEntityId}`,
          email: salesperson.email || '',
          phone: salesperson.phone || '',
          filledSigningPhrase: `Executed by ${salesperson.name} on behalf of ${partyIdentifier} pursuant to section 126 of the ${formatAct(LegalJurisdiction.Commonwealth, 'CorporationsAct2001')}`,
          linkedSalespersonId: salesperson.linkedSalespersonId,
          addressSingleLine: salesperson.agency.address,
          company: salesperson.agency.company,
          abn: salesperson.agency.abn ? canonicalisers.abnacn(salesperson.agency.abn).display : undefined
        };
        break;
      }
      case SigningPartySourceType.Vendor: {
        const vendor = (data.vendors || []).filter(v => v.id === effectivePartyId).at(0);
        if (!vendor) break;
        result = this.getAuthorityPartySnapshot(party, vendor, data, data.primaryVendor);
        break;
      }
      case SigningPartySourceType.VendorFirstParty: {
        // Deprecated. Persists for compatibility with old sessions
        const vendor = (data.vendors || []).filter(v => v.id === effectivePartyId).at(0);
        if (!vendor) break;
        result = {
          name: vendor.personName1 || '',
          signingPartyIdentifier: vendor.personName1 || '',
          partyIdentifier: vendor.fullLegalName || '',
          originalPartySourceId: vendor.id,
          filledSigningPhrase: populateSigningPhrase(vendor),
          phone: vendor.phone1 || '',
          email: vendor.email1 || '',
          isPrimary: data.primaryVendor === vendor.id && vendor.primarySubcontact !== 1,
          ...this.getAuthorityPartyAddress(vendor, data),
          ...this.getAuthorityPartyCompany(vendor)
        };
        break;
      }
      case SigningPartySourceType.VendorSecondParty: {
        // Deprecated. Persists for compatibility with old sessions
        const vendor = (data.vendors || []).filter(v => v.id === effectivePartyId).at(0);
        if (!vendor) break;
        result = {
          name: vendor.personName2 || '',
          signingPartyIdentifier: vendor.personName2 || '',
          partyIdentifier: vendor.fullLegalName || '',
          originalPartySourceId: vendor.id,
          filledSigningPhrase: populateSigningPhrase(vendor),
          phone: vendor.phone2 || '',
          email: vendor.email2 || '',
          isPrimary: data.primaryVendor === vendor.id && vendor.primarySubcontact === 1,
          ...this.getAuthorityPartyAddress(vendor, data),
          ...this.getAuthorityPartyCompany(vendor)
        };
        break;
      }
      case SigningPartySourceType.Purchaser: {
        const purchaser = (data.purchasers || []).filter(v => v.id === effectivePartyId).at(0);
        if (!purchaser) break;
        result = this.getAuthorityPartySnapshot(party, purchaser, data, data.primaryPurchaser);
        break;
      }
      case SigningPartySourceType.PurchaserFirstParty: {
        // Deprecated. Persists for compatibility with old sessions
        const purchaser = (data.purchasers || []).filter(v => v.id === effectivePartyId).at(0);
        if (!purchaser) break;
        result = {
          name: purchaser.personName1 || '',
          signingPartyIdentifier: purchaser.personName1 || '',
          partyIdentifier: purchaser.fullLegalName || '',
          originalPartySourceId: purchaser.id,
          filledSigningPhrase: populateSigningPhrase(purchaser),
          phone: purchaser.phone1 || '',
          email: purchaser.email1 || '',
          isPrimary: data.primaryPurchaser === purchaser.id && purchaser.primarySubcontact !== 1,
          ...this.getAuthorityPartyAddress(purchaser, data),
          ...this.getAuthorityPartyCompany(purchaser)
        };
        break;
      }
      case SigningPartySourceType.PurchaserSecondParty: {
        // Deprecated. Persists for compatibility with old sessions
        const purchaser = (data.purchasers || []).filter(v => v.id === effectivePartyId).at(0);
        if (!purchaser) break;
        result = {
          name: purchaser.personName2 || '',
          signingPartyIdentifier: purchaser.personName2 || '',
          partyIdentifier: purchaser.fullLegalName || '',
          originalPartySourceId: purchaser.id,
          filledSigningPhrase: populateSigningPhrase(purchaser),
          phone: purchaser.phone2 || '',
          email: purchaser.email2 || '',
          isPrimary: data.primaryPurchaser === purchaser.id && purchaser.primarySubcontact === 1,
          ...this.getAuthorityPartyAddress(purchaser, data),
          ...this.getAuthorityPartyCompany(purchaser)
        };
        break;
      }
      case SigningPartySourceType.Other: {
        const other = (data.otherContacts || []).filter(v => v.id === effectivePartyId).at(0);
        if (!other) break;
        result = this.getAuthorityPartySnapshot(party, other, data, '00000000-0000-0000-0000-000000000000');
        break;
      }
      case SigningPartySourceType.OtherFirstParty: {
        // Deprecated. Persists for compatibility with old sessions
        const other = (data.otherContacts || []).filter(v => v.id === effectivePartyId).at(0);
        if (!other) break;
        result = {
          name: other.personName1 || '',
          signingPartyIdentifier: other.personName1 || '',
          partyIdentifier: other.fullLegalName || '',
          originalPartySourceId: other.id,
          filledSigningPhrase: populateSigningPhrase(other),
          phone: other.phone1 || '',
          email: other.email1 || '',
          isPrimary: data.primaryPurchaser === other.id && other.primarySubcontact !== 1,
          ...this.getAuthorityPartyAddress(other, data),
          ...this.getAuthorityPartyCompany(other)
        };
        break;
      }
      case SigningPartySourceType.OtherSecondParty: {
        // Deprecated. Persists for compatibility with old sessions
        const other = (data.otherContacts || []).filter(v => v.id === effectivePartyId).at(0);
        if (!other) break;
        result = {
          name: other.personName2 || '',
          signingPartyIdentifier: other.personName2 || '',
          partyIdentifier: other.fullLegalName || '',
          originalPartySourceId: other.id,
          filledSigningPhrase: populateSigningPhrase(other),
          phone: other.phone2 || '',
          email: other.email2 || '',
          isPrimary: data.primaryPurchaser === other.id && other.primarySubcontact === 1,
          ...this.getAuthorityPartyAddress(other, data),
          ...this.getAuthorityPartyCompany(other)
        };
        break;
      }
    }
    return result;
  }

  static getAuthorityPartyAddress(party: AuthorityParty | VendorParty, data: MaterialisedPropertyData): { addressSingleLine?: string; addressSingleLine_parts?: AddressParts; } {
    if ('addrSameAsSale' in party && party.addrSameAsSale) {
      const addr = data.saleAddrs?.at(0);
      return addr ? {
        addressSingleLine: addr.streetAddr,
        addressSingleLine_parts: addr.streetAddr_parts
      } : { };
    }

    return {
      addressSingleLine: party.addressSingleLine,
      addressSingleLine_parts: party.addressSingleLine_parts
    };
  }

  static getAuthorityPartyCompany(party: AuthorityParty): { company?: string, abn?: string } {
    switch (party.partyType) {
      case PartyType.AdministratorCompany:
      case PartyType.Corporation:
      case PartyType.ExecutorCompany:
      case PartyType.MortgageeCompany:
        return {
          company: party.fullLegalName,
          abn: party.abn ? canonicalisers.abnacn(party.abn).display : undefined
        };
      default:
        return {};
    }
  }

  // todo: move some of these signing things out into a separate utility class?
  public static getSigningSessionSignatureSnapshots(parties: SigningParty[], data: MaterialisedPropertyData, { memberEntities, previousData }: {previousData?: MaterialisedPropertyData, memberEntities: BelongingEntityMeta}) {
    const result = new Map<string, SigningPartySnapshot>();
    const salespersons = this.getAgentsWithAgencies(data);
    const previousSalespersons = this.getAgentsWithAgencies(previousData);

    for (const party of parties) {
      const staging = party?.source?.isRemoving && party?.snapshot
        ? party?.snapshot
        : this.getIndividualSnapshot(
          party,
          previousData && party?.source?.isRemoving
            ? previousData
            : data,
          previousData && party?.source?.isRemoving
            ? previousSalespersons
            : salespersons,
          { memberEntities }
        );
      staging && result.set(party.id, staging);
    }
    return result;
  }

  public static getAgentsWithAgencies(data?: MaterialisedPropertyData): AgentWithAgency[] {
    if (!data) return [];
    return (data.agent ?? [])
      .concat(data.authRep ?? [])
      .flatMap(a => a.salesp
        ? a.salesp.map(sp=>({ ...sp, agency: a }))
        : []
      );
  }

  public static getAgentRecipientEmailAddresses(data?: MaterialisedPropertyData): string[] {
    return [...new Set(
      (data?.agent
        ?.flatMap(a => a.salesp
          ?.map(sp => sp.email) ??[]) ?? [])
        .filter(Predicate.isTruthy)
    )];
  }

  public static sourcesMatch(a: SigningPartySource, b: SigningPartySource) {
    function flattenRepHierarchy(src: SigningPartySource) {
      return (src.representationHierarchy??[]).map(rh => `${rh.accessKey}:${rh.itemId??rh.position}`).join('_');
    }
    const [aFlat, bFlat] = [flattenRepHierarchy(a), flattenRepHierarchy(b)];

    const response = a.id === b.id && a.type === b.type && a.isRemoving === b.isRemoving && a.added === b.added && a.sublineageId === b.sublineageId && aFlat === bFlat;
    return response;
  }

  public static sourcesDontMatch(a: SigningPartySource, b: SigningPartySource) {
    return !this.sourcesMatch(a, b);
  }

  private static removeUnexpectedSigningParties(existingParties: SigningParty[], expectedSources: SigningPartySource[]) {
    if (!existingParties.length) return;
    // since we're in-place manipulating the array, perform the operation from last to first so index remains usable
    let idx = existingParties.length - 1;
    while (idx >= 0) {
      const existing = existingParties[idx];
      if (expectedSources.every(expected => this.sourcesDontMatch(expected, existing.source))) {
        existingParties.splice(idx, 1);
      }
      idx = idx - 1;
    }
  }

  public static addMissingSigningParties(
    existingParties: SigningParty[],
    expectedSources: SigningPartySource[],
    { latestSnapshot, sessionInfo, memberEntities }: {
      latestSnapshot?: MaterialisedPropertyData,
      sessionInfo?: SessionInfo,
      memberEntities: BelongingEntityMeta
  }
  ) {
    const usedColours = existingParties.map(p => p.colour);

    for (const expected of expectedSources) {
      const type = expected.type === SigningPartySourceType.Salesperson ? SigningPartyType.SignInPerson : SigningPartyType.SignOnline;

      const foundExistingParty = existingParties.find(existing =>
        (existing?.source?.isRemoving === expected.isRemoving)
          && !(existing?.source?.isRemoving && !existing?.snapshot?.name)
          && this.sourcesMatch(existing.source, expected));

      if (foundExistingParty) {
        //If the salesperson has changed to/from the logged in user to a different one, update the host type
        if (foundExistingParty.source.type === SigningPartySourceType.Salesperson) {
          if (sessionInfo && 'agentId' in sessionInfo && sessionInfo.agentId === expected.agencySalesPersonId) {
            // type 2 (SignInPerson) should always have an agent ID, right? Maybe it just isn't set
            // yet?
            foundExistingParty.typeHostComposite = SigningPartyType.SignInPerson.toString();
            foundExistingParty.type = SigningPartyType.SignInPerson;
          } else {
            foundExistingParty.typeHostComposite = SigningPartyType.SignOnline.toString();
            foundExistingParty.type = SigningPartyType.SignOnline;
          }
        }
        continue;
      }
      const colour = getLeastUsedColour(usedColours, userColours);
      usedColours.push(colour);

      const newParty: SigningParty = {
        id: v4(),
        source: expected,
        colour,
        type,
        typeHostComposite: type.toString()
      };
      if (latestSnapshot && expected.isRemoving) {
        // In this case, there is no data in the active form to refer to, but the configuration page
        // still expects a path. So, we'll just pre-prepare the snapshot, and make edits to the
        // fields go there.

        const previousSalespersons = this.getAgentsWithAgencies(latestSnapshot);
        newParty.snapshot = this.getIndividualSnapshot(newParty, latestSnapshot, previousSalespersons, { memberEntities });
      }
      existingParties.push(newParty);
    }
  }

  public static addSigningParty(opts: {
    signing: FormInstanceSigning,
    newParties: (Omit<SigningPartySource, 'isRemoving'> & { snapshot: SigningPartySnapshot; signingPartyType?: SigningPartyType })[],
    latestSnapshot: MaterialisedPropertyData
  }) {
    const { signing, newParties, latestSnapshot } = opts;

    if (!signing.parties) {
      signing.parties = [];
    }

    const existingParties = signing.parties;
    const usedColours = existingParties.map(p => p.colour);

    for (const expected of newParties) {
      const defaultSigningType: SigningPartyType = expected.type === SigningPartySourceType.Salesperson
        ? SigningPartyType.SignInPerson
        : SigningPartyType.SignOnline;
      const type: SigningPartyType = expected.signingPartyType ?? defaultSigningType;
      const colour = getLeastUsedColour(usedColours, userColours);
      usedColours.push(colour);

      const newParty: SigningParty = {
        id: v4(),
        source: expected,
        colour,
        type,
        typeHostComposite: type.toString()
      };

      existingParties.push(newParty);
    }
  }

  /**
   * Add missing and remove unexpected signing parties from the configuration.
   * Preserve existing config where it makes sense
   * Note: per-element mutations are required otherwise immer will declare that the whole array has changed.
   *   i.e. immer doesn't do a deep comparison of before/after to determine the changeset
   */
  public static recalculateSigningParties(
    existingParties: SigningParty[],
    expectedSources: SigningPartySource[],
    { latestSnapshot, sessionInfo, memberEntities }: {
      latestSnapshot?: MaterialisedPropertyData,
      sessionInfo?: SessionInfo
      memberEntities: BelongingEntityMeta
    }
  ) {
    this.removeUnexpectedSigningParties(existingParties, expectedSources);
    this.addMissingSigningParties(existingParties, expectedSources, { latestSnapshot, sessionInfo, memberEntities });
  }

  public static instanceIsSubmitted (instance: FormInstance) {
    const expectedSigningPartySourceTypes = instance?.signing?.session?.partySourceTypeRestriction?.length
      ? new Set(instance.signing.session.partySourceTypeRestriction)
      : undefined;
    const allSigningParties = instance.signing?.parties || [];
    const expectedSigningParties = expectedSigningPartySourceTypes
      ? allSigningParties.filter(party => expectedSigningPartySourceTypes.has(party.source.type))
      : allSigningParties || [];

    return allSigningParties.length !== expectedSigningParties.length && expectedSigningParties.every(p => allPartyFieldsSigned(p.id, instance));
  }

  private static ensureBaseSigningConfiguration(instance: FormInstance, state: FormSigningState): FormInstanceSigning {
    if (instance.signing) {
      instance.signing.state = state;
      return instance.signing;
    }

    instance.signing = {
      state: state,
      general: {
        message: '',
        subject: ''
      }
    };
    return instance.signing;
  }

  public static clearSigningSession(signing: FormInstanceSigning) {
    if (signing.session) {
      delete signing.session;
    }
  }

  public static clearSigningPartyResponse(party: SigningParty) {
    delete party.declineReason;
    delete party.declineType;
    delete party.declineTimestamp;
    delete party.lastAccessedTimestamp;
    delete party.linkLastSent;
    delete party.signedTimestamp;
    delete party.lastEmailEventTimestamp;
    delete party.lastEmailEvent;
    if (party.signedPdf) FileStorage.delete(party.signedPdf?.id);
    delete party.signedPdf;
  }

  public static clearSigningPartyResponses(signing: FormInstanceSigning) {
    for (const party of signing.parties || []) {
      this.clearSigningPartyResponse(party);
    }
  }

  private static resetMessages(signing: FormInstanceSigning, formCode: string, dataBinder: Binder<MaterialisedPropertyData>, metadataBinder: Binder<TransactionMetaData>, sessionInfo?: SessionInfo, form?: FormInstance) {
    if (!signing.general) {
      signing.general = { subject: '', message: '' };
    }

    const transRoot = dataBinder.get();
    const documentName = getDocumentName(formCode as FormCodeUnion, form);
    const addressSummary = generateHeadlineFromMaterialisedData(transRoot);
    signing.general.subject = emailSubject(documentName, addressSummary);
  }

  public static clearUnnecessaryCustomFields(signing: FormInstanceSigning) {
    if (!signing.customFields?.length) return;
    const parties = new Map(signing.parties?.map(p => [p.id, p]) || []);
    for (let idx = signing.customFields.length - 1; idx >= 0; idx--) {
      const cf = signing.customFields[idx];
      if (this.shouldRemoveCustomField(cf, parties, Boolean(signing.useSigningOrder), getSigningOrderVersion(signing))) {
        signing.customFields.splice(idx, 1);
      }
    }
  }

  /**
   * transform the contents of signingOrderSettings into individual party order values,
   * and then remove the signingOrderSettings array entirely.
   */
  public static decommissionPartyCategoryOrder(signing: FormInstanceSigning) {
    if (getSigningOrderVersion(signing) !== SigningOrderVersion.Grouped) {
      return;
    }

    const clone = [...signing.signingOrderSettings || []];
    clone.sort(byMapperFn(x => x.order));
    let nextOrderNum = 1;

    clone.forEach((item, index, items) => {
      (signing.parties || [])
        .filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === item.type)
        .sort(byMapperFn(x => x.signingOrderSettings?.order ?? 999))
        .forEach(party => {
          party.signingOrderSettings = {
            order: nextOrderNum,
            auto: item.auto
          };
          nextOrderNum += 1;
        });
    });

    delete signing.signingOrderSettings;
    signing.signingOrderVersion = SigningOrderVersion.Flat;
  }

  /**
   * Assign new order numbers to parties without order numbers.
   * Preserve any existing party order, and then use form type hints to decide order for the rest.
   */
  public static ensureGroupOrPartySigningOrderSettings(signing: FormInstanceSigning, formCode: FormCodeUnion, authRepSigning?: boolean) {
    if (!signing.useSigningOrder) {
      delete signing.signingOrderVersion;
      delete signing.signingOrderSettings;
      for (const party of signing.parties || []) {
        delete party.signingOrderSettings;
      }
      return;
    }

    const form = FormTypes[formCode];

    switch (getSigningOrderVersion(signing)) {
      case SigningOrderVersion.Flat:
        delete signing.signingOrderSettings;
        this.ensurePartySigningOrderPreserveExisting(signing, formCode, authRepSigning);
        break;
      case SigningOrderVersion.Grouped:
      default:
        if (signing.signingOrderSettings === undefined) {
          const formParties = authRepSigning ? form.authRepParties : form.parties;
          if (formParties?.length) {
            signing.signingOrderSettings = formParties.map((p, index) => ({
              id: uuidv4(),
              type: p.type,
              order: index,
              auto: false
            }));
          } else {
            // uploaded docs don't have a default party order, but we could salvage one from the current party list
            const seenCategories = new Set(signing?.parties
              ?.map(p => mapSigningPartySourceTypeToCategory(p.source.type))
              ?.filter(Predicate.isNotNull) || []);
            if (seenCategories.size) {
              signing.signingOrderSettings = [...seenCategories.values()].map((type, order) => ({
                id: uuidv4(),
                type,
                order,
                auto: false
              }));
            }
          }
        }
        break;
    }
  }

  private static ensurePartySigningOrderPreserveExisting(signing: FormInstanceSigning, formCode: FormCodeUnion, authRepSigning?: boolean) {
    let nextOrderNum = 1 + (signing.parties || [])
      .reduce((acc, cur) => cur.signingOrderSettings && cur.signingOrderSettings.order > acc
        ? cur.signingOrderSettings.order
        : acc, -1);
    const form = FormTypes[formCode];
    const partyTypeOrders = new Map<PartyCategory, number>(((authRepSigning ? form?.authRepParties : form?.parties) || [])
      .map((p, index) => [p.type, index]));
    (signing.parties || [])
      .filter(p => !p.signingOrderSettings)
      .sort(byMapperFn(party => {
        const type = mapSigningPartySourceTypeToCategory(party.source.type);
        return (type
          ? partyTypeOrders.get(type)
          : undefined) ?? 99999;
      }))
      .forEach(party => {
        if (party.signingOrderSettings) return;
        party.signingOrderSettings = { order: nextOrderNum, auto: false };
        nextOrderNum += 1;
      });
  }

  private static shouldRemoveCustomField(field: CustomFieldConfiguration, parties: Map<string, SigningParty>, useSigningOrder: boolean, signingOrderVersion: number) {
    if (customFieldMetas[field.type].firstPartyOnly && (!useSigningOrder || signingOrderVersion !== SigningOrderVersion.Flat)) {
      return true;
    }
    if ('partyId' in field && field.partyId) {
      const party = parties.get(field.partyId);
      if (!party) return true;
      if (party.type === SigningPartyType.SignWet) return true;
    }
    return false;
  }

  public static extractLatestSigningPurchasersFromInstances(instances?: FormInstance[]) {
    const latestContractWithSigningParties = [...instances??[]]
      ?.filter(inst => inst.signing?.parties && ![FormSigningState.Configuring, FormSigningState.None].includes(inst.signing?.state)) // Look for sessions which have started, so have parties, and hopefully snapshots
      ?.sort(compareFormInstances)[0]; // We should get newest first, and unsigned first if modification times don't exist
    return {
      purchasers: latestContractWithSigningParties?.signing?.parties?.filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === 'purchaser').filter(Predicate.isNotNull),
      instance: latestContractWithSigningParties
    };
  }

  public static ensureSigningParties(
    signing: FormInstanceSigning,
    formCode: string,
    {
      propertyData,
      dataBinder,
      history,
      sessionInfo,
      forceParties,
      memberEntities
    }: ({
    propertyData?: MaterialisedPropertyData
  }|{
    dataBinder?: Binder<MaterialisedPropertyData>
  }) & {
    history?: InstanceHistory,
    sessionInfo?: SessionInfo,
    forceParties?: FormDescriptorParty[],
    memberEntities: BelongingEntityMeta
  }) {

    const { currentSigningSources, previousSigningSources } = this.getExpectedSigningSources(
      formCode,
      propertyData ?? dataBinder.get(),
      history,
      signing,
      forceParties
    );
    const expected: SigningPartySource[] = [...currentSigningSources, ...(previousSigningSources??[])];
    if (!signing.parties) {
      signing.parties = [];
    }
    const instList = history?.instanceList;
    const latestSnapshot = instList && history?.data?.[instList[instList.length-1]?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id??''];

    this.recalculateSigningParties(signing.parties, expected, { latestSnapshot, sessionInfo, memberEntities });
  }

  public static buildSigningFieldForPartyNonCustomFactory(isSubscriptionForm: boolean) {
    return function(party: SigningParty) {
      return {
        id: v4(),
        partyId: party.id,
        type: SigningSessionFieldType.Signature,
        subtype: isSubscriptionForm
          ? SigningSessionSubType.RenderInfoInline
          : undefined
      };
    };
  }

  /**
   * @param signing A mutable instance which will be modified by this function
   * @param outForSigningData
   * @param dataBinder
   * @param getSublineageData
   * @param isSubscriptionForm
   * @param previousData
   */
  public static ensureSigningSession(
    signing: FormInstanceSigning,
    outForSigningData: Maybe<IOutForSigningData>,
    dataBinder: Binder<MaterialisedPropertyData>,
    { getSublineageData, isSubscriptionForm, previousData }: {isSubscriptionForm?: boolean, previousData?: MaterialisedPropertyData, getSublineageData?: (sublineageId: string) => Maybe<MaterialisedPropertyData>}
  ) {
    if (!outForSigningData) {
      return;
    }
    const { memberEntities } = outForSigningData;
    if (signing.session?.id) {
      return signing.session.id;
    }

    const partiesActuallySigning = getPartyIdsActuallySigning(signing);
    const parties = signing.parties || [];
    const property = dataBinder.get();
    let portalPrimaryPurchaserId: string | null = null;
    if (outForSigningData.portalSource?.commsPref) {
      portalPrimaryPurchaserId = property.primaryPurchaser ?? null;
    }
    const snapshotLoader = new PartySnapshotLoader(
      this.getSigningSessionSignatureSnapshots(parties, property, { previousData, memberEntities }),
      parties,
      memberEntities,
      getSublineageData
    );

    // we need to set ignoreForSigning early because it impacts signing order decisions
    // also, may as well apply the snapshot early too, because of the potential for error throwing
    for (const party of parties) {
      const snapshot = snapshotLoader.getSnapshot(party);
      if (!snapshot) {
        // indicates a programmer error
        throw new Error(`Could not find snapshot for party ${party.id} ${JSON.stringify(party)}`);
      }
      party.snapshot = snapshot;
      if (party.snapshot?.originalPartySourceId === portalPrimaryPurchaserId) {
        party.commsPrefPortal = outForSigningData.portalSource?.commsPref;
      }
      if (partiesActuallySigning.has(party.id)) {
        delete party.ignoreForSigning;
      } else {
        party.ignoreForSigning = true;
      }
      // set it here, well in advance, avoid chances of race conditions down the line perhaps.
      party.serverAcceptPending = true;
      delete party.declineReason;
      delete party.declineType;
    }

    let signingOrder: SigningSessionOrderItem[] | undefined = undefined;
    let partyOrder: SigningSessionOrderParty[] | undefined = undefined;

    switch (getSigningOrderVersion(signing)) {
      case SigningOrderVersion.Flat: {
        if (signing.useSigningOrder) {
          const newPartyOrder = new Array<SigningSessionOrderParty>();
          getInvolvedSigningParties(signing)
            .sort(byMapperFn(p => p.signingOrderSettings?.order ?? 99999))
            .forEach((party, index, parties, ) => {
              if (index === 0) {
                newPartyOrder.push({
                  partyId: party.id,
                  state: 'active',
                  order: index,
                  auto: Boolean(party.signingOrderSettings?.auto)
                });
                delete party.signingOrderBlocked;
              } else {
                newPartyOrder.push({
                  partyId: party.id,
                  state: 'inactive',
                  order: index,
                  auto: Boolean(party.signingOrderSettings?.auto),
                  dependsOn: parties.at(index - 1)?.id
                });
                party.signingOrderBlocked = true;
              }
            });
          partyOrder = newPartyOrder;
        } else {
          parties.forEach(party => delete party.signingOrderBlocked);
        }
        break;
      }
      case SigningOrderVersion.Grouped:
      default: {
        signingOrder = buildSigningSessionOrder(signing);
        const signingOrderDependentPartyCategories = new Set((signingOrder || []).filter(o => o.dependsOn).map(o => o.type));
        const partyOrderDependentPartyIds = new Set((signingOrder || [])
          .flatMap(o => (o.parties || [])
            .map((party, index) => index === 0 ? undefined : party.partyId)
            .filter(Predicate.isNotNull)));

        for (const party of parties) {
          const category = mapSigningPartySourceTypeToCategory(party.source.type);
          if (category && (signingOrderDependentPartyCategories.has(category) || partyOrderDependentPartyIds.has(party.id))) {
            party.signingOrderBlocked = true;
          } else {
            delete party.signingOrderBlocked;
          }
        }

        break;
      }
    }

    const initiator: SigningInitiator = {
      ...(signing.sessionInitiator || outForSigningData.initiator),
      notifyOnSigning: (outForSigningData.userPrefs
        ? (outForSigningData.hasRemoteSigning??true) && !!outForSigningData.userPrefs?.lastNotifyOnRemoteSign
        : null
      ) ?? outForSigningData.initiator.notifyOnSigning,
      portalUser: outForSigningData.initiator.portalUser
    };
    const buildSigningField = this.buildSigningFieldForPartyNonCustomFactory(!!isSubscriptionForm);
    signing.session = {
      id: outForSigningData.sessionId || v4(),
      file: outForSigningData.baseFile,
      associatedFiles: outForSigningData.accompanyingFileIds,
      headline: generateHeadlineFromMaterialisedData(property),
      fields: signing.customFields
        ? this.buildFieldsFromCustom(signing, outForSigningData.customFieldsIdMap)
        : parties.map(buildSigningField),
      initiator: initiator,
      timestamp: Date.now(),
      partySourceTypeRestriction: outForSigningData.partySourceTypeRestriction,
      signingOrder,
      partyOrder
    };

    return signing.session.id;
  }

  /**
   * Update signing order state after a party has signed.
   * @param signing A mutable instance which will be modified by this function
   * @returns applied
   */
  public static applySigningOrderChanges({
    signing
  }: {
    signing: FormInstanceSigning
  }): boolean {
    switch (getSigningOrderVersion(signing)) {
      case SigningOrderVersion.Flat:
        this.applySigningOrderChangesV2({ signing });
        break;
      case SigningOrderVersion.Grouped:
      default:
        this.applySigningOrderChangesV1({ signing });
        break;
    }
  }

  private static applySigningOrderChangesV2({
    signing
  }: {
    signing: FormInstanceSigning
  }) {
    if (!signing.session?.partyOrder?.length) return false;
    const parties = (signing.parties || []);

    for (const partyOrder of signing.session.partyOrder) {
      if (partyOrder.state !== 'inactive') continue;

      const party = parties.find(p => p.id === partyOrder.partyId);
      if (!party) continue;

      const dependsOnParty = partyOrder?.dependsOn
        ? parties.find(p => p.id === partyOrder.dependsOn)
        : undefined;
      if (dependsOnParty && !dependsOnParty.signedTimestamp) continue;

      if (partyOrder.auto) {
        console.log('unblock party', party.snapshot?.name, 'and set party order state to active');
        partyOrder.state = 'active';
        delete party.signingOrderBlocked;
      } else {
        console.log('set party', party.snapshot?.name, 'party order state to ready');
        partyOrder.state = 'ready';
      }
    }
  }

  private static applySigningOrderChangesV1({
    signing
  }: {
    signing: FormInstanceSigning
  }) {
    if (!signing.session?.signingOrder?.length) return false;

    // decide which party categories are active
    for (const signingOrder of signing.session.signingOrder) {
      if (signingOrder.state !== 'inactive') continue;
      const dependsOnParties = (signing.parties || []).filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === signingOrder.dependsOn);
      if (!dependsOnParties.every(party => party.signedTimestamp)) continue;

      if (signingOrder.auto) {
        console.log('set', signingOrder.type, 'group to active');
        signingOrder.state = 'active';
      } else {
        signingOrder.state = 'ready';
        const firstParty = signingOrder?.parties?.at(0);
        if (firstParty) {
          firstParty.state = 'ready';
        }
      }
    }

    // unblock blocked parties which should be unblocked
    for (const signingOrder of signing.session.signingOrder) {
      if (signingOrder.state !== 'active') continue;
      const partiesWhoShouldBeBlocked = new Set<string>();
      signingOrder.parties?.forEach(({ partyId, auto }, index, items) => {
        // the first party in the list depends on nothing
        if (index === 0) return;
        const prev = items[index - 1];
        const prevParty = signing?.parties?.find(p => p.id === prev.partyId);
        if (!prevParty) return;
        if (prevParty.signedTimestamp) return;

        console.log('party', partyId, 'depends on', prevParty, 'which has not signed');
        partiesWhoShouldBeBlocked.add(partyId);
      });

      if (partiesWhoShouldBeBlocked.size) {
        console.log('assembled a list of parties who should be blocked (i.e. category sub-order)', [...partiesWhoShouldBeBlocked.values()]);
      }

      for (const party of signing.parties || []) {
        if (!party.signingOrderBlocked) continue;
        const category = mapSigningPartySourceTypeToCategory(party.source.type);
        if (signingOrder.type !== category) continue;
        if (partiesWhoShouldBeBlocked.has(party.id)) continue;
        if (!signingOrder.parties?.length) {
          // default behaviour
          console.log('unblock', category, 'party', party.snapshot?.name);
          party.signingOrderBlocked = false;
          continue;
        }

        const orderStateParty = signingOrder.parties.find(p => p.partyId === party.id);
        if (!orderStateParty) {
          console.log('unblock', category, 'party', party.snapshot?.name, '- but no order state found');
          party.signingOrderBlocked = false;
          continue;
        }

        if (orderStateParty.auto) {
          console.log('unblock', category, 'party', party.snapshot?.name, 'and set order state to active');
          orderStateParty.state = 'active';
          party.signingOrderBlocked = false;
        } else {
          console.log('set party', party.snapshot?.name, 'order state to ready');
          orderStateParty.state = 'ready';
        }
      }
    }
  }

  public static buildFieldsFromCustom(signing: FormInstanceSigning, customFieldsIdMap: Map<string, string> | undefined): SigningSessionField[] {
    const result: SigningSessionField[] = [];
    if (!signing.customFields?.length) return result;
    if (!customFieldsIdMap) throw new Error('Cannot initiate custom field signing unless a field id map has been provided');
    const parties = getInvolvedSigningParties(signing);
    const firstParty = getFirstOrderedParty(parties, signing);

    for (const customField of signing.customFields) {
      const meta = customFieldMetas[customField.type];
      const signingSessionFieldType = meta.signingSessionFieldType;
      if (!signingSessionFieldType) continue;
      const partyId = 'partyId' in customField && customField.partyId
        ? customField.partyId
        : meta.firstPartyOnly
          ? firstParty?.id
          : undefined;
      if (!partyId) continue;

      const party = parties.find(p => p.id === partyId);
      if (!party) continue;

      let signingFieldId = customFieldsIdMap.get(customField.id);
      if (!signingFieldId) {
        const newId = v4();
        customFieldsIdMap.set(customField.id, newId);
        signingFieldId = newId;
      }

      result.push({
        id: signingFieldId,
        partyId,
        type: signingSessionFieldType,
        subtype: SigningSessionSubType.None,
        customFieldId: customField.id
      });
    }

    return result;
  }

  public static transitionSigningState(
    config: {
      formCode: string,
      formId: string,
      metaBinder: Maybe<Binder<TransactionMetaData>>,
      dataBinder: Maybe<Binder<MaterialisedPropertyData>>,
      history?: InstanceHistory,
      sessionInfo?: PortalSessionInfoResult | Pick<AgentSessionInfoResult, 'type' | 'agentId' | 'name' | 'email'>,
      store: Store<unknown, AnyAction> | undefined // Don't submit actions on this without considering the effects on the prepareForSigning functions. At the moment it seems to be read only
      authRep?: Agent;
      // allow searching for subscription forms without definitions
      allowUndefinedSubscription?: boolean;
      getSublineageData?: (sublineageId: string) => Maybe<MaterialisedPropertyData>
      entitySigningOpts?: EntitySettingsSigningOptions | undefined // Only exclude if transitioning to None or if this is Portal or some other non-agent context
    },
    value: ITransitionSigningStateOpts
  ) {
    const {
      formCode,
      formId,
      metaBinder,
      dataBinder,
      history,
      sessionInfo,
      authRep,
      allowUndefinedSubscription,
      getSublineageData
    } = config;
    if (!(metaBinder && dataBinder)) {
      return;
    }

    const { to, outForSigningData, configuringData } = value;
    if (
      (to === FormSigningState.OutForSigning || to === FormSigningState.OutForSigningPendingUpload)
      && !outForSigningData
    ) {
      throw new Error('Cannot set signing state as OutForSigning without supplying outForSigningData');
    }
    const current = metaBinder.get();

    const instance = this.getFormState(formCode, formId, current, allowUndefinedSubscription);
    if (!instance) {
      return;
    }

    if (instance.signing?.state === FormSigningState.Signed) {
      return;
    }

    if (instance.signing?.state === to) {
      return;
    }

    const instList = history?.instanceList;
    const latestSnapshot = instList && history?.data?.[instList[instList.length-1]?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id??''];

    if (authRep) {
      dataBinder.update(state => {
        if (sessionInfo?.type === 'agent' && !state.authRep) {
          state.authRep = [authRep];
        }
      });
    }

    metaBinder.update(state => {
      const instance = this.getFormState(formCode, formId, state, allowUndefinedSubscription);
      if (!instance) {
        return;
      }

      const signing = this.ensureBaseSigningConfiguration(instance, to);

      if (signing.instanceAutoForm1 == null && config?.entitySigningOpts != null) {
        signing.instanceAutoForm1 = !!config.entitySigningOpts.autoServeForm1;
      }

      switch (to) {
        case FormSigningState.None:
          this.clearSigningSession(signing);
          this.clearSigningPartyResponses(signing);
          delete signing.customiseScreen;
          break;
        case FormSigningState.Configuring:
          this.clearSigningSession(signing);
          this.clearSigningPartyResponses(signing);
          this.resetMessages(signing, formCode, dataBinder, metaBinder, config.sessionInfo, instance);
          this.ensureSigningParties(signing, formCode, {
            dataBinder,
            history,
            sessionInfo: config.sessionInfo,
            forceParties: configuringData?.forceParties
          });
          this.clearUnnecessaryCustomFields(signing);
          // default value is group-based ordering
          if (signing.useSigningOrder === undefined) {
            signing.useSigningOrder = true;
            signing.signingOrderVersion = SigningOrderVersion.Grouped;
          }
          if (configuringData?.skipToCustomFields) {
            signing.customiseScreen = 'fields';
          } else {
            delete signing.customiseScreen;
          }
          if (configuringData?.forceParties) {
            signing.partiesForced = true;
          } else {
            delete signing.partiesForced;
          }

          if (getSigningOrderVersion(signing) === SigningOrderVersion.Flat) {
            this.decommissionPartyCategoryOrder(signing);
          }
          this.ensureGroupOrPartySigningOrderSettings(signing, formCode as FormCodeUnion, instance?.order?.type === FormOrderType.Filler);
          signing.sessionInitiator = generateInitiator(
            metaBinder.get(),
            config.sessionInfo,
            config.store?.getState()?.[entityMetaKey]?.[metaBinder.get().entity?.id.toString() || '']
          );
          break;
        case FormSigningState.OutForSigning:
        case FormSigningState.OutForSigningPendingUpload:
          signing.parties?.forEach(p=>delete p.noVoidNotify);
          this.ensureSigningSession(signing, outForSigningData, dataBinder, { isSubscriptionForm: !!instance.subscription?.documentId, previousData: latestSnapshot, getSublineageData });
          break;
        default:
          break;
      }
    });
  }

  public static getSigningParty(signingPartyId: string, instance: FormInstance) {
    return (instance.signing?.parties || [])
      .filter(sp => sp.id === signingPartyId)[0];
  }

  /**
   * General function for updating targeted part of a ydoc.
   * Note: the selector function is applied twice to avoid applying unnecessary updates to the ydoc.
   * @param binder immer-yjs binder to access ydoc state
   * @param selector select an item within the ydoc, or return undefined if it doesn't exist
   * @param update apply a change to the selected item, if it exists
   * @param predicate optional additional check if the selection exists
   */
  public static applyUpdateOnSelectionIfExists<TRoot extends Snapshot, TSelection>(
    binder: Maybe<Binder<TRoot>>,
    selector: (state: TRoot) => Maybe<TSelection>,
    update: (selection: TSelection) => void,
    predicate?: (selection: TSelection) => boolean
  ) {
    if (!binder) {
      return;
    }

    const preCheckResult = selector(binder.get());
    if (preCheckResult == null) {
      return;
    }

    if (predicate && !predicate(preCheckResult)) {
      return;
    }

    binder.update(state => {
      const preCheckResult = selector(state);

      if (preCheckResult == null) {
        return;
      }

      update(preCheckResult);
    });
  }

  public static getSigningPartySelector(
    formCode: string,
    formId: string,
    signingPartyId: string
  ) {
    return (state: TransactionMetaData) => {
      const instance = this.getFormState(
        formCode,
        formId,
        state);

      if (!instance) {
        return undefined;
      }

      return this.getSigningParty(signingPartyId, instance);
    };
  }

  public static updateSigningParty(
    formCode: string,
    formId: string,
    signingPartyId: string,
    updateSigningPartyFn: (signingParty: SigningParty) => void,
    metaBinder: Maybe<Binder<TransactionMetaData>>
  ) {
    this.applyUpdateOnSelectionIfExists(
      metaBinder,
      this.getSigningPartySelector(formCode, formId, signingPartyId),
      updateSigningPartyFn
    );
  }

  public static getUpdateSigningPartyHandler(
    formCode: string,
    formId: string,
    signingPartyId: string,
    metaBinder: Maybe<Binder<TransactionMetaData>>
  ) {
    return (updateSigningPartyFn: (signingParty: SigningParty) => void) => {
      this.updateSigningParty(formCode, formId, signingPartyId, updateSigningPartyFn, metaBinder);
    };
  }
}

export function generateNewRootKeyMigrationFn (ydoc: Y.Doc, fromMetaRootKey = `${PropertyRootKey.Meta}`, toRootKey?: string, extraOp?: (draft: TransactionMetaData)=>boolean): MigrationV2_1<TransactionMetaData> {
  const parentMeta = ydoc.getMap(fromMetaRootKey).toJSON() as TransactionMetaData;
  return {
    name: 'Set metadata on new sublineage',
    ...(toRootKey ? { docKey: toRootKey } : undefined),
    fn: (draft: TransactionMetaData) => {
      draft.createdUtc = (new Date()).toISOString();
      draft.entity = parentMeta.entity;
      draft.creator = parentMeta.creator;
      return extraOp?.(draft);
    }
  };
}

export function cancelFormOrder(ydoc: Y.Doc | undefined, formCode: string, formId: string) {
  if (!ydoc) {
    return;
  }

  const formType = FormTypes[formCode];
  if (!formType.orderable) {
    return;
  }

  applyMigrationsV2<TransactionMetaData>({
    typeName: 'Property',
    doc: ydoc,
    docKey: PropertyRootKey.Meta,
    migrations: [{
      name: 'delete doc instance',
      fn: draft => {
        const family = FormTypes[formCode].formFamily;
        const instances = draft.formStates?.[family]?.instances;
        if (!instances?.length) return;

        const index = instances.findIndex(fi => fi.id === formId);
        if (index < 0) return;

        //can only cancel an order before its actually ordered
        if ([FormOrderState.None, FormOrderState.ClientOrdering].includes(instances[index].order?.state||FormOrderState.None)) {
          instances.splice(index, 1);
        }
      }
    }]
  });
}

type OrderedSigningParty = Required<Pick<SigningParty, 'signingOrderSettings'>> & SigningParty;
function toOrderedSigningParty(p: SigningParty): OrderedSigningParty | undefined {
  if (!p.signingOrderSettings) return undefined;
  return p as OrderedSigningParty;
}

function buildSigningSessionOrder(signing: FormInstanceSigning): undefined | SigningSessionOrderItem[] {
  if (!signing.useSigningOrder) return undefined;
  if (!signing.signingOrderSettings?.length) return undefined;
  const parties = (signing.parties || []).filter(p => !p.ignoreForSigning);
  // signing session order is kinda pointless if all the parties are signing hosted/on paper.
  if (!parties.some(p => p.type === SigningPartyType.SignOnline || p.type === SigningPartyType.SignOnlineSms)) return undefined;
  const partyTypes = new Set(parties.map(p => mapSigningPartySourceTypeToCategory(p.source.type)));

  const sorted = (JSON.parse(JSON.stringify(signing.signingOrderSettings)) as SigningOrderSettingsItem[])
    .filter(x => partyTypes.has(x.type))
    .sort(byMapperFn(x => x.order));

  return sorted.map<undefined | SigningSessionOrderItem>((settings, index, all) => {
    const prev = index === 0
      ? undefined
      : all[index - 1];
    const dependsOnCount = prev
      ? parties.filter(party => mapSigningPartySourceTypeToCategory(party.source.type) === prev.type).length
      : 0;

    const orderedParties: OrderedSigningParty[] = [];
    for (const party of parties) {
      if (mapSigningPartySourceTypeToCategory(party.source.type) !== settings.type) continue;
      const asOrdered = toOrderedSigningParty(party);
      if (!asOrdered) continue;
      orderedParties.push(asOrdered);
    }
    orderedParties.sort(byMapperFn(x => x.signingOrderSettings.order));

    const categoryIsAuto = !!settings.auto;
    return {
      id: settings.id,
      type: settings.type,
      dependsOn: prev?.type,
      dependsOnCount,
      auto: categoryIsAuto,
      state: prev
        ? 'inactive'
        : 'active',
      parties: orderedParties.length
        ? orderedParties.map((p, index) => {
          const first = 0 === index;
          return {
            partyId: p.id,
            order: p.signingOrderSettings.order,
            // party is always automatic if it's the first party of an automatic group
            auto: (first && categoryIsAuto) || p.signingOrderSettings.auto,
            // first party of the first group is active
            state: !prev && first
              ? 'active'
              : 'inactive'
          };
        })
        : undefined
    };
  }).filter(Predicate.isNotNull);
}

function optimisationSearchableText(...data: (string|undefined|null)[]): string {
  return data.filter(x => !!x).join(' ').trim();
}

export function removeUnexpectedSigningOrderSettings(instance: FormInstance, expected: Set<PartyCategory>) {
  if (!instance.signing?.signingOrderSettings) return;

  const signingOrderSettings = instance.signing.signingOrderSettings;
  for (let index = signingOrderSettings.length - 1; index >= 0; index --) {
    if (expected.has(signingOrderSettings[index].type)) continue;
    signingOrderSettings.splice(index, 1);
  }
}

export function addMissingSigningOrderSettings(instance: FormInstance, expected: Set<PartyCategory>) {
  if (!instance.signing) return;
  if (getSigningOrderVersion(instance.signing) !== SigningOrderVersion.Grouped) return;

  if (instance.signing.signingOrderSettings) {
    const signingOrderSettings = instance.signing.signingOrderSettings;
    let nextOrder = signingOrderSettings.length
      ? 1 + Math.max(...signingOrderSettings.map(s => s.order))
      : 0;
    for (const type of expected) {
      if (signingOrderSettings.find(setting => setting.type === type)) continue;
      signingOrderSettings.push({
        id: uuidv4(),
        type,
        auto: false,
        order: nextOrder,
        partyOrder: type === 'other'
      });
      nextOrder++;
    }
  } else {
    instance.signing.signingOrderSettings = [...expected].map((type, index) => ({
      id: uuidv4(),
      type,
      auto: false,
      order: index,
      partyOrder: type === 'other'
    }));
  }
}

export function removeUnexpectedSigningParties(instance: FormInstance, expected: SigningPartySource[]) {
  if (!instance?.signing?.parties?.length) return;

  const parties = instance.signing.parties;
  for (let index = parties.length - 1; index >= 0; index--) {
    const party = parties[index];
    if (!expected.find(exp => FormUtil.sourcesMatch(exp, party.source))) {
      parties.splice(index, 1);
    }
  }
}

export function addMissingSigningParties(instance: FormInstance, expected: SigningPartySource[]) {
  if (!instance.signing) return;
  if (!instance.signing.parties) {
    instance.signing.parties = [];
  }

  const parties = instance.signing.parties;
  for (const item of expected) {
    if (parties.find(p => FormUtil.sourcesMatch(p.source, item))) {
      continue;
    }

    const type = mapSigningPartySourceTypeToCategory(item.type);
    const useOrder = !!instance.signing.signingOrderSettings?.find(s => s.type === type)?.partyOrder;
    const existingOrders = parties
      .filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === type)
      .map((p, index) => p.signingOrderSettings?
        p.signingOrderSettings.order
        : 100 + index);

    parties.push({
      id: uuidv4(),
      type: item.agencySalesPersonId
        ? SigningPartyType.SignInPerson
        : SigningPartyType.SignOnline,
      typeHostComposite: item.agencySalesPersonId
        ? SigningPartyType.SignInPerson.toString()
        : SigningPartyType.SignOnline.toString(),
      typeHostParty: item.agencySalesPersonId
        ? item.agencySalesPersonId.toString()
        : undefined,
      source: omit(item, ['_optimisation']),
      colour: getLeastUsedColour(parties.map(p => p.colour), userColours),
      // if signing order is being used, we want to make sure it appears at the end
      signingOrderSettings: useOrder
        ? {
          order: 1 + (existingOrders.length ? Math.max(...existingOrders) : 0),
          auto: false
        }
        : undefined
    });
  }

  FormUtil.ensureGroupOrPartySigningOrderSettings(instance.signing, instance.formCode, instance.order?.type === FormOrderType.Filler);
}

export function setSigningOrderVersion(instance: FormInstance, useSigningOrder: boolean, signingOrderVersion: number) {
  if (!instance.signing) return;

  instance.signing.useSigningOrder = useSigningOrder;
  if (useSigningOrder) {
    instance.signing.signingOrderVersion = signingOrderVersion;
  }

  FormUtil.ensureGroupOrPartySigningOrderSettings(instance.signing, instance.formCode, instance.order?.type === FormOrderType.Filler);
}

export function generateDocumentSummary(form: FormFamilyState | undefined, newData: MaterialisedPropertyData) {
  if (!form) return '';
  const instance = form.instances?.sort(compareFormInstances)[0];
  if (!instance) return '';

  switch (instance.formCode) {
    case FormCode.RSC_ContractOfSale:
    case FormCode.OfferToPurchase: {
      const primaryPurchaser = newData.purchasers?.find(p => p.id === newData.primaryPurchaser)?.fullLegalName;
      const numPurchasers = newData.purchasers?.filter(p => p.fullLegalName)?.length??0;
      return numPurchasers
        ? numPurchasers === 1 ? primaryPurchaser : `${primaryPurchaser} and ${numPurchasers - 1} other${numPurchasers>2?'s':''}`
        : form.label||'';
    }

    default:
      return '';
  }
}

export function generateFormFamilySigningStatus(family: FormFamilyState | undefined) {
  if (!family) return AllDocumentStatus.Draft;
  return generateFormInstanceSigningStatus(
    family,
    family?.instances?.sort(compareFormInstances)?.at(0));
}

export function generateFormInstanceSigningStatus(family: FormFamilyState, instance: FormInstance | undefined) {
  if (!instance) return AllDocumentStatus.Draft;
  if (family.terminatedTime) return AllDocumentStatus.Terminated;

  switch (instance.signing?.state||FormSigningState.None) {
    case FormSigningState.None:
      return AllDocumentStatus.Draft;

    case FormSigningState.Configuring:
      return AllDocumentStatus.Configuring;

    case FormSigningState.OutForSigning:
    case FormSigningState.OutForSigningPendingServerProcessing:
    case FormSigningState.OutForSigningPendingUpload:
      return AllDocumentStatus.OutForSigning;

    case FormSigningState.Signed:
    case FormSigningState.SignedPendingUpload:
    case FormSigningState.SignedPendingDistribution:
      if (family.recipients?.length) return AllDocumentStatus.Distributed;
      return AllDocumentStatus.Signed;

    default:
      return AllDocumentStatus.Draft;
  }
}

export function getPartyIdsActuallySigning(signing: FormInstanceSigning): Set<string> {
  if (signing.customFields) {
    return getPartyIdsActuallySigningFromCustomFields(signing.customFields);
  }

  return new Set<string>((signing.parties || []).map(p => p.id));
}

export function getPartyIdsActuallySigningFromCustomFields(customFields: CustomFieldConfiguration[]): Set<string> {
  const result = new Set<string>();
  for (const cf of customFields) {
    if ('partyId' in cf && cf.partyId && customFieldMetas[cf.type].signingSessionFieldType) {
      result.add(cf.partyId);
    }
  }
  return result;
}

export function getOrderedPartyIds(signing: FormInstanceSigning | undefined) {
  if (!signing?.useSigningOrder) return [];

  const ids = new Set<string>();

  [...(signing.signingOrderSettings || [])]
    .sort(byMapperFn(x => x.order))
    .forEach(orderSettings => {
      const partiesOfType = signing.parties
        ?.filter(party => orderSettings.type === mapSigningPartySourceTypeToCategory(party.source.type)) || [];
      if (orderSettings.partyOrder) {
        partiesOfType.sort(byMapperFn(party => party.signingOrderSettings?.order ?? 99999));
      }
      partiesOfType.forEach(({ id }) => {
        ids.add(id);
      });
    });

  return [...ids];
}

export function getSigningOrderDump(signing?: FormInstanceSigning) {
  return {
    parties: signing?.parties?.map(p => ({
      id: p.id,
      type: mapSigningPartySourceTypeToCategory(p.source.type),
      name: p.snapshot?.name,
      signingOrderBlocked: p.signingOrderBlocked,
      signingOrderSettings: p.signingOrderSettings,
      ignoreForSigning: p.ignoreForSigning
    })),
    useSigningOrder: signing?.useSigningOrder,
    signingOrderSettings: signing?.signingOrderSettings,
    signingOrder: signing?.session?.signingOrder,
    signingOrderVersion: signing?.signingOrderVersion,
    partyOrder: signing?.session?.partyOrder
  };
}

export class PartySnapshotLoader {
  private sublineageCache = new Map<string, Map<string, SigningPartySnapshot>>();

  constructor(
    private base: Map<string, SigningPartySnapshot>,
    private parties: SigningParty[],
    private memberEntities: BelongingEntityMeta,
    private getSublineageData?: (sublineageId: string) => Maybe<MaterialisedPropertyData>,
  ) { }

  public getSnapshot(party: SigningParty): SigningPartySnapshot | undefined {
    if (!party.source.sublineageId) {
      return this.base.get(party.id);
    }

    const cached = this.sublineageCache.get(party.source.sublineageId);
    if (cached) return cached.get(party.id);
    if (!this.getSublineageData) {
      this.sublineageCache.set(party.source.id, new Map());
      return undefined;
    }

    const data = this.getSublineageData(party.source.sublineageId);
    if (!data) {
      this.sublineageCache.set(party.source.id, new Map());
      return undefined;
    }

    const snaps = FormUtil.getSigningSessionSignatureSnapshots(this.parties, data, { memberEntities: this.memberEntities });
    this.sublineageCache.set(party.source.sublineageId, snaps);
    return snaps.get(party.id);
  }
}

export const SigningOrderVersion = {
  Grouped: 1,
  Flat: 2
};

export function getSigningOrderVersion(signing?: Pick<FormInstanceSigning, 'signingOrderVersion'>): number {
  return signing?.signingOrderVersion || SigningOrderVersion.Grouped;
}
