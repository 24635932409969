import { YDocContentType } from '../ws';
import { UserPreferencesSchemaTemplate } from './user-preferences';
import { PropertyStatsSchemaTemplate } from './property-stats';
import { EntitySettingsSchemaTemplate } from './entity-settings';

// todo: (future) assuming this stuff works and if we go harder into yjs,
//       move to a @property-folders/common package and just emit an initialised Y.Doc();
export function getTemplateForYDocContentType(type: YDocContentType) {
  switch (type) {
    case YDocContentType.UserPreferences:
      return UserPreferencesSchemaTemplate;
    case YDocContentType.PropertyStats:
      return PropertyStatsSchemaTemplate;
    case YDocContentType.EntitySettings:
      return EntitySettingsSchemaTemplate;
    default:
      return undefined;
  }
}
