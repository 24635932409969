import { WrField } from '../CommonComponentWrappers';

export function OfferAdditionalConditionsFreeText() {
  return <div className='w-100'>
    <WrField.TextArea
      name='offerAdditionalConditions'
      myPath='offerAdditionalConditions'
      label='Additional Conditions'
      maxLength={2000}
      showLengthRemaining={true}
      rows={10}
      minHeight={'105px'} />
  </div>;
}
