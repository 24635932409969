import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getPathParentAndIndex, getValueByPath } from '../../../../util/pathHandling';

/**Enables Search Fees when setting whether to use EPF for Form 1
 * input only fields: fixedSearchFees.epfFee.enable
 * output only: fixedSearchFees.councilSearch.enable
 *
 */
export const saaSearchCostBind: FieldGroupFn = (fieldId, updatedPath, immerProxy) => {

  if (updatedPath === 'fixedSearchFees.epfFee.enable') {
    const { parent, indexer } = getPathParentAndIndex('fixedSearchFees.councilSearch.enable', immerProxy);
    parent[indexer] = getValueByPath(updatedPath, immerProxy, true);
  }
};
