import * as Y from 'yjs';
import {
  FormInstance,
  TransactionMetaData
} from '@property-folders/contract';

import { META_APPEND, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { FormUtil } from './form';
import { PropertyFormYjsDal } from '../yjs-schema/property/form';
import { uniq } from 'lodash';
import { materialisePropertyMetadata } from '../yjs-schema/property';

// todo: migrate some stuff to common/yjs-schema/property/form.ts
export class YFormUtil {

  public static getFormLocationFromId(formId: string, ydoc: Y.Doc) {
    if (!ydoc) {
      return;
    }
    // Doing discovery here, so we start at the root
    const rootMeta = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
    const { formFamilyCode, instance } = FormUtil.getCodeFromFormId(formId, rootMeta)??{};
    if (instance) {
      return {
        dataRootKey: PropertyRootKey.Data.toString(),
        metaRootKey: PropertyRootKey.Meta.toString(),
        formFamilyCode,
        instance
      };
    }
    for (const dataRootKey of rootMeta.sublineageRoots??[]) {
      const metaRootKey = dataRootKey+META_APPEND;
      const rootMeta = ydoc.getMap(metaRootKey).toJSON() as TransactionMetaData;
      const { formFamilyCode, instance } = FormUtil.getCodeFromFormId(formId, rootMeta)??{};
      if (instance) {
        return {
          dataRootKey,
          metaRootKey,
          formFamilyCode,
          instance
        };
      }
    }
  }

  public static getDistinctFormFamilies(ydoc: Y.Doc) {
    const rootMeta = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
    const formFamilies = PropertyFormYjsDal.getDistinctFormFamilies(rootMeta);
    for (const sublineageId of rootMeta?.sublineageRoots??[]) {
      const subMeta = ydoc.getMap(sublineageId+META_APPEND).toJSON() as TransactionMetaData;
      formFamilies.push(...PropertyFormYjsDal.getDistinctFormFamilies(subMeta));
    }
    return uniq(formFamilies);
  }

  public static getFormLocationFromSigningSessionId(signingSessionId: string, ydoc: Y.Doc): undefined | { dataRootKey: string, metaRootKey: string, formFamilyCode: string, instance: FormInstance } {
    if (!ydoc) return undefined;
    const rootMeta = materialisePropertyMetadata(ydoc);

    const found = FormUtil.getFormInstanceFromSigningSession(signingSessionId, rootMeta);
    if (found) {
      return {
        dataRootKey: PropertyRootKey.Data.toString(),
        metaRootKey: PropertyRootKey.Meta.toString(),
        ...found
      };
    }

    for (const dataRootKey of rootMeta.sublineageRoots??[]) {
      const metaRootKey = dataRootKey+META_APPEND;
      const rootMeta = ydoc.getMap(metaRootKey).toJSON() as TransactionMetaData;
      const found = FormUtil.getFormInstanceFromSigningSession(signingSessionId, rootMeta);
      if (found) {
        return {
          dataRootKey,
          metaRootKey,
          ...found
        };
      }
    }

    return undefined;
  }
}
