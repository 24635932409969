import { canonicalisers } from '../canonicalisers';

export function prettifyPhone(phone?: string, maskSensitive?: boolean, fnTemplate?: (display: string) => string, includeCountryPrefix = true) {
  if (!phone) return undefined;
  if (maskSensitive) {
    return fnTemplate
      ? fnTemplate('*****')
      : '*****';
  }

  const canon = canonicalisers.phone(phone, { alwaysCountryPrefix: includeCountryPrefix });
  return fnTemplate
    ? fnTemplate(canon.display)
    : canon.display;
}
