import { SigningParty } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getPathParentAndIndex } from '../../../../util/pathHandling';

export const splitPartySignerType: FieldGroupFn = (fieldId, updatedPath, immerProxy) => {
  if (fieldId.split('.').splice(-1,1)[0] !== 'typeHostComposite') {
    console.warn('bad field trigger', fieldId.split('.').splice(-1,1)[0]);
    return;
  }

  const { parent: party } = getPathParentAndIndex(updatedPath, immerProxy) as unknown as {parent: SigningParty};
  applySplitPartySignerType(party);
};

export function applySplitPartySignerType(party: Pick<SigningParty, 'proxyAuthority' | 'type' | 'typeHostComposite' | 'typeHostParty'> | undefined, opts?: {noProxyChange?: boolean}) {

  if (!party?.typeHostComposite) {
    return;
  }

  const [rawType, rawHostAgentId] = party.typeHostComposite.split('_');
  const type = parseInt(rawType, 10);
  if (!Number.isInteger(type)) {
    return;
  }

  const hostAgentId = parseInt(rawHostAgentId, 10);
  party.typeHostParty = hostAgentId.toString() === rawHostAgentId
    ? hostAgentId
    : rawHostAgentId;

  party.type = type;
  if (!rawHostAgentId) {
    delete party.typeHostParty;
    return;
  }

}
