import { Spinner } from 'react-bootstrap';
import './LinkButton.scss';
import { ReactNode, useState } from 'react';
import { Icon } from '../dragged-components/Icon';

export function LinkButton(props: {onClick: React.MouseEventHandler<HTMLButtonElement>, children: ReactNode, async?: boolean}) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault(); // Prevent a navigation/reload or whatever it usually does
    if (!props.async) {
      props.onClick(e);
      return;
    }

    setProcessing(true);
    setError(false);
    try {
      await props.onClick(e);
    } catch (e) {
      setError(true);
    } finally {
      setProcessing(false);
    }

  };
  return <button className='link-button' onClick={handleClick}>
    {error && <Icon name='error' style={{ fontSize: '1rem' }} />}
    {processing && <Spinner animation="border" size={'sm'}/>}{props.children??'Retry?'}
  </button>;
}