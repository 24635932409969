import { createSlice } from '@reduxjs/toolkit';

export type NewlyAvailableFiles = {
  [uuid: string]: {available: true}
};

export const REDUCER_NAME = 'newlyAvailableFiles';

const thisReducer = createSlice({
  name: REDUCER_NAME,
  initialState: {} as NewlyAvailableFiles,
  reducers: {
    addAvailableFile(
      state,
      action: { payload: { id: string } },
    ) {
      if (!state[action.payload.id] || typeof state[action.payload.id] !== 'object') {
        state[action.payload.id] = { available: true };
        return;
      }
      state[action.payload.id].available = true;
    }
  }
});

export const {
  addAvailableFile
} = thisReducer.actions;
export default thisReducer.reducer;
