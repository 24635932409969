import { createSlice } from '@reduxjs/toolkit';
import { IconPacks } from '../types/IconProps';

export type sidebarElement = {
  id: string,
  icon?: string,
  iconPack?: IconPacks,
  label?: string,
  href?: string,
  dispatchById: boolean,
};

export type NavigationFormState = {
  currentForm: string;
  currentSelection: string;
  sidebarSelected: string;
  sidebarItems: sidebarElement[];
};

export const REDUCER_NAME = 'navigation';

const thisReducer = createSlice({
  name: REDUCER_NAME,
  initialState: { sidebar: { form: {
    currentForm: '',
    currentSelection: '',
    sidebarSelected: '', // Explicitly selected element by user from sidebar, not detected.
    sidebarItems: [] as sidebarElement[]
  } as NavigationFormState } } ,
  reducers: {
    setActiveForm(state, action) {
      state.sidebar.form.currentForm = action.payload.formName;
      state.sidebar.form.sidebarItems = action.payload.sidebarItems;
      state.sidebar.form.currentSelection = action.payload.sidebarItems[0]?.id ?? '';
    },
    setActiveFormSection(state, action) {
      if (!state.sidebar.form.sidebarSelected) {
        state.sidebar.form.currentSelection = action.payload;
      }
    },
    activeFormSectionClick(state, action) {
      state.sidebar.form.sidebarSelected = action.payload;
      state.sidebar.form.currentSelection = action.payload;
    },
    unlockFormSectionClick(state) {
      state.sidebar.form.sidebarSelected = '';
    },
    clearFormMode(state,) {
      state.sidebar.form.currentForm = '';
      state.sidebar.form.currentSelection = '';
      state.sidebar.form.sidebarSelected = '';
      state.sidebar.form.sidebarItems = [];
    }
  }
});

export const { setActiveForm, setActiveFormSection, activeFormSectionClick, unlockFormSectionClick, clearFormMode } = thisReducer.actions;
export default thisReducer.reducer;
