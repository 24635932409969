import { LastServedContactSnapshot, MaterialisedPropertyData, SigningParty } from '@property-folders/contract';
import * as hash from 'object-hash';
import { FormUtil } from './form';

export function hashPurchasers(purchasers: SigningParty[]) {
  const hashObject = purchasers?.map(p=> ({
    id: p.id,
    name: p.snapshot?.name,
    email: p.snapshot?.email,
    address: p.snapshot?.addressSingleLine,
    phone: p.snapshot?.phone,
    isPrimary: p.snapshot?.isPrimary
  }));
  return hash.MD5(hashObject) as string;
}

export function remapSignerToContactPurchaser(purchasers: SigningParty[]) {
  return purchasers?.map(p=> ({
    id: p.id,
    name: p.snapshot?.name,
    email: p.snapshot?.email,
    address: p.snapshot?.addressSingleLine,
    phone: p.snapshot?.phone,
    isPrimary: p.snapshot?.isPrimary
  }));
}

export function produceContactDetailsSnapshotCompareString (snapshot: LastServedContactSnapshot[] ) {
  return snapshot?.map(ps=> {
    const comparePoints = {
      name: ps.name,
      address: ps.address,
      isPrimary: ps.isPrimary
    };
    return JSON.stringify(comparePoints);
  }).sort().join('');
}

export function correlateRecipientWithPurchaser(recipient: SigningParty, property: MaterialisedPropertyData) {
  // This will be especially fun if the source type changes.
  // We should be able to use the old recipient data, if anything changes here, we're stuffed
  // anyway, and should just rerender.
  // TODO: When merging the new representatives for attorneys/guardians, will have to check if the
  // updated getPartyDetailsPaths will need any more 'real' data than what is being given here.

  const snapshot = FormUtil.getIndivdualSnapshot(recipient, property, []);
  if (!snapshot) return null;
  return {
    id: recipient.id,
    name: snapshot?.name,
    email: snapshot?.email,
    phone: snapshot?.phone,
    address: snapshot?.addressSingleLine,
    isPrimaryContact: snapshot?.isPrimary
  };
}