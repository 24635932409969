import { Button } from 'react-bootstrap';
import { FallbackProps } from '@property-folders/components/telemetry/ErrorBoundary';

export function DocumentViewFallback (props: FallbackProps) {
  return <div className='PDFContainer primary'>
    <div className='bg-white p-3 mt-2 d-flex flex-column bg-white align-items-center'>
      <div>Error loading document.</div>
      <div><Button variant='link' onClick={props.resetErrorBoundary}  style={{ border: 0, padding: 0 }}>Retry?</Button></div>
    </div>
  </div>;
}

export function PreviewFallback(props: FallbackProps & {children?: JSX.Element}) {
  return <div className='w-100 h-100 d-flex align-items-center flex-column'>
    <div className='bg-white p-3 mt-2 d-flex flex-column bg-white align-items-center'>
      <div>Error loading preview.</div>
      <div><Button variant='link' onClick={props.resetErrorBoundary}  style={{ border: 0, padding: 0 }}>Retry?</Button>{props.children}</div>
    </div>
  </div>;
}
