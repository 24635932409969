import * as lph from 'google-libphonenumber';
import * as bnvalidator from 'au-bn-validator';
import { EMAIL_REGEX } from '../../data-and-text/regex';
import { validateNZBN } from './nzbn-validator';

const longFormTitleRegEx = /(certificate\s+of\s+title|crown\s+lease|crown\s+record).+volume\s*([0-9]{1,4}).+folio\s*([0-9]+)\s*/i;
const titleMatchRegEx = /(C[TRL])\s*([0-9]{1,4})\s*\/\s*([0-9]+)\s*/;
const suburbStatePostRegEx = /(.+) ([A-Z]{2,3}) ([0-9]{4})/;
const isoDateRegEx = /([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})/;
const timehm24RegEx = /([0-2][0-9]):([0-5][0-9])/;
const dollarRegex = /^\s*(\$?[0-9, \s]+(\.[0-9\s]*)?)\s*$/;
const dollarRegexAllowNeg = /^\s*(\$?-?\$?[0-9, \s]+(\.[0-9\s]*)?)\s*$/;
// the percentage regex allows for .23 as a valid entry, as well as 5. Both sides of the decimal are
// thus optional. Check seperately whether any number is present, otherwise you could end up
// coercing and empty string and get NaN.00%
const percentRegex = /^\s*(%?-?%?[0-9, \s]*\.?[0-9\s]*\s*%?)\s*$/;
const anyNumberRegex = /[0-9]/;
const abnacnRegex = /^\s*([0-9\-\s]*)+\s*$/;
const nzbnRegex = /^\s*([0-9\-\s]*)+\s*$/;
const hexColourRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const testEmail = (potentialEmail: unknown) => {
  if (typeof potentialEmail !== 'string') return false;
  if (potentialEmail.includes(' ')) return false;

  return EMAIL_REGEX.test(potentialEmail);
};

/**
   *
   * @param value
   * @param noEnforce Whether to always say the value is true, so as to not trip up full page validation (for bad agent data that cannot be edited on page)
   * @returns
   */
function baseAbnAcn (value: string, noEnforce?: boolean) {
  const r: CanonicalResults = {
    canonical: value,
    display: value,
    valid: !!noEnforce, // Because we are not enforcing this due to crappy data
    infoValidity: false
  };

  let type = '';
  if (abnacnRegex.test(value)) {
    const numsOnly = value.replace(/[^0-9]/g,'');
    if (numsOnly.length === 11) {
      type='abn';
      r.infoValidity = bnvalidator.validateABN(numsOnly);

      if (r.infoValidity) {
        r.display = `${numsOnly.slice(0,2)} ${numsOnly.slice(2,5)} ${numsOnly.slice(5,8)} ${numsOnly.slice(8,11)}`;
        r.canonical = numsOnly;
        r.components = { type };
      }
    } else if (numsOnly.length === 9) {
      type='acn';
      r.infoValidity = bnvalidator.validateACN(numsOnly);
      if (r.infoValidity) {
        r.display = `${numsOnly.slice(0,3)} ${numsOnly.slice(3,6)} ${numsOnly.slice(6,9)}`;
        r.canonical = numsOnly;
        r.components = { type };
      }
    }
    if (!noEnforce) r.valid = r.infoValidity;
  }

  return r;
}

function baseNzbn (value: string, noEnforce?: boolean) {
  const r: CanonicalResults = {
    canonical: value,
    display: value,
    valid: !!noEnforce,
    infoValidity: false
  };

  if (nzbnRegex.test(value)) {
    const numsOnly = value.replace(/[^0-9]/g,'');
    if (numsOnly.length === 13) {
      r.infoValidity = validateNZBN(numsOnly);
      if (r.infoValidity) {
        r.display = numsOnly;
        r.canonical = numsOnly;
        r.components = { type: 'nzbn' };
      }
    }

    if (!noEnforce) r.valid = r.infoValidity;
  }

  return r;
}

// If a canonicaliser has some notion of validity, it should only apply the transformer if it is valid
export type CanonicalResults = {
  canonical: string | number,
  display: string,
  infoValidity?: boolean | undefined,
  valid: boolean | undefined,
  components? : Record<string, any>
};

export class canonicalisers {
  static phone(value: string, opts?: {alwaysCountryPrefix?: boolean}): CanonicalResults {
    const { alwaysCountryPrefix } = opts ?? {};
    const putil = lph.PhoneNumberUtil.getInstance();
    let nummo: lph.PhoneNumber | undefined;
    try {
      nummo = putil.parse(value);
    }
    catch {
      try {
        nummo = putil.parse(value, 'AU');
      }
      catch {
        // Do nothing, we're returning the input
      }
    }
    let canonical = value;
    let display = value;
    let valid = false;
    if (nummo && putil.isValidNumber(nummo)) {
      canonical = putil.format(nummo, lph.PhoneNumberFormat.E164);
      display = (nummo.getCountryCode() === 61 && !alwaysCountryPrefix)
        ? putil.format(nummo, lph.PhoneNumberFormat.NATIONAL)
        : putil.format(nummo, lph.PhoneNumberFormat.INTERNATIONAL);
      valid = true;
    }
    return { canonical, display, valid };
  }

  static email(value: string): CanonicalResults {
    const canonical = value;
    const display = value;
    const valid = testEmail(value);

    return { canonical, display, valid };
  }

  static title(value: string): CanonicalResults {
    let base = (value ?? '')?.toUpperCase();

    const fullMatches = titleMatchRegEx.exec(base);
    let valid = false;
    // We parse this early because someone might have typed too many digits
    const folioInt = parseInt(fullMatches?.[3] ?? '');
    if (folioInt >= 0 && folioInt < 1000) {
      valid = titleMatchRegEx.test(base);
    }
    let components: Record<string, any> | undefined;
    if (valid && fullMatches && fullMatches[1] && fullMatches[2] && fullMatches[3]) {
      const titleType = fullMatches[1];
      const volume = fullMatches[2];
      const folio = fullMatches[3];
      base = `${titleType} ${volume}/${folio}`;
      components = { titleType, volume, folio };
    }

    const r: CanonicalResults = { canonical: base, display: base, valid };
    if (components) {
      r.components = components;
    }
    return r;
  }

  static stateSubPost(value: string): CanonicalResults {
    const fullMatches = suburbStatePostRegEx.exec(value);
    const valid = suburbStatePostRegEx.test(value);
    let components: Record<string, any> | undefined;
    if (valid && fullMatches && fullMatches?.length >= 4) {
      const [_,suburb,postcode,state] = fullMatches;
      components = { suburb, postcode, state };
    }
    const r: CanonicalResults = { canonical: value, display: value, valid };
    if (components) {
      r.components = components;
    }
    return r;
  }

  static aud(value: string | number = '', opts?: {validationOnly?: boolean}): CanonicalResults {
    return canonicalisers.audWithNegative(value, { ...opts, forbidNegative: true });
  }

  static audWithNegative(value: string | number = '', opts?: {forbidNegative?: boolean, validationOnly?: boolean}): CanonicalResults {
    const { forbidNegative } = opts ?? {};
    const r: CanonicalResults = {
      canonical: value,
      display: value.toString(),
      valid: (forbidNegative?dollarRegex:dollarRegexAllowNeg).test(value.toString())
    };
    const base = ((typeof value === 'number' ? value.toString() : value)??'').replace(/[^0-9-.]/g,'');
    if (r.valid) {
      let num = parseFloat(base);
      num = parseFloat(num.toFixed(2));
      r.canonical = num;
      // , trailingZeroDisplay: 'stripIfInteger'  not working too well. Let's manually adjust
      r.display = num.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
    } else if (!/\d/.test(`${value}`)) { // If there's no numbers, let's aggressively remove the nonsense values
      r.display = '';
      r.canonical = '';
      if (opts?.validationOnly) r.valid = true;
    }
    return r;
  }

  static percent(value: string | number = ''): CanonicalResults {
    // This canonicaliser is intentionally string, despite it ostensibly being a number. This is due
    // to floating point rounding issues that kept cropping up when we were converting to an actual
    // multiplier/ratio (ie dividing/multiplying by 100). Making this a number and saving the
    // percentage as the already multiplied by 100 form seems like a good way to introduce bugs,
    // because someone multiplied the value directly without dividing by 100 first.

    // Start by just stripping out junk, so if the user enters a $ instead, we just look at the number
    // but only if it is actually a number
    const base = anyNumberRegex.test(value.toString()) ? ((typeof value === 'number' ? value.toString() : value)??'').replace(/[^0-9-.]/g,'') : value.toString();
    const r: CanonicalResults = {
      canonical: base,
      display: base,
      // the percentage regex allows for .23 as a valid entry, as well as 5. Both sides of the
      // decimal are thus optional, and rather than complicating the regex itself to find any number
      // I'll just do it programatically here
      valid: percentRegex.test(base.toString()) && anyNumberRegex.test(base.toString())
    };
    if (r.valid) {
      const filtered = (`${base}`??'').replace(/[^0-9-.]/g,''); // not that this should be an empty string, but ts

      const num = parseFloat(filtered);
      // We still have a significant issue with floating point errors. This will survive one parse,
      // but stored canonical values will end up messing this up at higher precision.

      // We're making this (-)XX.XX%
      const isNeg = num < 0;
      const integerPortion = Math.floor(Math.abs(num));
      // Because doing actual maths with floats is fraught with base 2->base 10 issues, I'm just
      // trying to get this to print out right, and because the options for number formatting aren't
      // great. Using toFixed will not allow more than the specified precision, and there's no
      // options for setting leading zeroes that I've seen in
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      let decimalIndex = filtered.indexOf('.');
      if (decimalIndex < 0) {
        decimalIndex = filtered.length;
      }
      const decimalsString = filtered.slice(decimalIndex+1).padEnd(2,'0');
      r.display = `${isNeg?'-':''}${integerPortion.toString()}.${decimalsString}%`;
      r.canonical = r.display;
    }
    return r;
  }

  static days(value: string | number, opts?: {extraSuffix?: string}): CanonicalResults {
    const { extraSuffix } = opts??{};
    const base = ((typeof value === 'number' ? value.toString() : value)??'').replace(/[^0-9]/g,'');
    const r: CanonicalResults = {
      canonical: base,
      display: base,
      valid: false
    };

    if (base.length > 0) {
      r.valid = true;
      const num = parseInt(base);
      r.canonical = num;
      r.display = `${num} days`;
      if (extraSuffix) r.display = r.display + extraSuffix;
    }
    return r;
  }

  static date(value: string): CanonicalResults {
    const base = value;
    const r: CanonicalResults = {
      canonical: base,
      display: base,
      valid: false
    };
    r.valid = isoDateRegEx.test(base);
    if (r.valid) {
      const fullMatches = isoDateRegEx.exec(value);
      const [_,ys,ms,ds] = fullMatches;
      const y = parseInt(ys);
      const m = parseInt(ms);
      const d = parseInt(ds);
      r.canonical = `${y.toString().padStart(4,'0')}-${m.toString().padStart(2,'0')}-${d.toString().padStart(2,'0')}`;
      r.display = Intl.DateTimeFormat('en-AU', { dateStyle: 'medium' }).format(new Date(r.canonical));

      r.components = { d,m,y };
    }
    return r;
  }

  static timehm(value: string): CanonicalResults {
    const base = value;
    const r: CanonicalResults = {
      canonical: base,
      display: base,
      valid: false
    };
    const baseMatch = timehm24RegEx.test(value);
    if (baseMatch) {
      const fullMatches = timehm24RegEx.exec(value);
      const [_,hs,ms] = fullMatches;
      const h = parseInt(hs);
      const m = parseInt(ms);
      if (h <=23 && m <=59) {
        r.valid = true;
        r.canonical = `${h.toString().padStart(2,'0')}:${m.toString().padStart(2,'0')}`;
        const isPm = h >= 12;
        let rem = h%12;
        if (rem === 0) {
          rem = 12;
        }
        r.display = `${rem}:${m.toString().padStart(2,'0')} ${isPm ? 'pm' : 'am'}`;
      }
    }
    return r;
  }

  static abnacn(value: string): CanonicalResults {
    return baseAbnAcn(value, false);
  }

  static abnacnAnyValid(value: string): CanonicalResults {
    return baseAbnAcn(value, true);
  }

  static nzbn(value: string): CanonicalResults {
    return baseNzbn(value, false);
  }

  static nzbnAnyValid(value: string): CanonicalResults {
    return baseNzbn(value, true);
  }

  static bn(value: string): CanonicalResults {
    const nzbn = canonicalisers.nzbn(value);
    if (nzbn.valid) return nzbn;

    return canonicalisers.abnacn(value);
  }

  static colour(value: string): CanonicalResults {
    const canonical = value ? !value?.startsWith('#') ? `#${value}` : value : undefined;
    const display = canonical;
    const valid = hexColourRegex.test(canonical);
    return { canonical, display, valid };
  }
}

export const inputTransformers: Record<string, (value:string) => string> = {
  email: (value: string) => {

    let base = value;
    const atParts = base.split('@');
    if (atParts.length > 1) {
      // @ symbols can exist in the local user portion of emails (ugh) if they are quoted, but not
      // in the domain, so lazy method is to take the string after the last @
      const domain = atParts[atParts.length-1].toLowerCase();
      const reformed = [...atParts.slice(0,atParts.length-1), domain].join('@');
      base = reformed;
    }
    return base.trim();
  },
  title: (value: string) => {

    let base = value;
    base = base.toUpperCase();
    base = base.replace(/[^0-9 CTRL/]/gi, '');
    const numberOnly = /^([0-9]+)[ ]*\/?[ ]*[0-9]*[ ]*$/.test(base.trimStart());

    if (numberOnly) {
      base = 'CT '+base.trimStart();
    }
    const beginsWithGoodSecondLetter = /^[TRL][ ]*[0-9]+[ ]*\/?[ ]*[0-9]*[ ]*$/.test(base.trimStart());
    if (beginsWithGoodSecondLetter) {
      base = 'C'+base.trimStart();
    }
    return base;
  },
  audWithNegative: (value: string) => {
    let base = value;
    base = base.replace(/[^0-9 ,.$-]/g, '');

    if (base.trimStart().trimEnd() === '$') {
      return base;
    }
    if (base.trimStart().trimEnd() === '') {
      return base;
    }

    base = base.replace(/\$/g, '');
    base = `$${base}`;
    const decimalPos = base.indexOf('.');
    if (decimalPos >= 0) {
      base = base.slice(0,decimalPos+1) + base.slice(decimalPos+1).replace(/\./g,'');
    }
    return base;
  },
  aud: (value: string) => {
    let base = value;
    base = base.replace(/[^0-9 ,.$]/g, '');
    return inputTransformers.audWithNegative(base);
  },
  percent: (value: string) => {
    let base = value;
    base = base.replace(/[^0-9-.%]/g, '');
    return base;
  }
};

// Processing for specific event types, such as paste/drop instead of typing
export const eventSourcePreprocessors: Record<string, Record<string, (input: string)=>string>> = {
  insert: {
    email: (value: string) => {
      const multiEmail = value.split(/[,;\n]/);
      let base = multiEmail.length > 0 ? multiEmail[0] : '';
      if (base.includes('<')) {
        const angleMatch = /<(.+@.+)>/.exec(base);
        if (angleMatch) {
          base = angleMatch && angleMatch?.length > 1 ? angleMatch[1] : '';
        } else {
          const partialAngleMatch = /<(.+@.+)/.exec(base);
          base = partialAngleMatch && partialAngleMatch?.length > 1 ? partialAngleMatch[1] : '';
        }
      } else if (base.includes('>')) {
        const partialAngleMatch = /(.+@.+)>/.exec(base);
        base = partialAngleMatch && partialAngleMatch?.length > 1 ? partialAngleMatch[1] : '';
      }
      return base;
    },
    title: (value: string) => {
      let base = value;
      const longParse = longFormTitleRegEx.exec(base);
      if (longParse) {
        let prefix = 'CT';
        const ttypeLong = longParse[1].toLowerCase().replace(/\s/g,'');
        switch (ttypeLong) {
          case 'certificateoftitle': prefix='CT'; break;
          case 'crownlease': prefix='CL'; break;
          case 'crownrecord': prefix='CR'; break;
          default: prefix='CT'; break;
        }
        base = `${prefix} ${longParse[2]}/${longParse[3]}`;
      }
      const transform1 = inputTransformers.title(base);
      const canonical1 = canonicalisers.title(transform1);
      if (canonical1.valid) {
        return canonical1.display;
      }
      return base;
    }
  }
};
