import { SignerProxyType } from '@property-folders/contract';

export class Predicate {
  static isNotNull<T>(x: T): x is NonNullable<T> {
    return !!x;
  }
  static isNotNullish<T>(x: T): x is NonNullable<T> {
    return x != null;
  }
  static isTruthy<T>(x: T): x is NonNullable<T> {
    return !!x;
  }
  static isNullish(x: any) {
    return Boolean(x == null);
  }
  static isString(x: any): x is string {
    return typeof x === 'string';
  }
  static isNotString<T>(x: T): x is Exclude<T, string> {
    return typeof x !== 'string';
  }
  static truthyOrUndefined(x: any) {
    return x === undefined || !!x;
  }
  static truthyOrEmpty(x: any) {
    return x === '' || x === undefined || !!x;
  }
  static emptyOrEqual(test: any, expect: any | any[]) {
    if (test === '' || test === undefined) {
      return true;
    }
    if (Array.isArray(expect)) {
      for (const expectation of expect) {
        if (test === expectation) return true;
      }
    } else {
      return test === expect;
    }
    return false;
  }
  static strEmptyOrWhitespace(x: string | undefined | null) {
    if (x == null) return true;
    return !x.trim();
  }
  static boolFalse(x: any) {
    return typeof x === 'boolean' && !x;
  }
  static isUuidForm(x: string) {
    if (typeof x !== 'string') {
      return false;
    }
    return !!x.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
  }

  static proxySelfOrNullish(value: any) {
    return value === SignerProxyType.Self || value == null;
  }

  static proxyNotSelf<T>(value: T): value is NonNullable<T> {
    return value !== SignerProxyType.Self && value != null;
  }
}
