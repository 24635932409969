import { ErrorBoundary as EB } from 'react-error-boundary';
import React from 'react';
import { AwsRum } from 'aws-rum-web';

export class ErrorBoundary extends EB {
  static rumWarningDisplayed = false;

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    super.componentDidCatch(error, info);
    const rum: AwsRum | null = (window as any)._awsRum;

    if (rum) {
      if (error || info) {
        rum.recordError({ ...(error||{}), react: info });
      }
    } else {
      if (!ErrorBoundary.rumWarningDisplayed) {
        console.warn('Rum not configured');
        ErrorBoundary.rumWarningDisplayed = true;
      }
    }
  }
}
