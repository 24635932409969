import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import { PurchaserPortalApi } from '~/api';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';

export function ConfirmWithdrawModal({ portalId, offerId, onClose }: { portalId: string, offerId: string, onClose: () => void }) {
  return <Modal show={true} onHide={() => onClose()}>
    <Modal.Header>
      <Modal.Title>Are you sure?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      You are about to withdraw this offer, are you sure?
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => onClose()}>Cancel</Button>
      <AsyncButton
        key='withdraw'
        variant='danger'
        onClick={async () => {
          try {
            await PurchaserPortalApi.postPurchaserPortalWithdrawOffer(portalId, offerId);
            onClose();
          } catch (err: unknown) {
            console.error(err);
          }
        }}
      >Withdraw</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
