export function setOrUnsetIfUndefined(setterParent: any, setKey: string, setValue: any) {
  // Not going to detect undefined parent, other than hide the error, what would we do?

  // As I understand it, either Y or immer doesn't like null, so we'll clear in either case
  if (setValue === undefined || setValue === null) {
    if (setterParent[setKey] !== undefined) {
      delete setterParent[setKey];
    }
  } else {
    setterParent[setKey] = setValue;
  }
}

export const bindSetOrUnsetIfUndefined = (setterParent: any) => (setKey: string, setValue: any): void => {
  setOrUnsetIfUndefined(setterParent, setKey, setValue);
};
