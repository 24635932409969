export enum YDocContentType {
  Unknown = 0,
  Property = 1,
  UserPreferences = 2,
  PropertyStats = 3,
  EntitySettings = 4,
  PurchaserPortal = 5
}

export interface YMessage {
  /**
   * Document ID
   */
  id: string;

  /**
   * Content Type
   * Information about the kind of content within the document
   * Recipient can use this to decide on different behaviours relating to the update content.
   * If the value is crucial to some functionality,
   *   It's recommended the recipient double-check by loading the whole document and then deciding what to do.
   * If the value is not critical, the recipient can opt to trust the supplied value and behave appropriately.
   *   (e.g. if it's a user prefs message, then the server may update mysql immediately, vs bundling property updates into time slices)
   */
  ct: YDocContentType
}

export function IsYMessage(object: any): object is YMessage {
  return !!object.id && object.ct != null;
}

export interface YSequentialMessage extends YMessage {
  /**
   * Unique message Id
   */
  mid: string;
}

export function IsYSequentialMessage(object: any): object is YSequentialMessage {
  return !!object.mid;
}

export function IsYUpdateMessage(object: any): object is YUpdateMessage {
  return object &&
    IsYMessage(object) &&
    'type' in object &&
    object.type === 'y-up';
}

/**
 * Ask peer to send document changes if it receives them.
 * i.e. Subscribe to document changes.
 */
export interface YSubscribeMessage {
  type: 'y-sub';

  /**
   * List of documents to subscribe to
   */
  docs?: {
    /**
     * Document ID
     */
    id: string;

    /**
     * Type of content expected in the doc
     */
    ct: YDocContentType

    /**
     * Requestor's state vector for this document, base64 encoded.
     *
     * If supplied, the requestor would like peer to send a {@link YUpdateMessage}
     */
    vector?: string;
  }[];
}

/**
 * Ask peer to stop sending document changes if it receives them.
 * i.e. Unsubscribe to document changes.
 */
export interface YUnsubscribeMessage {
  type: 'y-unsub';

  /**
   * List of Document IDs
   */
  ids?: string[];
}

/**
 * Send document updates to a peer.
 */
export interface YUpdateMessage extends YSequentialMessage {
  type: 'y-up';

  /**
   * Document ID
   */
  id: string;

  /**
   * Document update, base64 encoded.
   */
  update?: string;

  /**
   * Pre-signed URL for big updates
   * @deprecated
   */
  uri?: string;

  /**
   * Pre-signed URL for big updates
   */
  url?: string;
}

/**
 * Send awareness updates to a peer
 */
export interface YAwarenessUpdateMessage extends YMessage {
  type: 'y-aw-up';

  /**
   * Known awareness state
   */
  update: string;
}

/**
 * For cases where client has a pending update that is too big to send without a pre-signed URL.
 * Server should respond with an appropriate y-getupdates message
 */
export interface YUpdatePendingMessage extends YMessage {
  type: 'y-pend';
}

/**
 * Request document updates from a peer.
 */
export interface YGetUpdatesMessage extends YMessage {
  type: 'y-get';

  /**
   * Requestor's state vector, base64 encoded.
   *
   * If not supplied, give everything back
   */
  vector?: string;

  /**
   * Pre-signed URL for big updates
   * @deprecated use url instead
   */
  uri?: string;

  /**
   * Pre-signed URL for big updates
   */
  url?: string;
  headers?: {[key: string]: string};
}

/**
 * Request awareness update from a peer.
 * @deprecated
 */
export interface YAwarenessGetUpdateMessage extends YMessage {
  type: 'y-aw-get';
}

/**
 * Heartbeat messages.
 * In response to a ping, send a pong.
 */
export interface PingPongMessage {
  type: 'ping' | 'pong';
}

export interface YSequentialAckMessage extends YMessage {
  type: 'y-ack' | 'y-nack';

  /**
   * Original message ID this is in reply to
   */
  omid: string;
}

export interface PropertyAccessNotification {
  type: 'property-access',
  propertyId: string
}

/**
 * If a client receives this message, they should stop sending messages related to the specified ids, and delete what's stored locally.
 */
export interface YDocAccessDeniedNotification {
  type: 'y-denied',
  ids: string[]
}

/**
 * Client sends this message to ask the server for some temporary storage urls
 */
export interface RequestPreSignedUrls {
  type: 'psurl-request';
  count?: number;
}

export interface RequestPreSignedRequests {
  type: 'psreq-request';
  count?: number;
}

/**
 * Server sends this message to give the client some pre-signed urls
 */
export interface GivePreSignedUrls {
  type: 'psurl-provide';
  urls: string[];
}

export interface GivePreSignedRequests {
  type: 'psreq-provide';
  reqs: {
    url: string;
    headers?: Record<string,string>;
  }[];
}

/**
 * Client tells server to load the pre-signed url at this location and process it
 */
export interface ProcessPreSignedUrl {
  type: 'psurl-process';
  url: string;
}

/**
 * Messages that can potentially be sent over the wire
 */
export type WsMessage =
  | PingPongMessage
  | YUpdateMessage
  | YUpdatePendingMessage
  | YGetUpdatesMessage
  | YSubscribeMessage
  | YUnsubscribeMessage
  | YSequentialAckMessage
  | YAwarenessUpdateMessage
  | YAwarenessGetUpdateMessage
  | PropertyAccessNotification
  | YDocAccessDeniedNotification
  | RequestPreSignedUrls
  | RequestPreSignedRequests
  | GivePreSignedUrls
  | GivePreSignedRequests
  | ProcessPreSignedUrl;
