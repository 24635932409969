export function toTitleCase<T extends string|undefined>(str: T): T {
  if (!isString(str)) {
    // return null or undefined
    return str;
  }

  let transform = str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );

  // mccarthy -> McCarthy
  if (transform.startsWith('Mc')) {
    transform = 'Mc' + transform.charAt(2).toUpperCase() + transform.substr(3);
  }

  // d'arcy -> D'Arcy
  if (transform.charAt(1) === '\'') {
    transform = transform.charAt(0) + '\'' + transform.charAt(2).toUpperCase() + transform.substr(3);
  }

  // sAlly-Anne -> Sally-Anne
  if (transform.indexOf('-') !== -1) {
    transform = transform.split('-').map(t => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()).join('-');
  }

  return transform as T;
}

function isString(t: any): t is string {
  return typeof t === 'string';
}
