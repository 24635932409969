import { PathType } from '@property-folders/contract/yjs-schema/model';
import { bind, UpdateFn, Snapshot } from 'immer-yjs';
import { useContext, useState, useMemo } from 'react';
import { v4 } from 'uuid';
import { UPDATING_FLASH_TIME } from '@property-folders/common/data-and-text/constants';
import { getValueByPath, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { useTimeout } from '@property-folders/components/hooks/useTimeout';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';

/**Gives an update function that allows immer style updating of this path
 *
 * Note that this does not trigger any validation (at least not yet), nor any group update functions
 * or special validation. It should only be used for data that i  s not entered directly by the user
 * or is otherwise not updated from our from. The initial use of this is for setting owning entity
 * details, where the data source is controlled by the db.
 *
 * It is notable that because use transaction field will recieve these updates, it will still
 * perform update flashes on the relevant fields, as they will see the changes as their own.
 * Thankfully their logic will also prevent them actually performing a ydoc update because the
 * value won't be different to what is in the ydoc. It is probably using the 'hack' flash path
 * which was originally developed to allow people to re-enter the same data formatted differently,
 * and make it seem like it is updating so they don't get confused why it isn't doing anything
 *
 * @param param0.path Draft parameter to your update function will be from here
 * @returns
 */
export function useYdocBinder<TValue extends Snapshot>({ path, ydocForceKey, noDebounce, bindToMetaKey }:{path: PathType, ydocForceKey?: string, noDebounce?: boolean, bindToMetaKey?: boolean}) {
  const { ydoc, transactionRootKey, transactionMetaRootKey,declareDebounce, clearDeclareDebounce } = useContext(YjsDocContext);
  const [updatedDelayID, setUpdatedDelayID] = useState<null|string>(null);
  const rootBinder = useMemo(()=>ydoc && bind<TValue>(ydoc.getMap(ydocForceKey || (bindToMetaKey && transactionMetaRootKey) || transactionRootKey)), [ydoc]);
  const updateDraft = rootBinder
    ? (userFunc: UpdateFn<TValue>) => {
      rootBinder.update(draft => {
        const scopedDraft = getValueByPath(path, draft);
        return userFunc(scopedDraft);
      });
      !noDebounce && declareDebounce(normalisePathToStr(path));
      !noDebounce && setUpdatedDelayID(v4());
    }
    : undefined;

  useTimeout(()=>{
    clearDeclareDebounce(normalisePathToStr(path));
  }, UPDATING_FLASH_TIME, updatedDelayID);
  return { updateDraft, rootBinder };
}
