import { createContext, ReactNode, useMemo, useState } from 'react';

export type PDFLoadStateContextType = {
  awaitingLoadCompletion: boolean

};

export type PDFLoadStateSetterType = {
  setAwaitingLoadCompletion: (complete: boolean) => void
};

// Seperate because context updates will propogate to every consumer, and we have a lot of write
// only sources

export const PDFLoadStateContext = createContext<PDFLoadStateContextType>({
  awaitingLoadCompletion: true
});

export const PDFLoadStateSetterContext = createContext<PDFLoadStateSetterType>({
  setAwaitingLoadCompletion: () => { /**/ }
});

export function SetupPdfLoadStateContext(props: { children: ReactNode }) {
  const [pdfActiveUpdate, setPdfActiveUpdate] = useState(false);
  const loadState = useMemo<PDFLoadStateContextType>(()=>({
    awaitingLoadCompletion: pdfActiveUpdate
  }), [pdfActiveUpdate]);
  const loadStateSetter = useMemo<PDFLoadStateSetterType>(() => {
    return {
      setAwaitingLoadCompletion: (value: boolean) => {
        setPdfActiveUpdate(value);
      }
    };
  }, []);

  return (
    <PDFLoadStateContext.Provider value={loadState}>
      <PDFLoadStateSetterContext.Provider value={loadStateSetter}>
        {props.children}
      </PDFLoadStateSetterContext.Provider>
    </PDFLoadStateContext.Provider>
  );
}
