import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getPathParentAndIndex, getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';
import { Predicate } from '../../../../predicate';
import { FeeCalcMechanism } from '@property-folders/contract';

/**Replace path segments with other for array IDs. In place replacement
 *
 * @param template
 * @param replaceSegments
 * @returns
 */
function hydratePathTemplateWithIDs (template: string[], replaceSegments: (string|null)[]) {
  if (template.length !== replaceSegments.length) throw new Error('Paths don\'t match');
  for (const i in template) {
    const replacement = replaceSegments[i];
    if (replacement === null) continue;
    if (!template[i].startsWith('[')) throw new Error('Paths don\'t match definition');
    template[i] = replacement;
  }
  return template;
}

/**Swap lower and upper values
 *
 * If the two paths exist under an array entry, the two paths must be in the same object as
 * properties
 *
 * @param lowerFieldTemplatePath
 * @param upperFiledTemplatePath
 * @returns
 */
const swapRangeValuesIfWrongFactory:
(lowerFieldTemplatePath: string, upperFiledTemplatePath: string, discountTemplatePath: string, modePath: string, preprocessor?: <T>(input: T)=>number, postprocessor?: <T>(input: number)=>T) => FieldGroupFn =
(lowerFieldTemplatePath, upperFiledTemplatePath, discountTemplatePath, modePath, preprocessor)=>
  (fieldId, updatedPath, immerProxy) => {
    const pathSpecifics = normalisePathToStrArray(updatedPath).map(segment=>segment.startsWith('[') ? segment : null);
    let pathNormal = normalisePathToStrArray(lowerFieldTemplatePath);
    let pathUpper = normalisePathToStrArray(upperFiledTemplatePath);
    let pathDiscount = normalisePathToStrArray(discountTemplatePath);
    let pathMode = normalisePathToStrArray(modePath);
    if (pathSpecifics.filter(Predicate.isNotNullish).length) {
      pathNormal = hydratePathTemplateWithIDs(pathNormal, pathSpecifics);
      pathUpper = hydratePathTemplateWithIDs(pathUpper, pathSpecifics);
      pathDiscount = hydratePathTemplateWithIDs(pathDiscount, pathSpecifics);
      pathMode = hydratePathTemplateWithIDs(pathMode, pathSpecifics);
    }
    const normalRaw = getValueByPath(pathNormal, immerProxy, true);
    const upperRaw = getValueByPath(pathUpper, immerProxy, true);
    const discountRaw = getValueByPath(pathDiscount, immerProxy, true);
    const modeRaw = getValueByPath(pathMode, immerProxy, true) as FeeCalcMechanism;

    if (modeRaw === FeeCalcMechanism.Discounted) {
      const lowerNum = (preprocessor??parseFloat)(discountRaw);
      const upperNum = (preprocessor??parseFloat)(normalRaw);
      if (isFinite(lowerNum) && isFinite(upperNum) && lowerNum > upperNum) {
        const { parent: parentLow, indexer: indexerLow } = getPathParentAndIndex(pathDiscount, immerProxy);
        const { parent: parentHigh, indexer: indexerHigh } = getPathParentAndIndex(pathNormal, immerProxy);
        parentHigh[indexerHigh] = discountRaw; // Perform swap, so variable names shouldn't match
        parentLow[indexerLow] = normalRaw;
      }
      return;
    }

    const lowerNum = (preprocessor??parseFloat)(normalRaw);
    const upperNum = (preprocessor??parseFloat)(upperRaw);

    if (isFinite(lowerNum) && isFinite(upperNum) && lowerNum > upperNum) {
      const { parent: parentLow, indexer: indexerLow } = getPathParentAndIndex(pathNormal, immerProxy);
      const { parent: parentHigh, indexer: indexerHigh } = getPathParentAndIndex(pathUpper, immerProxy);
      parentHigh[indexerHigh] = normalRaw;
      parentLow[indexerLow] = upperRaw;
    }
  };

export const swapFixedFeeRangeIfWrong = swapRangeValuesIfWrongFactory(
  'professFee.fixed',
  'professFee.fixedUpper',
  'professFee.fixedDiscount',
  'professFee.fixedMode',

);

export const swapCommissionFeeRangeIfWrong = swapRangeValuesIfWrongFactory(
  'professFee.commis',
  'professFee.commisUpper',
  'professFee.commisDiscount',
  'professFee.commissionMode',
  (input: string)=> parseFloat(`${input}`.replace(/[^0-9-.]/g,''))
);
