import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { generateParentPathWithProperty, getValueByPath } from '../../../../util/pathHandling';
import { canonicalisers } from '../../../../util/formatting';

export const softFormatAud: FieldGroupFn = (fieldId, updatedPath, immerProxy) => {
  const { parentPath, property } = generateParentPathWithProperty(updatedPath);
  const parent = getValueByPath(parentPath, immerProxy) as unknown;

  if (typeof parent !== 'object') return;
  if (!parent) return;
  if (!(property in parent)) return;

  const value = parent[property];
  const canon = canonicalisers.aud(value);
  if (!canon.valid) return;
  if (canon.display === value) return;

  parent[property] = canon.display;
};
