import { fieldsSpacing } from '..';
import { LegalActs } from '../constants';
import { formatBI } from '../formatBI';

import type { LegalActsType, LegalJurisdictionValue } from '../constants';
import type { Content, OrderedListElement, StyleReference } from 'pdfmake/interfaces';

export type ReaformsContentMarkerOverrides = {
  markerOverrides?: {
    style?: StyleReference | 'sectionDetailSubTitle'
  }
};

// Really should have a proper type if we got custom adding types
export type ReaformsContent = Content & ReaformsContentMarkerOverrides & { ol?: (OrderedListElement & ReaformsContentMarkerOverrides)[] };

/**
 * Wrap `text` with `prefix` and `suffix`.
 * @param text text to wrap.
 * @param prefix string to prefix `text` with.
 * @param suffix string to suffix `text` with.
 * @returns wrapped string.
 * @example
 * wrap('wrap me', '+', '-');
 * // "+wrap me-"
 */
export function wrap<T extends string, TPrefix extends string, TSuffix extends string>(text: T, prefix: TPrefix, suffix: TSuffix): `${TPrefix}${T}${TSuffix}` {
  return `${prefix}${text}${suffix}`;
}

/**
 * Wrap `text` in single quotes.
 * @param text text to wrap.
 * @returns `text` wrapped in single quotes.
 * @example
 * quote('important');
 * // "‘important’"
 */
export function quote<T extends string>(text: T): `‘${T}’` {
  return wrap(text, '‘', '’');
}

/**
 * Wrap `text` in double quotes.
 * @param text text to wrap.
 * @returns `text` wrapped in double quotes.
 * @example
 * quote('important');
 * // "“important”"
 */
export function doubleQuote<T extends string>(text: T): `“${T}”` {
  return wrap(text, '“', '”');
}

/**
 * Prepare `text` for bolding with `formatBI()`.
 * - Wrap `text` in `*-*`.
 * @param text text to wrap.
 * @returns `text` wrapped in bold.
 * @example
 * bold('important');
 * // "*-*important*-*"
 */
export function bold<T extends string>(text: T): `*-*${T}*-*` {
  return wrap(text, '*-*', '*-*');
}

/**
 * Prepare `text` for italics with `formatBI()`.
 * - Wrap `text` in `/-/`.
 * @param text text to wrap.
 * @returns `text` wrapped in italics.
 * @example
 * italic('important');
 * // "/-/important/-/"
 */
export function italic<T extends string>(text: T): `/-/${T}/-/` {
  return wrap(text, '/-/', '/-/');
}

export type FormatActOptions =
  | { allItalics?: never; noItalics?: boolean; noLegalJurisdiction?: boolean }
  | { allItalics?: boolean; noItalics?: never; noLegalJurisdiction?: never };

/**
 * Format Act.
 * @param legalJurisdiction Legal jurisdiction.
 * @param actName Act name relative to `legalJurisdiction`.
 * @param options Optional formatting options.
 * @returns Formatted Act string.
 * @example
 * ```ts
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999');
 * // /-/A New Tax System (Goods and Services Tax) Act 1999/-/ (Cth)
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { noItalics: true });
 * // A New Tax System (Goods and Services Tax) Act 1999 (Cth)
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { allItalics: true });
 * // /-/A New Tax System (Goods and Services Tax) Act 1999 (Cth)/-/
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { noLegalJurisdiction: true });
 * // /-/A New Tax System (Goods and Services Tax) Act 1999/-/
 * formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999', { noItalics: true, noLegalJurisdiction: true });
 * // A New Tax System (Goods and Services Tax) Act 1999
 * ```
 */
export function formatAct<TLegalJurisdiction extends LegalJurisdictionValue>(
  legalJurisdiction: TLegalJurisdiction,
  actName: keyof LegalActsType[TLegalJurisdiction],
  options?: FormatActOptions
) {
  const act = LegalActs[legalJurisdiction][actName] as string;
  const { allItalics, noItalics, noLegalJurisdiction } = options ?? {};

  if (allItalics) {
    return italic(`${act} (${legalJurisdiction})`);
  } else if (noItalics) {
    return `${act}${noLegalJurisdiction ? '' : ` (${legalJurisdiction})`}`;
  } else {
    return `${italic(act)}${noLegalJurisdiction ? '' : ` (${legalJurisdiction})`}`;
  }
}

export function clause(title: string, ...items: ReaformsContent[]): ReaformsContent {
  return {
    stack: [
      { ...header(title), margin: [0, 0, 0, fieldsSpacing] },
      ...items
    ],
    markerOverrides: { style: 'sectionDetailSubTitle' }
  };
}

export function stack(...items: ReaformsContent[]) {
  // Apply same style to stack if a style exists, eg. `header()` style
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (typeof item === 'object' && 'style' in item) {
      return {
        stack: [ ...items ],
        markerOverrides: { style: item.style } };
    }
  }

  return {
    stack: [ ...items ]
  };
}

export function header(heading: string) {
  return { text: heading, style: 'sectionDetailSubTitle' };
}

export function ol (...items: (ReaformsContent | undefined )[]) {
  const result: ReaformsContent = { ol: [] };

  for (let i = 0; i < items.length; i++) {
    const item: ReaformsContent | undefined = items[i];

    // Allow conditional items by not handling `undefined`
    if (item === undefined) {
      continue;
    }

    const nextItem: ReaformsContent | undefined = items[i + 1];
    const itemToAdd = typeof item === 'string' ? formatBI(item) : item;

    if (typeof nextItem === 'object' && 'ol' in nextItem && nextItem.ol && nextItem.ol.length > 0) {
      const li: ReaformsContent = { stack: [] };
      li.stack.push(itemToAdd);
      li.stack.push(nextItem);
      // Apply same style to stack if a style exists, eg. `header()` style
      if (typeof itemToAdd === 'object' && 'style' in itemToAdd) {
        li.markerOverrides = { style: itemToAdd.style };
      }
      result.ol.push(li);
      i++;
    } else {
      result.ol.push(itemToAdd);
    }
  }
  return result;
}

/**
 * Create page break after content.
 * - Using a function to contain this mess in case want to update in the future.
 * @returns Content object to break page after.
 * @example
 * ```ts
 * clause('My Clause Header',
 *   'Test List:',
 *   ol(
 *     'First list item related to Test List',
 *     'Second list item related to Test List',
 *     'Third list item related to Test List',
 *   ),
 *   // Create a page break
 *   breakAfter(),
 *   'Content on next page'
 * ),
 * ```
 */
export const breakAfter = (): Content => {
  return { text: '', pageBreak: 'after' };
};

/**
 * Create page break before content.
 * - Using a function to contain this mess in case want to update in the future.
 * @returns Content object to break page before.
 * @example
 * ```ts
 * clause('My Clause Header',
 *   'Test List:',
 *   // Create a page break
 *   breakBefore(),
 *   // This list is on the next page
 *   ol(
 *     'First list item related to Test List',
 *     'Second list item related to Test List',
 *     'Third list item related to Test List',
 *   ),
 * ),
 * ```
 */
export const breakBefore = (): Content => {
  return { text: '', pageBreak: 'before' };
};

export { LegalActs };
