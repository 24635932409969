import { createSlice } from '@reduxjs/toolkit';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';

export type EntitySettingsExtendedType =
  Pick<EntitySettingsEntity, 'entityId' | 'name' | 'salespeople'> & Partial<EntitySettingsEntity>;

export type BelongingEntityMeta = {
  [entityId: string]: EntitySettingsExtendedType | undefined;
};

export const REDUCER_NAME = 'entityMeta';

const thisReducer = createSlice({
  name: REDUCER_NAME,
  initialState: {} as BelongingEntityMeta,
  reducers: {
    updateEntityMetaEntry(
      state,
      action: { payload: { id: number, data: EntitySettingsExtendedType }}
    ) {
      state[action.payload.id] = action.payload.data;
    },
    removeOtherEntityMetaEntries(state, action) {
      const keepIds = new Set((action.payload.keepIds as number[]).map(x => x.toString()));
      for (const key of Object.keys(state)) {
        if (keepIds.has(key)) continue;
        delete state[key];
      }
    },
    markLogoUpdated(state, action: {payload: {id: string, updatedTime: number}}) {
      const item = state[action.payload.id];
      if (item) {
        item.lastLogoUpdateInSession = action.payload.updatedTime;
      }
    }
  }
});

export const {
  updateEntityMetaEntry,
  removeOtherEntityMetaEntries,
  markLogoUpdated
} = thisReducer.actions;
export default thisReducer.reducer;
