import { configureStore } from '@reduxjs/toolkit';
import validation from '@property-folders/common/redux-reducers/validation';
import navigation from '@property-folders/common/redux-reducers/navigation';
import { restApiSlice } from '@property-folders/common/redux-reducers/restApiSlice';
import entityMeta from '@property-folders/common/redux-reducers/entityMeta';
import guidanceNotes from '@property-folders/common/redux-reducers/guidanceNotes';
import newlyAvailableFiles from '@property-folders/common/redux-reducers/available-files';

export const store = configureStore({
  reducer: {
    validation,
    navigation,
    [restApiSlice.reducerPath]: restApiSlice.reducer,
    entityMeta,
    guidanceNotes,
    newlyAvailableFiles
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(restApiSlice.middleware)
});
