import React, { createContext } from 'react';

type WizardFieldFocusState = {
  addFieldFocus: (fieldId: string, evt: React.FocusEvent<HTMLElement, Element>) => void;
  remFieldFocus: (fieldId: string, evt: React.FocusEvent<HTMLElement, Element>) => void;
};
// No guarantee of this context being available

export const WizardFieldFocusStateContext = createContext<WizardFieldFocusState | undefined>({
  addFieldFocus: () => undefined,
  remFieldFocus: () => undefined
});

export type WizardDisplayContextType = {
  showFocusErrors: boolean;
  focusErrList: string[];
};

export const WizardDisplayContext = createContext<WizardDisplayContextType>({
  showFocusErrors: false,
  focusErrList: []
});
