import React, { createContext, useContext, useMemo } from 'react';

export type ModalDepthContextType = {
  depth: number;
  modalClassName: string;
  backdropClassName: string;
};

export const ModalDepthContext = createContext<ModalDepthContextType>({
  depth: 0,
  modalClassName: '',
  backdropClassName: ''
});

export function WithinModal(props: React.PropsWithChildren) {
  const { depth: oldDepth } = useContext(ModalDepthContext);
  const value = useMemo<ModalDepthContextType>(() => {
    const depth = oldDepth + 1;
    return {
      depth,
      modalClassName: `z-bump-${depth}`,
      backdropClassName: `z-bump-${depth}`
    };
  }, [oldDepth]);
  return <ModalDepthContext.Provider value={value}>
    {props.children}
  </ModalDepthContext.Provider>;
}
