import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormCode } from '../../property';
import { salesAgencyAgreement } from './sales-agency-agreement';
import { saleContractFormRules } from './sale-contract-residential';

export * from './new-transaction';
export * from './sales-agency-agreement';
export * from './sale-contract-residential';
export * from './agency-agreement-variation-overlay';
export * from './entity-settings';

// use this to deep-sort object properties for storing in form definitions above^
export function sortObj(obj: any) {
  if (typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj;

  const newObj: any = {};
  Object.keys(obj).sort().forEach(k => {
    newObj[k] = sortObj(obj[k]);
  });
  return newObj;
}
FormTypes;
export const formCodeRuleMap = {
  [FormCode.RSAA_SalesAgencyAgreement]: salesAgencyAgreement,
  [FormCode.RSC_ContractOfSale]: saleContractFormRules
};
