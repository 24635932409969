import { MaterialisedPropertyData } from '@property-folders/contract';
import { MultipleExpectedValuePathDefn, PathType } from '@property-folders/contract/yjs-schema/model';
import { getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';
import { CustomValidationReturnType } from '@property-folders/contract/yjs-schema/model/custom-point-validator';

export function notBeforeDate(
  nodeTree: MaterialisedPropertyData,
  updatedPath: string[] | undefined,
  params?: {otherDatePath: PathType, enableIf: MultipleExpectedValuePathDefn}
): CustomValidationReturnType {
  const mutablePath = [];

  for (const pathSegment of normalisePathToStrArray(params?.otherDatePath??[])) {
    if (pathSegment === '..') {
      mutablePath?.splice(mutablePath.length-1,1);
      continue;
    }
    if (pathSegment === '.') {
      mutablePath.push(...((updatedPath??[]).slice()));
      continue;
    }
    mutablePath?.push(pathSegment);
  }
  const referenceRaw = getValueByPath(mutablePath, nodeTree, true);
  const complyingValue = new Date(getValueByPath(updatedPath, nodeTree, true));
  const referenceValue = new Date(referenceRaw);
  if (referenceValue.getTime() > complyingValue.getTime()) {
    return ['notBeforeOtherDate'];
  }
  return [];
}