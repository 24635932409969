import _, { cloneDeep } from 'lodash';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { baseReaformsCost, epfBundlePrice } from '../../static-pricing';
import { DocumentFieldType, PathSegments, PathType } from '@property-folders/contract/yjs-schema/model';
import { canonicalisers } from '../formatting/canonicalisers';
import { formatTimestamp } from '../formatting/functions/formatTimestamp';
import { getValueByPath, normalisePathToStrArray } from '../pathHandling';
import { expectedValueEvaluatorDirectory } from '../../yjs-schema/property/validation/expected-evaluator';
import { Predicate } from '../../predicate';
import { determineReplacedRemovedLocked } from '../dataExtract';
import { DiffCollection } from '../form/DiffCollection';
import { FormInstance } from '@property-folders/contract';
import { DataGeneration } from '../dataExtractTypes';

import type { CanonicalResults } from '../formatting/canonicalisers';

const getLeafMatchPaths = (fieldDefnObj: DocumentFieldType, currPath: PathSegments = []): [string, string[]][] => {
  let matchList: [string, PathSegments][] = [];
  if (fieldDefnObj?._type === 'Array' && fieldDefnObj._children) {
    matchList = matchList.concat(getLeafMatchPaths(fieldDefnObj._children, currPath.concat(['[]'])));
  } else if (fieldDefnObj?._type === 'Map') {
    for (const key in fieldDefnObj) {
      if (!key.startsWith('_')) {
        matchList = matchList.concat(getLeafMatchPaths(fieldDefnObj[key], currPath.concat([key])));
      }
    }
  } else if (typeof fieldDefnObj?._subtype === 'string') {
    matchList.push([fieldDefnObj._subtype, currPath]);
  }
  return matchList;
};

const subtypeMatchers = (rules: DocumentFieldType)=>{
  const matchers: {[subtype: string]: PathSegments[]} = {};
  const subtypeList = getLeafMatchPaths(rules);
  subtypeList.forEach(([subtype, path])=>{
    if (!matchers[subtype]) {
      matchers[subtype] = [];
    }
    matchers[subtype].push(path);
  });
  return matchers;
};

const PROPERTY_FOLDER_SUBTYPE_MATCHERS = subtypeMatchers(propertyFolder);

const transformAtPath = (dataTree: {[key:string]: any}, subtype: string, path: PathSegments) => {
  if (path.length === 1) {
    const canonResult: CanonicalResults | undefined = (dataTree && canonicalisers[subtype]?.(dataTree?.[path[0]])) as CanonicalResults || undefined;
    canonResult && (dataTree[path[0]] = canonResult?.display);
    canonResult && subtype === 'aud' && (dataTree[`${path[0]}_raw`] = canonResult?.canonical);
    if (canonResult?.components) {
      for (const virtualSibling in canonResult.components) {
        dataTree[virtualSibling] = canonResult.components[virtualSibling];
      }
    }
  } else if (path.length > 1 && path[0] === '[]' && Array.isArray(dataTree)) {
    for (const k in dataTree) {
      transformAtPath(dataTree[k], subtype, path.slice(1));
    }
  } else if (path.length > 1 && typeof dataTree === 'object' && path[0] in dataTree) {
    transformAtPath(dataTree[path[0]], subtype, path.slice(1));
  }
};

const transformDataTreeForDisplay = (matchers: ReturnType<typeof subtypeMatchers>)=>(dataTree: {[key:string]: any}) => {
  const r = cloneDeep(dataTree);
  for (const subtype in matchers) {
    const matchList = matchers[subtype];
    for (const match of matchList) {
      transformAtPath(r, subtype, match);
    }
  }
  return r;
};

export const transformPropertyFolderDataForDisplay = transformDataTreeForDisplay(PROPERTY_FOLDER_SUBTYPE_MATCHERS);

export function mapOptsForCheckbox(
  mapSrc: {[key: string]: string}
  | {[key: string|number]: {label: string, order?: number}},
  otherValue?: string
) {
  let asList = Object.entries(mapSrc);
  if (asList.length === 0) {
    return [];
  }
  if (typeof asList[0][1] !== 'string' && asList[0][1].order != null)  {
    asList = _.sortBy(asList, i=>i[1].order);
  }
  return asList.map(([k,v]) => {
    const label = typeof v === 'string' ? v : v.label;
    if (label.toLowerCase() === 'other') {
      return { other: true, label, selectMatch: k, content: otherValue };
    }
    return { other: false, label, selectMatch: k };
  });
}

export type StatutoryFeeDescObject = {
  reaformsStandalonePrice: number,
  reaformsStandaloneDesc: string|Content,
  reaformsEpfBundlePrice: number,
  reaformsEpfBundleDesc: string|Content,
  statutorySearchDesc: string|Content
};

export function generateStatutoryFeeDescObject (): StatutoryFeeDescObject {
  return {
    reaformsEpfBundleDesc: {
      stack: [
        'Standard legal package',
        {
          ul: [
            'reaforms - legal documentation fee $66.00',
            'Eckermann Property Forms - Form 1 preparation fee $330.00'
          ]
        }
      ]
    },
    reaformsEpfBundlePrice: epfBundlePrice,
    reaformsStandaloneDesc: 'reaforms - legal documentation fee',
    reaformsStandalonePrice: baseReaformsCost,
    statutorySearchDesc: 'Statutory and Council Searches'
  };

}

export function propertyFolderMaskDataNotRelevant (dataTree: {[key:string]: any}) {
  return maskDataNotRelevant(dataTree, propertyFolder);
}

export function maskDataNotRelevant(dataTree: {[key:string]: any}, fieldDefn: DocumentFieldType, node?: any, currentPath: PathSegments = []): any {
  /*
  recursively process definitions. child nodes will return an empty object, and parent will run Object.assign
  */
  const localNode = currentPath.length === 0 ? dataTree : node;
  if (fieldDefn?._noPdfValueIf) {
    const testFieldValue = getValueByPath(fieldDefn._noPdfValueIf.field, dataTree);

    if (fieldDefn?._noPdfValueIf && 'expectedValue' in fieldDefn._noPdfValueIf) {
      if (testFieldValue === fieldDefn?._noPdfValueIf.expectedValue) {
        return undefined;
      }
    }
    if (fieldDefn?._noPdfValueIf && 'notExpectedValue' in fieldDefn._noPdfValueIf) {
      if (testFieldValue !== fieldDefn?._noPdfValueIf.notExpectedValue) {
        return undefined;
      }
    }
    if (fieldDefn?._noPdfValueIf && 'oneOfExpectedValue' in fieldDefn._noPdfValueIf) {
      if (fieldDefn?._noPdfValueIf.oneOfExpectedValue.includes(testFieldValue)) {
        return undefined;
      }
    }
    if (fieldDefn?._noPdfValueIf && 'notOneOfExpectedValue' in fieldDefn._noPdfValueIf) {
      if (!fieldDefn?._noPdfValueIf.notOneOfExpectedValue.includes(testFieldValue)) {
        return undefined;
      }
    }
    if (fieldDefn._noPdfValueIf && 'empty' in fieldDefn._noPdfValueIf) {
      if (testFieldValue === '' || testFieldValue == null) {
        return undefined;
      }
    }
    if (fieldDefn._noPdfValueIf.evaluationFunction) {
      const evalFunc = expectedValueEvaluatorDirectory?.[fieldDefn._noPdfValueIf.evaluationFunction];
      if (evalFunc && evalFunc(testFieldValue)) {
        return undefined;
      }
    }
  }
  if (Array.isArray(localNode)) {
    const childFieldDefn = fieldDefn?._children;
    return localNode.map((childValue, childIndex) => {
      const pathSegment = `[${childValue?.id ?? childIndex}]`;
      const processValue = maskDataNotRelevant(dataTree, childFieldDefn, childValue, [...currentPath, pathSegment]);
      if (processValue == null) {
        return undefined;
      }
      return processValue;
    }).filter(Predicate.isTruthy);
  }
  if (localNode && typeof localNode === 'object') {
    return Object.assign({}, ...Object.entries(localNode).map(([childKey, childValue]) => {
      if (childKey.startsWith('_')) {
        return undefined;
      }
      const processValue = maskDataNotRelevant(dataTree, fieldDefn?.[childKey], childValue, [...currentPath, childKey]);
      if (processValue == null) {
        return undefined;
      }
      return { [childKey]: processValue };
    }));
  }
  return localNode;
}

const expectedUpdateStateMap = {
  'added': DataGeneration.Added,
  'removed': DataGeneration.Removed,
  'updated': DataGeneration.CarriedOver
};

type RecursiveObject = {[pathSegmentKey: string]: RecursiveObject};
function getByDirectKey (tree: RecursiveObject, pathSegments: PathSegments) {
  const nextNode = tree ? tree[pathSegments[0]] : tree;
  if (pathSegments.length <= 1) {
    return nextNode;
  }
  return getByDirectKey(nextNode, pathSegments.slice(1));

}

export function buildListDiffSpec(listHistory: ReturnType<typeof determineReplacedRemovedLocked>, currentData: any[], listPath: PathType, changeSets: DiffCollection) {
  // Create a list which makes reference to only the most recent version if removed, rather than
  // removed in general, which the current functions generate
  // Metadata returned would thus look a bit like {itemOrder: <number>, addedRemovedChanged: enum, updatedData: listItemdata}
  const positionIndicatedItems = listHistory.map((a,idx)=>({ itemOrder: idx, ...a }));
  const result: typeof positionIndicatedItems = [];

  function iterateOverDiffSet(setId: keyof typeof expectedUpdateStateMap) {
    const listPathSegs = normalisePathToStrArray(listPath);
    const tree = getByDirectKey(changeSets[setId], listPathSegs);

    for (const modKey of Object.keys(tree??{})) {
      const key = (/\[(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\]/.exec(modKey)??[])[1];
      if (!key) {
        console.warn('Expected UUID style key, not numerical key');
        continue;
      }
      const matchedHistory = positionIndicatedItems.find(lh=>lh.id === key);
      if (!matchedHistory) {
        console.warn('Change element not found in history');
        continue;
      }
      if (matchedHistory.state !== expectedUpdateStateMap[setId]) {
        console.warn('Updated type doesn\'t match expectation');
        continue;
      }
      result.push(matchedHistory);
    }
  }
  iterateOverDiffSet('added');
  iterateOverDiffSet('removed');
  iterateOverDiffSet('updated');

  result.sort((a,b) => {
    return a.itemOrder - b.itemOrder;
  });
  return result;
}

export function signingTimeInTimezoneFromInstance(instance: FormInstance, dateOnly = false, tzOverride?: string) {
  const completedTimeMs = instance.signing?.session?.completedTime??0;
  const instanceTz = tzOverride ?? instance?.signing?.session?.initiator.timeZone ?? 'Australia/Adelaide';
  return formatTimestamp(completedTimeMs, instanceTz, !dateOnly);
}
