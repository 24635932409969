import { Snapshot } from 'immer-yjs/dist/immer-yjs.es';
import {
  generateParentPath,
  getPathParentAndIndex,
  getValidationDefnByPath,
  getValueByPath,
  normalisePathToStrArray
} from './pathHandling';
import { v4 } from 'uuid';
import { extractPathIndexText, isUuidForm } from './dataExtract';
import { PathType } from '@property-folders/contract/yjs-schema/model';

function setDefinitionAtPath(path: PathType, defn: { [pathKey: string]: any }, immerDraft: Snapshot) {
  const pathSegs = normalisePathToStrArray(path);

  const { parent, indexer: indexerDerived } = getPathParentAndIndex(pathSegs, immerDraft);
  let indexer = indexerDerived;
  let insertUuid = v4();
  if (indexer === '-1') {
    let expectedId = extractPathIndexText(pathSegs[pathSegs.length - 1]);
    if (!expectedId) {
      console.warn('regex did not extract ID, is it something other than a UUID or number?');
      expectedId = ''; // Stop crashes
    }
    const speculativeInteger = Number.parseInt(expectedId);
    if (isUuidForm(expectedId)) {
      insertUuid = expectedId;
    } else if (`${speculativeInteger}` === expectedId) {
      indexer = expectedId;
    }
  }
  const validationDefn = getValidationDefnByPath(pathSegs, defn);
  switch (validationDefn._type) {
    case 'Array':
      parent[indexer] = [];
      break;
    case 'number': // Yes, an empty string is still a valid, as it represents an empty field, aka unset
    case 'string':
      parent[indexer] = '';
      break;
    case 'Map':
    default:
      if (Array.isArray(parent)) {
        // Yep, you can't splice a locally captured array, you need to get its parent in order to
        // splice it.
        const { parent: px, indexer: ix } = getPathParentAndIndex(generateParentPath(pathSegs), immerDraft);
        const setPosition = indexer === '-1' ? px[ix].length : parseInt(indexer);
        px[ix].splice(setPosition, 0, { id: insertUuid });
      } else {
        parent[indexer] = {};
      }
  }
}

export function checkBuildPath(path: PathType, defn: { [pathKey: string]: any }, immerDraft: Snapshot) {
  const pathSegs = normalisePathToStrArray(path);
  const p1 = getValueByPath(generateParentPath(pathSegs), immerDraft, true);
  if (!p1) {
    checkBuildPath(generateParentPath(pathSegs), defn, immerDraft);
    setDefinitionAtPath(generateParentPath(pathSegs), defn, immerDraft);
  }
  const { parent: p3, indexer: i3 } = getPathParentAndIndex(pathSegs, immerDraft);
  if (!p3[i3]) {
    setDefinitionAtPath(pathSegs, defn, immerDraft);
  }
}
