import { AgentSessionInfoResult } from '@property-folders/contract';
import { db } from './db';

export const CurrentSessionInfoId = 'current';

export interface ISessionPointer {
  id: string;
  agentId: number;
}

/**
 * Note: only agents can have offline sessions.
 * If that changes, we'll have to do a bit of rework to support other session types.
 */
export class OfflineSession {
  static async write(info: AgentSessionInfoResult) {
    await db.transaction('rw', db.sessionInfo, db.sessionPointers, async () => {
      await db.sessionInfo.put(info);
      await db.sessionPointers.put({
        id: CurrentSessionInfoId,
        agentId: info.agentId
      });
    });
  }

  static async read(): Promise<AgentSessionInfoResult | undefined> {
    return await db.transaction('r', db.sessionPointers, db.sessionInfo, async () => {
      const current = await db.sessionPointers.get(CurrentSessionInfoId);
      if (!current?.agentId) {
        return undefined;
      }
      return await db.sessionInfo.get(current.agentId);
    });
  }

  static async clearCurrentSessionPointer() {
    await db.sessionPointers.delete(CurrentSessionInfoId);
  }

  static async deleteAgent(agentId: number) {
    await db.transaction('rw', db.sessionInfo, db.sessionPointers, async () => {
      await db.sessionInfo.delete(agentId);
      const pointer = await db.sessionPointers.get(CurrentSessionInfoId);
      if (pointer?.agentId === agentId) {
        await db.sessionPointers.delete(pointer.id);
      }
    });
  }

  static async nonCurrentSessions() {
    return db.transaction('r', db.sessionPointers, db.sessionInfo, async () => {
      const current = await db.sessionPointers.get(CurrentSessionInfoId);

      return await db.sessionInfo
        .filter(si => si.agentId !== current?.agentId)
        .toArray();
    });
  }

  static async getAgent(agentId: number) {
    return db.sessionInfo.get(agentId);
  }
}
