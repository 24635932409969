import { FeeCalcMechanism, ProfessionalFeeType } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { generateParentPath, getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';

export const setOneFeeMode: FieldGroupFn = (_fieldId, updatedPath, immerProxy) => {
  const value = getValueByPath(updatedPath, immerProxy, true);
  if (value !== FeeCalcMechanism.None) return;

  const pathSegs = normalisePathToStrArray(updatedPath);
  const pathLeaf = pathSegs[pathSegs.length-1];
  const parentSegs = generateParentPath(pathSegs);
  const parentValue = getValueByPath(parentSegs, immerProxy, true) as ProfessionalFeeType;

  const alternatePathLeaf = pathLeaf === 'commissionMode' ? 'fixedMode' : 'commissionMode';

  if (parentValue[alternatePathLeaf] === FeeCalcMechanism.None) {
    delete parentValue[alternatePathLeaf];
  }
};
