import { FormInstance } from '@property-folders/contract';
import { determineFormState } from '../yjs-schema/property';

/**
 * Compare form instances for ordering.
 * If they have creation dates, use those.
 * In this case creation dates will be in /descending/ (newest first) order
 *
 * Otherwise, signed documents come last
 */
export function compareFormInstances(a: FormInstance, b: FormInstance) {
  const aMod = a.modified || a.created;
  const bMod = b.modified || b.created;
  if (aMod && bMod) {
    return bMod - aMod;
  }

  const aState = determineFormState(a);
  const bState = determineFormState(b);

  // Added that both being not signed shall be equivalent. This was probably a far simpler list
  // of states before, and now it's got a lot of other states which may make the sort unstable
  // if they are also without dates.
  if (aState === bState || (aState !== 'SIGNED' && bState !== 'SIGNED')) {
    return 0;
  }

  return aState === 'SIGNED'
    ? 1
    : -1;
}
