import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Predicate } from '@property-folders/common/predicate';

export interface AsyncButtonProps {
  inline?: boolean;
  onProcessingChange?: (isProcessing: boolean) => void
  onError?: MouseEventHandler<HTMLButtonElement>
  processingLabel?: React.ReactNode
}

export function AsyncButton(props: ButtonProps & AsyncButtonProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.onClick) return;

    setProcessing(true);
    Promise.resolve(props.onClick(e))
      .finally(() => setProcessing(false));
  }, [props.onClick]);

  useEffect(() => {
    props.onProcessingChange?.(processing);
  }, [processing]);
  const { processingLabel } = props;
  return <SpinnerButton {...props} onClick={onClick} disabled={props.disabled || processing} processing={processing || false}>
    {processingLabel && processing ? processingLabel : props.children}
  </SpinnerButton>;
}

export interface SpinnerButtonProps extends AsyncButtonProps {
  processing: boolean;
}

export const SpinnerButton = React.forwardRef(({ processing, ...props }: ButtonProps & SpinnerButtonProps, ref) => {
  const className = useMemo(() => {
    return [
      props.className,
      'flex-row align-items-center justify-content-center gap-1',
      props.inline ? 'd-inline-flex' : 'd-flex'
    ]
      .filter(Predicate.isTruthy)
      .join(' ');
  }, [props.className]);

  return <Button {...props} ref={ref} className={className} disabled={props.disabled || processing}>
    {processing && <Spinner animation="border" size={'sm'}/>}
    {props.children}
  </Button>;
});
