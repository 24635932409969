// We follow the structure here, because requirements may differ for different tree contexts, so

import { globalFieldGroups } from '../field';
import { merge } from 'lodash';
import { ValidationDefnType } from '../validation-field';
import { StandardFieldGroupFnMaps } from '../field-group-function';
import { vendorChildRules } from './common-rules';

// perhaps a contact in one field requires a Postal Address, is not required in another.
export const newTransaction: ValidationDefnType = {
  _atLeastDefns: [{
    focusTarget: 'property-section',
    fields: [['','saleAddrs', '[0]', 'streetAddr'], '.saleTitles.[0].title', '.headline']
  }],
  vendors: {
    _children: merge(structuredClone(vendorChildRules),{
      addressSingleLine: { _requiredIf: false } // Comment to make this required again
    }),
    _minimum: 1
  },
  agent: {
    _children: {
      _required: false,
      linkedEntityId: {
        _required: true
      },
      profileName: {
        _required: false
      },
      salesp: {
        _children: {
          _required: false,
          linkedSalespersonId: {
            _required: true
          },
          name: {
            _required: true
          }
        },
        _minimum: 1,
        _required: false
      }
    },
    _minimum: 1,
    _required: false
  }
};

export const ntFieldGroups = {
  ...globalFieldGroups,
  'vendors.[].partyType': 'partyResetActivity',
  'vendors.[].authority': 'partyResetActivity',
  'vendors.[].inTrust': 'partyResetActivity',
  'vendors.[].registeredOnTitle': 'partyResetActivity',
  'vendors.[].namedExecutors.[].partyType': 'partyResetActivity',
  'vendors.[].namedExecutors.[].authority': 'partyResetActivity',
  ...StandardFieldGroupFnMaps.dueDateText
};
