import { LoaderResult } from '~/types';
import { Predicate } from '@property-folders/common/predicate';
import React from 'react';

export function mergeLoaderResults<T = unknown>(loaders: LoaderResult[]): Promise<T> {
  return Promise
    .all(loaders.map(loader => loader?.data).filter(Predicate.isNotNull))
    .then(items => Object.assign({}, ...items) as T);
}

export function swallow(fn: () => (Promise<unknown> | Promise<void>)) {
  return () => {
    fn().finally(() => { /**/ });
  };
}

export function noBubble<T = HTMLElement>(fn?: () => void) {
  return (e: React.MouseEvent<T> | React.FormEvent<T>) => {
    e.preventDefault();
    e.stopPropagation();
    if (fn) {
      fn();
    }
  };
}
