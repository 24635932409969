import { DocumentFieldType } from '../document-field';

export const propertyFolderSigning: DocumentFieldType = {
  _type: 'Map',
  id: { _type: 'UUID', _required: true },
  mixedSigning: { _type: 'boolean' },
  vendors: {
    _type: 'Array',
    _label: 'Vendors',
    _children: {
      _type: 'Map',
      _label: 'Vendor',
      id: { _type: 'UUID', _required: true },
      email1: { _type: 'string', _subtype: 'email' },
      phone1: { _type: 'string', _subtype: 'phone' },
      email2: { _type: 'string', _subtype: 'email' },
      phone2: { _type: 'string', _subtype: 'phone' }
    }
  },
  purchasers: {
    _type: 'Array',
    _label: 'Purchasers',
    _children: {
      _type: 'Map',
      _label: 'Purchaser',
      id: { _type: 'UUID', _required: true },
      email1: { _type: 'string', _subtype: 'email' },
      phone1: { _type: 'string', _subtype: 'phone' },
      email2: { _type: 'string', _subtype: 'email' },
      phone2: { _type: 'string', _subtype: 'phone' }
    }
  },
  otherContacts: {
    _type: 'Array',
    _label: 'Contacts',
    _children: {
      _type: 'Map',
      _label: 'Contact',
      id: { _type: 'UUID', _required: true },
      email1: { _type: 'string', _subtype: 'email' },
      phone1: { _type: 'string', _subtype: 'phone' },
      email2: { _type: 'string', _subtype: 'email' },
      phone2: { _type: 'string', _subtype: 'phone' }
    }
  }
};
