import { Card, Col, Container, Row } from 'react-bootstrap';
import React from 'react';

interface ContentCardProps {
  agencyHighlights?: boolean
}

const agencyHighlightStyle: React.CSSProperties = {
  borderTopWidth: '4px',
  borderTopStyle: 'solid',
  borderTopColor: 'var(--agency-color-primary)'
};

export function ContentCard({ children, agencyHighlights }: React.PropsWithChildren<ContentCardProps>) {
  agencyHighlights = agencyHighlights === undefined ? true : agencyHighlights;
  const style: React.CSSProperties = {
    ...((agencyHighlights === undefined || agencyHighlights === true) ? agencyHighlightStyle : undefined)
  };
  return <Container fluid>
    <Row>
      <Col lg={1} xl={2}></Col>
      <Col lg={10} xl={8}>
        <Card className='bg-white' style={style}>
          {children}
        </Card>
      </Col>
      <Col lg={1} xl={2}></Col>
    </Row>
  </Container>;
}
