import { useGetIntegrationContactsForListingQuery, useGetIntegrationInfoQuery } from '@property-folders/common/redux-reducers/restApiSlice';
import { SaleAddress } from '@property-folders/contract';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { IContactSearchSuggestion, IContactSearchSuggestions } from '@property-folders/contract/rest/address-search';
import { Icon } from '../dragged-components/Icon';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { prettifyPhone } from '@property-folders/common/util/formatting';

const IntegrationRoles = ['Vendor', 'Purchaser'];

export function useIntegrationContacts(saleProperties: SaleAddress[], partyType: string) {

  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState<IContactSearchSuggestion[] | undefined>(undefined);
  const activeSearch = useRef<{abort: ()=>void, response: Promise<IContactSearchSuggestions | undefined>}>();
  const { data: integrationInfo } = useGetIntegrationInfoQuery();
  const { data: listingContacts } = useGetIntegrationContactsForListingQuery({ listingId: (saleProperties)?.map(ad => ad.integrationId) }, { skip: !(integrationInfo?.hasIntegration && (saleProperties)?.some(ad => ad.integrationId)) });

  const listingSuggestions = useMemo(() => {
    return listingContacts?.Predictions?.map(s => ({
      label: `${s.firstName} ${s.lastName}`?.trim(),
      ...s
    }));
  }, [listingContacts]);

  const search = useCallback((query: any) => {
    activeSearch.current?.abort();
    if (!query || query.trim() === '') {
      setSuggestions(undefined);
      return;
    }
    setIsSearching(true);
    activeSearch.current = PropertySearchApi.getIntegrationContactSuggestions(query);
    activeSearch.current.response.then((s: any) => {
      setSuggestions(s?.Predictions.slice(0, 10).map((c: IContactSearchSuggestion) => ({
        label: `${c.firstName} ${c.lastName}`?.trim(),
        ...c,
        ...s
      })) ?? []);
    }).catch(e => {
      console.warn('Error getting contact suggestions', e);
    }).finally(() => {
      setIsSearching(false);
    });
  }, []);

  const options = (suggestions !== undefined ? suggestions : listingSuggestions?.filter(s => !IntegrationRoles.includes(partyType) || partyType === s.role))||[];

  const optionRenderer = (option: Option) => {
    let icon, tooltip, pack = 'material-icons', variant = 'filled';
    const contact = (listingSuggestions?.find(ls => ls.id === option.id) || option) as IContactSearchSuggestion;

    if ((contact.isInListing && contact.role === partyType) || !IntegrationRoles.includes(partyType)) {
      icon = 'circle';
      tooltip = `This contact is linked to the listing${IntegrationRoles.includes(partyType) ? ' and has the correct role' : ''}`;
    } else if (contact.isInListing) {
      icon = 'clock_loader_40';
      pack = 'material-symbols';
      variant = 'outlined';
      tooltip = 'This contact is linked to the listing but does not have the correct role';
    }

    return <div className={'d-flex'}>
      {hasAnyIntegrationContacts && <div style={{ width: '17px' }}>{icon && <Icon pack={pack} title={tooltip} name={icon} variant={variant} icoClass={'agentbox-icon'} />}</div>}
      <div className={'flex-grow-1'}>
        <div><span className={'fw-bold'}>{`${contact.firstName} ${contact.lastName}`}</span></div>
        <div className={'combo-field-subtext d-flex'}>{[contact.address.address?.trim(), [contact.address.suburb?.trim(), contact.address.state?.trim(), contact.address.postcode?.trim()].filter(Boolean).join(' ')].filter(Boolean).join(', ')}</div>
        <div className={'combo-field-subtext d-flex'}>
          <div>{contact.email ? contact.email : ''}</div>
          <div className={'ms-auto'}>{prettifyPhone(contact.mobile, false, undefined, false)}</div>
        </div>
      </div>
    </div>;
  };

  const hasAnyIntegrationContacts = useMemo(() => {
    return !suggestions?.length || suggestions?.some(s => listingSuggestions?.map(l => l.id)?.includes(s.id));
  }, [suggestions, listingSuggestions]);

  return {
    listingSuggestions,
    suggestions,
    options,
    hasAnyIntegrationContacts,
    isSearching,
    hasIntegration: integrationInfo?.hasIntegration,
    search,
    optionRenderer
  };

}
