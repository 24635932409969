import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { generateParentPath, getValueByPath } from '../../../../util/pathHandling';
import { PortionOfLandDwellingDetails, TitleDivision } from '@property-folders/contract';
import { canonicalisers } from '../../../../util/formatting';

export const titleDivisionPlanDueDateText: FieldGroupFn = (fieldId, updatedPath, immerProxy) => {
  console.log('titleDivisionPlanDueDateText');
  const parentPath = generateParentPath(updatedPath);
  const titleDivision = getValueByPath(parentPath, immerProxy) as TitleDivision;

  if (!titleDivision) return;
  if (titleDivision.planDue12) {
    titleDivision.planDueText = '12 months after Contract Date';
  } else if (titleDivision.planDueDate) {
    titleDivision.planDueText = canonicalisers.date(titleDivision.planDueDate).display;
  } else {
    delete titleDivision.planDueText;
  }
};

export const dwellingBuildContractDueDateText: FieldGroupFn = (fieldId, updatedPath, immerProxy) => {
  const parentPath = generateParentPath(updatedPath);
  const titleDivision = getValueByPath(parentPath, immerProxy) as PortionOfLandDwellingDetails;

  if (!titleDivision) return;
  if (titleDivision.buildContractDue12) {
    titleDivision.buildContractDueText = '12 months after Contract Date';
  } else if (titleDivision.buildContractDueDate) {
    titleDivision.buildContractDueText = canonicalisers.date(titleDivision.buildContractDueDate).display;
  } else {
    delete titleDivision.buildContractDueText;
  }
};
