import React, { createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ShortId } from '@property-folders/common/util/url';

export interface LinkBuilderContextType {
  restApi(path: string): string;
  wsApi(): string;
}

function normalisePath(path: string): string {
  return path.startsWith('/')
    ? path
    : `/${path}`;
}

function switchToWebsocketProtocol(url: URL) {
  url.protocol = url.protocol === 'http:'
    ? 'ws:'
    : 'wss:';
  return url;
}

export class DefaultLinkBuilderContext implements LinkBuilderContextType {
  restApi(path: string): string {
    return new URL(`api/rest${normalisePath(path)}`, window.location.origin).toString();
  }

  wsApi(): string {
    const url = switchToWebsocketProtocol(
      new URL(normalisePath('/api/ws'), window.location.origin)
    );
    return url.toString();
  }
}

/**
 * Example - given a path prefix of /purchaser/api/rest,.
 * and a rewrite rule of [{ fromPrefix: '/api/rest/portal/purchaser', toPrefix: '/' }]
 * map the following:
 * * /property-searches/a/*
 * * /api/rest/portal/purchaser/b/*
 * to:
 * * /purchaser/api/rest/property-searches/a/*
 * * /purchaser/api/rest/b/*
 *
 * Server-side will map /purchaser/api/rest/b/* back to /api/rest/portal/purchaser/b/*
 * but any pathed cookies will be preserved :+)
 */
export class RewriteLinkBuilderContext implements LinkBuilderContextType {
  private pathPrefix: string;
  private pathPrefixRest: string;
  private pathPrefixWs: string;

  constructor(
    pathPrefix: string,
    private rewriteRules: { fromPrefix: string, toPrefix: string }[],
    private origin: string
  ) {
    this.pathPrefix = normalisePath(pathPrefix.endsWith('/')
      ? pathPrefix.slice(0, -1)
      : pathPrefix);
    this.pathPrefixRest = this.pathPrefix + '/api/rest';
    this.pathPrefixWs = this.pathPrefix + '/api/ws';
  }

  restApi(path: string): string {
    const rewritten = this.applyRewrite(path);
    const url = new URL(rewritten && rewritten !== '/'
      ? this.pathPrefixRest + normalisePath(rewritten)
      : this.pathPrefixRest, this.origin);
    return url.toString();
  }

  wsApi(): string {
    return switchToWebsocketProtocol(new URL(this.pathPrefixWs, this.origin)).toString();
  }

  private applyRewrite(path: string): string {
    for (const rule of this.rewriteRules) {
      if (path.startsWith(rule.fromPrefix)) {
        return path.replace(rule.fromPrefix, rule.toPrefix);
      }
    }
    return path;
  }
}

const defaultContextInstance = new DefaultLinkBuilderContext();
export const LinkBuilderContext = createContext<LinkBuilderContextType>(defaultContextInstance);

export function SetLinkBuilderContext({ children, mode }: React.PropsWithChildren<{ mode: undefined | 'purchaser-portal' }>) {
  const params = useParams();
  const { portalIdRaw } = params as { portalIdRaw?: string };
  const portalId = portalIdRaw
    ? ShortId.toUuid(portalIdRaw)
    : undefined;
  const context = useMemo(() => {
    switch (mode) {
      case 'purchaser-portal':
        return portalId
          ? new RewriteLinkBuilderContext(`/purchaser/${portalId}`, [{
            fromPrefix: `/api/rest/portal/purchaser/${portalId}`, toPrefix: '/'
          }, {
            fromPrefix: `/portal/purchaser/${portalId}`, toPrefix: '/'
          }], window.location.origin)
          : defaultContextInstance;
      default:
        return defaultContextInstance;
    }
  }, [mode, portalId]);

  return <LinkBuilderContext.Provider value={context}>
    {children}
  </LinkBuilderContext.Provider>;
}
