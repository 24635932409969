export enum SaleMethod {
  Auction = 'auction',
  PrivateTreaty = 'private',
  OffMarket = 'off',
  Other = 'other',
  ExpressionOfInterest = 'eoi'
}

export enum FormOrderState {
  /**
   * Invalid state
   */
  None = 0,
  /**
   * The agent is drafting the request to the third party.
   */
  ClientOrdering = 1,
  /**
   * The job has been submitted to the third party.
   * The third party is doing something with it.
   */
  ThirdPartyPreparing = 2,
  /**
   * The third party has sent the form back to the client because:
   * * Refused
   * * Completed (draft, agent will manage signing)
   * * Completed (fully signed)
   */
  ReturnedToClient = 3,
  /**
   * The job has been returned to the third party.
   * The third party is doing something with it.
   */
  ReturnedToThirdParty = 4,
  /**
   * The job has been cancelled
   */
  Cancelled = 5,
}

export enum FormOrderType {
  Agent = 0,
  Filler = 1
}

export enum GstFreeReason {
  GoingConcern = 'goingConcern',
  FarmlandForFarmingBusiness = 'farmingBusiness'
}

export enum CustomFieldType {
  signature = 'signature',
  initials = 'initials',
  timestamp = 'timestamp',
  name = 'name',
  email = 'email',
  phone = 'phone',
  company = 'company',
  abn = 'abn',
  authority = 'authority',
  address = 'address',
  saleAddress = 'saleAddress',
  saleTitle = 'saleTitle',
  text = 'text',
  checkmark = 'checkmark',
  purchaserName = 'purchaserName',
  purchaserAddress = 'purchaserAddress',
  contractDate = 'contractDate',
  remoteText = 'remoteText',
  remoteRadio = 'remoteRadio',
  remoteCheck = 'remoteCheck'
}

export enum DivisionType {
  Torrens = 'landDiv',
  Community = 'commDiv'
}

export enum PortionOfLandAutomaticClauseCondition {
  None = '',
  TorrensAndNoDevAuth = 'torrens_and_no_dev_auth',
  CommunityAndNoDevAuth = 'community_and_no_dev_auth',
  TorrensAndYesDevAuth = 'torrens_and_yes_dev_auth',
  CommunityAndYesDevAuth = 'community_and_yes_dev_auth',
}
