import * as Y from 'yjs';

import { EntitySettingsRootKey, EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { applyMigrations, Migration } from '..';
import { bind } from 'immer-yjs';

export function materialiseEntitySettings(doc: Y.Doc | undefined): EntitySettingsEntity | undefined {
  if (!doc) {
    return undefined;
  }

  return doc.getMap(EntitySettingsRootKey.Main)?.toJSON() as EntitySettingsEntity | undefined;
}

// When we add marketing templates, we should do so here.
const migrations: Array<Migration<EntitySettingsEntity>> = [];

export function migrateEntitySettings(doc: Y.Doc | undefined): Uint8Array | undefined {
  if (!doc) {
    return undefined;
  }

  const preVector = Y.encodeStateVector(doc);
  const binder = bind<EntitySettingsEntity>(doc.getMap(EntitySettingsRootKey.Main));

  if (!applyMigrations('EntitySettingsEntity', binder, migrations)) {
    return undefined;
  }

  return Y.encodeStateAsUpdate(doc, preVector);
}
