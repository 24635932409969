import {
  AgencySalesperson,
  AuthorityParty,
  SaleTitle,
  SigningAuthorityType,
  TitleInclusionState,
  jointTypes,
  multiRepAuthority
} from '@property-folders/contract';
import {
  ExpectedEvaluatorFunction,
  ValidationContext
} from '@property-folders/contract/yjs-schema/model/expected-evaluator';
import { Predicate } from '../../../../predicate';
import { ComplexValidationContext } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { uuidOrIndex } from '../../../../util/pathHandling';

export function anyTitlePortions(titles: SaleTitle[] | undefined) {
  return determineTitleInclusionState(titles ?? []).titleInclusionState === TitleInclusionState.portion;
}

export function explicitTitlePortions(titles: SaleTitle[] | undefined) {
  const { titleInclusionState, explicit } = determineTitleInclusionState(titles ?? []);
  return explicit && titleInclusionState === TitleInclusionState.portion;
}

export function determineTitleInclusionState(titles: SaleTitle[]): { titleInclusionState: TitleInclusionState, explicit?: boolean } {
  const portionTypesPresent = titlePortionTypes(titles);
  if (portionTypesPresent.size > 1) {
    return {
      titleInclusionState: TitleInclusionState.portion,
      explicit: portionTypesPresent.has(TitleInclusionState.portion)
    };
  }

  const titleInclusionState = [...portionTypesPresent].at(0);
  return titleInclusionState === undefined
    ? { titleInclusionState: TitleInclusionState.whole }
    : { titleInclusionState, explicit: true };
}

export function titlePortionTypes(titles: SaleTitle[]): Set<TitleInclusionState> {
  return new Set<TitleInclusionState>(titles.flatMap(
    title => (title.subTitles ?? []).map(
      st => st.portionType)));
}

export function getLinkedTitles(referenceTitle?: SaleTitle, titles?: SaleTitle[], includeReference?: boolean): SaleTitle[] {
  if (!referenceTitle) return [];
  if (!titles?.length) return [];

  const addressIdSet = new Set(referenceTitle.linkedAddresses);
  let included = false;
  const result = titles.filter(t => {
    if (t.id === referenceTitle.id) {
      if (includeReference) {
        included = true;
        return true;
      }
      return false;
    }

    return t.linkedAddresses?.some(la => addressIdSet.has(la));
  });

  if (includeReference && !included) {
    result.push(referenceTitle);
  }

  return result;
}

export function anySalespersonName (salespersons?: AgencySalesperson[]) {
  return (salespersons??[]).filter(sp=>Predicate.isTruthy(sp.name)).length>0;
}

export function primarySubcontactIsFirstPerson(party?: AuthorityParty) {
  if (!party) return false;
  if (multiRepAuthority.includes(party.authority)) return false;
  return !party.primarySubcontact;
}

export function primarySubcontactIsSecondPerson(party?: AuthorityParty) {
  if (!party) return false;
  if (multiRepAuthority.includes(party.authority)) return false;
  return party.primarySubcontact === 1;
}

export function isPrimaryContact(primaryId: string, context?: ComplexValidationContext | undefined, validationContext?: ValidationContext) {
  if (!validationContext) return true;

  const { nodePath } = validationContext;
  return nodePath.some(p => p && p.indexOf(primaryId) >= 0);
}

/** Is old style representative, not joint guardian/attorney
 *
 * @param party
 * @returns
 */
export function isRepresentative(party?: AuthorityParty) {
  return [
    SigningAuthorityType.attorney,
    SigningAuthorityType.guardian,
    SigningAuthorityType.authRep,
    SigningAuthorityType.directors2,
    SigningAuthorityType.directorSecretary,
    SigningAuthorityType.sole
  ].includes(party?.authority || SigningAuthorityType.self);
}

export function isDualPartyType(party?: AuthorityParty) {
  return [
    SigningAuthorityType.directors2,
    SigningAuthorityType.directorSecretary
  ].includes(party?.authority || SigningAuthorityType.self);
}

export function isNotJointAuthorityPartyParent(party?: AuthorityParty) {
  if (party?.partyType == null) return true;
  return !jointTypes.includes(party.partyType);
}

// Returning true by default as this is probably used in a required if, and
// if the data is in error, we should probably flag that to the user. Not
// having silent errors here
export function notLastInGivenArray(array: any[] | any, _ctx?: ComplexValidationContext, validationContext?: ValidationContext) {
  const { nodePath, fieldPath } = validationContext??{};
  if (!Array.isArray(array)) return true;
  const memberIdStr = nodePath?.[fieldPath?.length??-1]; // Should be member path segment, being one further down than the array itself
  const { valid, uuid, index } = uuidOrIndex(memberIdStr);
  if (!valid) return true;
  if (uuid) {
    const i = array.findIndex(ae => typeof ae === 'object' && ae.id === uuid);
    return i !== -1 && i < array.length-1;
  }
  if (index) {
    return index !== -1 && index < array.length-1;
  }

  return true;
}

export const expectedValueEvaluatorDirectory: {[functionName:string]: ExpectedEvaluatorFunction | undefined} = {
  invertValue: (value: any) => !value,
  anyTitlePortions,
  anySalespersonName,
  primarySubcontactIsFirstPerson,
  primarySubcontactIsSecondPerson,
  isPrimaryContact,
  isDualPartyType,
  isRepresentative,
  isNotJointAuthorityPartyParent,
  notLastInGivenArray
};
