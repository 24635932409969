import * as Y from 'yjs';
import { bind } from 'immer-yjs';
import { SigningParty, SigningPartyType, TandCAcceptEvent, TransactionMetaData } from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { canonicalisers } from '../../util/formatting';
import { Predicate } from '../../predicate';

type CanonType = 'email' | 'phone' | 'text';
function canonicalise(value: string | undefined, type: CanonType) {
  if (!value) return undefined;
  switch (type) {
    case 'email':
      return canonicalisers.email(value).canonical;
    case 'phone':
      return canonicalisers.phone(value).canonical;
    case 'text':
      return value.toLowerCase().trim();
  }
}

function isCanonicalMatch(a: string | undefined, b: string | undefined, type: CanonType) {
  return canonicalise(a, type) === canonicalise(b, type);
}

function isMatch(event: TandCAcceptEvent, identifier: PartyIdentifier): boolean {
  if (event.type !== identifier.type) return false;
  if (!isCanonicalMatch(event.name,identifier.name, 'text')) return false;

  switch (identifier.type) {
    case SigningPartyType.SignOnline:
      return isCanonicalMatch(event.email, identifier.email, 'email');
    case SigningPartyType.SignOnlineSms:
      return isCanonicalMatch(event.phone, identifier.phone, 'phone');
    case SigningPartyType.SignInPerson:
      return event.salespersonId === identifier.salespersonId &&
        (
          event.partyId === identifier.partyId ||
          isCanonicalMatch(event.email,identifier.email, 'email') ||
          isCanonicalMatch(event.phone, identifier.phone, 'phone')
        );
  }
}

function mapSigningPartyToPartyIdentifier(party: SigningParty, salespersonId?: string | number): PartyIdentifier| undefined {
  if (!party.snapshot) return undefined;
  const proxyMode = Predicate.proxyNotSelf(party.proxyAuthority);
  const recipient = {
    name: (proxyMode ? party.proxyName : party.snapshot.name) ?? '',
    phone: (proxyMode ? party.proxyPhone : party.snapshot.phone) ?? '',
    email: (proxyMode ? party.proxyEmail : party.snapshot.email) ?? '',
    partyId: party.id
  };
  switch (party.type) {
    case SigningPartyType.SignOnline:
      return { type: SigningPartyType.SignOnline, ...recipient };
    case SigningPartyType.SignOnlineSms:
      return { type: SigningPartyType.SignOnlineSms, ...recipient };
    case SigningPartyType.SignInPerson:
      return salespersonId
        ? { type: SigningPartyType.SignInPerson, ...recipient, salespersonId }
        : undefined;
    case SigningPartyType.SignWet:
    default:
      return undefined;
  }
}

export type PartyIdentifier =
  | { type: SigningPartyType.SignOnline, name: string, partyId: string, email: string, phone?: string }
  | { type: SigningPartyType.SignOnlineSms, name: string, partyId: string, phone: string, email?: string }
  | { type: SigningPartyType.SignInPerson, name: string, salespersonId: number | string, partyId: string, email?: string, phone?: string };

export class TandcAcceptPartyYjsDal {
  metaRootKey: string;
  constructor(
    private doc: Y.Doc
  ) { this.metaRootKey = PropertyRootKey.Meta;} // Stored only in the root meta not a sublineage

  private get metaBinder() {
    return bind<TransactionMetaData>(this.doc.getMap(this.metaRootKey));
  }

  public static addAcceptEvent(state: TransactionMetaData| undefined, timestampMs: number, identifier: PartyIdentifier) {
    if (!state) return;
    if (!state.tandcAccept) {
      state.tandcAccept = [];
    }

    const existing = state.tandcAccept.find(e => isMatch(e, identifier));
    if (existing) {
      return;
    }

    state.tandcAccept.push({
      ...identifier,
      timestampMs
    });
  }

  public static partyAddAcceptEvent(state: TransactionMetaData | undefined, timestampMs: number, party: SigningParty, salespersonId?: string | number) {
    if (!state) return;
    const identifer = mapSigningPartyToPartyIdentifier(party, salespersonId);
    if (!identifer) return;
    this.addAcceptEvent(state, timestampMs, identifer);
  }

  public static hasAcceptEvent(state: TransactionMetaData| undefined, identifier: PartyIdentifier) {
    if (!state?.tandcAccept) return false;
    return !!state.tandcAccept.find(e => isMatch(e, identifier))?.timestampMs;
  }

  public static partyHasAcceptEvent(state: TransactionMetaData | undefined, party: SigningParty, salespersonId?: string | number) {
    if (!state) return false;
    const identifer = mapSigningPartyToPartyIdentifier(party, salespersonId);
    return identifer && this.hasAcceptEvent(state, identifer);
  }

  public partyHasAcceptEvent(party: SigningParty, salespersonId?: string | number) {
    return TandcAcceptPartyYjsDal.partyHasAcceptEvent(this.metaBinder.get(), party, salespersonId);
  }
}
