import { CustomFieldType } from './enum';
import { IconProps } from '@property-folders/common/types/IconProps';
import { SigningPartyType, SigningSessionFieldType } from './index';

export type CustomFieldMetaAttributeStringTypeInfo = { type: 'string', defaultValue?: string, minLength?: number, maxLength?: number };
export type CustomFieldMetaAttributeNumberTypeInfo = { type: 'number', defaultValue?: number, min?: number, max?: number };
export type CustomFieldMetaAttributeOptionsTypeInfo = { type: 'options', defaultValue?: string, options: string[] };
export type CustomFieldMetaAttributeBooleanTypeInfo = { type: 'boolean', defaultValue?: boolean };
export type CustomFieldMetaAttributeColourTypeInfo = { type: 'colour', defaultValue?: string };
export type CustomFieldMetaAttributeTypeInfo =
  | CustomFieldMetaAttributeNumberTypeInfo
  | CustomFieldMetaAttributeStringTypeInfo
  | CustomFieldMetaAttributeOptionsTypeInfo
  | CustomFieldMetaAttributeBooleanTypeInfo
  | CustomFieldMetaAttributeColourTypeInfo;

export interface CustomFieldMetaAttribute {
  // maybe can become a path and use that path walking stuff elsewhere or bind into the ydoc more closely.
  name: string;
  title: string;
  typeInfo: CustomFieldMetaAttributeTypeInfo;
  editable?: boolean;
  // is it shown in the side bar?
  hidden?: boolean;
  afterEdit?: 'calc-text-height' | 'turn-off-group-members';
}

export type CustomFieldMetaGroup =
  | 'signing'
  | 'contact'
  | 'property'
  | 'serve'
  | 'other';

export interface CustomFieldMeta {
  title: string;
  description: string;
  displayGroup: CustomFieldMetaGroup;
  displayRestricted?: boolean;
  icon: IconProps;
  showIconForExisting: boolean;
  // links this custom field type to a type of field a signing party will fill in (signature, initials).
  // used to decide if a party should be ignored or not during signing.
  signingSessionFieldType?: SigningSessionFieldType;
  formField?: boolean;
  firstPartyOnly?: boolean;
  onlyPartyTypes?: SigningPartyType[];
  // affects how custom field components are shown on screen
  predefinedText?: boolean;
  partyField?: boolean;
  resize: 'manual' | 'auto';
  attributes: CustomFieldMetaAttribute[];
}

export const defaultFontSize = 12;
export const defaultLineHeight = 14;
export const fallbackFieldText = 'This is just some text';
export const defaultCheckmark = '✕';

const fontFamilyCustomFieldAttribute: CustomFieldMetaAttribute = {
  name: 'fontFamily',
  title: 'Font',
  typeInfo: { type: 'options', defaultValue: 'Roboto', options: ['Roboto', 'Open Sans', 'Courier Prime', 'Playfair Display'] },
  editable: true
};
const fontSizeCustomFieldAttribute: CustomFieldMetaAttribute = {
  name: 'fontSize',
  title: 'Font Size',
  typeInfo: { type: 'number', defaultValue: defaultFontSize, min: 10, max: 72 },
  editable: true
};
const lineHeightCustomFieldAttribute: CustomFieldMetaAttribute =  {
  name: 'lineHeight',
  title: 'Line Height',
  typeInfo: { type: 'number', defaultValue: defaultLineHeight, min: 10, max: 72 },
  editable: true
};
const fontColourCustomFieldAttribute: CustomFieldMetaAttribute =  {
  name: 'fontColour',
  title: 'Text Colour',
  typeInfo: { type: 'colour', defaultValue: '#000000' },
  editable: true
};
const fontCustomFieldAttributes: CustomFieldMetaAttribute[] = [
  fontFamilyCustomFieldAttribute,
  fontSizeCustomFieldAttribute,
  lineHeightCustomFieldAttribute,
  fontColourCustomFieldAttribute
];

const bgCustomFieldAttributes: CustomFieldMetaAttribute[] = [{
  name: 'bg',
  title: 'Background',
  typeInfo: { type: 'boolean', defaultValue: true },
  editable: true
},{
  name: 'bgColour',
  title: 'Background Colour',
  typeInfo: { type: 'colour', defaultValue: '#ffffff' },
  editable: true
}];

export const customFieldMetas: Record<CustomFieldType, CustomFieldMeta> = {
  // signing fields
  [CustomFieldType.signature]: {
    title: 'Signature',
    description: 'The party will be prompted to sign at this location',
    displayGroup: 'signing',
    icon: { name: 'signature', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: true,
    signingSessionFieldType: SigningSessionFieldType.Signature,
    formField: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.initials]: {
    title: 'Initials',
    description: 'The party will be prompted to initial at this location',
    displayGroup: 'signing',
    icon: { name: 'approval', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: true,
    signingSessionFieldType: SigningSessionFieldType.Initials,
    formField: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.timestamp]: {
    title: 'Sign date',
    description: 'The date the party completed signing will be inserted into the document at this location',
    displayGroup: 'signing',
    icon: { name: 'event', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    showIconForExisting: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  // predefined party details
  [CustomFieldType.name]: {
    title: 'Name',
    description: 'The party\'s name will be inserted into the document at this location',
    displayGroup: 'contact',
    icon: { name: 'person', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.authority]: {
    title: 'Authority',
    description: 'The party\'s authority text will be inserted into the document at this location',
    displayGroup: 'signing',
    displayRestricted: true,
    icon: { name: 'verified', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.phone]: {
    title: 'Phone',
    description: 'The party\'s phone will be inserted into the document at this location',
    displayGroup: 'contact',
    icon: { name: 'phone', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.email]: {
    title: 'Email',
    description: 'The party\'s email will be inserted into the document at this location',
    displayGroup: 'contact',
    icon: { name: 'alternate_email', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.company]: {
    title: 'Company',
    description: 'The party\'s company will be inserted into the document at this location',
    displayGroup: 'contact',
    icon: { name: 'store', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.abn]: {
    title: 'ABN/ACN',
    description: 'The party\'s ABN/ACN will be inserted into the document at this location',
    displayGroup: 'contact',
    icon: { name: 'store', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.address]: {
    title: 'Postal address',
    description: 'The party\'s postal address will be inserted into the document at this location',
    displayGroup: 'contact',
    icon: { name: 'local_post_office', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    partyField: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  // property details
  [CustomFieldType.saleAddress]: {
    title: 'Sale address',
    description: 'The sale address will be inserted into the document at this location',
    displayGroup: 'property',
    icon: { name: 'home', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.saleTitle]: {
    title: 'Sale title',
    description: 'The sale title will be inserted into the document at this location',
    displayGroup: 'property',
    icon: { name: 'developer_guide', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    predefinedText: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.text]: {
    title: 'Label',
    description: 'Predefined text content',
    displayGroup: 'other',
    icon: { name: 'abc', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    resize: 'manual',
    attributes: [
      {
        name: 'text',
        title: 'Text',
        typeInfo: { type: 'string', defaultValue: 'Custom text' },
        editable: true,
        hidden: true
      },
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.checkmark]: {
    title: 'Checkmark',
    description: 'Checkmark',
    displayGroup: 'other',
    icon: { name: 'check', variant: 'outlined', pack: 'material-symbols' },
    showIconForExisting: false,
    resize: 'auto',
    attributes: [
      {
        name: 'checkmark',
        title: 'Mark',
        typeInfo: { type: 'options', defaultValue: defaultCheckmark, options: ['✕','✓','✗'] },
        editable: true
      }, {
        name: 'fontSize',
        title: 'Font Size',
        typeInfo: { type: 'number', defaultValue: defaultFontSize, min: 10, max: 72 },
        editable: true
      }, {
        name: 'fontColour',
        title: 'Colour',
        typeInfo: { type: 'colour', defaultValue: '#000000' },
        editable: true
      }
    ]
  },
  [CustomFieldType.purchaserName]: {
    title: 'Purchaser Name',
    description: 'Purchaser Name',
    displayGroup: 'serve',
    icon: { name: 'person', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    showIconForExisting: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.purchaserAddress]: {
    title: 'Purchaser Address',
    description: 'Purchaser Address',
    displayGroup: 'serve',
    icon: { name: 'local_post_office', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    showIconForExisting: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.contractDate]: {
    title: 'Contract Date',
    description: 'Contract Date',
    displayGroup: 'serve',
    icon: { name: 'event', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    showIconForExisting: true,
    resize: 'manual',
    attributes: [
      ...fontCustomFieldAttributes,
      ...bgCustomFieldAttributes
    ]
  },
  [CustomFieldType.remoteText]: {
    title: 'Text input',
    description: 'Allow the signing party to enter text',
    displayGroup: 'signing',
    icon: { name: 'text_fields', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    signingSessionFieldType: SigningSessionFieldType.Text,
    showIconForExisting: true,
    firstPartyOnly: true,
    onlyPartyTypes: [SigningPartyType.SignOnline, SigningPartyType.SignInPerson, SigningPartyType.SignOnlineSms],
    resize: 'manual',
    attributes: [
      {
        name: 'multiline',
        title: 'Multiline',
        typeInfo: { type: 'boolean', defaultValue: false },
        editable: true
      },
      {
        name: 'required',
        title: 'Required',
        typeInfo: { type: 'boolean', defaultValue: true },
        editable: true
      },
      { ...fontFamilyCustomFieldAttribute, afterEdit: 'calc-text-height' },
      { ...fontSizeCustomFieldAttribute, afterEdit: 'calc-text-height' },
      { ...lineHeightCustomFieldAttribute, afterEdit: 'calc-text-height' },
      fontColourCustomFieldAttribute,
      ...bgCustomFieldAttributes,
      {
        name: 'text',
        title: 'Text',
        typeInfo: { type: 'string' },
        editable: true
      }
    ]
  },
  [CustomFieldType.remoteCheck]: {
    title: 'Checkbox',
    description: 'Allow the signing party to check a checkbox',
    displayGroup: 'signing',
    icon: { name: 'check_box', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    signingSessionFieldType: SigningSessionFieldType.Check,
    showIconForExisting: false,
    firstPartyOnly: true,
    onlyPartyTypes: [SigningPartyType.SignOnline, SigningPartyType.SignInPerson, SigningPartyType.SignOnlineSms],
    resize: 'auto',
    attributes: [
      ...fontCustomFieldAttributes,
      {
        name: 'label',
        title: 'Label',
        typeInfo: { type: 'string', defaultValue: '' },
        editable: true
      },
      {
        name: 'on',
        title: 'Checked',
        typeInfo: { type: 'boolean', defaultValue: false },
        editable: true
      }
    ]
  },
  [CustomFieldType.remoteRadio]: {
    title: 'Radio option',
    description: 'Allow the signing party to choose one of many options',
    displayGroup: 'signing',
    icon: { name: 'radio_button_checked', variant: 'outlined', pack: 'material-symbols' },
    formField: true,
    signingSessionFieldType: SigningSessionFieldType.Radio,
    showIconForExisting: false,
    firstPartyOnly: true,
    onlyPartyTypes: [SigningPartyType.SignOnline, SigningPartyType.SignInPerson, SigningPartyType.SignOnlineSms],
    resize: 'auto',
    attributes: [
      ...fontCustomFieldAttributes,
      {
        name: 'group',
        title: 'Radio Group',
        typeInfo: { type: 'string', defaultValue: 'radio-1' },
        editable: true
      }, {
        name: 'label',
        title: 'Label',
        typeInfo: { type: 'string', defaultValue: '' },
        editable: true
      }, {
        name: 'on',
        title: 'Checked',
        typeInfo: { type: 'boolean', defaultValue: false },
        editable: true,
        afterEdit: 'turn-off-group-members'
      }
    ]
  }
};
