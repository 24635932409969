import { MaterialisedPropertyData, SaleMethod } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { normalisePathToStrArray } from '../../../../util/pathHandling';

/**Sets the deposit option if the sale method is changed
 *
 * input only fields: sale.saleMethod
 * output only fields: contractPrice.depositPayAt
 */
export const setDepositTime: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData, variationsSnapshot, history, previousState) => {
  const updatedPathSegs = normalisePathToStrArray(updatedPath);
  if (!(updatedPathSegs.length === 2 && updatedPathSegs[0] === 'sale' && updatedPathSegs[1] === 'saleMethod')) {
    // The only inputs we use are in the sale map, so we'll just ignore anything else
    return;
  }
  const method = immerProxy.sale?.saleMethod;
  if ( // The sale method has been cleared, and the depositPayAt option is set to a default value
    !method && previousState?.sale?.saleMethod
    && (
      (previousState.sale.saleMethod === SaleMethod.Auction && immerProxy.contractPrice?.depositPayAt === 'immediate')
    || (previousState.sale.saleMethod !== SaleMethod.Auction && immerProxy.contractPrice?.depositPayAt === 'afterCooling')
    )
  ) {
    delete immerProxy.contractPrice.depositPayAt;
    return;
  }
  if (!method) return; // If we're not deleting, we have no business here
  if (!immerProxy.contractPrice) immerProxy.contractPrice = {};
  const depOpt = immerProxy.contractPrice.depositPayAt;
  if (method === SaleMethod.Auction && (!depOpt || depOpt === 'afterCooling')) { // afterCooling being the default for non-auctions. We probably don't want to wipe out waived or other
    immerProxy.contractPrice.depositPayAt = 'immediate';
    return;
  }
  if (method && method !== SaleMethod.Auction && (!depOpt || depOpt === 'immediate')) {
    immerProxy.contractPrice.depositPayAt = 'afterCooling';
    return;
  }
};
