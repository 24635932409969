import { IPartyDetailPaths, SigningPartyVerificationTypeOptions } from '@property-folders/components/dragged-components/signing/Common';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { Container, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import { SigningPartyVerificationConfig } from '@property-folders/contract';

export function CustomiseVerification({
  paths,
  path,
  phoneDisabled,
  representativeLabel
}: {
  path: string;
  paths: IPartyDetailPaths
  phoneDisabled?: boolean,
  representativeLabel?: string
}) {
  return <Container>
    <Row>
      <Col lg={3}>
        <WrField.Control
          parentPath={paths.data.base}
          myPath={paths.data.phone}
          name={paths.data.phone}
          label={`${representativeLabel ? representativeLabel+'\'s ' : ''}Mobile Number`}
          disabled={phoneDisabled}
          bindToMetaKey={paths.dataIsMeta}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        A code will be sent to the {representativeLabel?.toLocaleLowerCase()||'party'} via SMS.
      </Col>
    </Row>
  </Container>;
}

// question: can this be merged with the above? 🤷‍♀️
export function CustomiseVerificationStandalone({
  config,
  phone,
  phoneValid,
  onConfigChange,
  onPhoneChange,
  phoneDisabled
}: {
  config: SigningPartyVerificationConfig,
  phone: string,
  phoneValid: boolean,
  onConfigChange: (value: SigningPartyVerificationConfig) => void,
  onPhoneChange: (phone: string) => void,
  phoneDisabled: boolean
}) {
  return <Container fluid>
    <Row>
      <Col lg={3}>
        <FloatingLabel label='Authentication Type'>
          <Form.Select
            value={config.type?.toString() || ''}
            onChange={e => {
              const newType = parseInt(e.target.value, 10);
              onConfigChange({
                type: Number.isInteger(newType)
                  ? newType
                  : undefined });
            }}>
            {Object.entries(SigningPartyVerificationTypeOptions).map(([k, v]) => (
              <option key={k} value={k}>{v}</option>))}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>
    <Row>
      <p>A code will be sent to the party via SMS. A fee will be charged per usage.</p>
    </Row>
    <Row>
      <Col lg={6}>
        <FloatingLabel label={'Mobile Number'}>
          <Form.Control
            value={phone}
            onChange={e => onPhoneChange(e.target.value)}
            isInvalid={!phoneValid}
            disabled={phoneDisabled}
          />
          <Form.Control.Feedback type={'invalid'}>Phone is required.</Form.Control.Feedback>
        </FloatingLabel>
      </Col>
    </Row>
  </Container>;
}
