import { FeeCalcMechanism, FeeEnables, MaterialisedPropertyData } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';

const expectedChangedGroups = new Set(['fixedFee', 'commis']);

/**Sets the deposit option if the sale method is changed
 *
 *
 * output only fields: professFee.
 */
export const setFeeDefaultSetupAndPatchUpNone: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData, variationsSnapshot, history, previousState, furtherProps) => {
  if (!updatedPath.startsWith('professFee.enabledModes')) return; // We run outputs on other values, but we're not concerned with them changing

  const professFee = immerProxy.professFee;
  const ps = normalisePathToStrArray(updatedPath);
  const changedGroup = ps[ps.length-2];
  if (!expectedChangedGroups.has(changedGroup)) {
    console.warn('Unexpected change path structure in enabled modes', changedGroup);
    return;
  } // We are only concerned with leaf node changes
  const otherGroups = new Set(expectedChangedGroups);
  otherGroups.delete(changedGroup);
  const otherGroup = otherGroups.values().next().value as string; // No idea why this type doesn't survive...

  const { preCheckPreviousState } = furtherProps??{};
  const oldState = preCheckPreviousState??previousState;
  // Tasks:
  // 1. Assess if we are in default mode
  // 2. Determine the changed field
  // 3. Set default fields, ignoring the changed fields

  const previousModes = (oldState as MaterialisedPropertyData | undefined)?.professFee?.enabledModes;

  if (previousModes == null) {
    if (!immerProxy.professFee) {
      immerProxy.professFee = {};
    }
    if (!immerProxy.professFee?.enabledModes) {
      immerProxy.professFee.enabledModes = {};
    }

    const assignIfEmpty = (nodeKey: string, baseContainer: Record<string, any>, value: boolean)=> {
      if (Object.hasOwn(baseContainer, nodeKey)) return;
      if (!value) return;
      baseContainer[nodeKey] = value;
    };
    const assignDefaults = (baseContainer: FeeEnables)=> {
      assignIfEmpty('set', baseContainer, true);
      assignIfEmpty('range', baseContainer, true);
      assignIfEmpty('discount', baseContainer, false);
      assignIfEmpty('scale', baseContainer, false);
      assignIfEmpty('none', baseContainer, false);
    };
    if (!immerProxy.professFee.enabledModes.fixedFee) {
      immerProxy.professFee.enabledModes.fixedFee = {};
    }
    assignDefaults(immerProxy.professFee.enabledModes.fixedFee);

    if (!immerProxy.professFee.enabledModes.commis) {
      immerProxy.professFee.enabledModes.commis = {};
    }
    assignDefaults(immerProxy.professFee.enabledModes.commis);
  }

  const targetModeStr = changedGroup === 'fixedFee' ? 'fixedMode' : changedGroup === 'commis' ? 'commissionMode' : '';
  const newVal = getValueByPath(updatedPath, immerProxy);
  const pathLeaf = ps[ps.length-1];

  if (professFee?.enabledModes?.[changedGroup] && professFee.enabledModes[changedGroup].none && pathLeaf !== 'none' && newVal) {
    // kill the none here, it's enabled and we're switching something else to true
    delete professFee.enabledModes[changedGroup].none;
  }

  // You shall not be allowed to set both to none
  if (pathLeaf === 'none' && newVal && professFee?.enabledModes?.[otherGroup]?.none) {
    delete professFee.enabledModes[changedGroup].none;
  } else if (pathLeaf !== 'none' && !newVal && professFee?.enabledModes?.[otherGroup]?.none && Object.values(professFee.enabledModes[changedGroup]).every(a=>!a)) {
    // You shall not be able to de-select the last value and render them all none either
    // We can check them all for false because this is after the current change.
    professFee.enabledModes[changedGroup][pathLeaf] = true;
  }

  if (professFee.enabledModes[changedGroup] && pathLeaf !== 'none' && !newVal && Object.values(professFee.enabledModes[changedGroup]).every(a=>!a)) {
    // all other values other than none were unset, let's get set to none
    professFee.enabledModes[changedGroup].none = true;
  }

  // After the default setter:
  // Check if any values of 'none' are set, and set all others in that group to undefined (delete)

  const turnOffOthers = (enables: FeeEnables | undefined) => {
    if (!enables) return;
    if (!enables.none) return;
    for (const key in enables) {
      if (key === 'none') continue;
      delete enables[key];
    }
  };
  turnOffOthers(immerProxy.professFee?.enabledModes?.fixedFee);
  turnOffOthers(immerProxy.professFee?.enabledModes?.commis);

  // Then, we need to remove values for those that have been hidden. This is an output concern, but we need to keep
  // in mind that we're messing with something that already has group functions attached to it
  if (updatedPath.startsWith('professFee.enabledModes') && professFee && targetModeStr) {
    const modeVal = professFee[targetModeStr];

    const runDelete = () => {
      /* if (changedGroup === 'fixedFee') {
        delete professFee.fixedPayableWhen;
      } */ // Perhaps this value should actually be retained
      delete professFee[targetModeStr];
    };

    switch (pathLeaf) {
      case 'none': {
        if (!newVal) break;
        runDelete();
        break;
      }
      case 'set': {
        if (newVal) break; // We're just capturing disappearing values
        if (modeVal === FeeCalcMechanism.Set) runDelete();
        break;
      }
      case 'range': {
        if (newVal) break; // We're just capturing disappearing values
        if (modeVal === FeeCalcMechanism.Range) runDelete();
        break;
      }
      case 'discount': {
        if (newVal) break; // We're just capturing disappearing values
        if (modeVal === FeeCalcMechanism.Discounted) runDelete();
        break;
      }
      case 'scale': {
        if (newVal) break; // We're just capturing disappearing values
        if (modeVal === FeeCalcMechanism.Scale) runDelete();
        break;
      }
    }
  }
};
