import {
  FormSigningState,
  MaterialisedPropertyData,
  SigningParty,
  SigningPartyType,
  SigningPartyVerificationType,
  TransactionMetaData
} from '@property-folders/contract';
import {
  ComplexValidationContext,
  ComplexValidationReturnErrors,
  ComplexValidationReturnType
} from '@property-folders/contract/yjs-schema/model/complex-validator';
import { ValidationRuntimeContext } from '../process-validation';
import { FormTypes, getPartyDetailPaths, IPartyDetailPaths } from '../../form';
import { getValueByPath } from '../../../../util/pathHandling';
import { canonicalisers } from '../../../../util/formatting';
import { Predicate } from '../../../../predicate';

function log(message?: any, ...optionalParams: any[]): void {
  if (!ValidationRuntimeContext.enableLogging) return;

  console.log('validateSigningRequirements', message, ...optionalParams);
}

const successResponse: ComplexValidationReturnType = { forceUpdatesOn: [], errorObj: {} };

export function validateSigningRequirements(
  nodeTree: MaterialisedPropertyData,
  updatedPath: string[] | undefined,
  context?: Pick<ComplexValidationContext, 'signing' | 'formCode' | 'formId' | 'formPath' | 'propertyMeta'>
): ComplexValidationReturnType {
  return validateSigningRequirementsInner(nodeTree, updatedPath, context);
}
export function validateSigningRequirementsNoEmail(
  nodeTree: MaterialisedPropertyData,
  updatedPath: string[] | undefined,
  context?: Pick<ComplexValidationContext, 'signing' | 'formCode' | 'formId' | 'formPath' | 'propertyMeta'>
): ComplexValidationReturnType {
  return validateSigningRequirementsInner(nodeTree, updatedPath, context, false);
}

export function validateSigningRequirementsInner(
  nodeTree: MaterialisedPropertyData,
  updatedPath: string[] | undefined,
  context?: Pick<ComplexValidationContext, 'signing' | 'formCode' | 'formId' | 'formPath' | 'propertyMeta'>,
  emailRequired: boolean = true
): ComplexValidationReturnType {
  log('validateSigningRequirements', nodeTree, updatedPath, context);

  if (context?.signing?.state !== FormSigningState.Configuring) return successResponse;
  if (!context.formCode) return successResponse;
  if (!context.formId) return successResponse;
  if (!context.formPath) return successResponse;

  const errorObj: ComplexValidationReturnErrors = {};

  const parties = context.signing.parties || [];
  validateDisableCounterpartSigning(context.formCode, parties, errorObj);

  const metaStatic = context.propertyMeta;
  for (const party of context.signing.parties || []) {
    const isRemoving = party.source.isRemoving;

    const paths = getPartyDetailPaths(
      party,
      nodeTree,
      context.formPath,
      false
    );

    log('process party', party, 'paths', paths, 'isRemoving', isRemoving);

    if (!paths) continue;
    const treeTop = (isRemoving ? metaStatic : nodeTree) ?? {};

    emailRequired && validatePartyEmail(party, errorObj, paths, treeTop, metaStatic);
    validatePartyPhone(party, errorObj, paths, treeTop, metaStatic);

    validatePartyProxyName(party, errorObj, paths, treeTop, metaStatic);

  }

  log('errorObj', errorObj);
  return {
    forceUpdatesOn: [],
    errorObj
  };
}

function validatePartyProxyName(party: SigningParty, errorObj: ComplexValidationReturnErrors, paths: IPartyDetailPaths, treeTop: Record<string, any>, metaTree: TransactionMetaData) {
  log('validatePartyProxyName', 'party type', party.type);
  if (!Predicate.proxyNotSelf(party.proxyAuthority)) return;
  const useMeta = true;
  let path = `${paths.meta.base}.proxyName`;
  const name = getValueByPath(path, metaTree, true);
  if (name) {
    return;
  }

  if (useMeta) path = `_metaTree.${path}`;
  errorObj[path] = ['required'];
}

function validatePartyEmail(party: SigningParty, errorObj: ComplexValidationReturnErrors, paths: IPartyDetailPaths, treeTop: Record<string, any>, metaTree: TransactionMetaData) {
  log('validatePartyEmail', 'party type', party.type);
  if (party.type !== SigningPartyType.SignOnline) return;
  const useMeta = Predicate.proxyNotSelf(party.proxyAuthority);
  let path = useMeta ? `${paths.meta.base}.proxyEmail` : `${paths.data.base}.${paths.data.email}`;
  const email = getValueByPath(path, useMeta ? metaTree : treeTop, true);

  if (email && canonicalisers.email(email).valid) {
    return;
  }

  if (useMeta) path = `_metaTree.${path}`;
  errorObj[path] = ['required'];
}

function validatePartyPhone(party: SigningParty, errorObj: ComplexValidationReturnErrors, paths: IPartyDetailPaths, treeTop: Record<string, any>, metaTree: TransactionMetaData) {
  log('validatePartyPhone', 'party type', party.type);
  if (party.type !== SigningPartyType.SignOnlineSms
    && party.verification?.type !== SigningPartyVerificationType.Sms) {
    return;
  }
  const useMeta = Predicate.proxyNotSelf(party.proxyAuthority);
  let path = useMeta ? `${paths.meta.base}.proxyPhone` : `${paths.data.base}.${paths.data.phone}`;
  const phone = getValueByPath(path, useMeta ? metaTree : treeTop, true);

  if (phone && canonicalisers.phone(phone).valid) {
    return;
  }

  if (useMeta) path = `_metaTree.${path}`;
  errorObj[path] = ['required'];
}

function validateDisableCounterpartSigning(formCode: string, signers: SigningParty[], errorObj: ComplexValidationReturnErrors) {
  if (!FormTypes[formCode].disableCounterpartSigning) return;
  if (signers.length <= 1) return;

  const desiredWetMode = signers[0].type === SigningPartyType.SignWet;
  for (let i = 1; i < signers.length; i++) {
    const wetMode = signers[i].type === SigningPartyType.SignWet;
    if (desiredWetMode !== wetMode) {
      errorObj['mixedSigning'] = ['invalid'];
      return;
    }
  }

  return;
}
