import { EditorListChildProps } from './CollectionEditor';
import { WrField } from './CommonComponentWrappers';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useTransactionField } from '../../hooks/useTransactionField';
import { AuthorityParty } from '@property-folders/contract';
import { TextClickCheck } from './TextClickCheck';
import { useContext } from 'react';
import { LineageContext } from '../../hooks/useVariation';
import { PathSegments } from '@property-folders/contract/yjs-schema/model';
import { normalisePathToStr, normalisePathToStrArray } from '@property-folders/common/util/pathHandling';

export type PartyMultipleExcludingAutoChildProps = {
  titlePrefixIdentifier?: string | false | undefined
  outerPartyLabel: string,
  partyLabel: string,
  disabledEmail?: boolean
  varied: boolean,
  baseLabels: {
    name: string,
    email: string,
    phone: string
  },
  representativeNoun: string,
  primaryPartyPath: PathSegments | null
  formerPrimaryPartyPath: PathSegments | null
  setPrimaryPartyPath: (path: PathSegments) => void
};

export type PartyMultipleRepProps =  EditorListChildProps & PartyMultipleExcludingAutoChildProps;
export function PartyAuthorityMultipleRepresentative({
  titlePrefixIdentifier,
  outerPartyLabel,
  partyLabel,
  representativeNoun,
  baseLabels,
  myIndex,
  hideDelete = false,
  removable,
  onInsert,
  primaryPartyPath = null,
  formerPrimaryPartyPath = null,
  setPrimaryPartyPath,
  ...restProps
}: PartyMultipleRepProps) {
  const { variationsMode } = useContext(LineageContext);

  const { fullPath, handleRemove } = useTransactionField<AuthorityParty['legalRepresentatives'][number] | undefined>(restProps);

  const iAmPrimaryContact = primaryPartyPath && fullPath === normalisePathToStr(primaryPartyPath);
  const closeButton = handleRemove && <CollectionRemoveButton removable={!!removable} onRemove={handleRemove} />;
  const [prevPath, currPath] = [normalisePathToStr(formerPrimaryPartyPath), normalisePathToStr(primaryPartyPath)];
  const isVaried = variationsMode && (prevPath !== currPath) && (fullPath === prevPath || fullPath === currPath);

  const primaryAndSecondaryCheckbox = <TextClickCheck
    label={'Primary contact'}
    type='checkbox'
    checked={iAmPrimaryContact}
    markVaried={isVaried}
    onSelected={()=>{
      setPrimaryPartyPath(normalisePathToStrArray(fullPath));
    }}
  />;
  const partyBody = <>
    <div className='d-flex delete-div'>
      <div className='d-flex flex-wrap flex-grow-1'>
        <div className='flex-grow-0 mt-2 p-1 pe-4'>{primaryAndSecondaryCheckbox}</div>
      </div>
      <div className='delete-button-negative flex-grow-0 mt-2 '>{!hideDelete && closeButton}</div>
    </div>
    <div className="d-flex w-100 flex-wrap">
      <div className='flex-grow-1 mt-2' style={{ width: '300px' }}>
        <WrField.Control
          parentPath={fullPath}
          myPath='name'
          name='name'
          label={baseLabels.name?.replace('{{partyLabel}}', partyLabel)}
        />
      </div>
    </div>
    <div className="d-flex w-100 flex-wrap">
      <div className='mt-2' style={{ width: '200px', flexGrow: 2 }}>
        <WrField.Control
          parentPath={fullPath}
          myPath='email'
          name='email'
          label={baseLabels.email?.replace('{{partyLabel}}', partyLabel)}
        />
      </div>
      <div className='flex-grow-1 mt-2' style={{ width: '100px' }}>
        <WrField.Control
          parentPath={fullPath}
          myPath='phone'
          name='phone'
          label={baseLabels.phone?.replace('{{partyLabel}}', partyLabel)}
        />
      </div>
      <div className='flex-grow-1 mt-2' style={{ width: '100px' }}>
        <WrField.Control
          parentPath={fullPath}
          myPath='abn'
          name='abn'
          label='ABN (if applicable)'
        />
      </div>
    </div>

  </>;

  return <div className='w-100'>
    <div className='d-flex flex-row'>
      <div className='fs-5'>{titlePrefixIdentifier ? `${titlePrefixIdentifier} - ` : ''}{representativeNoun} {myIndex+1}</div>
      <div className='d-flex align-items-center delete-div ms-auto mt-2'></div>
    </div>

    <div className='w-100 d-flex'>
      <div className='flex-grow-1'>{partyBody}</div>
    </div>
  </div>;
}