import { seoFriendlySlug, ShortId } from './url';
import { PortalType } from '../client-api/auth';
import { stringify as querystring } from 'querystring';
import { FolderType } from '@property-folders/contract';

export interface SeoFriendlySlugOptions {
  id: string,
  nicetext?: string
}

export class LinkBuilder {
  private static basePath = '/legacy';

  static get websocketEndpoint(): string {
    const path: string = import.meta.env.VITE_API_WS_PATH || '/api/ws';
    const url: URL = import.meta.env.VITE_API_WS_URL_BASE
      ? new URL(path, import.meta.env.VITE_API_WS_URL_BASE)
      : new URL(path, window.location.origin);

    url.protocol = url.protocol === 'http:'
      ? 'ws'
      : 'wss';

    return url.toString();
  }

  static getPortalWebsocketEndpoint(type: PortalType) {
    const path = `/${type}/api/ws`;
    const url: URL = import.meta.env.VITE_API_WS_URL_BASE
      ? new URL(path, import.meta.env.VITE_API_WS_URL_BASE)
      : new URL(path, window.location.origin);

    url.protocol = url.protocol === 'http:'
      ? 'ws'
      : 'wss';

    return url.toString();
  }

  static get restApiBase(): string {
    const path: string = import.meta.env.VITE_API_REST_PATH || '/api/rest';
    const url: URL = import.meta.env.VITE_API_REST_URL_BASE
      ? new URL(path, import.meta.env.VITE_API_REST_URL_BASE)
      : new URL(path, window.location.origin);

    return url.toString();
  }

  static get signingSiteBase(): string {
    const path = '/sign';
    const url: URL = import.meta.env.VITE_SIGNING_URL_BASE
      ? new URL(path, import.meta.env.VITE_SIGNING_URL_BASE)
      : new URL(path, window.location.origin + this.basePath);

    return url.toString();
  }

  static get portalSiteBase(): string {
    const path = '';
    const url: URL = import.meta.env.VITE_PORTAL_URL_BASE
      ? new URL(path, import.meta.env.VITE_PORTAL_URL_BASE)
      : new URL(path, window.location.origin + this.basePath);

    url.hostname = `portal.${url.hostname}`;

    return url.toString();
  }

  static restApi(path: string) {
    return `${this.restApiBase}${path}`;
  }

  static reaformsFromRoot(path: string, isLegacy = true): string {
    if (path.startsWith('/') && isLegacy) {
      path = this.basePath + path;
    }

    const url: URL = import.meta.env.VITE_REAFORMS_URL_BASE
      ? new URL(path, import.meta.env.VITE_REAFORMS_URL_BASE)
      : new URL(path, window.location.origin);

    return url.toString();
  }

  static signingPage(path: string) {
    return `${this.signingSiteBase}${path}`;
  }

  static propertiesImages(path: string): string {
    const base: URL = import.meta.env.VITE_REAFORMS_URL_BASE
      ? new URL(import.meta.env.BASE_URL, import.meta.env.VITE_REAFORMS_URL_BASE)
      : new URL(import.meta.env.BASE_URL, window.location.origin + this.basePath);
    const imagesRoot = new URL('images-pf/', base);
    return new URL(path, imagesRoot).toString();
  }

  static propertiesPrescribedPdfs(path: string): string {
    const base: URL = import.meta.env.VITE_REAFORMS_URL_BASE
      ? new URL(import.meta.env.BASE_URL, import.meta.env.VITE_REAFORMS_URL_BASE)
      : new URL(import.meta.env.BASE_URL, window.location.origin + this.basePath);
    const pdfRoot = new URL('prescribed-pdfs/', base);
    return new URL(path, pdfRoot).toString();
  }

  /**
   * 🐌➖🐚
   */
  static seoFriendlySlug(uuid: string, nicetext?: string): string {
    return seoFriendlySlug(uuid, nicetext);
  }

  static propertyPath(property: SeoFriendlySlugOptions) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    return `/properties/${propertySlug}`;
  }

  static documentPath(
    property: SeoFriendlySlugOptions,
    document: SeoFriendlySlugOptions,
    isSubscriptionFormOrConfig: boolean | { isSubscriptionForm?: boolean; folderType?: FolderType }
  ) {
    let folderType = FolderType.Property;
    let isSubscriptionForm = false;

    if (typeof isSubscriptionFormOrConfig === 'object') {
      folderType = isSubscriptionFormOrConfig.folderType ?? FolderType.Property;
      isSubscriptionForm = isSubscriptionFormOrConfig.isSubscriptionForm ?? (folderType === FolderType.Document);
    } else {
      isSubscriptionForm = isSubscriptionFormOrConfig;
    }

    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    const documentSlug = this.seoFriendlySlug(document.id, document.nicetext);

    switch (folderType) {
      case FolderType.Document:
        return `/subscription-folder/${propertySlug}/document/${documentSlug}`;

      case FolderType.Property:
      default:
        return isSubscriptionForm
          ? `/properties/${propertySlug}/subscription/${documentSlug}`
          : `/properties/${propertySlug}/document/${documentSlug}`;
    }
  }

  static purchaserPortal(
    portal: SeoFriendlySlugOptions
  ) {
    const portalSlug = this.seoFriendlySlug(portal.id, portal.nicetext);
    return `/purchaser/${portalSlug}`;
  }

  static externalReviewPath(property: SeoFriendlySlugOptions) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    return `/properties/${propertySlug}/review-external-update`;
  }

  static propertyPagePath(property: SeoFriendlySlugOptions, path: string) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    return `/properties/${propertySlug}/${path}`;
  }

  static entityImage(entityId: number, image: string) {
    return `${this.restApiBase}/entities/${entityId}/image/${image}`;
  }

  static publishedDocument(id: string) {
    return `${this.restApiBase}/publish/${id}`;
  }

  static contractManagementPath(property: SeoFriendlySlugOptions) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    return `/properties/${propertySlug}/contracts`;
  }

  static offerPurchaserPath(property: SeoFriendlySlugOptions, purchaser: SeoFriendlySlugOptions) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    const purchaserSlug = this.seoFriendlySlug(purchaser.id, purchaser.nicetext);
    return `/properties/${propertySlug}/offer-management/${purchaserSlug}`;
  }

  static prospectivePurchaserPath(property: SeoFriendlySlugOptions, purchaser: {id: string, name: string}) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    return `/properties/${propertySlug}/prospective-purchasers/?id=${ShortId.fromUuid(purchaser.id)}&name=${encodeURIComponent(purchaser.name)}`;
  }

  static LetterOfOfferPreviewPath(property: SeoFriendlySlugOptions, offer: {id: string, name: string}) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    return `/properties/${propertySlug}/offer-management/?previewId=${ShortId.fromUuid(offer.id)}&name=${encodeURIComponent(offer.name)}`;
  }

  static vendorToSignPath(property: SeoFriendlySlugOptions, purchaser: SeoFriendlySlugOptions) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    const purchaserSlug = this.seoFriendlySlug(purchaser.id, purchaser.nicetext);
    return `/properties/${propertySlug}/contracts/${purchaserSlug}`;
  }

  static auditPath(
    property: SeoFriendlySlugOptions,
    formId?: string
  ) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    const friendlyFormId = formId
      ? this.seoFriendlySlug(formId)
      : undefined;
    const qs = friendlyFormId
      ? this.buildQueryString({ formId: friendlyFormId })
      : undefined;

    return qs
      ? `/properties/${propertySlug}/audit?${qs}`
      : `/properties/${propertySlug}/audit`;
  }
  static signingPath(
    property: SeoFriendlySlugOptions,
    document: SeoFriendlySlugOptions,
    party: SeoFriendlySlugOptions,
    folderType: FolderType = FolderType.Property
  ) {
    const propertySlug = this.seoFriendlySlug(property.id, property.nicetext);
    const documentSlug = this.seoFriendlySlug(document.id, document.nicetext);
    const partySlug = this.seoFriendlySlug(party.id, party.nicetext);

    return folderType === FolderType.Document
      ? `/subscription-folder/${propertySlug}/sign/${documentSlug}/as/${partySlug}`
      : `/properties/${propertySlug}/sign/${documentSlug}/as/${partySlug}`;
  }

  static get abn(): string {
    return 'https://abr.business.gov.au/ABN/View?id=19647076926';
  }

  static get appStore(): string {
    return 'https://itunes.apple.com/au/app/reaforms/id1057837420?mt=8';
  }

  static get reaformsManageProfile(): string {
    return this.reaformsFromRoot('/user_profile.php');
  }

  static get reaformsEmailStatus(): string {
    return this.reaformsFromRoot('/emailstatus.php');
  }

  static get reaformsPendingESignings(): string {
    return this.reaformsFromRoot('/esignstatus.php');
  }

  static get reaformsFormTemplates(): string {
    return this.reaformsFromRoot('/user_form_templates.php');
  }

  static get reaformsEmailTemplates(): string {
    return this.reaformsFromRoot('/user_email_templates.php');
  }

  static get reaformsTerms(): string {
    return this.reaformsFromRoot('/help/terms_of_use.php');
  }

  static get reaformsUserGuide(): string {
    return this.reaformsFromRoot('/help/ReaForms_User_Guide.html');
  }

  static get reaformsMailToSupport(): string {
    return 'mailto:support@reaforms.com.au?subject=support';
  }

  static get reaformsPhone(): string {
    return 'tel:0881661188';
  }

  static get ajax(): string {
    return this.reaformsFromRoot('/ajax.php');
  }

  static ajaxRequest(queryParams: {action: string, [key: string]: any}) {
    return `${this.reaformsFromRoot('/ajax.php', true)}?${querystring(queryParams)}`;
  }

  static legacyApi(path: string): string {
    return this.reaformsFromRoot(`/API${path}`);
  }

  static get localImages(): string {
    return this.reaformsFromRoot('/images-pf');
  }

  static loginPage(returnPath?: string, params?: {[key: string]: string}) {
    const url = new URL(this.reaformsFromRoot('login'));

    if (returnPath) {
      url.searchParams.append('returnPath', returnPath);
    }

    if (params) {
      for (const key of Object.keys(params)) {
        url.searchParams.append(key, params[key]);
      }
    }

    return url;
  }

  static readonly homePage = '/index.php';

  static signingImages(path: string): string {
    const base: URL = import.meta.env.VITE_REAFORMS_URL_BASE
      ? new URL(import.meta.env.BASE_URL, import.meta.env.VITE_REAFORMS_URL_BASE)
      : new URL(import.meta.env.BASE_URL, window.location.origin);
    const imagesRoot = new URL('images-pf/', base);
    return new URL(path, imagesRoot).toString();
  }

  static applyQueryString(qs: URLSearchParams, params: {[key:string]: boolean | number | string | undefined} | undefined) {
    if (!params) return;

    for (const key of Object.keys(params)) {
      const val = params[key];
      if (val == null) {
        continue;
      }
      switch (typeof val) {
        case 'boolean':
        case 'number':
          qs.set(key, val.toString());
          break;
        case 'string':
          qs.set(key, val);
          break;
        default:
          break;
      }
    }
  }

  static buildQueryString(params: {[key:string]: boolean | number | string | undefined} | undefined) {
    const qs = new URLSearchParams();
    this.applyQueryString(qs, params);
    return qs.toString();
  }
}
