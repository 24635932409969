import { DefaultPageLayout } from '~/components/default-page-layout';
import { Button } from 'react-bootstrap';
import {
  PurchaserSubmittedDocument,
  PurchaserSubmittedDocumentStatus
} from '@property-folders/contract/yjs-schema/purchaser-portal';
import { PurchaserPortalApi } from '~/api';
import { SimpleCrumbs } from '~/components/simple-crumbs';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { getSubmittedDocumentFileName } from '~/routes/purchaser/authenticated-offer';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { ConfirmWithdrawModal } from '~/components/confirm-withdraw-modal';
import { Icon } from '@property-folders/components/dragged-components/Icon';

export function ViewSubmittedOfferPage({
  headline,
  agencyCssOverrides,
  submittedMatch,
  portalId
}: {
  headline: string,
  agencyCssOverrides?: Record<string, string>,
  submittedMatch: PurchaserSubmittedDocument,
  portalId: string
}) {
  const navigate = useNavigate();
  const [selectedDocument, setSelectedDocument] = useState<DocumentReference | undefined>(undefined);
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false);
  const documents = useMemo(() => getDocumentReferences({
    submittedMatch,
    headline,
    portalId
  }), [submittedMatch, submittedMatch.lifecycleCopies?.length]);
  useEffect(() => {
    const last = documents.at(0);
    setSelectedDocument(current => {
      return current?.key === last?.key ? current : last;
    });
  }, [documents.length]);
  const breadcrumbText = `${submittedMatch.name} (${submittedMatch.status})`;

  return <DefaultPageLayout
    title={'Review an offer'}

    subtitle={[<SimpleCrumbs key='crumbs'>
      <Link key='backlink' to={'..'} relative={'route'}>{headline}</Link>
      <span key='crumb'>{breadcrumbText}</span>
    </SimpleCrumbs>]}
    agencyCssOverrides={agencyCssOverrides}
    noPad={true}
  >
    {showConfirmWithdraw && <ConfirmWithdrawModal
      portalId={portalId}
      offerId={submittedMatch.documentId}
      onClose={() => setShowConfirmWithdraw(false)} />}
    {selectedDocument && <>
      <div style={{ position: 'absolute', top: '96px', left: '0', width: '100%', height: 'calc(100% - 96px)' }}>
        <SetupPdfLoadStateContext>
          <PDFViewer
            bookmark=''
            pdfUrl={selectedDocument.src}
            filename={selectedDocument.fileName}
            renderTextLayer={false}
            standalonePreview={true}
            useLoadSuccessForCompletion={true}
            activeViews={1}
            toolbarRight={<>
              {documents.length > 1
                ? <select
                  key='documents'
                  onChange={e => {
                    const doc = documents.find(d => d.key === e.target.value);
                    setSelectedDocument(doc);
                  }}
                  value={selectedDocument?.key}
                >
                  {documents.map(document => {
                    return <option key={document.key} value={document.key}>{document.fileName}</option>;
                  })}
                </select>
                : undefined}
              {submittedMatch.status === PurchaserSubmittedDocumentStatus.Submitted
                ? <Button
                  key='withdraw'
                  variant='danger'
                  className='text-button'
                  onClick={() => setShowConfirmWithdraw(true)}
                >Withdraw</Button>
                : undefined}

              <Button
                variant="secondary"
                onClick={()=>{
                  navigate('..', { relative: 'route' });
                }}
              >
                <Icon name='close'/>
              </Button>
            </>}
          />
        </SetupPdfLoadStateContext>
      </div>
    </>}
  </DefaultPageLayout>;
}

interface DocumentReference {
  key: string;
  src: string;
  fileName: string;
  timestamp: number;
}

function getDocumentReferences({
  submittedMatch,
  headline,
  portalId
}: {
  submittedMatch: PurchaserSubmittedDocument,
  headline: string,
  portalId: string
}): DocumentReference[] {
  const result: DocumentReference[] = [];

  const baseFileName = getSubmittedDocumentFileName(headline, submittedMatch);
  result.push({
    key: 'base',
    timestamp: 0,
    fileName: baseFileName,
    src: PurchaserPortalApi.getPurchaserPortalSubmittedDocumentUrl({
      portalId,
      documentId: submittedMatch.documentId,
      fileName: baseFileName
    })
  });

  result.push(...(submittedMatch.lifecycleCopies || []).map(copy => {
    const fileName = getSubmittedDocumentFileName(headline, submittedMatch, copy.status);
    return {
      key: copy.id,
      timestamp: copy.timestamp,
      fileName,
      src: PurchaserPortalApi.getPurchaserPortalSubmittedDocumentUrl({
        portalId,
        documentId: submittedMatch.documentId,
        lifecycleCopyId: copy.id,
        fileName
      })
    };
  }));

  result.sort((a, b) => b.timestamp - a.timestamp);
  return result;
}
