import { DivisionType, MaterialisedPropertyData, SaleTitle, TitleInclusionState } from '@property-folders/contract';
import { anyTitlePortions, determineTitleInclusionState } from './validation/expected-evaluator';
import { Predicate } from '../../predicate';

export function proposedPrefixTextGen(depositChoice: DivisionType | undefined, proposedLots: string | undefined, saleTitles: SaleTitle[] | undefined) {
  if (!(depositChoice && proposedLots)) {
    return '';
  }
  const { titleInclusionState, explicit } = determineTitleInclusionState(saleTitles || []);
  return explicit && titleInclusionState === TitleInclusionState.portion && proposedLots
    ? depositChoice === DivisionType.Community
      ? `Proposed Lot(s) ${proposedLots}`
      : `Proposed Allotment(s) ${proposedLots}`
    : '';
}

/**
 * Notably different from other headline generators as it does not consider transaction 'headline'
 */
export function summariseAddressesOrTitles(transRoot?: Pick<MaterialisedPropertyData, 'saleAddrs' | 'saleTitles' | 'titleDivision'>, narrowMode = false) {
  if (!transRoot) {
    return '';
  }
  const proposedLots = proposedPrefixTextGen(
    transRoot.titleDivision?.depositChoice,
    transRoot.titleDivision?.proposedLots,
    transRoot.saleTitles
  );
  if (
    !(Array.isArray(transRoot.saleAddrs) && transRoot.saleAddrs.length > 0 && transRoot.saleAddrs[0].streetAddr)
    && !(Array.isArray(transRoot.saleTitles) && transRoot.saleTitles.length > 0 && transRoot.saleTitles[0].title)
  ) {
    // If there are absolutely no property details, just forget about it
    return proposedLots;
  }

  if (
    !(Array.isArray(transRoot.saleAddrs) && transRoot.saleAddrs.length > 0 && transRoot.saleAddrs[0].streetAddr)
    && Array.isArray(transRoot.saleTitles) && transRoot.saleTitles.length > 0 && transRoot.saleTitles[0].title
  ) {
    return [
      proposedLots,
      transRoot.saleTitles.map(t => (anyTitlePortions([t]) ? 'Whole of ' : 'Portion of ') + t.title).join(' and ')
    ].filter(Predicate.isTruthy).join(', ');
  }

  if (Array.isArray(transRoot.saleAddrs) && transRoot.saleAddrs.length > 0 && transRoot.saleAddrs[0].streetAddr) {
    return [
      proposedLots,
      (narrowMode
        ? transRoot.saleAddrs.map(a => a.streetAddr)
        : transRoot.saleAddrs.map(a => [a.streetAddr, a.subStateAndPost].join(', '))
      ).join(' and ')
    ].filter(Predicate.isTruthy).join(', ');
  }

  // Fall over condition. The above is written like guard statements, but TS still doesn't
  // like it
  return proposedLots;
}
