import { useRoom } from '@y-presence/react';
import { AwarenessData } from '@property-folders/contract';
import { createContext, ReactNode, useCallback } from 'react';
import { debounce } from 'lodash';

interface IDebouncedAwareness {
  updatePresence?: (partial: Partial<AwarenessData>) => void;
}

export const DebouncedAwarenessContext = createContext<IDebouncedAwareness>({});

export function SetupDebouncedAwarenessContext(
  props: {
    delay: number,
    children: ReactNode
  }
) {
  const room = useRoom<AwarenessData>();
  const updatePresence = useCallback(
    debounce((partial: Partial<AwarenessData>) => {
      room.updatePresence(partial);
    }, props.delay),
    [room]
  );
  const value: IDebouncedAwareness = {
    updatePresence
  };

  return (
    <DebouncedAwarenessContext.Provider value={value}>
      {props.children}
    </DebouncedAwarenessContext.Provider>
  );
}
