import { Navigate, NavigateProps, useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { useAuth } from '~/context/auth-context';
import { PortalSessionInfoResult } from '@property-folders/contract';

type PathParams = Record<string, string | undefined>;
export type ProtectedRouteRedirectFunc = ((params: PathParams, pathname: string) => NavigateProps);
export type ProtectedRouteSessionAssertionFunc = (sessionInfo: PortalSessionInfoResult, params: PathParams) => boolean;

export interface ProtectedRouteParams {
  redirect?: NavigateProps | ProtectedRouteRedirectFunc;
  sessionCheck?: ProtectedRouteSessionAssertionFunc;
}

export function ProtectedRoute({ children, redirect, sessionCheck }: React.PropsWithChildren<ProtectedRouteParams>) {
  const params = useParams();
  const { pathname } = useLocation();
  const { sessionInfo, loaded } = useAuth();

  if (!loaded) {
    return <></>;
  }
  // Here's where we redirect to /login if we aren't good to access
  const accessGo = hasAccess(sessionInfo,  sessionCheck, params);
  if (!accessGo) {
    if (redirect) {
      return typeof redirect === 'function'
        ? <Navigate {...redirect(params, pathname)} />
        : <Navigate {...redirect} />;
    }

    return <div style={{ color: 'white', background: 'red', padding: '10px' }}>Unauthorised!</div>;
  }

  return <>{children}</>;
}

function hasAccess(
  sessionInfo: PortalSessionInfoResult | undefined,
  sessionCheck: ProtectedRouteSessionAssertionFunc | undefined,
  params: PathParams
): boolean {
  if (!sessionInfo?.authenticated) {
    return false;
  }
  if (sessionCheck && !sessionCheck(sessionInfo, params)) {
    return false;
  }

  return true;
}
