import { normalisePathToStr } from '../../pathHandling';
import { PathType } from '@property-folders/contract/yjs-schema/model';

export const ERROR_FOCUS_PREFIX = 'error-path-focus-';

/**
 *
 * @param fullPath to the node with the error, not to any leafnode that caused the error in the case
 * of complex validators.
 * @param focusTarget
 * @returns
 */
export function composeErrorPathClassName(fullPath: PathType, focusTarget: string | undefined | null) {
  const pathStr = normalisePathToStr(fullPath).replace(/[^\w]/g, '_');
  return ERROR_FOCUS_PREFIX + pathStr + (focusTarget ? `-${focusTarget}` :'');
}
