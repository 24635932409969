export function validateNZBN(numsOnly: string): boolean {
  const reversed = numsOnly
    .split('')
    .map(Number)
    .reverse();
  const expected = reversed[0];
  return calculateCheckDigit(reversed.slice(1)) === expected;
}

function calculateCheckDigit(nonCheckDigitsReversed: number[]) {
  const sum = nonCheckDigitsReversed
    .reduce((acc, cur, idx) => acc + (idx % 2 === 0
      ? cur * 3
      : cur),
    0);
  return 10 - (sum % 10);
}
