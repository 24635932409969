import { ContractPriceType, depositPayAtOpts } from '@property-folders/contract';
import { Predicate } from '../../../predicate';
import {
  CheckboxDefn, drawUnderline,
  fieldFocus,
  fieldFocusMap,
  generateCheckboxRows,
  itemiser,
  itemSection,
  itemSubsection,
  noborder,
  singleFieldTable,
  spaceStackLinesSideEffect
} from '..';
import { canonicalisers, CanonicalResults, parseInt2 } from '../../formatting';
import { calculateGstConditionsDisplay } from './contractGst';
import { FieldPlaceholderStyle } from '../standards';

export function calculateFinalPrice(inputTaxed?: boolean, gstIncluded?: boolean, price?: string, gst?: string) {
  const calcGst = !!(Predicate.boolFalse(inputTaxed) && Predicate.boolFalse(gstIncluded));
  // So previously the intent was that we showed an empty GST field if the gst state was not
  // selected. However somehow that's not what is happening. Given the vast majority case is input
  // taxed, and we've gotten a crappy bug, it seems appropriate to only calculate GST if explicitly
  // set
  const onlyPrice = !calcGst;
  let finalPrice;
  const parseAud = canonicalisers.aud;
  const parsedPrice = parseAud(price||'');
  const parsedGst = parseAud(calcGst ? (gst||'') : '');
  if (onlyPrice && parsedPrice.valid) {
    finalPrice = parsedPrice;
  } else if (calcGst && parsedPrice.valid && parsedGst.valid) {
    finalPrice = parseAud(parseInt2(parsedPrice.canonical) + parseInt2(parsedGst.canonical));
  }

  return {
    finalPriceDisplay: finalPrice?.valid ? finalPrice?.display : undefined,
    finalPrice: finalPrice?.valid ? finalPrice?.canonical : undefined,
    onlyPrice,
    parsedPrice,
    parsedGst
  };
}

function sumResults(...items: CanonicalResults[]) {
  return items
    .map(item => {
      if (typeof item.canonical === 'number') return item.canonical;
      return parseInt2(item.canonical);
    })
    .filter(Predicate.isNotNullish)
    .reduce((acc, value) => acc + value, 0);
}

export function calculateFinalPrice2(vendorGst?: boolean, contractPrice?: ContractPriceType) {
  const { onlyPrice, isTaxableSupplyGstIncluded, isInputTaxed } = calculateGstConditionsDisplay(
    { vendorGst },
    contractPrice || {}
  );
  const {
    purchasePrice,
    purchaseGst
  } = contractPrice || {};

  const parsedPrice = canonicalisers.aud(purchasePrice || '');
  const parsedGst = canonicalisers.aud(onlyPrice ? '' : (purchaseGst || ''));
  const finalPrice = onlyPrice
    ? parsedPrice.valid
      ? parsedPrice
      : undefined
    : parsedPrice.valid && parsedGst.valid
      ? canonicalisers.aud(sumResults(parsedPrice, parsedGst).toString())
      : undefined;

  const result = {
    finalPriceDisplay: finalPrice?.valid ? finalPrice.display : undefined,
    purchasePriceDisplay: parsedPrice.valid ? parsedPrice.display : undefined,
    finalPrice: finalPrice?.valid ? finalPrice.canonical : undefined,
    onlyPrice,
    parsedPrice,
    parsedGst,
    isTaxableSupplyGstIncluded,
    isInputTaxed
  };
  return result;
}

export function displayPrice(price?: string) {
  const parseAud = canonicalisers.aud;
  const parsedPrice = parseAud(price||'');
  return parsedPrice?.valid ? parsedPrice.display : '<Not Set>';
}

function displayIfValid(canonResults: CanonicalResults): string | undefined {
  return canonResults.valid ? canonResults.display : undefined;
}

function priceSubSection({
  vendorGst,
  contractPrice,
  isVariation
}: {
  vendorGst?: boolean,
  contractPrice?: Partial<ContractPriceType>,
  isVariation?: boolean
}) {
  const {
    onlyPrice,
    parsedGst,
    parsedPrice
  } = calculateFinalPrice2(vendorGst, contractPrice);
  const subsectionItems = [];

  subsectionItems.push({ ...itemiser([
    { id: 'purchasePrice', itemDesc: 'Amount payable', itemCost: displayIfValid(parsedPrice) },
    { id: 'purchaseGst', itemDesc: 'GST payable (if in addition)', itemCost: onlyPrice ? 'N/A' : displayIfValid(parsedGst) }
  ], 'Purchase Price', 0, undefined, undefined,'left', noborder, '40%')?.[0], margin: [-6,0,0,0] });

  return itemSubsection({
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subsectionItems),
    unbreakable: true,
    bookmark: ['subsection-contract-price', ...fieldFocus('contractPrice.purchasePrice', 'contractPrice.purchaseGst')],
    isVariation
  });
}

function depositSubsection(depositAmount?: string, depositAtOption?: string, depositAtOther?: string, isVariation?: boolean) {
  const subsectionTitle = 'Deposit';
  const parsedDeposit = canonicalisers.aud(depositAmount||'');
  const subsectionItems = [];

  if (parsedDeposit.valid) {
    subsectionItems.push([{ ...singleFieldTable({ fieldName: 'Deposit amount', fieldValue: depositAmount, fieldPlaceholder: FieldPlaceholderStyle.Price }), margin: [0,10,0,0] }]);
  } else {
    subsectionItems.push({ ...itemiser([{ id: 'depositAmount', itemDesc: 'Deposit amount', itemCost: displayIfValid(parsedDeposit) }],
      undefined, 0, undefined, undefined,'left', noborder, '40%', true)?.[0], margin: [-6,10,0,0] });
  }

  const anySelection = depositAtOption && depositPayAtOpts[depositAtOption];
  subsectionItems.push({ text: 'The Deposit will be paid:', margin: [0,10,0,0] });
  const opts: CheckboxDefn[] = Object.values(depositPayAtOpts).slice(0, -1);
  opts.push({ other: true, label: 'other', content: depositAtOption === 'other' && depositAtOther ? depositAtOther : drawUnderline(FieldPlaceholderStyle.Default) });
  subsectionItems.push(generateCheckboxRows(opts, Predicate.isNotNullish(anySelection)?[anySelection]:[], 1));

  return itemSubsection({
    subsectionTitle: subsectionTitle,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subsectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-deposit', ...fieldFocusMap({ contractPrice: ['deposit', 'depositPayAt', 'depositDateOther'] })],
    isVariation
  });
}

export function contractPriceSection(
  itemNo: number,
  vendorGst: boolean | undefined,
  contractPrice: ContractPriceType | undefined,
  options?: { isVariation?: boolean; templateContractMode?: boolean }
) {
  const { templateContractMode, isVariation } = options ?? {};
  const sectionItems = [];
  sectionItems.push(priceSubSection({
    isVariation,
    vendorGst,
    contractPrice: {
      ...contractPrice,
      purchasePrice: templateContractMode ? undefined : contractPrice?.purchasePrice,
      purchaseGst: templateContractMode ? undefined : contractPrice?.purchaseGst
    }
  }));
  sectionItems.push(depositSubsection(
    templateContractMode ? undefined : contractPrice?.deposit,
    contractPrice?.depositPayAt,
    contractPrice?.depositDateOther
  ));
  return itemSection({
    itemNo: itemNo, itemTitleParam: 'Purchase Price'.toUpperCase(), bookmark: 'bookmark_contractPrice', stackContent: sectionItems,
    isVariation
  });
}

export function offerPriceSection(itemNo: number, contractPrice: ContractPriceType | undefined) {
  const parsedPrice = canonicalisers.aud(contractPrice?.purchasePrice || '');
  const parsedDeposit = canonicalisers.aud(contractPrice?.deposit || '');
  return itemSection({
    itemNo: itemNo, itemTitleParam: 'Purchase Price'.toUpperCase(), bookmark: 'bookmark_contractPrice', stackContent: [
      itemSubsection({
        titleLineContent: undefined,
        subsectionContent: spaceStackLinesSideEffect([
          { ...itemiser([
            { id: 'purchasePrice', itemDesc: 'Purchase Price', itemCost: displayIfValid(parsedPrice) },
            { id: 'depositAmount', itemDesc: 'Deposit amount', itemCost: displayIfValid(parsedDeposit) }
          ], 'Purchase Price', 0, undefined, undefined,'left', noborder, '40%', true)?.[0], margin: [-6,0,0,0] }
        ]),
        unbreakable: true
      })
    ]
  });
}
