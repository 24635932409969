import { z } from 'zod';
import { PurchaserPortalData, SettingsOfferType } from '../../yjs-schema/purchaser-portal';

export enum GnafType {
  PROPERTY = 'PROPERTY',
  AGENCY = 'AGENCY',
  DEFAULT = 'DEFAULT'
}

export interface GetPurchaserPortalResult {
  portalId: string;
  headline: string;
  entityName: string;
  authenticated: boolean;
  cssOverrides: Record<string,string>;
  gnaf: {
    id: string;
    type: GnafType
  };
  settings: {
    anonymousRegistration?: boolean;
    brochure?: boolean;
    form1?: boolean;
    exampleContract?: boolean;
    offerType?: SettingsOfferType;
    timeZone?: string;
    unauthDocs: DocumentTypePP[]
  };
  sharedDocuments: PurchaserPortalSharedDocument[];
}

export interface CreatePurchaserPortalResult {
  portalId: string;
}

export interface GetPurchaserPortalAuthenticatedResult extends GetPurchaserPortalResult {
  portalUserId: string;
  yDocId: string;
}

export interface PurchaserPortalSharedDocument {
  name: string;
  fileName: string;
  status: string;
  timestamp?: number;
  url: string;
  documentId?: string;
  old?: boolean;
  loginRequired?: boolean;
  documentType:
    | 'form-1'
    | 'brochure'
    | 'contract' // Template Contract
    // e.g. form r3
    | 'prescribed-notice';
}
export type DocumentTypePP = PurchaserPortalSharedDocument['documentType'];
export interface GetPurchaserPortalTokenResult {
  portalUserId: string;
  portalRootDocumentId: string;
}

export const PostPurchaserPortalLoginBodySchema = z.object({
  username: z.string().min(1)
});
export type PostPurchaserPortalLoginBody = z.infer<typeof PostPurchaserPortalLoginBodySchema>;

export type resendSourceType = 'login' | 'registration';

export const PostPurchaserPortalResendBodySchema = z.object({
  username: z.string().min(1),
  resendSource: z.string().min(1)
});
export type PostPurchaserPortalResendBody = z.infer<typeof PostPurchaserPortalResendBodySchema>;

export type PostPurchaserPortalLoginResponse =
  | { type: 'success' }
  | { type: 'sms_verification_required', phoneSuffix: string };

export const PostPurchaserPortalLoginVerifyBodySchema = z.object({
  code: z.string().min(1)
});
export type PostPurchaserPortalLoginVerifyBody = z.infer<typeof PostPurchaserPortalLoginVerifyBodySchema>;

export type GetPurchaserPortalRegistrationResult =
  | { type: 'continue', portalUserId: string, ydocId: string }
  | { type: 'verification_required', portalUserId: string, ydocId: string, email?: boolean, phone?: boolean }
  | { type: 'login_redirect', message: string };

export const PostPurchaserPortalRegistrationBodySchema = z.object({
  username: z.string().min(1),
  inviteToken: z.string().optional()
});
export type PostPurchaserPortalRegistrationBody = z.infer<typeof PostPurchaserPortalRegistrationBodySchema>;

export const PostPurchaserPortalInvitationBodySchema = z.object({
  userId: z.string(),
  subject: z.string(),
  message: z.string()
});
export type PostPurchaserPortalInvitationBody = z.infer<typeof PostPurchaserPortalInvitationBodySchema>;

export const PostPurchaserPortalSettingsBodySchema = z.object({
  anonymousRegistration: z.boolean().optional(),
  brochure: z.boolean().optional(),
  form1: z.boolean().optional(),
  exampleContract: z.boolean().optional(),
  offerType: z.enum([SettingsOfferType.offer, SettingsOfferType.contract, SettingsOfferType.none]).optional(),
  unauthDocs: z.array(z.string()).optional()
});
export type PostPurchaserPortalSettingsBody = z.infer<typeof PostPurchaserPortalSettingsBodySchema>;

export type PostPurchaserPortalRegistrationResult =
  | { type: 'verification_required', email?: boolean, phone?: boolean }
  | { type: 'completed', replacePortalUserId?: string }
  | { type: 'invalid_username' };

export const PostPurchaserPortalRegistrationVerificationBodySchema = z.object({
  phoneCode: z.string().optional()
});
export type PostPurchaserPortalRegistrationVerificationBody = z.infer<typeof PostPurchaserPortalRegistrationVerificationBodySchema>;

export type PostPurchaserPortalRegistrationVerificationResult =
  | { type: 'verification_required', email?: boolean, phone?: boolean }
  | { type: 'completed', replacePortalUserId?: string };

export const PostPurchaserForgotPasswordBodySchema = z.object({
  username: z.string().min(1)
});
export type PostPurchaserForgotPasswordBody = z.infer<typeof PostPurchaserForgotPasswordBodySchema>;

export const PostPurchaserPasswordResetBodySchema = z.object({
  password: z.string().min(1),
  confirmPassword: z.string().min(1)
});
export type PostPurchaserPasswordResetBody = z.infer<typeof PostPurchaserPasswordResetBodySchema>;

export interface PostPurchaserPortalCreateOfferResult {
  yDocId: string;
}

export interface GetPurchaserPortalOfferSigningSessionResult {
  status: 'active' | 'void' | 'done' | 'pending' | 'failed';
  statusMessage?: string;
  signingUrl?: string;
}

export const PostPurchaserDocument = z.object({
  documentType: z.string().transform(t => t as PurchaserPortalSharedDocument['documentType']),
  fileName: z.string()
});
export type PostPurchaserDocumentBody = z.infer<typeof PostPurchaserDocument>;

export interface PostPurchaserDocumentResponse {
  url: string;
  directedHeaders?: Map<string,string>
  documentId: string;
}

export const PostPropertyPurchaserPortalUserBodySchema = z.object({
  // note: doesn't validate it, but at least provides the expected shape to consumers
  initialData: z.custom<PurchaserPortalData>(),
  invite: z.optional(z.object({
    subject: z.string(),
    message: z.string()
  }))
});

export type PostPropertyPurchaserPortalUserBody = z.infer<typeof PostPropertyPurchaserPortalUserBodySchema>;
