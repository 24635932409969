import { createContext, ReactNode } from 'react';
import { useEffectOnce, useInterval } from 'react-use';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { useStore } from 'react-redux';

interface IFileSyncContextData {
  instance?: FileSync;
}

export const FileSyncContext = createContext<IFileSyncContextData>({});

async function handleInterval(instance: FileSync) {
  await instance.syncFiles();
}

export function SetupFileSyncContext({ agentId, children }: {agentId: number, children: ReactNode }) {
  const instance = new FileSync(agentId, useStore());
  const value: IFileSyncContextData = {
    instance
  };

  useEffectOnce(() => {
    handleInterval(instance).catch(console.error);
  });

  useInterval(() => {
    handleInterval(instance).catch(console.error);
  }, 60000);

  return (
    <FileSyncContext.Provider value={value}>
      {children}
    </FileSyncContext.Provider>
  );
}
