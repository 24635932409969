import { createContext } from 'react';
import { YjsDocContextType } from '@property-folders/common/types/YjsDocContextType';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';

export const YjsDocContext = createContext<YjsDocContextType>({
  ydoc: undefined,
  awareness: undefined,
  docName: undefined, // yjs document id
  transactionRootKey: PropertyRootKey.Data,
  transactionMetaRootKey: PropertyRootKey.Meta,
  declareDebounce: () => {
    return;
  },
  clearDeclareDebounce: () => {
    return;
  }
});
