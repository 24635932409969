import { Card } from 'react-bootstrap';
import { ContentCard } from '~/components/content-card';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import type { ReactNode } from 'react';
import type { MaterialisedPropertyData } from '@property-folders/contract';

export function OfferMakingAnOffer({ contractMode, footer }: { contractMode: boolean; footer: ReactNode | undefined; }) {
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });

  return (
    <ContentCard>
      <Card.Header>
        <h4>Making an offer</h4>
      </Card.Header>
      <Card.Body>
        <p>{`This wizard will guide you through the process of making an offer for ${generateHeadlineFromMaterialisedData(property)}.`}</p>
        <p>We will ask you for details about all of the purchasers, the amount being offered, and any conditions that the offer may have.</p>
        <p>Afterwards, the purchasers will sign the offer and it will be submitted to the agency for presentation to the vendor.</p>
        {!contractMode && <p>You will be able to withdraw your submitted offer at any time. If your offer is accepted then it will form the basis of the contract that all of the parties will sign.</p>}
        {contractMode && <p>You will be able to withdraw your submitted offer at any time, prior to it being accepted by the vendor. If accepted by the vendor this contract will be legally binding.</p>}
      </Card.Body>
      <Card.Footer className='d-flex flex-row justify-content-end gap-2'>
        {footer}
      </Card.Footer>
    </ContentCard>
  );
}
