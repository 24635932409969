import * as React from 'react';
import { useDebounce } from '@react-hook/debounce';
import useResizeObserver from '@react-hook/resize-observer';

export function useSize<T extends HTMLElement>(target: React.RefObject<T> | null, debounceMs = 100) {
  const [size, setSize, setSizeImmediate] = useDebounce<DOMRectReadOnly>(new DOMRect(0,0,0,0), debounceMs);

  React.useLayoutEffect(() => {
    if (!target?.current) {
      return;
    }
    setSizeImmediate(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));

  return size;
}
