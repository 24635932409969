import { Predicate } from '../predicate';

export class KeywordsBuilder {
  private tokens = new Set<string>();

  private addToken(token: string | undefined | null) {
    const trimmed = token?.trim();
    if (!trimmed) return;

    this.tokens.add(trimmed);

    const unpunctuated = trimmed.replaceAll(/['`]/gi, '').trim();
    if (!unpunctuated) return;
    if (unpunctuated === trimmed) return;

    this.tokens.add(unpunctuated);
  }

  public add(text: string | undefined | null) {
    if (!text) return;
    const tokens = text.split(/[,_\-\s\r\n]+/gi)
      .map(s => s.trim().toLowerCase())
      .filter(Predicate.isTruthy);
    for (const token of tokens) {
      this.addToken(token);
    }
  }

  public addMultiple<T>(ts: T[], selector?: (t: T) => string | null | undefined) {
    for (const t of ts) {
      switch (typeof t) {
        case 'string':
        case 'undefined':
          this.add(t);
          break;
        default:
          if (!selector) throw new Error('keyword selector must be specified for non-string types');
          this.add(selector(t));
          break;
      }
    }
  }

  public keywords() {
    return [...this.tokens]
      .filter(Predicate.isTruthy);
  }
}
