import { useEffect, useState } from 'react';

/**
 * Note: not guaranteed to be 100% accurate, browsers vary in their detection.
 */
export function useOnline() {
  const [ online, setOnline ] = useState(window.navigator.onLine === undefined ? true : window.navigator.onLine);
  const handleOnline = () => setOnline(true);
  const handleOffline = () => setOnline(false);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return online;
}
