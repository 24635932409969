import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { WrField, WrFieldControlProps } from './CommonComponentWrappers';
import '../Form.scss';
import { Button, Modal } from 'react-bootstrap';
import { CollectionEditor, EditorListChildProps } from './CollectionEditor';
import { boolYesNoOpts, signingAuthorityOptions, vendorTypeOptions } from '@property-folders/common/data-and-text/constants';
import { jointPartyConfig, partyFieldConfig } from '@property-folders/common/util/formatting/constants';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { TextClickCheck } from './TextClickCheck';
import { PartyType, PurchaserParty, SaleAddress, VendorParty } from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import { AddressSelector } from './AddressSelector';
import { contactAddressDerivation } from '@property-folders/common/util/contactAddressDerivation';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { CollectionValidCheck } from './CollectionValidCheck';
import { LineageContext } from '../../hooks/useVariation';
import { generateParentPath, getValueByPath, mergePaths, normalisePathToStr, normalisePathToStrArray } from '@property-folders/common/util/pathHandling';
import clsJn from '@property-folders/common/util/classNameJoin';
import { AuthorityParty, jointGroupingPermitted, jointPartyNouns, jointTypes, PropertyRootKey, SigningAuthorityType, VendorPartyJoint } from '@property-folders/contract/yjs-schema/property';
import { PathSegments, PathType } from '@property-folders/contract/yjs-schema/model';
import Fuse from 'fuse.js';
import { notesTable } from '../../display/GuidanceNotes';
import { usePropertyGnafLocation } from '../../hooks/usePropertyGnafLocation';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { IContactSearchSuggestion } from '@property-folders/contract/rest/address-search';
import { PartyAuthorityMultipleRepresentative, PartyMultipleExcludingAutoChildProps } from './PartyAuthorityMultipleRepresentative';
import { getContactsFromAuthorityParty, primaryContactRelativePath, setAuthorityPartyPrimaryContact } from '@property-folders/common/util/digest-authority-party-details';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { multiRepAuthority } from '@property-folders/contract/property';
import { commaAndReducer } from '@property-folders/common/util/formatting/string-composites';
import { ModalDepthContext } from '../../context/WithinModal';
import { useIntegrationContacts } from '../../hooks/useIntegrationContacts';
import { Option } from 'react-bootstrap-typeahead/types/types';

const fuzzySearchConfig = {
  minMatchCharLength: 3,
  keys: ['fullLegalName']
};

type ExecutorMemberProps = {
  partyTypeGroup: PartyType
  primaryPartyPath: PathSegments | null
  formerPrimaryPartyPath: PathSegments | null
  setPrimaryPartyPath: (path: PathSegments) => void
  parentIsPrimary?: boolean
  subPrimaryId?: string
};

export const PartyAuthorityInput = ({
  contractMode = false,
  removable = true,
  hideDelete = false,
  primaryId,
  myIndex,
  typeOptions = vendorTypeOptions,
  partyLabel = 'Vendor',
  outerPartyLabel,
  primaryIdAbsPath = 'primaryVendor',
  hideOnTitleField = false,
  enableSameAsSaleAddr = false,
  enableSameAsPrimaryPurchaser = false,
  autoFocus,
  collectionRemovedList,
  onCollectionRestoreItem,
  onCollectionRemoveItemHereForChild,
  hideRootPrimaryContactCheckbox,
  purchaserPortalRegistration,
  otherContact,
  hidePrimaryContactCheckbox,
  gnaf,
  setAutoComplete,
  disabledPhone,
  disabledEmail,
  thisLevel,
  onInsert,
  executorMember,
  saleProperties,
  ...restProps
}: EditorListChildProps & {
  hideOnTitleField: boolean,
  partyLabel: string,
  outerPartyLabel?: string,
  typeOptions: {name: string, label: string, groupheading?: boolean, groupentry?: boolean}[]
  enableSameAsSaleAddr?: boolean;
  enableSameAsPrimaryPurchaser?: boolean;
  contractMode?: boolean;
  hideRootPrimaryContactCheckbox?: boolean;
  purchaserPortalRegistration?: boolean;
  otherContact?: boolean;
  hidePrimaryContactCheckbox?: boolean;
  gnaf?: { id: string }
  setAutoComplete?: boolean,
  disabledEmail?: boolean,
  disabledPhone?: boolean,
  executorMember?: ExecutorMemberProps,
  saleProperties?: SaleAddress[]
}): JSX.Element => {

  const { value: party, fullPath, handleRemove } = useTransactionField<VendorParty|VendorPartyJoint|PurchaserParty|undefined>(restProps);
  const { handleUpdate: handleUpdateAddrSameAsSale } = useTransactionField<VendorParty|VendorPartyJoint|PurchaserParty|undefined>({ parentPath: fullPath, myPath: 'addrSameAsSale' });
  const fullPathSegs = normalisePathToStrArray(fullPath);
  const directParentPath = generateParentPath(fullPathSegs);
  const { value: parentPartiesList } = useLightweightTransaction<AuthorityParty[]>({ myPath: normalisePathToStr(directParentPath) });
  const { value: topPrimaryId } = useLightweightTransaction<string|undefined>({ myPath: primaryIdAbsPath });
  const { rootBinder } = useYdocBinder({ path: '', noDebounce: true });
  const handleSetPrimaryPartyPath = executorMember ? executorMember.setPrimaryPartyPath : (path: PathSegments) => {
    if (!rootBinder?.update) {console.error('No primary contact update available'); return;}
    setAuthorityPartyPrimaryContact(rootBinder.update, {
      newPrimaryPath: path,
      relativeBase: directParentPath,
      topLevelUniqueRef: primaryIdAbsPath
    });
  };

  const primaryPartyRelPath = !executorMember ? primaryContactRelativePath(parentPartiesList??[], primaryId) ?? null : null;
  const primaryPartyPath = executorMember ? executorMember.primaryPartyPath : primaryPartyRelPath && mergePaths(directParentPath, primaryPartyRelPath);

  if (!outerPartyLabel) outerPartyLabel = partyLabel;

  const { value: saleAddrs } = useLightweightTransaction<SaleAddress[]|undefined>({ myPath: 'saleAddrs' });
  const jointParent = jointTypes.includes(party?.partyType);

  // We do want the parent meta for this one, so even bindToMetaKey would be inappropriate, as that
  // would get the sublineage meta key
  const { value: previouslySignedParties } = useLightweightTransaction<string[]|undefined>(
    { ydocForceKey: PropertyRootKey.Meta, myPath: 'previouslySignedParties' }
  );
  const hookGnaf = usePropertyGnafLocation();
  const gnafId = gnaf?.id ?? hookGnaf;

  const editLocked = (previouslySignedParties??[]).includes(party?.id);
  const [unlockEdit, setUnlockEdit] = useState(false);
  const [showUnlock, setShowUnlock] = useState(false);

  const { readOnly: subcontactReadOnly } = useTransactionField<number|undefined>({ myPath: 'primarySubcontact', parentPath: fullPath });
  const { handleUpdate: updatePrimaryNamedExecutor } = useTransactionField<string|undefined>({ myPath: 'primaryNamedExecutor', parentPath: fullPath });
  const { handleUpdate: updatePrimarySubId } = useTransactionField<string|undefined>({ myPath: 'primarySubcontactId', parentPath: fullPath });

  const containerRef = useRef(null);
  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  const jp = party as VendorPartyJoint;
  useEffect(()=>{
    if (!(Array.isArray(jp?.namedExecutors) && jp.namedExecutors.length > 0)) return;
    const alreadySet = !!jp.namedExecutors.find(e=>e.id === jp.primaryNamedExecutor);
    if (alreadySet) return;
    updatePrimaryNamedExecutor(jp.namedExecutors[0].id, true);
  }, [Array.isArray(jp?.namedExecutors) && jp.namedExecutors.length]);

  useEffect(()=>{
    if (!(Array.isArray(party?.legalRepresentatives) && party.legalRepresentatives.length > 0)) return;
    const alreadySet = !!party.legalRepresentatives.find(e=>e.id === party.primarySubcontactId);
    if (alreadySet) return;
    updatePrimarySubId(party.legalRepresentatives[0].id, true);
  }, [Array.isArray(party?.legalRepresentatives) && party.legalRepresentatives.length]);

  const { useAllowed: saleAddrAllowed } = contactAddressDerivation('', true, saleAddrs||[]);
  useEffect(() => {
    if (!saleAddrAllowed && party && 'addrSameAsSale' in party && party.addrSameAsSale) {
      handleUpdateAddrSameAsSale(false, true);
    }
  }, [saleAddrAllowed, saleAddrs?.length]);

  let primaryContactComponent;
  const isLeafPrimary = primaryPartyPath && fullPath === normalisePathToStr(primaryPartyPath);

  const { partyType, authority } = (party || {}) as {partyType?: PartyType | string, authority?: SigningAuthorityType | string};
  const configuration = jointParent ? jointPartyConfig[partyType] : (partyType && authority && partyFieldConfig[partyType]?.[authority]);

  const {
    legalNameWizard,
    legalName,
    trustOrAppointedName,
    person1Name,
    person2Name,
    abnTitle,
    email1,
    phone1,
    email2,
    phone2,
    streetAddr,
    appointedAlwaysApplicable,
    addressLinePos, positionContact1, positionContact2
  } = configuration || {};

  const dualContact = person1Name && person2Name;
  const multiRepresentative = multiRepAuthority.includes(authority);
  const shouldShowPrimaryAtThisLevel = !jointParent && !dualContact && !multiRepresentative;
  const isBehalf = !executorMember && (!!party?.inTrust || !!appointedAlwaysApplicable || !!(jointParent && party.partyType !== PartyType.MortgageeJoint ));

  const { variationsMode, snapshotHistory } = useContext(LineageContext);
  const st = snapshotHistory?.signingTimelines;
  const st2 = st?.[st?.length-1];
  const latestSignedId = st2?.[st2?.length-1]?.id;
  const snapshotData = latestSignedId && snapshotHistory?.data[snapshotHistory.instanceList.find(i=>i.id === latestSignedId)?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id];

  // We don't need to check whether it was an executor member before, as this check is a data source concern related to current data
  const formerPartyMembers = snapshotData && (getValueByPath(directParentPath, snapshotData, true)??[]);
  const formerPrimaryPartyRelPath = formerPartyMembers && !executorMember ? primaryContactRelativePath(formerPartyMembers, getValueByPath(primaryIdAbsPath, snapshotData)) ?? null : null;
  const formerPrimaryPartyPath = executorMember ? executorMember.formerPrimaryPartyPath : formerPrimaryPartyRelPath && mergePaths(directParentPath, formerPrimaryPartyRelPath);

  const [prevPrimaryPath, currPrimaryPath] = [normalisePathToStr(formerPrimaryPartyPath), normalisePathToStr(primaryPartyPath)];
  const isVaried = variationsMode && prevPrimaryPath !== currPrimaryPath;
  let legacyIsPrimaryContact = false;

  if (!executorMember && !jointParent) {
    legacyIsPrimaryContact = party?.id === topPrimaryId;
  } if (executorMember?.parentIsPrimary && executorMember.subPrimaryId === party.id) {
    legacyIsPrimaryContact = true;
  }

  if (shouldShowPrimaryAtThisLevel && !hideRootPrimaryContactCheckbox) {
    const variationIsThisPath = prevPrimaryPath === fullPath || currPrimaryPath === fullPath;
    primaryContactComponent = <TextClickCheck
      label='Primary contact'
      type='checkbox'
      markVaried={isVaried && variationIsThisPath}
      checked={isLeafPrimary}
      onSelected={()=>{
        handleSetPrimaryPartyPath(fullPathSegs);
      }}

    />;
  }

  const dualContactBinder = (index = 0) => {
    const thisContactIsPrimary = !!primaryPartyPath
    && primaryPartyPath[primaryPartyPath?.length-2] === 'primarySubcontact'
    && primaryPartyPath[primaryPartyPath?.length-3] === `[${party?.id}]`
    && primaryPartyPath[primaryPartyPath?.length-1] === `[${index}]`;
    const thisContactWasPrimary = !!formerPrimaryPartyPath
    && formerPrimaryPartyPath[formerPrimaryPartyPath?.length-2] === 'primarySubcontact'
    && formerPrimaryPartyPath[formerPrimaryPartyPath?.length-3] === `[${party?.id}]`
    && formerPrimaryPartyPath[formerPrimaryPartyPath?.length-1] === `[${index}]`;
    return <TextClickCheck
      disabled={subcontactReadOnly}
      label='Primary contact'
      type='checkbox'
      checked={thisContactIsPrimary}
      markVaried={isVaried && (thisContactIsPrimary || thisContactWasPrimary)}
      onSelected={()=>{
        handleSetPrimaryPartyPath(mergePaths(fullPathSegs, ['primarySubcontact', `[${index}]`]));
      }}
    />;
  };

  // We are not creating a JSX element because we don't want it to adjust focus based on
  // its own internal state.
  const renderTextInput = (fieldKey: keyof PartyType, label: string, extraProps?: Partial<WrFieldControlProps>) =>
    <WrField.Control {...extraProps} parentPath={fullPath} myPath={fieldKey} name={fieldKey} label={label.replace('{{partyLabel}}', partyLabel)} />;

  const { updateDraft } = useYdocBinder<VendorParty|PurchaserParty>({ path: fullPath });
  const  { options, isSearching, hasIntegration, search, optionRenderer } = useIntegrationContacts(saleProperties??saleAddrs??[], partyLabel);

  const vendorEntity = hasIntegration
    ? <WrField.AsyncAutoComplete
      name='fullLegalName'
      myPath='fullLegalName'
      label={legalNameWizard || legalName || '!Full Legal Name'}
      parentPath={fullPath}
      menuClassName={'PartySearchMenu'}
      debounceMs={0}
      minLength={options?.length ? 0 : 1}
      useCache={false}
      options={options}
      isLoading={isSearching}
      onSearch={search}
      onInputChange={val => {
        if (!val) search('');
      }}
      optionRender={optionRenderer}
      onSuggestSelect={(sel) => {
        const newParty = sel?.[0] as IContactSearchSuggestion;
        updateDraft?.(draft=> {
          draft.integrationId = newParty.id;
          draft.integrationName = newParty.integrationName;
          draft.fullLegalName = `${newParty.firstName} ${newParty.lastName}`;
          draft.email1 = newParty.email;
          draft.phone1 = newParty.mobile;
          draft.addressSingleLine = [newParty.address.address?.trim(), [newParty.address.suburb?.trim(), newParty.address.state?.trim(), newParty.address.postcode?.trim()].filter(Boolean).join(' ')].filter(Boolean).join(', ');
        });
      }}
      filterBy={() => true}
    />
    : <WrField.AutoComplete
      name='fullLegalName'
      myPath='fullLegalName'
      parentPath={fullPath}
      autocompleteLabelKey='fullLegalName'
      autoComplete={setAutoComplete
        ? partyType === PartyType.Corporation
          ? 'organization'
          : 'name'
        : undefined}
      options={collectionRemovedList??[]}
      canClear={false}
      filterBy={(option, props) => {
        const searchTerm = props?.instanceRef?.current?.inputNode?.value;
        const maybeFoundItem = new Fuse([option], fuzzySearchConfig).search(searchTerm)[0];
        return !!maybeFoundItem;
      }}
      onSuggestSelect={(selections: Option[], alteredFromPath: PathType)=>{
        onCollectionRestoreItem && selections.forEach(selection=>onCollectionRestoreItem(selection));
        onCollectionRemoveItemHereForChild?.(alteredFromPath);
      }}
      onlySelectCallback={true}
      label={legalNameWizard || legalName || '!Full Legal Name'}
    />;
  const trustAppointEntity = renderTextInput('onBehalfOf',trustOrAppointedName || '!for and on behalf of');
  const nameField1 = positionContact1 && renderTextInput('personName1', person1Name || '!Contact 1', setAutoComplete ? { autoComplete: 'name' } : undefined);
  const nameField2 = positionContact2 && renderTextInput('personName2', person2Name || '!Contact 2');
  const emailField1 = renderTextInput('email1', email1 || '!Contact 1\'s email', { autoComplete: setAutoComplete ? 'email' : undefined, disabled: disabledEmail ? true : undefined });
  const emailField2 = email2 && renderTextInput('email2', email2, { disabled: disabledEmail ? true : undefined });

  const phoneFieldExtraConfig = {
    inputMode: 'tel',
    autoComplete: setAutoComplete ? 'tel' : undefined,
    disabled: disabledPhone ? true : undefined,
    invalidCharacterRegex: /[^\d+() ]/
  };
  const phoneField1 = renderTextInput(
    'phone1',
    phone1 || '!Contact 1\'s phone',
    phoneFieldExtraConfig
  );
  const phoneField2 = phone2 && renderTextInput(
    'phone2',
    phone2,
    phoneFieldExtraConfig
  );
  const abnField = renderTextInput('abn', abnTitle || `!ABN of ${partyLabel}`);
  const streetAddrField = <AddressSelector
    label={streetAddr?.replace('{{partyLabel}}', partyLabel) || `!${partyLabel}'s postal address`}
    myPath='addressSingleLine'
    parentPath={fullPath}
    international={true}
    gnafCentre={gnafId}
    setAutoComplete={setAutoComplete}
  />;

  const abnPos: 'withAddress' | 'underVendor' | 'withContact' = addressLinePos === 'underName'
    ? 'withAddress'
    : (nameField1
      ? 'underVendor'
      : 'withContact'
    );

  const streetAndABNRender = <div className="d-flex flex-wrap w-100">
    {!executorMember && !(enableSameAsSaleAddr && party?.addrSameAsSale) && !(enableSameAsPrimaryPurchaser && party?.addrSameAsPrimary && !legacyIsPrimaryContact) && <div className='flex-grow-1 mt-2' style={{ width: '300px' }}>
      {streetAddrField}
    </div>}
    {abnPos === 'withAddress' && <div className='flex-grow-1 mt-2' style={{ width: '300px' }}>
      {abnField}
    </div>}
  </div>;

  const jointPartyChildProps = useMemo(()=>{
    if (!party) {
      return;
    }

    const jParty = party as VendorPartyJoint;
    const permittedJointOptions = jointGroupingPermitted[party?.partyType] ?? [];
    const typeOptionsChild = typeOptions.filter((opt) => permittedJointOptions.includes(opt.name));
    const executorMember: ExecutorMemberProps = {
      partyTypeGroup: party?.partyType || PartyType.Individual,
      primaryPartyPath,
      formerPrimaryPartyPath,
      setPrimaryPartyPath: handleSetPrimaryPartyPath,
      parentIsPrimary: topPrimaryId === party?.id,
      subPrimaryId: jParty.primaryNamedExecutor
    };
    return {
      executorMember,
      partyLabel: jointPartyNouns[party?.partyType],
      typeOptions: typeOptionsChild,
      outerPartyLabel
    };
  }, [party?.partyType, primaryPartyPath]);

  const multiRepChildProps: PartyMultipleExcludingAutoChildProps = {
    titlePrefixIdentifier: executorMember && `${partyLabel} ${myIndex+1}`,
    primaryPartyPath,
    formerPrimaryPartyPath,
    setPrimaryPartyPath: handleSetPrimaryPartyPath,
    baseLabels: {
      name: person1Name,
      email: email1,
      phone: phone1
    },
    outerPartyLabel,
    partyLabel,
    representativeNoun: [SigningAuthorityType.attorney, SigningAuthorityType.attorneyJoint].includes(authority)
      ? 'Attorney'
      : [SigningAuthorityType.guardian, SigningAuthorityType.guardianJoint].includes(authority)
        ? 'Guardian'
        : 'Representative'
  };
  const mortgageNumberElementControl = [PartyType.MortgageeCompany, PartyType.MortgageeNatural, PartyType.MortgageeJoint].includes(party?.partyType) && !executorMember
  && <div className='flex-grow-1 mt-2'>
    <WrField.Control
      label={'Mortgage Number'}
      parentPath={fullPath}
      name="mortgageNumber"
      myPath="mortgageNumber"
    />
  </div>;

  const individualAuthority = <>
    <div className="d-flex w-100 flex-wrap">
      <div className="flex-grow-1 mt-2" style={{ width: '300px' }}>
        {vendorEntity}
      </div>
      {isBehalf && <div className="flex-grow-1 mt-2" style={{ width: '300px' }}>
        {trustAppointEntity}
      </div>}
    </div>
    {mortgageNumberElementControl &&  <div className="d-flex w-100 flex-wrap">
      {mortgageNumberElementControl}
    </div>}
    {addressLinePos === 'underName' && streetAndABNRender}
    {abnPos === 'underVendor' && <div className='mt-2 w-100'>{abnField}</div>}

    {multiRepresentative
      ? <div className='mt-4 w-100'><CollectionEditor
        childItemRenderer={PartyAuthorityMultipleRepresentative}
        childProps={multiRepChildProps}
        autoAddFirst={true}
        parentPath={fullPath}
        myPath='legalRepresentatives'
        itemNoun={multiRepChildProps.representativeNoun}
        addButtonAboveList={true}
      />
      </div>
      : <>{nameField1 && <div className="d-flex w-100 flex-wrap">
        <div className='flex-grow-1 mt-2' style={{ width: '300px' }}>
          {nameField1}
        </div>
        {dualContact && !hidePrimaryContactCheckbox && <div className='flex-grow-0 mt-2 p-1 pe-4 ms-2 d-flex align-items-center'>
          {dualContactBinder(0)}
        </div>}
      </div>}
      <div className="d-flex w-100 flex-wrap">
        <div className='flex-grow-1 mt-2' style={{ width: '300px' }}>
          {emailField1}
        </div>
        <div className='d-flex flex-grow-1'>
          <div className='flex-grow-1 mt-2' style={{ width: '130px' }}>
            {phoneField1}
          </div>
          {abnPos === 'withContact' && <div className='flex-grow-1 mt-2' style={{ width: '130px' }}>
            {abnField}
          </div>}
        </div>

      </div>
      </>
    }
    {nameField2 && <>
      <div className="d-flex w-100 flex-wrap">
        <div className='flex-grow-1 mt-2' style={{ width: '300px' }}>
          {nameField2}
        </div>
        {dualContact && !hidePrimaryContactCheckbox && <div className='flex-grow-0 mt-2 p-1 pe-4 ms-2 d-flex align-items-center'>
          {dualContactBinder(1)}
        </div>}
      </div>
      <div className="d-flex w-100 flex-wrap">
        <div className='flex-grow-1 mt-2' style={{ width: '200px' }}>
          {emailField2}
        </div>
        <div className='flex-grow-1 mt-2' style={{ width: '200px' }}>
          {phoneField2}
        </div>
      </div>
    </>}
    {(!addressLinePos || addressLinePos === 'end') && streetAndABNRender}

  </>;

  const authorityOpts = useMemo(()=>{
    const extractOpts = party?.partyType ? signingAuthorityOptions[party.partyType] ?? {} : {};
    return (purchaserPortalRegistration || otherContact) ? Object.assign({}, ...Object.entries(extractOpts)
      .filter(([k,v]) => {
        if (multiRepAuthority.includes(k)) return false;
        return true;
      })
      .map(([k,v])=>({ [k]: v }))
    ) :extractOpts;
  }, [party?.partyType, purchaserPortalRegistration]);

  const partyBody = <>

    <div className="d-flex w-100 flex-wrap">
      <div className={clsJn('flex-grow-1 mt-2', party?.partyType && notesTable[party.partyType] && 'me-5' )} ref={containerRef}>
        <WrField.Select
          label={`${partyLabel} type`}
          name="partyType"
          parentPath={fullPath}
          autoFocus={autoFocus}
          myPath="partyType"
          options={typeOptions}
          guidanceNoteId={party?.partyType && notesTable[party.partyType] ? party.partyType : undefined}
        />
      </div>
      {party?.partyType && !jointParent && <div className={clsJn('flex-grow-1 mt-2', party?.authority && notesTable[party.authority] && 'me-4' )}>
        <WrField.Select
          label="Signing authority"
          name="authority"
          parentPath={fullPath}
          myPath="authority"
          options={authorityOpts}
          guidanceNoteId={
            party?.authority && notesTable[party.authority]
              ? party.authority
              : undefined
          }
        />
      </div>}
      {jointParent && isBehalf && <div className={clsJn('flex-grow-1 mt-2' )}>
        {trustAppointEntity}
      </div>}
    </div>
    {partyType && (authority || jointParent) && <div className='d-flex'>
      <div className="d-flex w-100 flex-wrap flex-grow-1">
        {primaryContactComponent && <div className='flex-grow-0 mt-2 p-1 pe-4'>
          {primaryContactComponent}
        </div>}
        {!hideOnTitleField && !executorMember && <div className='flex-grow-0 mt-2 p-1 pe-4'>
          <WrField.BoolCheck
            label='Registered on title'
            parentPath={fullPath}
            name="registeredOnTitle"
            myPath="registeredOnTitle"
          />
        </div>}
        {!appointedAlwaysApplicable && !executorMember && <div className='flex-grow-0 mt-2 p-1 pe-4'>
          <WrField.BoolCheck
            label='On behalf of trust'
            parentPath={fullPath}
            name="inTrust"
            myPath="inTrust"
          />
        </div>}
        {enableSameAsSaleAddr && saleAddrAllowed && <div className='flex-grow-0 mt-2 p-1 pe-4'>
          <WrField.BoolCheck
            label='Postal address same as Property'
            parentPath={fullPath}
            name="addrSameAsSale"
            myPath="addrSameAsSale"
          />
        </div>}
        {enableSameAsPrimaryPurchaser && !legacyIsPrimaryContact && !executorMember && <div className='flex-grow-0 mt-2 p-1 pe-4'>
          <WrField.BoolCheck
            label='Postal address same as primary Purchaser'
            parentPath={fullPath}
            name="addrSameAsPrimary"
            myPath="addrSameAsPrimary"
          />
        </div>}
        {jointParent &&  <div className="d-flex w-100 flex-wrap">
          {mortgageNumberElementControl}
          <div className='flex-grow-1 mt-2'>{streetAddrField}</div>
        </div>}
        {purchaserPortalRegistration && <>
          <p className='mt-2'>You will be the primary contact on any offers that you make. If you want to make an offer together with one or more other parties, you will be able to add the other parties once you have completed registration.</p>
          <p>Please provide details below as completely as possible. These details will appear in any agreements entered into.</p>
        </>}
      </div>
    </div>}
    { partyType && (authority || jointParent) && contractMode
      && (
        party?.partyType === PartyType.AdministratorNatural
        || party?.partyType === PartyType.AdministratorCompany
        || party?.partyType === PartyType.ExecutorNatural
        || party?.partyType === PartyType.ExecutorCompany
      )
      && <div className='w-100 d-flex flex-wrap'>
        <div className='me-3 mt-2'>
          <WrField.CheckRadio
            label={party?.partyType === PartyType.ExecutorNatural || party?.partyType === PartyType.ExecutorCompany ? 'Probate has been granted?' : 'Letters of Administration have been granted?'}
            radioType='checkbox'
            inline={true}
            options={boolYesNoOpts}
            valueType='boolean'
            parentPath={fullPath}
            name="probateGranted"
            myPath="probateGranted"
          />
        </div>
        {Predicate.boolFalse(party?.probateGranted) && <div className='flex-grow-1 mt-2'>
          <WrField.Control
            label={party?.partyType === PartyType.ExecutorNatural || party?.partyType === PartyType.ExecutorCompany ? 'Probate to be granted by' : 'Letters of Administration to be granted by'}
            type='date'
            useCanonical={true}
            parentPath={fullPath}
            name="probateByDate"
            myPath="probateByDate"
          />
        </div>}
      </div>
    }
    {jointParent
      ? <div className='mt-4 w-100'><CollectionEditor
        childItemRenderer={PartyAuthorityInput}
        myPath={fullPath+'.namedExecutors'}
        childProps={jointPartyChildProps}
        autoAddFirst={true}
        level={2}
        addButtonAboveList={true}
        itemNoun={jointPartyNouns[party?.partyType]}
      />
      </div>
      : partyType && authority && individualAuthority}
  </>;

  const editable = <div className={clsJn('w-100', (myIndex ??0) > 0 && 'mt-3')}>
    <div className={'d-flex flex-row'}>
      {(typeof myIndex === 'number' || myIndex) && <div className={`fs-${thisLevel+3}`}>{partyLabel} {(typeof myIndex === 'string' ? parseInt(myIndex) : myIndex)+1}</div>}
      <div className='d-flex flex-wrap justify-content-center'>
        {party?.fromLssa && <CollectionValidCheck />}
      </div>

      {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-auto'>
        {!hideDelete && closeButton}
      </div>}
    </div>

    {purchaserPortalRegistration && <p>In order for you to make an offer on this property, we need to record the legal capacity in which you will be operating:</p>}

    {executorMember
      ? <div className='d-flex w-100'>
        <div className='flex-grow-1'>{partyBody}</div>

      </div>
      : partyBody
    }

  </div>;

  const editMaskerAddressStatement = enableSameAsSaleAddr && saleAddrAllowed && party?.addrSameAsSale ? 'Postal address same as Property' : party?.addressSingleLine;
  const abnCanon = canonicalisers.abnacn(party?.abn);
  const scopedContacts = getContactsFromAuthorityParty(party, party?.id, { setPrimaryToFirst: topPrimaryId === party?.id ? false : true });
  const scopedPrimary = scopedContacts.filter(p=>p.isPrimary)[0];

  const emEmail = scopedPrimary?.email ?? party?.email1;
  const emPhone = scopedPrimary?.phone ?? party?.phone1;
  const editMasker = (editLocked && !unlockEdit) && <div className={clsJn('w-100', (myIndex ??0) > 0 && 'mt-3')}>
    <div className={'d-flex flex-row mb-3'}>
      {(typeof myIndex === 'number' || myIndex) && <div className='fs-4'>{partyLabel} {(typeof myIndex === 'string' ? parseInt(myIndex) : myIndex)+1}</div>}
      <div className='d-flex align-items-center delete-div ms-auto'>

      </div>
    </div>
    <div className='w-100'>
      <div className='fs-5 d-flex justify-content-between w-100'>
        <div>{jointParent ? party?.namedExecutors.map(e=>e.fullLegalName).filter(Predicate.isTruthy).reduce(commaAndReducer) : party?.fullLegalName}{isBehalf && ` ${trustOrAppointedName} ${party?.onBehalfOf??''}`}{party?.abn && ` ${(abnCanon.components?.type??'abn').toUpperCase()} ${abnCanon.display}`}</div>
        <Button
          variant="outline-secondary"
          title='Unlock'
          onClick={()=>setShowUnlock(true)}
        >
          Unlock
        </Button>
      </div>

      {addressLinePos==='underName' && editMaskerAddressStatement && <div className='party-locked-attr'>{editMaskerAddressStatement}</div>}
      {party?.registeredOnTitle && <div className='party-locked-attr'>Registered on title</div>}

      <div className='party-locked-attr'>{typeOptions.find(({ name: key })=>key===party?.partyType)?.label}</div>

      {positionContact1 && <div className='mt-2 party-locked-attr'>{positionContact1}: {scopedPrimary?.name??'Name not Set!'}</div>}
      {addressLinePos!=='underName' && editMaskerAddressStatement && <div className='party-locked-attr'>{editMaskerAddressStatement}</div>}
      {emEmail && <div className='party-locked-attr'>{emEmail}</div>}
      {emPhone && <div className='party-locked-attr'>{canonicalisers.phone(emPhone).display}</div>}
      {positionContact2 && <div className='mt-2 party-locked-attr'>{positionContact2}: {party?.personName2??'Name not Set!'}</div>}
      {positionContact2 && party?.email2&& <div className='party-locked-attr'>{party?.email2}</div>}
      {positionContact2 && party?.phone2&& <div className='party-locked-attr'>{canonicalisers.phone(party?.phone2).display}</div>}

    </div>
  </div>;

  return (
    <div className="d-flex w-100 scrollspy-target" data-focus-path={`subsection-${restProps.parentPath}.[${party?.id}]`}>
      {editMasker || editable}
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!showUnlock} onClose={()=>setShowUnlock(false)} />}>
        <PartyEditAfterSigningModal
          show={!!showUnlock}
          removable={!!removable}
          onHide={()=>setShowUnlock(false)}
          onEdit={()=>{setUnlockEdit(true); setShowUnlock(false);}}
          onRemove={()=>{handleRemove(); setShowUnlock(false);}}
        />
      </ErrorBoundary>
    </div>
  );
};

function PartyEditAfterSigningModal({ show, onEdit, onHide, onRemove, removable }: {onEdit: ()=>void, onRemove: ()=>void, onHide: ()=>void, show: boolean, removable: boolean}) {
  const { modalClassName, backdropClassName } = useContext(ModalDepthContext);

  return <Modal show={show} onHide={onHide} className={modalClassName} backdropClassName={backdropClassName}>
    <Modal.Header closeButton><Modal.Title>Party locked</Modal.Title></Modal.Header>
    <Modal.Body>
    This party has signed, or is signing, documents for this transaction. Changing the party's details must not alter who the party is, or the capacity in which they have signed.
    </Modal.Body>
    <Modal.Footer className='d-flex'>
      <Button
        className='flex-fill'
        onClick={onEdit}
      >
      Make a correction to this party
      </Button>
      <Button
        className='flex-fill'
        variant='outline-secondary'
        disabled={!removable}
        onClick={onRemove}
      >
      Remove this party
      </Button>
      <Button
        className='flex-fill'
        variant='outline-secondary'
        onClick={onHide}
      >Cancel</Button>
    </Modal.Footer>
  </Modal>;
}
