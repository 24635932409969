import { PendingUpdates } from './pendingUpdates';
import { StorageDexie } from './db';
import { getKeyFromPropertyId } from '../yjs-schema/property-stats';

export class PropertyDoc {
  static async deleteForce(id: string) {
    const statsKey = getKeyFromPropertyId(id);
    await StorageDexie.delete(id);
    await StorageDexie.delete(statsKey);

    await PendingUpdates.unsetForId(id);
    await PendingUpdates.unsetForId(statsKey);
  }
}
