import * as Y from 'yjs';
import { bind } from 'immer-yjs';
import {
  PurchaserDocumentStatusDetail,
  PurchaserEditableDocumentStatus,
  PurchaserPortalData,
  PurchaserPortalRootKey,
  PurchaserSubmittedDocument, PurchaserSubmittedDocumentLifecycleFileCopy,
  PurchaserSubmittedDocumentStatus
} from '@property-folders/contract/yjs-schema/purchaser-portal';
import { applyMigrationsV2 } from '../index';

export class PurchaserPortalYjsDal {
  constructor(
    private doc: Y.Doc
  ) {
  }

  public get binder() {
    return bind<PurchaserPortalData>(this.doc.getMap(PurchaserPortalRootKey.Main));
  }

  public transitionDocumentFromEditableToSubmitted(documentId: string, snapshot: PurchaserSubmittedDocument['snapshot'], submittedToPropertyId: string) {
    return applyMigrationsV2<PurchaserPortalData>({
      doc: this.doc,
      docKey: PurchaserPortalRootKey.Main,
      typeName: 'PurchaserPortal',
      migrations: [{
        name: 'Transition document from editable to submitted',
        fn: PurchaserPortalYjsDal.transitionDocumentFromEditableToSubmittedFunc(documentId, snapshot, submittedToPropertyId)
      }]
    });
  }

  public static transitionDocumentFromEditableToSubmittedFunc(documentId: string, snapshot: PurchaserSubmittedDocument['snapshot'], submittedToPropertyId: string) {
    return (state: PurchaserPortalData) => {
      if (!state.editableDocuments) return false;

      const editableDocumentIndex = state.editableDocuments.findIndex(d => d.documentId === documentId);
      if (editableDocumentIndex < 0) return false;

      const doc = state.editableDocuments[editableDocumentIndex];
      if (!doc) return false;
      if (doc.status !== PurchaserEditableDocumentStatus.Signing) return false;

      if (!state.submittedDocuments) {
        state.submittedDocuments = [];
      }

      if (!state.submittedDocuments.find(d => d.documentId === documentId)) {
        state.submittedDocuments.push({
          id: doc.id,
          documentId: doc.documentId,
          documentType: doc.documentType,
          name: doc.name,
          status: PurchaserSubmittedDocumentStatus.Submitted,
          statusAtMs: Date.now(),
          snapshot,
          submittedToPropertyId
        });
      }

      state.editableDocuments.splice(editableDocumentIndex, 1);
    };
  }

  public transitionEditableDocumentState(documentId: string, to: PurchaserEditableDocumentStatus) {
    return applyMigrationsV2<PurchaserPortalData>({
      doc: this.doc,
      docKey: PurchaserPortalRootKey.Main,
      typeName: 'PurchaserPortal',
      migrations: [{
        name: 'Transition editable document state',
        fn: state => {
          const doc = (state.editableDocuments || []).find(d => d.documentId === documentId);
          if (!doc) return false;
          if (doc.status === to) return false;
          if (!validEditableStateTransitions[doc.status].includes(to)) return false;

          doc.status = to;
          doc.statusAtMs = Date.now();
        }
      }]
    });
  }

  public transitionSubmittedDocumentState(
    documentId: string,
    to: PurchaserSubmittedDocumentStatus,
    lifecycleCopies?: Pick<PurchaserSubmittedDocumentLifecycleFileCopy, 'id' | 'contentType' | 'status'>[],
    statusDetail?: PurchaserDocumentStatusDetail
  ) {
    const now = Date.now();
    return applyMigrationsV2<PurchaserPortalData>({
      doc: this.doc,
      docKey: PurchaserPortalRootKey.Main,
      typeName: 'PurchaserPortal',
      migrations: [{
        name: 'Transition submitted document state',
        fn: state => {
          const doc = (state.submittedDocuments || []).find(d => d.documentId === documentId);
          if (!doc) return false;
          if (doc.status === to) return false;
          if (!validSubmittedStateTransitions[doc.status].includes(to)) return false;

          doc.status = to;
          doc.statusAtMs = now;
          if (statusDetail) {
            doc.statusDetail = statusDetail;
          } else {
            delete doc.statusDetail;
          }

          if (lifecycleCopies?.length) {
            if (!doc.lifecycleCopies) {
              doc.lifecycleCopies = [];
            }

            doc.lifecycleCopies.push(...lifecycleCopies.map(lc => ({
              ...lc,
              timestamp: now
            })));
          }
        }
      }]
    });
  }
}

const validEditableStateTransitions: Record<PurchaserEditableDocumentStatus, PurchaserEditableDocumentStatus[]> = {
  [PurchaserEditableDocumentStatus.Draft]: [PurchaserEditableDocumentStatus.Signing, PurchaserEditableDocumentStatus.Cancelled],
  [PurchaserEditableDocumentStatus.Signing]: [PurchaserEditableDocumentStatus.Draft],
  [PurchaserEditableDocumentStatus.Cancelled]: []
};

// maps allowed transitions from status -> to status
const validSubmittedStateTransitions: Record<PurchaserSubmittedDocumentStatus, PurchaserSubmittedDocumentStatus[]> = {
  [PurchaserSubmittedDocumentStatus.Submitted]:
    [
      PurchaserSubmittedDocumentStatus.Withdrawn,
      PurchaserSubmittedDocumentStatus.Declined,
      PurchaserSubmittedDocumentStatus.Accepted
    ],
  [PurchaserSubmittedDocumentStatus.Withdrawn]:
    [
      PurchaserSubmittedDocumentStatus.Submitted
    ],
  [PurchaserSubmittedDocumentStatus.Declined]: [],
  [PurchaserSubmittedDocumentStatus.Accepted]: []
};

export function materialisePurchaserPortal(doc: Y.Doc): PurchaserPortalData | undefined {
  return doc.getMap(PurchaserPortalRootKey.Main)?.toJSON() as PurchaserPortalData | undefined;
}
