import clsJn from '@property-folders/common/util/classNameJoin';
import { IconProps, ImageIconProps } from '@property-folders/common/types/IconProps';

const defaultProps: IconProps = {
  pack: 'material-icons',
  name: ''
};

const defaultSymbolStyleSettings = {
  fontVariationSettings: '\'FILL\' 0,\'wght\' 500, \'GRAD\' 0, \'opsz\' 24'
};

export function Icon(iProps: IconProps & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'>) {
  const styles = iProps.pack === 'material-symbols' ? defaultSymbolStyleSettings : {};
  const props = { ...defaultProps , ...iProps };

  if (props.pack === 'img') {
    return <ImageIcon {...props} />;
  } else {
    const { icoClass, name, pack, variant, ...restProps } = props;
    switch (pack) {
      case 'material-icons': {
        const mPack = variant === 'outlined' ? 'material-icons-outlined' : 'material-icons';
        return <div className={clsJn('r-icon', mPack, icoClass)} {...restProps}>{name}</div>;
      }
      case 'material-symbols': {
        const mPack = 'material-symbols-outlined';
        return <div className={clsJn('r-icon', mPack, icoClass)} style={styles} {...restProps}>{name}</div>;
      }
      case 'la':
      case 'fa':
      case 'bi':
      default:
        return <div className={clsJn('r-icon', icoClass, pack, `${pack}-${name}`)} {...restProps}>{}</div>;
    }
  }
}

function ImageIcon(props: ImageIconProps & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'>) {
  const { src, icoClass } = props;
  return <img src={src} className={clsJn('img-icon svg-icon', icoClass)} alt='Icon'></img>;
}
