import { uniq } from 'lodash';

export const clsJn = (...params: (undefined|null|0|false|string|string[]|Record<string, boolean | undefined>)[]) => {
  let dupList: string[] = [];
  for (const cl of params) {
    if (Array.isArray(cl)) {
      dupList = dupList.concat(clsJn(...cl));
    } else if (typeof cl === 'object' && cl !== null) {
      for (const className of Object.keys(cl)) {
        if (cl[className]) {
          dupList = dupList.concat(className.split(' '));
        }
      }
    } else if (cl) {
      dupList = dupList.concat(cl.split(' '));
    } // and ignoring empty strings + undefined
  }
  return uniq(dupList).join(' ');
};

export default clsJn;
