import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { Predicate } from '@property-folders/common/predicate';
import { BelongingEntityMeta } from '@property-folders/common/redux-reducers/entityMeta';
import { DefinitionMode, IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { Maybe } from '@property-folders/common/types/Utility';
import { FormUtil, ITransitionSigningStateOpts } from '@property-folders/common/util/form';
import { DiffCollection } from '@property-folders/common/util/form/DiffCollection';
import { generateHeadlineFromMaterialisedData, getDocumentName } from '@property-folders/common/yjs-schema/property';
import {
  FormTypes,
  getFirstOrderedParty,
  mapSigningPartySourceTypeToCategory, PartyCategory,
  PropertyFormYjsDal
} from '@property-folders/common/yjs-schema/property/form';
import {
  Annexure,
  ContentType,
  CustomFieldType,
  FormCode,
  FormCodeUnion,
  FormInstance,
  FormInstanceSigning,
  FormSigningState,
  InstanceHistory,
  LandType,
  ManifestData,
  ManifestType,
  MaterialisedPropertyData,
  RemoteSigningTypes,
  SessionInfo,
  SigningInitiator,
  SigningMarketingData,
  SigningSessionFieldType,
  SigningSessionSubType,
  TransactionMetaData
} from '@property-folders/contract';
import { UserPreferencesMain } from '@property-folders/contract/yjs-schema/user-preferences';
import { BrandConfig } from '../../hooks/useEntity';
import { PdfWorker } from '../../hooks/usePdfWorker';
import { Binder } from 'immer-yjs';
import { AnyAction, Store } from 'redux';
import { v4 } from 'uuid';
import { applyPatches, enablePatches, Patch, produce } from 'immer';
import {
  applyPdfChanges,
  injectCustomFields,
  injectSigningLocations,
  makeUploadPdfChangeFns,
  PdfInformationExtractor
} from '@property-folders/common/util/pdf';
import { EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';
import { customFieldMetas } from '@property-folders/contract/property/meta';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { applyAnnexures } from '@property-folders/common/subscription-forms/applyAnnexures';
import { injectServeFields } from '@property-folders/common/subscription-forms/injectServeFields';
import { Doc } from 'yjs';
import { blobTob64 } from '@property-folders/common/util/dataExtract';

enablePatches();

function produceTransition(props: {
  transitionConfig: Parameters<typeof FormUtil.transitionSigningState>[0]
  transitionData: ITransitionSigningStateOpts
}) {
  const { transitionConfig, transitionData } = props;
  const initialMeta = transitionConfig.metaBinder?.get();
  const initialData = transitionConfig.dataBinder?.get();
  const combinedPatches: Patch[] = [];
  const { meta: producedMeta, data: producedData } = produce(
    { meta: initialMeta, data: initialData },
    ({ meta: workingMeta, data: workingData }) => {

      const fakeMetaBinder: Binder<TransactionMetaData> = {
        get: () => workingMeta,
        update: (fn => {
          if (!workingMeta) return;
          fn(workingMeta);
        }),
        subscribe: () => () => { },
        unbind: () => { }
      };
      const fakeDataBinder: Binder<MaterialisedPropertyData> = {
        get: () => workingData,
        update: (fn => {
          if (!workingData) return;
          fn(workingData);
        }),
        subscribe: () => () => { },
        unbind: () => { }
      };

      FormUtil.transitionSigningState({
        ...transitionConfig,
        metaBinder: fakeMetaBinder,
        dataBinder: fakeDataBinder
      }, transitionData);

    },
    (patches) => {
      combinedPatches.push(...patches);
    }
  );

  const metaPatches = combinedPatches.filter(patch => patch.path[0] === 'meta').map(patch => ({ ...patch, path: patch.path.slice(1) }));
  const dataPatches = combinedPatches.filter(patch => patch.path[0] === 'data').map(patch => ({ ...patch, path: patch.path.slice(1) }));
  return { producedMeta, producedData, metaPatches, dataPatches };
}

export async function prepareForSigningSmartForms(
  reqParams: {
    definitionProvider: IPdfDefinitionProvider;
    // todo: refactor, pass in signing state manager type, constructed with binders
    property: Maybe<MaterialisedPropertyData>;
    formId: string;
    formCode: string;
    initiator: {
      id: number;
      name: string;
      timeZone: string;
      email: string;
      entity: {
        id: number;
        uuid?: string;
        name: string;
      };
    };
    formConfigs: BrandConfig;
    agencyName: string;
    pdfWorker: PdfWorker;
    memberEntities: BelongingEntityMeta;
    transitionConfig: Parameters<typeof FormUtil.transitionSigningState>[0]; // We will be overriding values in this, and then extracting the meta binder and data binders and updating for real later
    getFormInstance: () => Maybe<FormInstance>;
    getUserPrefsData?: () => UserPreferencesMain | undefined;
    store: Store<unknown, AnyAction>,
    ydoc: Doc | undefined
  },
  opts?: {
    fileSync?: FileSync | undefined;
    annexures?: Maybe<Annexure[]>;
    logoLocalUri?: string;
    getChangeSet?: () => { changes: DiffCollection | null; original?: MaterialisedPropertyData; history?: InstanceHistory; };
  }
): Promise<void> {
  const { annexures, fileSync, getChangeSet, logoLocalUri } = opts ?? {};
  const { ydoc, getFormInstance, transitionConfig, getUserPrefsData, agencyName, definitionProvider, formCode, formConfigs, formId, initiator, memberEntities, pdfWorker, property, store } = reqParams;
  const propertyId = property?.id;
  if (!propertyId) {
    throw new Error('Property ID required to prepare for signing');
  }

  // store pdf with id
  const signingSessionId = v4();
  const pdfId = v4();
  const headline = generateHeadlineFromMaterialisedData(property);

  const accompanyingFileRefs = {
    propertyDataSnapshot: { id: v4(), contentType: ContentType.Json }
  };

  const prefs = getUserPrefsData?.();
  const hasRemoteSigning = getFormInstance()?.signing?.parties?.find(p => RemoteSigningTypes.includes(p.type));

  const transitionData = {
    to: FormSigningState.OutForSigningPendingUpload,
    outForSigningData: {
      sessionId: signingSessionId,
      baseFile: {
        id: pdfId,
        contentType: ContentType.Pdf
      },
      accompanyingFileIds: accompanyingFileRefs,
      initiator: { ...initiator, notifyOnSigning: hasRemoteSigning && prefs?.lastNotifyOnRemoteSign },
      userPrefs: prefs,
      hasRemoteSigning: !!hasRemoteSigning,
      memberEntities
    }
  };

  const { dataPatches, metaPatches, producedMeta } = produceTransition({ transitionConfig, transitionData });

  const accompanyingData = {
    propertyDataSnapshot: property
  };

  const entityId = initiator.entity.id;
  const formConfig = formConfigs.getFormConfig(entityId);
  // build pdf with placeholders
  const { changes, original, history } = getChangeSet?.() ?? {};

  const formFamily = FormTypes[formCode]?.formFamily;
  const extraImages: Record<string, string> = {};
  const marketingTemplate = property?.marketingTemplate;
  if (formFamily === FormCode.RSAA_SalesAgencyAgreement && marketingTemplate?.headerImage?.id) {
    const headerImageFile = await FileStorage.read(marketingTemplate.headerImage?.id);
    extraImages.marketingHeaderImage = await blobTob64(headerImageFile?.data);
  }

  const definition = await definitionProvider.getDefinitionForPdfWorker(
    DefinitionMode.Signing,
    formConfig,
    agencyName,
    {
      agencyLogoImage: logoLocalUri,
      ...extraImages
    },
    changes ?? undefined,
    original,
    history,
    true,
    memberEntities,
    {
      metaOverride: producedMeta
    }
  );

  const basePdf = await pdfWorker.generatePdf({
    ...definition,
    brand: formConfig,
    meta: {
      headline,
      agentName: initiator.name,
      documentLabel: FormTypes[formCode].label
    }
  }, 'signing');

  const decoratedAnnexures = annexures && annexures.length > 0
    ? await Promise.all(annexures.map(a => pdfWorker.generateAnnexure({
      annexure: a,
      brand: formConfig,
      coversheet: {
        headline,
        agentName: initiator.name,
        documentLabel: FormTypes[formCode].label
      }
    })))
    : [];

  const mergedPdf = decoratedAnnexures.length > 0
    ? await pdfWorker.stitchPdf({ pdfs: [basePdf, ...decoratedAnnexures] })
    : basePdf;

  if (!mergedPdf) {
    throw new Error('Could not generate signing pdf');
  }
  const formInstanceSnapshot = FormUtil.getFormState(formCode, formId, producedMeta);

  const manifestData: ManifestData = formInstanceSnapshot
    ? {
      manifestType: ManifestType.FormInstancePlusMarketingAndSnapshot,
      data: {
        formInstance: formInstanceSnapshot,
        marketingData: {
          landType: typeof property?.landType === 'number' ? property?.landType : LandType.Unknown,
          transactionType: property?.transactionType,
          documentTemplateName: FormTypes[formCode]?.label, // We probably don't need to pass this through here, but it'll do for now
          propertyAddress: property?.saleAddrs?.map(addr => addr?.streetAddr_parts).filter(Predicate.isNotNullish) || []
        },
        accompanying: Object.assign({}, ...Object.entries(accompanyingFileRefs).map(([accKey, { id: fileId }]) => ({ [accKey]: { fileId: fileId } })))
      }
    }
    : {
      manifestType: ManifestType.None
    };

  await FileStorage.write(
    pdfId,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([mergedPdf], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId,
        formId,
        formCode,
        signingSessionId
      }
    },
    { store, ydoc },
    manifestData,
    undefined,
    accompanyingData
  );
  // Wait for the local file to commit before transitioning state, lest other tabs then try to
  // download it before the local write is finished, and mess up pending upload
  metaPatches.length && transitionConfig.metaBinder?.update(draft => {
    applyPatches(draft, metaPatches);
  });
  dataPatches.length && transitionConfig.dataBinder?.update(draft => {
    applyPatches(draft, dataPatches);
  });

  FileSync.triggerSync(fileSync);
}

export class NoFieldsError extends Error {
  constructor() {
    super('At least one field is required');
  }
}
export class MissingPurchaserFieldsError extends Error {
  constructor() {
    super('Purchaser fields missing');
  }
}
export class NoSignatureFieldsError extends Error {
  constructor() {
    super('At least one signature or initials field is required for signing');
  }
}
export class PartyRequiredError extends Error {
  constructor(public partyCategory: PartyCategory) {
    super(`The ${partyCategory}'s signature is required.`);
  }
}
export class RemoteCompletionPartyMissingSignatureError extends Error {
  constructor() {
    super('A signature or initials field is required for the remote completion party.');
  }
}
export class PdfNotFoundError extends Error { }
export class NotAnUploadedDocumentError extends Error { }
export interface PrepareForSigningCustomPdfResponse {
  error?:
    | NoFieldsError
    | MissingPurchaserFieldsError
    | NoSignatureFieldsError
    | PartyRequiredError
    | RemoteCompletionPartyMissingSignatureError
    | PdfNotFoundError
    | NotAnUploadedDocumentError
}

export async function prepareForSigningCustomPdf({
  dal: formDal,
  fileSync,
  formCode,
  formId,
  initiator,
  sessionInfo,
  store,
  entitySigningOpts,
  getUserPrefsData,
  memberEntities,
  ydoc
}: {
  dal: PropertyFormYjsDal;
  fileSync?: FileSync;
  formCode: FormCodeUnion;
  formId: string;
  initiator: SigningInitiator;
  sessionInfo?: SessionInfo;
  store: Store<unknown, AnyAction>;
  entitySigningOpts: EntitySettingsSigningOptions;
  getUserPrefsData?: () => UserPreferencesMain | undefined;
  memberEntities: BelongingEntityMeta;
  ydoc: Doc
}): Promise<PrepareForSigningCustomPdfResponse> {
  const instance = formDal.getFormInstance(formCode, formId);
  if (!instance?.upload?.id) {
    return { error: new NotAnUploadedDocumentError() };
  }

  if (!instance?.signing?.customFields?.length) {
    return { error: new NoFieldsError() };
  }

  // check serve fields are present
  if (FormTypes[formCode].formFamily === FormCode.Form1) {
    let name = false;
    let address = false;
    let contractDate = false;
    instance.signing.customFields.forEach(cf => {
      switch (cf.type) {
        case CustomFieldType.purchaserName:
          name = true;
          break;
        case CustomFieldType.purchaserAddress:
          address = true;
          break;
        case CustomFieldType.contractDate:
          contractDate = true;
          break;
      }
    });

    if (!name || !address || !contractDate) {
      return { error: new MissingPurchaserFieldsError(name, address, contractDate) };
    }
  }

  const fields = instance.signing.customFields;
  const categorisedParties = categoriseCustomSigningPartiesByField(instance.signing);
  if (FormTypes[formCode]?.allowEmptySigning) {
    if (instance.upload?.unsigned?.agent) {
      const agents = instance.signing.parties?.filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === 'agent') || [];
      if (!agents.some(a => categorisedParties.find(cp => cp.partyId === a.id))) {
        return { error: new PartyRequiredError('agent') };
      }
    }

    if (instance.upload?.unsigned?.vendor) {
      const vendors = instance.signing.parties?.filter(p => mapSigningPartySourceTypeToCategory(p.source.type) === 'vendor') || [];
      if (!vendors.some(v => categorisedParties.find(cp => cp.partyId === v.id))) {
        return { error: new PartyRequiredError('vendor') };
      }
    }
  } else {
    if (categorisedParties.filter(x => x.sign).length === 0) {
      return { error: new NoSignatureFieldsError() };
    }
  }

  if (categorisedParties.some(x => x.fill && !x.sign)) {
    return { error: new RemoteCompletionPartyMissingSignatureError() };
  }

  const pdfData = (await FileStorage.read(instance.upload.id))?.data;
  if (!pdfData) {
    return { error: new PdfNotFoundError() };
  }

  const signingSessionId = v4();
  const pdfId = v4();
  const accompanyingFileRefs = {
    propertyDataSnapshot: { id: v4(), contentType: ContentType.Json }
  };
  const fieldIdMap = new Map<string, string>();

  const hasRemoteSigning = !!instance?.signing?.parties?.find(p => RemoteSigningTypes.includes(p.type));

  const { dataPatches, metaPatches, producedMeta } = produceTransition({
    transitionConfig: {
      formCode,
      formId,
      metaBinder: formDal.metaBinder,
      dataBinder: formDal.dataBinder,
      sessionInfo,
      store,
      getSublineageData: sublineageId => formDal.getSublineageData(sublineageId),
      entitySigningOpts
    },
    transitionData: {
      to: FormSigningState.OutForSigningPendingUpload,
      outForSigningData: {
        sessionId: signingSessionId,
        baseFile: {
          id: pdfId,
          contentType: ContentType.Pdf
        },
        accompanyingFileIds: accompanyingFileRefs,
        initiator,
        customFieldsIdMap: fieldIdMap,
        userPrefs: getUserPrefsData?.(),
        hasRemoteSigning,
        memberEntities
      }
    }
  });

  const property = formDal.dataBinder.get();
  const formInstance = FormUtil.getFormState(formCode, formId, producedMeta)!;

  const transformedPdfBytes = await applyPdfChanges(await pdfData.arrayBuffer(), [
    ...makeUploadPdfChangeFns(formInstance?.upload?.actions || [], true),
    // async pdf => drawFixedReference(pdf),
    // async pdf => await drawReferenceTexts(pdf),
    async (pdf) => await injectCustomFields({
      pdf,
      customFields: fields,
      fieldIdMap,
      parties: formInstance.signing?.parties || [],
      property,
      debug: false
    })
  ]);

  const marketingData: SigningMarketingData = {
    landType: typeof property?.landType === 'number' ? property?.landType : LandType.Unknown,
    transactionType: property?.transactionType,
    documentTemplateName: getDocumentName(formInstance.formCode, formInstance), // We probably don't need to pass this

    // through here, but it'll do for now
    propertyAddress: property?.saleAddrs?.map(addr => addr?.streetAddr_parts).filter(Predicate.isNotNullish) || []
  };
  const manifestData: ManifestData = formInstance
    ? {
      manifestType: ManifestType.FormInstancePlusMarketingAndSnapshot,
      data: {
        formInstance,
        marketingData,
        accompanying: Object.assign({}, ...Object.entries(accompanyingFileRefs).map(([accKey, { id: fileId }]) => ({ [accKey]: { fileId: fileId } })))
      }
    }
    : {
      manifestType: ManifestType.None
    };

  await FileStorage.write(
    pdfId,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([transformedPdfBytes], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId: property.id,
        formId,
        formCode,
        signingSessionId
      }
    },
    { ydoc, store },
    manifestData,
    undefined,
    {
      propertyDataSnapshot: property
    }
  );

  // Wait for the local file to commit before transitioning state, lest other tabs then try to
  // download it before the local write is finished, and mess up pending upload
  metaPatches.length && formDal.metaBinder?.update(draft => {
    applyPatches(draft, metaPatches);
  });
  dataPatches.length && formDal.dataBinder?.update(draft => {
    applyPatches(draft, dataPatches);
  });

  FileSync.triggerSync(fileSync);
  return {};
}

export function categoriseCustomSigningPartiesByField(signing: FormInstanceSigning) {
  const fields = signing.customFields || [];
  const firstPartyId = getFirstOrderedParty(signing.parties || [], signing)?.id;
  const parties = new Map<string, { sign: boolean; fill: boolean; }>();
  const initParty = (partyId: string) => {
    const party = parties.get(partyId);
    if (party) return party;
    const newParty = { sign: false, fill: false };
    parties.set(partyId, newParty);
    return newParty;
  };

  for (const field of fields) {
    const signingSessionFieldType = customFieldMetas[field.type]?.signingSessionFieldType;
    if (!signingSessionFieldType) continue;

    switch (signingSessionFieldType) {
      case SigningSessionFieldType.Initials:
      case SigningSessionFieldType.Signature:
        if ('partyId' in field && field.partyId) {
          initParty(field.partyId).sign = true;
        }
        break;
      default:
        if (firstPartyId) {
          initParty(firstPartyId).fill = true;
        }
        // use first party id
        break;
    }
  }

  return [...parties.entries()].map(([partyId, value]) => ({
    partyId,
    ...value
  }));
}

export async function prepareForSigningSubscriptionForm({
  dal,
  formId,
  formCode,
  initiator,
  sessionInfo,
  fileSync,
  store,
  entitySigningOpts,
  getUserPrefsData,
  memberEntities,
  overrideFormDefinition,
  ydoc
}: {
  dal: PropertyFormYjsDal;
  formId: string;
  formCode: string;
  sessionInfo?: SessionInfo;
  initiator: {
    id: number;
    name: string;
    timeZone: string;
    email: string;
    entity: {
      id: number;
      uuid?: string;
      name: string;
    };
  };
  fileSync?: FileSync;
  store: Store<unknown, AnyAction>;
  entitySigningOpts: EntitySettingsSigningOptions | undefined;
  getUserPrefsData?: () => UserPreferencesMain | undefined;
  memberEntities: BelongingEntityMeta;
  overrideFormDefinition?: FormDescriptorRecord;
  ydoc: Doc
}) {
  const formDefn = overrideFormDefinition ?? FormTypes[formCode];
  const formInstanceInitial = dal.getFormInstance(formCode, formId)!;
  const documentId = formInstanceInitial?.subscription?.documentId;
  if (!documentId) return;

  const signingSessionId = v4();
  const pdfId = v4();
  const accompanyingFileRefs = {
    propertyDataSnapshot: { id: v4(), contentType: ContentType.Json }
  };

  const hasRemoteSigning = !!formInstanceInitial?.signing?.parties?.find(p => RemoteSigningTypes.includes(p.type));

  const transitionResults = produceTransition({
    transitionConfig: {
      formCode,
      formId,
      metaBinder: dal.metaBinder,
      dataBinder: dal.dataBinder,
      sessionInfo,
      store,
      entitySigningOpts
    },
    transitionData: {
      to: FormSigningState.OutForSigningPendingUpload,
      outForSigningData: {
        sessionId: signingSessionId,
        baseFile: {
          id: pdfId,
          contentType: ContentType.Pdf
        },
        accompanyingFileIds: accompanyingFileRefs,
        initiator,
        userPrefs: getUserPrefsData?.(),
        hasRemoteSigning,
        memberEntities
      }
    }
  });
  let { producedMeta } = transitionResults;
  const { dataPatches, metaPatches, producedData } = transitionResults;
  if (producedMeta == null) {
    // This is in serious error. Die now
    return;
  }
  const property = dal.dataBinder.get();
  let formInstance = FormUtil.getFormState(formCode, formId, producedMeta)!;
  const parties = formInstance.signing?.parties || [];
  const originalFields = formInstance.signing?.session?.fields || [];

  const result = await LegacyApi.ajax<{
    link: string;
  } & BaseAjaxResponse>('generatepdf', {
    DocumentID: documentId.toString(),
    Download: 'true',
    PFSignPrep: '2'
  });

  const pdfBytes = await WrappedFetch.bytes(result.link);
  const extractor = new PdfInformationExtractor(pdfBytes);
  const placements = await extractor.getImagePagePlacements();

  const pages = await extractor.getPageDimensions();

  const fields = formDefn.subscription?.signing?.useGroups !== false
    ? originalFields
    : placements.map(s => {
      const party = parties.find(p => {
        const originalType = p.source.originalType ?? '';
        const typeWithNumber = originalType.replace(/\d+/, '') == originalType
          ? originalType + '1'
          : originalType as any;

        if (!s.party) {
          return false;
        }

        return (s.party.partyType + s.party.number) === typeWithNumber;
      });

      return {
        type: s.party?.fieldType === 'initial' ? SigningSessionFieldType.Initials : SigningSessionFieldType.Signature,
        subtype: s.party?.fieldType === 'initial' ? SigningSessionSubType.None : SigningSessionSubType.RenderInfoInline,
        id: v4(),
        partyId: party?.id as string,
        isWetSigned: false,
        originalFieldName: s.name,
        placement: s
      };
    }).filter(s => s.partyId);

  producedMeta = produce(producedMeta, (workingMeta) => {
    const instance = FormUtil.getFormState(formCode, formId, workingMeta);
    if (!instance || !instance.signing?.session) return;

    instance.signing.session.fields = fields;
  },
  (patches) => {
    metaPatches.push(...patches);
  });

  formInstance = FormUtil.getFormState(formCode, formId, producedMeta)!;

  const transformedPdfBytes = await applyPdfChanges(pdfBytes, [
    async (pdf) => {
      await injectSigningLocations({
        parties,
        placements,
        fields,
        pdf,
        pages,
        placementStrategies: formDefn.subscription?.signing?.placementStrategies,
        debug: formDefn.debug,
        useGroups: formDefn.subscription?.signing?.useGroups
      });
    },
    async pdf => {
      await injectServeFields(pdf, formDefn.subscription?.signing?.serveFields);
    },
    async (pdf) => {
      await applyAnnexures(pdf, FormUtil.getAnnexuresFromFormInstance(formInstance));
    }
  ]);

  const marketingData: SigningMarketingData = {
    landType: typeof property?.landType === 'number' ? property?.landType : LandType.Unknown,
    transactionType: property?.transactionType,
    documentTemplateName: formDefn?.label, // We probably don't need to pass this
    // through here, but it'll do for now
    propertyAddress: property?.saleAddrs?.map(addr => addr?.streetAddr_parts).filter(Predicate.isNotNullish) || []
  };

  const manifestData: ManifestData = formInstance
    ? {
      manifestType: ManifestType.FormInstancePlusMarketingAndSnapshot,
      data: {
        formInstance,
        marketingData,
        accompanying: Object.assign({}, ...Object.entries(accompanyingFileRefs).map(([accKey, { id: fileId }]) => ({ [accKey]: { fileId: fileId } })))
      }
    }
    : {
      manifestType: ManifestType.None
    };

  await FileStorage.write(
    pdfId,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([transformedPdfBytes], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId: property.id,
        formId,
        formCode,
        signingSessionId
      }
    },
    { store, ydoc },
    manifestData,
    undefined,
    {
      propertyDataSnapshot: property
    }
  );

  // Wait for the local file to commit before transitioning state, lest other tabs then try to
  // download it before the local write is finished, and mess up pending upload
  metaPatches.length && dal.metaBinder?.update(draft => {
    applyPatches(draft, metaPatches);
  });
  dataPatches.length && dal.dataBinder?.update(draft => {
    applyPatches(draft, dataPatches);
  });

  FileSync.triggerSync(fileSync);
}

