import { Link, useAsyncValue, useParams } from 'react-router-dom';
import { GetPurchaserPortalResult } from '@property-folders/contract';
import { ShortId } from '@property-folders/common/util/url';
import React, { useMemo } from 'react';
import { ViewDocumentPage } from '~/components/view-document-page';
import { DefaultPageLayout } from '~/components/default-page-layout';
import { Alert } from 'react-bootstrap';
import { SimpleCrumbs } from '~/components/simple-crumbs';

type ResolvedSuspenseData = GetPurchaserPortalResult;

export function PurchaserAuthenticatedDocumentRoute() {
  const data = useAsyncValue() as ResolvedSuspenseData;
  const { documentIdRaw } = useParams<{ documentIdRaw: string }>();

  const document = useMemo(() => {
    if (!documentIdRaw) return undefined;
    const documentId = ShortId.toUuid(documentIdRaw);
    return data.sharedDocuments.find(d => d.documentId === documentId);
  }, [data, documentIdRaw]);

  if (!document) {
    return <DefaultPageLayout
      title={'Review document'}
      subtitle={[<SimpleCrumbs key='crumbs'>
        <Link key='backlink' to={'..'} relative={'route'}>{data.headline}</Link>
        <span key='crumb'>Invalid Document</span>
      </SimpleCrumbs>]}
      agencyCssOverrides={data.cssOverrides}
      noPad={true}>
      <Alert variant='danger'>That document does not exist or you do not have access. <Link key='backlink' to={'..'} relative={'route'}>Click to go back.</Link></Alert>
    </DefaultPageLayout>;
  }

  return <ViewDocumentPage
    headline={data.headline}
    agencyCssOverrides={data.cssOverrides}
    document={document}
  />;
}
