import { Col, Container, Row } from 'react-bootstrap';
import './Hero.scss';

export function Hero({ children }: React.PropsWithChildren) {

  return <div className="Hero hero-main">
    <Container fluid>
      <Row>
        <Col lg={1} xl={2}></Col>
        <Col lg={10} xl={8}>
          <div className='content'>{children}</div>
        </Col>
      </Row>
    </Container>
  </div>;
}