import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';

export interface BreakpointValueProps<T> {
  base?: T,
  sm?: T,
  md?: T,
  lg?: T,
  xl?: T,
  xxl?: T
}

export function useBreakpointValue<T>(props: BreakpointValueProps<T>, defaultValue: T): T {
  props.base = props.base == null ? defaultValue : props.base;
  props.sm = props.sm == null ? props.base : props.sm;
  props.md = props.md == null ? props.sm : props.md;
  props.lg = props.lg == null ? props.md : props.lg;
  props.xl = props.xl == null ? props.lg : props.xl;
  props.xxl = props.xxl == null ? props.xl : props.xxl;

  const xs = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const sm = useMediaQuery({ minWidth: BP_MINIMA.sm, maxWidth: BP_MINIMA.md });
  const md = useMediaQuery({ minWidth: BP_MINIMA.md, maxWidth: BP_MINIMA.lg });
  const lg = useMediaQuery({ minWidth: BP_MINIMA.lg, maxWidth: BP_MINIMA.xl });
  const xl = useMediaQuery({ minWidth: BP_MINIMA.xl, maxWidth: BP_MINIMA.xxl });
  const xxl = useMediaQuery({ minWidth: BP_MINIMA.xxl });

  if (xxl) return props.xxl;
  if (xl) return props.xl;
  if (lg) return props.lg;
  if (md) return props.md;
  if (sm) return props.sm;
  if (xs) return props.base;

  return defaultValue;
}
