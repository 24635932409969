import { useOthers } from '@y-presence/react';
import { User } from '@y-presence/client';
import { AwarenessData } from '@property-folders/contract';
import './PresentUsersList.scss';
import { Predicate } from '@property-folders/common/predicate';
import { UserUtil } from '@property-folders/common/util/user';

interface PresentUserProps {
  name: string;
  agentId?: number;
}

export function PresentUser(props: PresentUserProps) {
  const name = props.name;
  const agentId = props.agentId;
  const initials = UserUtil.getInitials(name);
  const style = UserUtil.getThumbnailStyle(name, agentId);

  // hue (0-359 degrees), saturation (50% = moderate), lighting (30% = darker)
  return <div className={'present-user'}>
    <span className={'initials'} style={style} title={name}>{initials}</span>
  </div>;
}

interface PresentUsersListProps {
  pathPrefix?: string;
  offset?: boolean;
}

function pathPrefixMatch(path?: string, prefix?: string) {
  return !prefix || path?.startsWith(prefix) || false;
}

function toFlattenedProps(u: User<AwarenessData>) {
  const user = u.presence.user;
  if (!user) {
    return undefined;
  }

  const name = user.name;
  const agentId = user.agentId;
  const userKey = agentId?.toString() || name;
  if (!userKey) {
    return undefined;
  }

  return {
    userKey,
    connectionId: u.id,
    name,
    agentId
  };
}

export function PresentUsersList(props: PresentUsersListProps) {
  // this might change slightly if we start using redux to manage yjs state
  const otherUsers = useOthers<AwarenessData>();
  const presentUsers = otherUsers
    .filter(u => pathPrefixMatch(u.presence.location?.path, props.pathPrefix))
    .map(toFlattenedProps)
    .filter(Predicate.isNotNull);

  if (!presentUsers.length) {
    return <></>;
  }

  const distinctPresentUsers = [...new Map(presentUsers.map(pu => [pu.userKey, pu])).values()];
  const className = [
    'present-users-list',
    props.offset
      ? 'offset'
      : undefined
  ].filter(cls => cls).join(' ');

  return <div className={className}>
    {distinctPresentUsers.map(pu => <PresentUser key={pu.connectionId} name={pu.name} agentId={pu.agentId}></PresentUser>)}
  </div>;
}
