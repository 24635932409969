import { Maybe } from '../types/Utility';
import { db } from './db';

export interface IPendingUpdate {
  id: string;
  agentId: number;
  pending: boolean;
}

export class PendingUpdates {
  static async setPending(id: string, agentId: number) {
    await db.pendingUpdates.put({
      id,
      agentId,
      pending: true
    });
  }

  static async unsetPending(id: string, agentId: number) {
    await db.pendingUpdates.delete([id, agentId]);
  }

  static async hasPending(agentId: Maybe<number>) {
    if (!agentId) {
      return (await db.pendingUpdates.count()) > 0;
    }
    return (await db.pendingUpdates.where('agentId').equals(agentId).count()) > 0;
  }

  static async isPending(id: string, agentId: number) {
    return (await db.pendingUpdates.get([id, agentId])) != null;
  }

  static async unsetForAgent(agentId: number) {
    return db.transaction('rw', db.pendingUpdates, async () => {
      return db.pendingUpdates.where('agentId').equals(agentId).delete();
    });
  }

  static async unsetForId(id: string) {
    return db.transaction('rw', db.pendingUpdates, async () => {
      return db.pendingUpdates.where('id').equals(id).delete();
    });
  }
}
