import { AuthorityParty, MaterialisedPropertyData, PartyType, PurchaserParty, SigningAuthorityType, VendorParty, VendorPartyJoint, companyTypes, jointTypes, multiRepAuthority } from '@property-folders/contract';
import { Predicate } from '../predicate';
import { PathSegments } from '@property-folders/contract/yjs-schema/model';
import { UpdateFn } from 'immer-yjs/src';
import { getPathParentAndIndex } from './pathHandling';
import { jointPartyConfig, partyFieldConfig } from './formatting/constants';

export interface AuthorityPartyContactInfo {
  name: string;
  email?: string;
  phone?: string;
  address?: string; // May only be available if you provide all relevant info to the generating function, eg sale property address or real primary party ID for purchasers, as checkboxes exist for these. If you don't need the address, don't set them
  isPrimary?: boolean;
  individualTrueCompanyFalse: boolean;
  partyType: PartyType;
  maybeSubType: Omit<PartyType, PartyType.AdministratorJoint | PartyType.ExecutorJoint | PartyType.MortgageeJoint>;
  authorityAsIndividual: SigningAuthorityType;
  fullLegalName: string;
  companyTypeAbn: string; // Meaning we don't want the ABN of the representing attorney or whatever
  onBehalf: {
    enabled: boolean
    onBehalfOf?: string
  }
}

/**
 * To use setPrimaryToFirst, you will have to externally set the primary ID to the value, then set
 * setPrimaryToFirst. This will allow you to extract a primary from any individual party. If you
 * are after a true primary ID, you do not want to set this value
 */
export function getContactsFromAuthorityParty(v: AuthorityParty | undefined, primaryId: string | null, opts?: {
  topLevelBehalf?: AuthorityPartyContactInfo['onBehalf'],
  topLevelType?: PartyType,
  topLevelAddress?: string,
  setPrimaryToFirst?: boolean,
  primarySaleAddress?: string,
  realPrimaryPurchaserIdForAddress?: string, // These address ones shouldn't need to be passed down to subExecutor recursion, as Purchasers don't have that.
  allPurchasersForAddress?: PurchaserParty[]
}): AuthorityPartyContactInfo[] {
  const { setPrimaryToFirst, topLevelBehalf, topLevelType } = opts??{};
  if (!v) return [];
  const isPrimary = primaryId === v.id;
  let address = v.addressSingleLine;
  if ((v as VendorParty).addrSameAsSale && opts?.primarySaleAddress) {
    address = opts.primarySaleAddress;
  }
  if ((v as PurchaserParty).addrSameAsPrimary && opts?.realPrimaryPurchaserIdForAddress && Array.isArray(opts.allPurchasersForAddress)) {
    const primaryPurchaser = opts.allPurchasersForAddress.find(p=> p.id === opts.realPrimaryPurchaserIdForAddress );
    address = primaryPurchaser?.addressSingleLine;
  }
  if (!v.partyType) return [];
  if (jointTypes.includes(v.partyType)) {
    const vendor = v as VendorPartyJoint;
    const { partyType } = vendor;
    const { appointedAlwaysApplicable } = partyType && jointPartyConfig[partyType]||{};
    const isBehalf = !!(vendor?.inTrust || appointedAlwaysApplicable);
    const onBehalf: AuthorityPartyContactInfo['onBehalf'] = {
      enabled: isBehalf,
      onBehalfOf: vendor.onBehalfOf
    };
    return (((vendor.namedExecutors)??[]) as AuthorityParty[])
      .flatMap(ne => getContactsFromAuthorityParty(
        ne,
        (isPrimary && ((setPrimaryToFirst ? null : vendor.primaryNamedExecutor)||vendor.namedExecutors?.[0].id))||null,
        { topLevelBehalf: onBehalf, topLevelType: partyType, setPrimaryToFirst, topLevelAddress: address }
      ));
  }

  if (!v.partyType || !v.authority || !v.fullLegalName) return [];

  const { appointedAlwaysApplicable } = v.partyType && partyFieldConfig[v.partyType][v.authority]||{};
  const isBehalf = !!(v?.inTrust || appointedAlwaysApplicable);
  const onBehalf: AuthorityPartyContactInfo['onBehalf'] = topLevelBehalf ?? {
    enabled: isBehalf,
    onBehalfOf: v.onBehalfOf
  };

  const individualTrueCompanyFalse = !companyTypes.includes(v.partyType);
  const authorityAsIndividual = v.authority === SigningAuthorityType.attorneyJoint
    ? SigningAuthorityType.attorney
    : v.authority === SigningAuthorityType.guardianJoint
      ? SigningAuthorityType.guardian
      : v.authority;

  const commonAttributes = {
    onBehalf,
    address: opts?.topLevelAddress || address,
    maybeSubType: v.partyType,
    partyType: topLevelType??v.partyType,
    individualTrueCompanyFalse,
    authorityAsIndividual,
    fullLegalName: v.fullLegalName,
    companyTypeAbn: v.abn ?? '' // We don't take the ABNs of the joint attorneys/guardians for this
  };
  switch (v.authority) {
    case SigningAuthorityType.directors2:
    case SigningAuthorityType.directorSecretary:
      return [
        v.personName1 ? { ...commonAttributes, name: v.personName1, email: v.email1, phone: v.phone1, isPrimary: isPrimary && (v.primarySubcontact !== 1 || setPrimaryToFirst) } : undefined,
        v.personName2 ? { ...commonAttributes, name: v.personName2, email: v.email2, phone: v.phone2, isPrimary: isPrimary && (!setPrimaryToFirst && v.primarySubcontact === 1) } : undefined
      ].filter(Predicate.isNotNull);

    case SigningAuthorityType.attorneyJoint:
    case SigningAuthorityType.guardianJoint:
      return v.legalRepresentatives?.map(le => (le.name ? {
        ...commonAttributes,
        name: le.name,
        email: le.email,
        phone: le.phone,
        isPrimary: isPrimary && (((setPrimaryToFirst ? null : v.primarySubcontactId)??v.legalRepresentatives?.[0].id)??null) === le.id
      } : null))?.filter(Predicate.isNotNull) ?? [];

      break;
    case SigningAuthorityType.sole:
    case SigningAuthorityType.attorney:
    case SigningAuthorityType.guardian:
    case SigningAuthorityType.authRep:
      return v.personName1
        ? [{ ...commonAttributes, name: v.personName1, email: v.email1, phone: v.phone1, isPrimary }]
        : [];
    default:
      return v.fullLegalName
        ? [{ ...commonAttributes, name: v.fullLegalName, email: v.email1, phone: v.phone1, isPrimary }]
        : [];
  }
}

export function primaryContactRelativePath(parties: AuthorityParty[], primaryId: string): PathSegments | void {
  const primaryParty = parties?.find(p=>p.id === primaryId);
  if (!primaryParty) return;
  const builtPath = [`[${primaryParty.id}]`];
  const { authority, partyType, primarySubcontact, primarySubcontactId, namedExecutors, primaryNamedExecutor, legalRepresentatives } = primaryParty;
  if (jointTypes.includes(partyType)) {
    const lowerRes = primaryContactRelativePath(namedExecutors, primaryNamedExecutor);
    if (!lowerRes) return;
    builtPath.push('namedExecutors', ...lowerRes);
    return builtPath;
  }
  if (multiRepAuthority.includes(authority)) {
    const multiMember = legalRepresentatives?.find(l=>l.id === primarySubcontactId);
    if (!multiMember) return;
    builtPath.push('legalRepresentatives', `[${primarySubcontactId}]`);
    return builtPath;
  }
  const configuration = jointTypes.includes(partyType) ? jointPartyConfig[partyType] : (partyType && authority && partyFieldConfig[partyType][authority]);
  const { person1Name, person2Name } = configuration || {};

  const dualContact = person1Name && person2Name;
  if (dualContact) {
    builtPath.push('primarySubcontact', `[${primarySubcontact}]`);
    return builtPath;
  }

  return builtPath;
}

const ArraySegmentRegex = /\[([\w-]+)\]/;
const ArraySegmentUUIDRegex = /\[(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\]/;

export function setAuthorityPartyPrimaryContact(
  update: (fn: UpdateFn<MaterialisedPropertyData>) => void,
  { newPrimaryPath, relativeBase, topLevelUniqueRef }: {
    topLevelUniqueRef: PathSegments,
    newPrimaryPath: PathSegments,
    relativeBase: PathSegments // the path of the list of the Authority party
  }
) {
  update(draft => {
    const workingNewPath = newPrimaryPath;
    const basePath = [];
    for (const segment of relativeBase) {
      if (segment != workingNewPath[0]) throw new Error('The new data path does not match the data root');
      basePath.push(...workingNewPath.splice(0,1));
    }

    const primaryKey = (ArraySegmentUUIDRegex.exec(workingNewPath[0]??'')??[])[1];
    if (!primaryKey) throw new Error('The primary path does not look like an array indexer');
    (()=>{
      const { parent, indexer } = getPathParentAndIndex(topLevelUniqueRef, draft);
      parent[indexer] = primaryKey;
    })();
    basePath.push(...workingNewPath.splice(0,1));
    if (workingNewPath.length === 0) return;
    if (workingNewPath[0] === 'namedExecutors') {
      const { parent, indexer } = getPathParentAndIndex([...basePath, 'primaryNamedExecutor'], draft);
      const executorKey = (ArraySegmentUUIDRegex.exec(workingNewPath[1]??'')??[])[1];
      parent[indexer] = executorKey;
      basePath.push(...workingNewPath.splice(0,2));
    }
    if (workingNewPath.length === 0) return;

    if (workingNewPath[0] === 'legalRepresentatives') {
      const { parent, indexer } = getPathParentAndIndex([...basePath, 'primarySubcontactId'], draft);
      const legalRepKey = (ArraySegmentUUIDRegex.exec(workingNewPath[1]??'')??[])[1];
      parent[indexer] = legalRepKey;
      return; // There should be no further children path, as this type doesn't contain further children
    }

    if (workingNewPath[0] === 'primarySubcontact') {
      const { parent, indexer } = getPathParentAndIndex([...basePath, 'primarySubcontact'], draft);
      const legalRepKey = (ArraySegmentRegex.exec(workingNewPath[1]??'')??[])[1];
      parent[indexer] = parseInt(legalRepKey);
      return; // There should be no further children path, as this type doesn't contain further children
    }
  });
}