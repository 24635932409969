import { AnchorHTMLAttributes } from 'react';
import clsJn from '@property-folders/common/util/classNameJoin';
import './ExternalLink.scss';

export function ExternalLink ({ children, className, ...restProps }: AnchorHTMLAttributes<HTMLAnchorElement>) {
  return <a className={clsJn(className, 'external-link')} target='_blank' {...restProps}>
    {children}
    <i className='la la-external-link external-link-icon'></i>
  </a>;
}
