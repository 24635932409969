import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';
import { useEffect, useState } from 'react';

export function useOfflineAwareness(
  doc: Y.Doc | undefined
) {
  const [awareness, setAwareness] = useState<Awareness>();

  useEffect(() => {
    const newAwareness = doc
      ? new Awareness(doc)
      : undefined;
    setAwareness(newAwareness);

    return () => {
      newAwareness?.destroy();
    };
  }, [!!doc]);

  return awareness;
}
