import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { generateParentPath, getValueByPath } from '../../../../util/pathHandling';
import { MaterialisedPropertyData, PurchaserParty } from '@property-folders/contract';

/**Manages addresses for purchasers. Makes assumptions about entire document structure.
 *
 */
export const purchaserAddrSameAsPrimary: FieldGroupFn = (fieldId, updatedPath, rootDraft, _variationSnapshot, _variationHistory, previousState) => {
  const parentPath = generateParentPath(updatedPath);
  const pointPath = fieldId.split('.').slice(-1)[0]; // The specified users of this updater should not include the root path []
  const currentTree = rootDraft as unknown as MaterialisedPropertyData;
  const previousTree = previousState as MaterialisedPropertyData;

  if (pointPath === 'primaryPurchaser') {
    const newPrimary = getValueByPath(`purchasers.[${currentTree.primaryPurchaser}]`, rootDraft) as PurchaserParty;
    const oldPrimary = getValueByPath(`purchasers.[${previousTree.primaryPurchaser}]`, rootDraft) as PurchaserParty;
    if (newPrimary.addrSameAsPrimary) {
      newPrimary.addrSameAsPrimary = false;
      oldPrimary.addrSameAsPrimary = true;
      newPrimary.addressSingleLine = oldPrimary.addressSingleLine;
    }
    return;
  }

  const partyCurrent = getValueByPath(parentPath, rootDraft) as PurchaserParty;
  const partyPreviousState = getValueByPath(parentPath, previousTree) as PurchaserParty;

  const isCurrentPrimary = partyCurrent.id === currentTree.primaryPurchaser;

  if (isCurrentPrimary && pointPath === 'addressSingleLine') {
    for (const partyIter of (currentTree?.purchasers??[])) {
      if (partyIter.id === currentTree.primaryPurchaser) continue;
      if (partyIter.addrSameAsPrimary) partyIter.addressSingleLine = partyCurrent.addressSingleLine;
    }
    return;
  }

  if (isCurrentPrimary && pointPath === 'addrSameAsPrimary' && partyCurrent.addrSameAsPrimary) {
    // Primary party should not have the option of turning on same as primary party, they are the primary party
    if (partyPreviousState.addrSameAsPrimary == null) {
      delete partyPreviousState.addrSameAsPrimary;
      return;
    }
    partyCurrent.addrSameAsPrimary = false;
    return;
  }

  if (pointPath === 'addrSameAsPrimary' && !partyCurrent.addrSameAsPrimary && partyPreviousState.addrSameAsPrimary ) {
    // Turning off addrSameAsPrimary
    partyCurrent.addressSingleLine = '';
    return;
  }

  if (!isCurrentPrimary && pointPath === 'addrSameAsPrimary' && partyCurrent.addrSameAsPrimary && !partyPreviousState.addrSameAsPrimary ) {
    // Turning on addrSameAsPrimary
    const primaryParty = getValueByPath(`purchasers.[${currentTree.primaryPurchaser}]`, rootDraft) as PurchaserParty;
    partyCurrent.addressSingleLine = primaryParty.addressSingleLine;
    return;
  }

};
