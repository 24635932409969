import { createContext, useCallback, useState } from 'react';
import { WarnBeforeUpdateDialog } from './WarnBeforeUpdateDialog';

export type WarnBeforeUpdateContextType = (proceedCallback: ()=>void, title: string, body: JSX.Element)=>void;

// null and null should mean "still loading"
export const WarnBeforeUpdateContext = createContext<WarnBeforeUpdateContextType>(()=>console.warn('Called into default warn setup function'));

export function WarnBeforeUpdateWithContext(props) {
  const [updateFunction, setUpdateFunction] = useState<(()=>void)|null>(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState<JSX.Element|null>();
  const [show, setShow] = useState(false);

  function handleHide() {
    setUpdateFunction(null);
    setTitle('');
    setBody(null);

    setShow(false);
  }

  const buildDialog = useCallback(function (proceedCallback: ()=>void, title: string, body: JSX.Element) {
    setShow(true);
    setTitle(title);
    setBody(body);
    // This looks odd, except that when setState recieves a function, it is a mutator function of
    // the form (previousState)=>newState and is executed immediately. This was calling the callback
    // I wanted to defer to user input. So to get around that, we make the set state function return
    // the intended callback
    setUpdateFunction(()=>proceedCallback);
  }, []);

  function handleProceed() {
    updateFunction?.();
    handleHide();
  }

  return <>
    <WarnBeforeUpdateContext.Provider value={buildDialog}>
      {props.children}
    </WarnBeforeUpdateContext.Provider>
    <WarnBeforeUpdateDialog show={show} onHide={handleHide} title={title} body={body??<></>} onProceedAnyway={handleProceed}></WarnBeforeUpdateDialog>
  </>;
}