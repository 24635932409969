import { createContext } from 'react';
import { FormContextType } from '@property-folders/common/types/FormContextType';

export const FormContextDefaultValue: FormContextType = {
  formName: 'default', //formCode
  transactionRules: { _type: 'Map' },
  metaRules: { _type: 'Map' },
  formId: ''
};
export const FormContext = createContext<FormContextType>(FormContextDefaultValue);
