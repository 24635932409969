import * as Y from 'yjs';
import {
  FileRef,
  FormCodeUnion,
  FormInstance,
  FormSigningState,
  META_APPEND,
  PropertyRootKey,
  MaterialisedProperty,
  MaterialisedPropertyData,
  SigningPartyType,
  SigningSessionFieldType,
  TransactionMetaData, InlineFile, SigningSessionOrderItem, SigningSessionOrderParty
} from '@property-folders/contract/yjs-schema/property';
import { applyMigrationsV2 } from '../index';
import {
  FormTypes,
  isOutForSigning,
  mapSigningPartySourceTypeToCategory,
  PartyCategory,
  PropertyFormYjsDal
} from './form';
import { PartyCacheYjsDal } from './party-cache';
import { ContentType, FormOrderState } from '@property-folders/contract';
import { Predicate } from '../../predicate';
import { formCompleted } from '../../util/form/formCompleted';
import { FormState } from './types';
import { summariseAddressesOrTitles } from './headlineGenerator';
import { StringUtil } from '../../util/string';
import { FormUtil } from '../../util/form';

export * from './headlineGenerator';
export * from './types';

function stripExtension(label?: string): string | undefined {
  if (!label) return undefined;
  if (label.endsWith('.pdf')) {
    const replaced = label.replace(/\.pdf$/, '');
    return replaced ? replaced : undefined;
  }
  return label;
}

export function getDocumentName(formCode: FormCodeUnion, form?: FormInstance): string {
  try {
    return stripExtension(form?.upload?.name)?.trim() || stripExtension(form?.subscription?.fileName)?.trim() || FormTypes[formCode].label;
  } catch (err: unknown) {
    console.error('getDocumentName threw', { formCode, err });
    throw err;
  }
}

export function getDocumentNameFromGeneric(record: { formCode: string, documentName?: string }): string {
  try {
    return stripExtension(record.documentName)?.trim() || FormTypes[record.formCode].label;
  } catch (err: unknown) {
    console.error('getDocumentNameFromGeneric threw', { record, err });
    throw err;
  }
}

export function generateHeadlineFromMaterialisedData(data?: Pick<MaterialisedPropertyData, 'saleAddrs' | 'saleTitles' | 'titleDivision' | 'headline'>, narrowMode = false) {
  return data?.headline || summariseAddressesOrTitles(data, narrowMode);
}

export const determineFormState = (instance?: FormInstance) => {
  if (instance === undefined) {
    return FormState.NOT_ADDED;
  }
  if (formCompleted(instance)) {
    return FormState.SIGNED;
  }
  if (instance?.signing?.parties?.filter(party=>Predicate.isNotNullish(party?.declineType) && party?.declineType > 0).length) {
    return FormState.DECLINED;
  }
  if (instance.signing?.state === FormSigningState.Configuring) {
    return FormState.CONFIGURING;
  }
  if (isOutForSigning(instance.signing?.state)) {
    return FormState.AWAITING_SIGN;
  }
  if (instance.order) {
    switch (instance.order.state) {
      case FormOrderState.ClientOrdering:
        return FormState.ORDER_ORDERING;

      case FormOrderState.ThirdPartyPreparing:
        return FormState.ORDER_PREPARING;

      case FormOrderState.ReturnedToThirdParty:
        return FormState.ORDER_RETURNED;

      case FormOrderState.Cancelled:
        return FormState.ORDER_CANCELLED;

      case FormOrderState.ReturnedToClient:
      case FormOrderState.None:
      default:
        break;
    }
  }
  return FormState.DRAFT;
};

export function generateFormFileName(formCode: string, data: MaterialisedPropertyData | undefined, fullySigned: boolean, docNumber?: number, docCount?: number): string {
  const parts = [
    FormTypes[formCode]?.label || formCode,
    data
      ? generateHeadlineFromMaterialisedData(data)
      : undefined,
    docNumber && docCount
      ? `${docNumber} of ${docCount}`
      : undefined
  ].filter(Predicate.isTruthy);
  const suffix = parts.length ? parts.join(' - ') + '.pdf' : 'document.pdf';

  return fullySigned
    ? StringUtil.sanitiseFileName(`Fully executed ${suffix}`)
    : StringUtil.sanitiseFileName(suffix);
}

export function emailSubject(documentName: string, addressSummary?: string) {
  /*
  'Please sign a document'
  'Please sign a document for An Address'
  'Please sign: Named Document'
  'Please sign: Named Document for An Address'
  */
  return `Please sign${documentName?':':''} ${documentName || 'a document'}` + (addressSummary ? ` for ${addressSummary}` : '');
}

/**Returns all data including sublineages with contracts
 *
 * @param doc
 * @param sublineageTypeAllowList List of form codes for which any present will result in inclusion of the data in the result. Pass an empty list to return no sublineages. Null will return all sublineages. Defaults to all sublineages
 * @returns
 */
export function materialiseProperty(doc: Y.Doc | undefined, sublineageTypeAllowList: FormCodeUnion[] | null = null): Required<MaterialisedProperty> | undefined {
  if (!doc) {
    return undefined;
  }

  const data = doc.getMap(PropertyRootKey.Data)?.toJSON() as MaterialisedPropertyData | undefined;
  const meta = doc.getMap(PropertyRootKey.Meta)?.toJSON() as TransactionMetaData | undefined;

  if (!(meta && data)) {
    return undefined;
  }

  const result: MaterialisedProperty = {
    meta,
    data,
    alternativeRoots: {}
  };

  const allSublineages = !Array.isArray(sublineageTypeAllowList);
  if (!allSublineages && sublineageTypeAllowList.length === 0) return result;

  for (const dataRootKey of meta.sublineageRoots??[]) {
    const metaRootKey = dataRootKey+META_APPEND;
    const data = doc.getMap(dataRootKey)?.toJSON() as MaterialisedPropertyData | undefined;
    const meta = doc.getMap(metaRootKey)?.toJSON() as TransactionMetaData | undefined;
    if (!(meta && data)) continue;

    const processingThisSublineage = allSublineages || sublineageTypeAllowList.some(allow=>Object.keys(meta.formStates??{}).includes(allow));
    if (!processingThisSublineage) continue;

    const insertion = {
      data,
      meta
    };
    if (!result.alternativeRoots) result.alternativeRoots = {}; // TS doesn't detect this set in the object initialiser
    result.alternativeRoots[dataRootKey] = insertion;
  }

  return result;
}

export function materialisePropertyMetadata(doc: Y.Doc, metaRootKey: string = PropertyRootKey.Meta): TransactionMetaData {
  return doc.getMap(metaRootKey).toJSON() as TransactionMetaData;
}

export function materialisePropertyData(doc: Y.Doc, dataRootKey: string = PropertyRootKey.Data): MaterialisedPropertyData {
  return doc.getMap(dataRootKey).toJSON() as MaterialisedPropertyData;
}

/**
 * Note: if you supply a timestamp for the fields, it will eventually be changed based on journal audit time.
 */
export function signFields(
  doc: Y.Doc,
  opts: {
    formCode: string,
    formId: string,
    signingSessionId: string,
    partyId: string,
    signedType?: SigningPartyType,
    wetSignedFileId?: string,
    fields: { id: string, timestamp: number, value: string, text?: string }[],
    signature?: FileRef,
    initials?: FileRef,
    smsSecret?: string,
    updatePartyImages?: {
      name?: string,
      email?: string
    },
    // note: do not rely on this for secure decision-making.
    // it's just a hint.
    isExecutingServerSide?: boolean,
    metaRootKey?: string,
    dataRootKey?: string,
    addInline?: InlineFile[],
    suppressNotification?: boolean
  }
) {
  if (!opts.fields.length) {
    return {
      update: undefined,
      allSigned: false
    };
  }

  const dataKey: string = opts.dataRootKey || PropertyRootKey.Data.toString();
  const metaKey: string = opts.metaRootKey || PropertyRootKey.Meta.toString();

  const update = applyMigrationsV2<TransactionMetaData>({
    doc,
    docKey: metaKey,
    typeName: 'Property',
    migrations: [{
      name: 'sign specified fields',
      fn: state => {
        const signing = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state)?.signing;
        const session = signing?.session;

        if (session?.id !== opts.signingSessionId) {
          return false;
        }

        for (const signedField of opts.fields) {
          const stateField = session.fields.find(f => f.id === signedField.id);
          if (!stateField) {
            console.log('could not find matching field', signedField);
            continue;
          }
          if (stateField.partyId !== opts.partyId) {
            console.log('submitted field does not belong to the specified party');
            continue;
          }

          const customFieldInfo = signing?.customFields?.find(cf => stateField.customFieldId && cf.id === stateField.customFieldId);

          stateField.timestamp = signedField.timestamp;
          stateField.smsSecret = opts.smsSecret;

          // no files for wet signatures
          if (opts.signedType === SigningPartyType.SignWet) {
            stateField.isWetSigned = true;
            stateField.text = 'Signed on paper counterpart';
            continue;
          }

          switch (stateField.type) {
            case SigningSessionFieldType.Signature:
              if (!opts.signature) {
                throw new Error('Signature required but file not provided');
              }
              stateField.file = { ...opts.signature };
              break;
            case SigningSessionFieldType.Initials:
              if (!opts.initials) {
                throw new Error('Initials required but file not provided');
              }
              stateField.file = { ...opts.initials };
              break;
            case SigningSessionFieldType.Text: {
              const text = signedField.text || signedField.value;
              const required = !customFieldInfo || !('required' in customFieldInfo) || customFieldInfo.required !== false;
              if (required && !text) {
                throw new Error('Text required but not provided');
              }
              stateField.text = text;
              break;
            }
            case SigningSessionFieldType.Radio:
            case SigningSessionFieldType.Check:
              stateField.text = signedField.text?.toLowerCase() === 'on'
                ? 'on'
                : signedField.value?.toLowerCase() === 'on'
                  ? 'on'
                  : 'off';
              break;
            default:
              console.warn('Unexpected stateField type', JSON.stringify({ stateField, signedField }));
              break;
          }
        }
      }
    }, {
      name: 'add inline images',
      fn: state => {
        if (!state.inlineFiles) {
          state.inlineFiles = [];
        }

        for (const inline of opts.addInline || []) {
          state.inlineFiles.push(inline);
        }
      }
    }, {
      name: 'remember party images',
      fn: state => {
        if (!opts.updatePartyImages) {
          return false;
        }

        return PartyCacheYjsDal.setPartyImagesInState(
          state,
          opts.updatePartyImages.name,
          opts.updatePartyImages.email,
          opts.signature,
          opts.initials);
      }
    }, {
      name: 'set party signing timestamp and notification preference',
      fn: state => {
        const form = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state);
        if (form?.signing?.session?.id !== opts.signingSessionId) {
          return;
        }

        const party = (form.signing.parties || []).find(p => p.id === opts.partyId);
        if (!party) {
          return;
        }

        party.signedTimestamp = Math.max(...opts.fields.map(f => f.timestamp));
        party.type = opts.signedType || party.type;
        party.type === SigningPartyType.SignWet && opts.wetSignedFileId && (party.signedPdf = { id: opts.wetSignedFileId, contentType: ContentType.Pdf });
        if (opts.suppressNotification) party.notificationBlock = true;
        if (opts.suppressNotification === false) delete party.notificationBlock; // This probably won't have an opportunity to change, but here it is
        if (opts.isExecutingServerSide) {
          console.log('set serverAcceptPending from', party.serverAcceptPending, 'to', false);
          party.serverAcceptPending = false;
        }
      }
    }, {
      name: 'apply signing order dependency changes',
      fn: state => {
        const form = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state);
        if (!form) return false;
        if (form.signing?.session?.id !== opts.signingSessionId) return false;

        return FormUtil.applySigningOrderChanges({
          signing: form.signing
        });
      }
    }, {
      name: 'complete the signing session if possible',
      fn: state => {
        const form = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state);
        if (form?.signing?.session?.id !== opts.signingSessionId) {
          return false;
        }

        if (!PropertyFormYjsDal.signingSessionAllSigned(form.signing.session)) {
          return false;
        }

        // only the server should set the state to Signed.

        form.signing.state = FormSigningState.SignedPendingDistribution;
        const formType = FormTypes[opts.formCode];
        if (formType.isTermination) {
          // This must exist if all the stuff above succeeds, so we're ignoring the
          // 'possibly undefined's
          // Also the server sets terminationConfirmed or whatever
          state.formStates[formType.formFamily].terminatedTime = Date.now();
        }
      }
    }]
  });

  const dal = new PropertyFormYjsDal(doc, dataKey, metaKey);

  return {
    update,
    allSigned: dal.getSigningSessionAllSigned(opts.formCode, opts.formId)
  };
}

export function setSigningPartyLocked(
  doc: Y.Doc,
  opts: {
    locked: boolean,
    formCode: string,
    formId: string,
    signingSessionId: string,
    partyId: string,
    metaRootKey?: string
  }
) {
  const update = applyMigrationsV2<TransactionMetaData>({
    doc,
    docKey: opts.metaRootKey??PropertyRootKey.Meta,
    typeName: 'Property',
    migrations: [{
      name: `set party locked to ${opts.locked}`,
      fn: state => {
        const form = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state);
        if (form?.signing?.session?.id !== opts.signingSessionId) return false;

        const party = (form?.signing?.parties || []).find(p => p.id === opts.partyId);
        if (!party) return false;
        if (opts.locked === !!party.locked) return false;

        party.locked = opts.locked;
      }
    }]
  });

  return {
    update
  };
}

export function unblockSigningPartyCategory(
  doc: Y.Doc,
  opts: {
    formCode: string,
    formId: string,
    signingSessionId: string,
    category: PartyCategory,
    metaRootKey?: string
  }
) {
  const update = applyMigrationsV2<TransactionMetaData>({
    doc,
    docKey: opts.metaRootKey??PropertyRootKey.Meta,
    typeName: 'Property',
    migrations: [{
      name: 'Manually unblock signing party category',
      fn: state => {
        const form = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state);
        if (form?.signing?.session?.id !== opts.signingSessionId) return false;

        const signingOrder = form.signing.session.signingOrder?.find(so => so.type === opts.category);
        if (signingOrder) {
          signingOrder.state = 'active';
        }

        let onlyPartyId: string | undefined = undefined;
        if (signingOrder?.parties?.length) {
          const firstParty = signingOrder.parties[0];
          firstParty.state = 'active';
          onlyPartyId = firstParty.partyId;
        }

        for (const party of form.signing.parties || []) {
          if (opts.category !== mapSigningPartySourceTypeToCategory(party.source.type)) continue;
          if (!party.signingOrderBlocked) continue;
          if (onlyPartyId && party.id !== onlyPartyId) continue;

          party.signingOrderBlocked = false;
        }
      }
    }]
  });

  return {
    update
  };
}

export function unblockSigningParty(
  doc: Y.Doc,
  opts: {
    formCode: string,
    formId: string,
    signingSessionId: string,
    partyId: string,
    metaRootKey?: string
  }
) {
  function activateMatchingSigningSessionOrderItem(signingOrder: SigningSessionOrderItem[]) {
    for (const order of signingOrder) {
      for (const party of order.parties || []) {
        if (party.partyId === opts.partyId) {
          party.state = 'active';
          order.state = 'active';
        }
      }
    }
    return {};
  }

  function activateMatchingSigningSessionOrderParty(orderParties: SigningSessionOrderParty[]) {
    for (const order of orderParties) {
      if (order.partyId === opts.partyId) {
        order.state = 'active';
      }
    }
  }

  applyMigrationsV2<TransactionMetaData>({
    doc,
    docKey: opts.metaRootKey??PropertyRootKey.Meta,
    typeName: 'Property',
    migrations: [{
      name: 'Manually unblock signing party',
      fn: state => {
        const form = PropertyFormYjsDal.getFormInstanceFromState(opts.formCode, opts.formId, state);
        if (form?.signing?.session?.id !== opts.signingSessionId) return false;

        if (form.signing.session.signingOrder?.length) {
          activateMatchingSigningSessionOrderItem(form.signing.session.signingOrder);
        }

        if (form.signing.session.partyOrder?.length) {
          activateMatchingSigningSessionOrderParty(form.signing.session.partyOrder);
        }

        const signingParty = form?.signing?.parties?.find(p => p.id === opts.partyId);
        if (signingParty?.signingOrderBlocked) {
          signingParty.signingOrderBlocked = false;
        }
      }
    }]
  });
}

export function unlinkIntegration(ydoc: Y.Doc, integrationId: string) {
  if (!ydoc) return;
  applyMigrationsV2<MaterialisedPropertyData>({
    typeName: 'Property',
    doc: ydoc,
    docKey: PropertyRootKey.Data,
    migrations: [{
      name: 'unlink integration',
      fn: draft => {
        const property = draft.saleAddrs?.find(sa => sa.integrationId === integrationId);
        if (!property) return;
        delete property.integrationId;
        delete property.integrationUrl;
        delete property.integrationName;
      }
    }]
  });
}
