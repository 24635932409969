export const userColours = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#fabed4',
  '#469990',
  '#dcbeff',
  '#9A6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9'
];

export function getLeastUsedColour(usedColours: string[], palette: string[]): string {
  const usage = new Map<string, number>(palette.map(c => [c.toLowerCase(), 0]));
  for (const colour of usedColours) {
    const key = colour.toLowerCase();
    usage.set(key, (usage.get(key) || 0) + 1);
  }

  const min = Math.min(...usage.values());
  for (const candidate of palette) {
    if (usage.get(candidate.toLowerCase()) === min) {
      return candidate;
    }
  }

  return palette[0];
}
