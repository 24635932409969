import { MaterialisedPropertyData } from '@property-folders/contract';
import { ComplexValidationFunction } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { normaliseToDayStart } from '../../../../util/formatting';

export const settlementDatesGreaterEqThan: ComplexValidationFunction = (
  nodeTree: MaterialisedPropertyData,
  updatedPath?: string[]
) => {
  if (
    (updatedPath && !(['contractSpecial', 'contractSettlement'].includes(updatedPath?.[updatedPath?.length-1]??'')))
    || !nodeTree?.contractSettlement?.onDate
  ) {
    return {
      forceUpdatesOn: [],
      errorObj: undefined
    };
  }
  const settlementDateDate = new Date(nodeTree.contractSettlement.date);
  if (isNaN(settlementDateDate.getTime())) {
    return {
      forceUpdatesOn: [],
      errorObj: { 'contractSettlement.date': ['needsValidDate'] }
    };
  }

  const financeReq = nodeTree.contractSpecial?.financeRequired;
  const saleReq = nodeTree.contractSpecial?.purchaserSaleRequired;

  let minDate = normaliseToDayStart(new Date());

  if (financeReq) {
    const finDate = new Date(nodeTree.contractSpecial?.financeDeadline);
    if (isNaN(finDate.getTime())) {
      return {
        forceUpdatesOn: [],
        errorObj: { 'contractSpecial.financeDeadline': ['needsValidDate'] }
      };
    }
    minDate = new Date(Math.max(minDate.getTime(), finDate.getTime()));
  }

  if (saleReq) {
    const saleDate = new Date(nodeTree.contractSpecial?.purchaserSaleSettlementDeadline);
    if (isNaN(saleDate.getTime())) {
      return {
        forceUpdatesOn: [],
        errorObj: { 'contractSpecial.purchaserSaleSettlementDeadline': ['needsValidDate'] }
      };
    }
    minDate = new Date(Math.max(minDate.getTime(), saleDate.getTime()));
  }

  if (settlementDateDate.getTime() < minDate.getTime()) {
    return {
      forceUpdatesOn: [],
      errorObj: { 'contractSettlement.date': ['mustBeAfterSpecialConditionDates'] }
    };
  }

  return {
    forceUpdatesOn: [],
    errorObj: undefined
  };
};