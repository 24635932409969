import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  EntityBrandFormConfig,
  EntityBrandPurchaserRegistrationConfig,
  EntityBrandSigningConfig,
  EntitySettingsBrand
} from '@property-folders/contract/yjs-schema/entity-settings';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { uuidv4 } from 'lib0/random';
import { reaformsCharcoal, reaformsOrange } from '@property-folders/common/visual';
import { YjsDocContext } from '../context/YjsDocContext';
import { useImmerYjs } from './useImmerYjs';
import { TransactionMetaData } from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { defaultFormBrandConfig } from '@property-folders/common/util/pdfgen/constants';
import { deriveAgencyDataFromEntitySettings } from '@property-folders/common/util/pdfgen/derive-agent-details-property-data-agent';

export const defaultPurchaserRegistrationBrandConfig: EntityBrandPurchaserRegistrationConfig = {
  page: {
    backgroundColour: '#fff',
    foregroundColour: reaformsCharcoal
  },
  stripe: {
    backgroundColour: '#000',
    foregroundColour: '#DDD'
  }
};

export const defaultSigningBrandConfig: EntityBrandSigningConfig = {
  button: {
    backgroundColour: reaformsOrange,
    foregroundColour: '#ffffff'
  }
};

export class BrandConfig {
  public repaintId = uuidv4();
  private config = new Map<number, EntitySettingsBrand>();

  constructor(
    items: { entityId: number, brand: EntitySettingsBrand | undefined }[]
  ) {
    for (const item of items) {
      if (!(item.entityId && item.brand)) {
        continue;
      }
      this.config.set(item.entityId, item.brand);
    }
  }

  getFormConfig(entityId: number | undefined): EntityBrandFormConfig {
    if (!entityId) return defaultFormBrandConfig;

    const brandConfig = this.config.get(entityId);
    const formColourData = brandConfig?.form || defaultFormBrandConfig;
    const agencyData = brandConfig?.agencyContact ? { agencyContact: brandConfig?.agencyContact } : null;
    return { ...formColourData, ...agencyData };
  }

  getPurchaserRegistrationConfig(entityId: number | undefined) {
    if (!entityId) return defaultPurchaserRegistrationBrandConfig;

    const brandConfig = this.config.get(entityId);
    return brandConfig?.purchaserRegistration || defaultPurchaserRegistrationBrandConfig;
  }

  getSigningConfig(entityId: number | undefined) {
    if (!entityId) return defaultSigningBrandConfig;

    const brandConfig = this.config.get(entityId);
    return brandConfig?.signing || defaultSigningBrandConfig;
  }

  getAgencyContact(entityId: number) {
    if (!entityId) return undefined;

    const brandConfig = this.config.get(entityId);
    return brandConfig?.agencyContact;
  }
}

export function useBrandConfig(): BrandConfig {
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const configs = Object.values(memberEntities || {}).map(e => {
    return {
      entityId: e.entityId,
      brand: { ...e.brand, agencyContact: deriveAgencyDataFromEntitySettings(e) }
    };
  });
  return useMemo(() => {
    return new BrandConfig(configs);
  }, [JSON.stringify(configs)]);
}

export function useEntities() {
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);

  return useMemo(() => {
    return memberEntities;
  }, []);
}

export function useEntity(entityId?: string | number) {
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);

  return entityId && memberEntities
    ? memberEntities[entityId] ?? null
    : null;
}

export function useCurrentEntity() {
  const { ydoc } = useContext(YjsDocContext);
  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { data: transMeta } = bindMetaState<TransactionMetaData>(meta => meta || {});

  return useEntity(transMeta?.entity?.id);
}
