import { Await, defer, LoaderFunctionArgs, Outlet, useLoaderData } from 'react-router-dom';
import React, { useContext } from 'react';
import { DefaultError } from '~/components/default-error';
import { FullScreenLoader } from '~/components/loaders';
import { ShortId } from '@property-folders/common/util/url';
import { LoaderResult } from '~/types';
import { mergeLoaderResults } from '~/util';
import { SetupYManagerContext, WaitForLoadedYDoc } from '@property-folders/components/context/YManagerContext';
import { GetPurchaserPortalAuthenticatedResult, YDocContentType } from '@property-folders/contract';
import * as Y from 'yjs';
import { BasicYjsContext } from '~/context/basic-yjs-context';
import { PurchaserPortalRootKey } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { PurchaserPortalApi } from '~/api';
import { LinkBuilderContext } from '@property-folders/components/context/link-builder-context';

export interface AuthenticatedRootData {
  headline: string
}

export async function purchaserAuthenticatedRootLoader({ params }: LoaderFunctionArgs) {
  const { portalIdRaw } = params as { portalIdRaw: string };
  const portalId = ShortId.toUuid(portalIdRaw);
  return defer({
    data: PurchaserPortalApi.getPurchaserPortalViewData(portalId)
  });
}

export function PurchaserAuthenticatedRootRoute() {
  const linkBuilder = useContext(LinkBuilderContext);
  const resolve = mergeLoaderResults<GetPurchaserPortalAuthenticatedResult>([
    useLoaderData() as LoaderResult
  ]);

  // if we set up the ymanager context at the authenticated root like so,
  // then all sub paths can reuse the context without it being killed/recreated between navs.
  // hopefully.
  return <React.Suspense fallback={<FullScreenLoader/>}>
    <Await resolve={resolve} errorElement={<DefaultError/>}>
      {(resolved: GetPurchaserPortalAuthenticatedResult) => {
        document.title = `${resolved.entityName} - Purchaser portal`;
        return <SetupYManagerContext
          user={{ portalUserId: resolved.portalUserId }}
          enabled={true}
          offlineEnabled={false}
          websocketEndpoint={linkBuilder.wsApi()}
          impersonator={false/* No impersonators in portal */}
        >
          <WaitForLoadedYDoc
            yDocId={resolved.yDocId!}
            yDocType={YDocContentType.PurchaserPortal}
            loadingElement={<FullScreenLoader/>}>
            {(yDoc: Y.Doc) => <BasicYjsContext
              yDoc={yDoc}
              yDocId={resolved.yDocId}
              rootKey={PurchaserPortalRootKey.Main}
              metaKey={PurchaserPortalRootKey.Main}
            >
              <Outlet/>
            </BasicYjsContext>}
          </WaitForLoadedYDoc>
        </SetupYManagerContext>;
      }}
    </Await>
  </React.Suspense>;
}
