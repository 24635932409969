import { Button } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useDispatch } from 'react-redux';
import { showNotes } from '@property-folders/common/redux-reducers/guidanceNotes';
import { notesTable } from '@property-folders/components/display/GuidanceNotes';
import './ShowGuidanceNotesButton.scss';

interface CommonProps {
  fontSize?: string;
}

interface CustomTriggerProps extends CommonProps {
  onTrigger: () => void
}

interface DispatchTriggerProps extends CommonProps {
  noteId: keyof typeof notesTable;
}

export function ShowGuidanceNotesButton(props: CustomTriggerProps | DispatchTriggerProps) {
  const dispatch = useDispatch();
  const { fontSize } = props;
  const onTrigger = 'onTrigger' in props ? props.onTrigger : () => dispatch(showNotes({ noteId: props.noteId }));

  return <Button variant='link' style={{  }} onClick={e=>{e.stopPropagation(); onTrigger();}} className='info-icon'>
    <Icon name='info' pack='material-symbols' style={{ ...(fontSize?{ fontSize }:{}) }} />
  </Button>;
}
