import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import { IAddressSearchResponse, IContactSearchSuggestions, IPropertySearchSuggestions, ISuburbSearchSuggestions } from '@property-folders/contract/rest/address-search';
import { ITitleSearchRequest } from './ITitleSearchRequest';
import { ITitleSearch, ITitleSearchResponse } from './ITitleSearchResponse';
import { IGetProprietorsResponse } from './IGetProprietorsResponse';
import { ITitleSearchDeliveryResponse } from './ITitleSearchDeliveryResponse';
import { PropertyAddress } from './LssaCommon';
import { addIfNotEmpty } from '../util/addIfNotEmpty';
import { toTitleCase } from '../util/toTitleCase';
import { AbbreviationExpander } from '../util/AbbreviationExpander';
import { cloneDeep } from 'lodash';

export type CacheListResult = { Results: { Id: string, PurchaseDate: string }[] };
// I feel like this is a janky approach, but for now it'll do I guess.
interface LinkBuilderOverride {
  restApi: (path: string) => string
}

export class PropertySearchApi {
  static getSuggestions(opts: {
    searchTerm: string,
    limitToSa?: boolean,
    gnafCentre?: string,
    international?: boolean,
    countries?: string[],
    linkBuilder?: LinkBuilderOverride
  }) {
    const {
      searchTerm,
      limitToSa = false,
      gnafCentre,
      international = false,
      countries, linkBuilder
    } = opts;

    const abortController = new AbortController();
    const pathRaw = `/property-searches/address/search?term=${encodeURIComponent(searchTerm)}${limitToSa ? '&state=SA' : ''}${gnafCentre ? `&centre=${gnafCentre}` : ''}${international ? '&international=true' : ''}${countries?.length ? '&countries='+countries.join('|') : ''}`;
    const path = linkBuilder
      ? linkBuilder.restApi(pathRaw)
      : LinkBuilder.restApi(pathRaw);

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<IPropertySearchSuggestions>(
        path,
        { signal: abortController.signal }
      )
    };
  }

  static getDetail(searchReturnedParams: {[key: string]: any}, linkBuilder?: LinkBuilderOverride) {
    const abortController = new AbortController();
    const builtQueryParams = Object.entries(searchReturnedParams).map(([key,value])=>`${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    const pathRaw = `/property-searches/address/detail?${builtQueryParams}`;
    const path = linkBuilder
      ? linkBuilder.restApi(pathRaw)
      : LinkBuilder.restApi(pathRaw);
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<IPropertySearchSuggestions>(
        path,
        { signal: abortController.signal }
      )
    };
  }

  static getSuburbSuggestions(searchTerm: string, limitToSa = false) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ISuburbSearchSuggestions>(
        LinkBuilder.restApi(`/property-searches/address/suburb/search?term=${encodeURIComponent(searchTerm)}${limitToSa ? '&state=SA' : ''}`),
        { signal: abortController.signal }
      )
    };
  }

  static getLgaSuggestions(searchTerm: string, limitToSa = false) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ISuburbSearchSuggestions>(
        LinkBuilder.restApi(`/property-searches/address/lga/search?term=${encodeURIComponent(searchTerm)}${limitToSa ? '&state=SA' : ''}`),
        { signal: abortController.signal }
      )
    };
  }

  static getHundredSuggestions(searchTerm: string, limitToSa = false) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ISuburbSearchSuggestions>(
        LinkBuilder.restApi(`/property-searches/address/hundred/search?term=${encodeURIComponent(searchTerm)}${limitToSa ? '&state=SA' : ''}`),
        { signal: abortController.signal }
      )
    };
  }

  static getIntegrationContactSuggestions(searchTerm: string) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<IContactSearchSuggestions>(
        LinkBuilder.restApi(`/property-searches/integration/contact/search?term=${encodeURIComponent(searchTerm)}`),
        { signal: abortController.signal }
      )
    };
  }

  static getIntegrationListing(listingId: string, gnafCentre: string | undefined = undefined) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<IAddressSearchResponse>(
        LinkBuilder.restApi(`/property-searches/integration/listing/${listingId}${gnafCentre ? `?centre=${gnafCentre}` : ''}`),
        { signal: abortController.signal }
      )
    };
  }

  static getIrrigationAreaSuggestions(searchTerm: string) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ISuburbSearchSuggestions>(
        LinkBuilder.restApi(`/property-searches/address/irrigation-area/search?term=${encodeURIComponent(searchTerm)}`),
        { signal: abortController.signal }
      )
    };
  }

  static getTitlesForAddress(parts: ITitleSearchRequest): ITitleSearchResponse {
    const abortController = new AbortController();
    if (!parts) {
      return {
        abort: () => undefined,
        response: Promise.resolve([])
      };
    }

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ITitleSearch>(
        LinkBuilder.restApi(`/property-searches/lssa/title?${this.toQueryString({ ...parts })}`),
        { signal: abortController.signal }
      )
    };
  }

  static getTitleDetails(reference: string) {
    if (!reference) {
      return {
        abort: () => undefined,
        response: Promise.resolve([])
      };
    }
    const abortController = new AbortController();

    reference = reference.replaceAll(' ', '_').replaceAll('/', '_');

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ITitleSearch>(
        LinkBuilder.restApi(`/property-searches/lssa/title/${reference}`),
        { signal: abortController.signal }
      )
    };
  }

  static purchase(purchaseToken: string, desiredOwner?: string) {
    if (!purchaseToken) {
      return {
        abort: () => undefined,
        response: Promise.resolve({ searches: [] })
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<{ searches: (ITitleSearchDeliveryResponse|IGetProprietorsResponse)[] }>(
        LinkBuilder.restApi(`/property-searches/lssa/purchase?ownerId=${desiredOwner ?? ''}`),
      {
        signal: abortController.signal,
        method: 'POST',
        body: JSON.stringify({ token: purchaseToken })
      }
      )
    };
  }

  static getVendorDetails(purchaseToken: string, desiredOwner?: string) {
    if (!purchaseToken) {
      return {
        abort: () => undefined,
        response: Promise.resolve({ searches: [] })
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<{ searches: IGetProprietorsResponse[] }>(
        LinkBuilder.restApi(`/property-searches/gnaf/proprietors/purchase?ownerId=${desiredOwner ?? ''}`),
        {
          signal: abortController.signal,
          method: 'POST',
          body: JSON.stringify({ token: purchaseToken })
        }
      )
    };
  }

  static getTitle(purchaseToken: string, desiredOwner?: string) {
    if (!purchaseToken) {
      return {
        abort: () => undefined,
        response: Promise.resolve({ searches: [] })
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<{ searches: ITitleSearchDeliveryResponse[] }>(
        LinkBuilder.restApi(`/property-searches/lssa/title/purchase?ownerId=${desiredOwner ?? ''}`),
        {
          signal: abortController.signal,
          method: 'POST',
          body: JSON.stringify({ token: purchaseToken })
        }
      )
    };
  }

  static getPdfFromCache(id: string) {
    return LinkBuilder.restApi(`/property-searches/lssa/cache/title/${id}/pdf`);
  }

  static listCachedTitles(title: string) {
    if (!title) {
      return {
        abort: () => undefined,
        response: Promise.resolve()
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<CacheListResult>(
        LinkBuilder.restApi(`/property-searches/lssa/cache/title?title=${title}&limit=1`),
        { signal: abortController.signal }
      )
    };
  }

  static listCachedProprietors(gnafId: string) {
    if (!gnafId) {
      return {
        abort: () => undefined,
        response: Promise.resolve()
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<CacheListResult>(
        LinkBuilder.restApi(`/property-searches/lssa/cache/proprietor?gnafId=${gnafId}&limit=1`),
        { signal: abortController.signal }
      )
    };
  }

  static getCachedTitle(id: string) {
    if (!id) {
      return {
        abort: () => undefined,
        response: Promise.resolve(null)
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<ITitleSearchDeliveryResponse>(
        LinkBuilder.restApi(`/property-searches/lssa/cache/title/${id}`),
        { signal: abortController.signal }
      )
    };
  }

  static getCachedProprietors(id: string) {
    if (!id) {
      return {
        abort: () => undefined,
        response: Promise.resolve()
      };
    }
    const abortController = new AbortController();

    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<IGetProprietorsResponse>(
        LinkBuilder.restApi(`/property-searches/lssa/cache/proprietor/${id}`),
        { signal: abortController.signal }
      )
    };
  }

  private static toQueryString(parts: {[key: string]: string | number}) {
    let queryString = '';
    for (const key of Object.keys(parts)) {
      if (!parts[key]) {
        continue;
      }

      queryString += `${queryString === '' ? '' : '&'}${key}=${parts[key]}`;
    }

    return queryString;
  }

  static stringifyPropertyAddressImmutable(addressOrig: PropertyAddress, withoutPostStateCommas: boolean = false) {
    let addressString = '';
    const address = cloneDeep(addressOrig);

    if (!address.addressType) {
      if (address.postalType || address.postalNo) {
        address.addressType =  'Postal Address';
      } else if (address.otherLine1 || address.otherLine2 || address.otherLine3 || address.otherLine4) {
        address.addressType = 'Other Address';
      } else {
        address.addressType = 'Complex Address';
      }
    }

    switch (address.addressType) {
      case 'Postal Address':
        // postalType
        // postalNo
        // suburb
        // state
        // postcode
        addressString = addIfNotEmpty(addressString, toTitleCase(address.postalType));
        addressString = addIfNotEmpty(addressString, toTitleCase(address.postalNo), ' ', ',');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.suburb), ' ', ',');
        addressString = addIfNotEmpty(addressString, address.stateTerritory, ' ');
        addressString = addIfNotEmpty(addressString, address.postcode, ' ');
        break;

      case 'Other Address':
        // otherLine1
        // otherLine2
        // otherLine3
        // otherLine4
        // country
        addressString = addIfNotEmpty(addressString, toTitleCase(address.otherLine1));
        addressString = addIfNotEmpty(addressString, toTitleCase(address.otherLine2), ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.otherLine3), ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.otherLine4), ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.country), ' ');
        break;

      default:
      case 'Street Address':
        // unitType
        // unitNo
        // streetNo
        // streetNoTo
        // streetName
        // streetType
        // streetSuffix
        // suburb
        // state
        // postcode
      case 'Complex Address': // eslint-disable-line
        // unitType
        // unitNo
        // levelType
        // levelNo
        // buildingName
        // lotNo
        // streetNo
        // streetNoTo
        // streetName
        // streetType
        // streetSuffix
        // suburb
        // state
        // postcode
        addressString = addIfNotEmpty(addressString, toTitleCase(AbbreviationExpander.SubUnitType(address.unitType)), '', ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.unitNo), '', '/');
        addressString = addIfNotEmpty(addressString, toTitleCase(AbbreviationExpander.FloorType(address.floorLevelType)), ' ', ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.floorLevelNo), '', '/');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.buildingName), ' ', ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.lotNo), ' Lot ', ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.streetNo), '');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.streetNoTo), '-');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.streetName), ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(AbbreviationExpander.StreetType(address.streetType)), ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.streetSuffix), ' ');
        addressString = addIfNotEmpty(addressString, toTitleCase(address.suburb), ', ');
        addressString = addIfNotEmpty(addressString, address.stateTerritory, withoutPostStateCommas ? ' ' : ', ', );
        addressString = addIfNotEmpty(addressString, address.postcode, withoutPostStateCommas ? ' ' : ', ');
        break;
    }

    return addressString.trim();
  }

  /**
   * @deprecated this function mutates the address object, use stringifyPropertyAddressImmutable
   * @param address
   */
  static stringifyPropertyAddress(address: PropertyAddress) {
    if (!address.addressType) {
      if (address.postalType || address.postalNo) {
        address.addressType =  'Postal Address';
      } else if (address.otherLine1 || address.otherLine2 || address.otherLine3 || address.otherLine4) {
        address.addressType = 'Other Address';
      } else {
        address.addressType = 'Complex Address';
      }
    }

    return this.stringifyPropertyAddressImmutable(address);
  }
}
