export interface Point {
  x: number,
  y: number
}

export interface PageDims {
  width: number;
  height: number;
  degrees: number;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * When translating between coordinate spaces, sometimes you need to reflect an axis.
 * For example, we're describing points based on top/left (common in html/canvas/drawing)
 * But PDF points are described based on bottom/left.
 * So, when converting points between the coordinate systems, the Y axis is reflected
 */
export interface AxisReflection {
  x?: boolean;
  y?: boolean;
}

export const HtmlPdfReflection: AxisReflection = { y: true };
export const NoReflection: AxisReflection = {};

export class CoordinateMath {
  public static scaleValue(fromValue: number, fromScale: number, toScale: number): number {
    return (fromValue / fromScale) * toScale;
  }

  public static normalisePage(page: PageDims): PageDims {
    return {
      width: Math.abs(page.width),
      height: Math.abs(page.height),
      degrees: this.normaliseDegrees(page.degrees)
    };
  }

  public static normaliseDegrees(angle: number): number {
    if (angle < 0) {
      return ((angle % -360) + 360) % 360;
    } else {
      return angle % 360;
    }
  }

  public static rotate90DegIncrementsOnAxis(degreesRaw: number, point: Point, axis: Point): Point {
    const degrees = this.normaliseDegrees(degreesRaw);
    if ((degrees % 90) !== 0) throw new Error('degrees must be divisible by 90');
    if (degrees === 0) return point;

    // subtract axis, rotate around 0,0, add axis
    const x = point.x - axis.x;
    const y = point.y - axis.y;

    // note: rotations around 0,0 are shown in comments
    switch (degrees) {
      case 90:
        // return { x: point.y, y: -point.x };
        return {
          x: axis.x + y,
          y: axis.y - x
        };
      case 180:
        // return { x: -point.x, y: -point.y };
        return {
          x: axis.x - x,
          y: axis.y - y
        };
      case 270:
        // return { x: -point.y, y: point.x };
        return {
          x: axis.x - y,
          y: axis.y + x
        };
      case 0:
      default:
        return point;
    }
  }

  public static changeOrigin(point: Point, newOrigin: Point): Point {
    return {
      x: point.x - newOrigin.x,
      y: point.y - newOrigin.y
    };
  }

  public static rotate90(point: Point): Point {
    return {
      x: point.y,
      y: -point.x
    };
  }

  public static rotate90Rect(point: Point, axis: Point): Point {
    const p = this.changeOrigin(point, axis);
    const rot = this.rotate90(p);
    return this.changeOrigin(rot, { x: -axis.y, y: -axis.x });
  }

  /**
   * rotate 90 degrees counter-clockwise around 0,0, (x,y) becomes (y,-x); therefore:
   * case 0: (x,y) => (x,y)
   * case 90: (x,y) => (y,-x)
   * case 180: (x,y) => (y,-x) => (-x,-y)
   * case 270: (x,y) => (y,-x) => (-x,-y) => (-y,x)
   */
  public static rotate(degrees: number, point: Point): Point {
    const normalised = this.normaliseDegrees(degrees);
    switch (normalised) {
      case 90:
        return {
          x: point.y,
          y: -point.x
        };
      case 180:
        return {
          x: -point.x,
          y: -point.y
        };
      case 270:
        return {
          x: -point.y,
          y: point.x
        };
      case 0:
      default:
        return point;
    }
  }

  public static counterRotate(degrees: number, point: Point): Point {
    return this.rotate(-degrees, point);
  }

  public static getCentrePoint(rect: { width: number, height: number }): Point {
    // the 0.5 subtraction is kinda important to avoid relative points being off by 1 in some rotations.
    // here's why:
    // say your rect is 4x4 pixels
    // [ ][ ][ ][ ]
    // [ ][ ][ ][ ]
    // [ ][ ][ ][ ]
    // [ ][ ][ ][ ]
    // then its mid point is not A(1,1), nor is it B(2,2)
    // [ ][ ][ ][ ]
    // [ ][ ][B][ ]
    // [ ][A][ ][ ]
    // [ ][ ][ ][ ]
    // it's actually halfway between A and B, which is 1.5, 1.5.
    // similarly, for a rect with odd dimensions, e.g. 5x5:
    // [ ][ ][ ][ ][ ]
    // [ ][ ][ ][ ][ ]
    // [ ][ ][X][ ][ ]
    // [ ][ ][ ][ ][ ]
    // [ ][ ][ ][ ][ ]
    // the mid point is not (2.5,2.5) but X(2,2)
    return {
      x: (rect.width / 2) - 0.5,
      y: (rect.height / 2) - 0.5
    };
  }

  public static orientToPage(point: Point, pageRaw: PageDims, reflect: AxisReflection = NoReflection) {
    const page = this.normalisePage(pageRaw);
    const degrees = page.degrees;
    // the centre point of the page if it were considered to be "upright"
    // need to relate the output to this origin,
    // because drawing to the pdf will be from an "upright" coordinate system
    const uprightCenter = this.getCentrePoint(page);
    // but obviously the input point is relative to some rotation, so we need to
    // find the centre point relative to the rotated coordinate system too.
    const rotatedCenter = (degrees === 90 || degrees === 270)
      ? { x: uprightCenter.y, y: uprightCenter.x }
      : uprightCenter;

    const relativePoint = this.applyReflection(
      this.changeOrigin(point, rotatedCenter),
      reflect
    );
    const rotatedPoint = this.counterRotate(degrees, relativePoint);
    return this.changeOrigin(rotatedPoint, { x: -uprightCenter.x, y: -uprightCenter.y });
  }

  public static applyReflection(point: Point, reflect?: AxisReflection): Point {
    return {
      x: reflect?.x ? -point.x : point.x,
      y: reflect?.y ? -point.y : point.y
    };
  }

  /**
   * given a rectangle with negative width/height, return the equivalent rectangle with positive width/height.
   * if an angle is provided, then x,y will be adjusted so that the rotated rectangle will appear in the same place as the original rectangle.
   * (because the rotation is applied with x,y as the origin)
   */
  public static absRect({ x, y, width, height }: Rect, degreesRaw = 0): Rect {
    // currently supports -height
    // future: (or never? because pdfs don't flip the x-axis afaik) support -width
    if (width >= 0 && height >= 0) {
      return {
        x, y, width, height
      };
    }
    const negW = width < 0;
    const negH = height < 0;
    const xP = negW ? x + width : x;
    const yP = negH ? y + height : y;
    const widthP = negW ? -width : width;
    const heightP = negH ? -height : height;

    const degrees = this.normaliseDegrees(degreesRaw);
    switch (degrees) {
      case 90: {
        const offsetX = negH ? -height : 0;
        const offsetY = negH ? -height : 0;
        return {
          x: xP + offsetX,
          y: yP + offsetY,
          width: widthP,
          height: heightP
        };
      }
      case 180:{
        const offsetX = 0;
        const offsetY = negH ? -height * 2 : 0;
        return {
          x: xP + offsetX,
          y: yP + offsetY,
          width: widthP,
          height: heightP
        };
      }
      case 270: {
        const offsetX = negH ? height : 0;
        const offsetY = negH ? -height : 0;
        return {
          x: xP + offsetX,
          y: yP + offsetY,
          width: widthP,
          height: heightP
        };
      }
      default: {
        return {
          x: xP,
          y: yP,
          width: widthP,
          height: heightP
        };
      }
    }
  }
}
