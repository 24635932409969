import { Await, useAsyncValue, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { Button, Card, Dropdown, Modal, Table } from 'react-bootstrap';
import { DefaultPageLayout } from '~/components/default-page-layout';
import { ContentCard } from '~/components/content-card';
import { LoaderResult } from '~/types';
import { DocumentTypePP, GetPurchaserPortalResult } from '@property-folders/contract';
import { DefaultError } from '~/components/default-error';
import { useAuth } from '~/context/auth-context';
import { FullScreenLoader } from '~/components/loaders';
import { mergeLoaderResults } from '~/util';
import { DocumentDescriptions } from '@property-folders/common/data-and-text/descriptions';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { ShortId } from '@property-folders/common/util/url';

function download(url: string, name: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function PurchaserUnauthenticatedIntroductionRoute() {
  const resolve = mergeLoaderResults([
    useRouteLoaderData('unauthenticated-root') as LoaderResult
  ]);

  return <React.Suspense fallback={<FullScreenLoader/>}>
    <Await
      resolve={resolve}
      errorElement={<DefaultError/>}
    >
      <PurchaserUnauthenticatedIntroductionRouteInner/>
    </Await>
  </React.Suspense>;
}

function PurchaserUnauthenticatedIntroductionRouteInner() {
  const { sessionInfo } = useAuth();
  const navigate = useNavigate();
  const data = useAsyncValue() as GetPurchaserPortalResult;
  const { portalIdRaw } = useParams();
  const [modalDocumentType, setModalDocumentType] = useState<DocumentTypePP|null>(null);

  const documents = useMemo(() => {
    return data.sharedDocuments.filter(d => !d.old);
  }, [data]);
  const isXs = useMediaQuery({ maxWidth: BP_MINIMA.sm });

  const registerButtonText = sessionInfo?.portalId === data.portalId
    ? sessionInfo?.userState === 'new'
      ? 'Resume Registration'
      : sessionInfo?.userState === 'verifying'
        ? 'Resume Verification'
        : 'Register'
    : 'Register';

  function processDocTypeQs(documentType?: DocumentTypePP | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (typeof documentType !== 'string') {
      return '';
    }
    return `?doctype=${encodeURIComponent(documentType)}`;
  }

  const loginHandler = (documentType?: DocumentTypePP | React.MouseEvent<HTMLButtonElement, MouseEvent>) => navigate(portalIdRaw ? `/purchaser/${portalIdRaw}/login${processDocTypeQs(documentType)}` : `login${processDocTypeQs(documentType)}`);
  const registerHandler = (documentType?: DocumentTypePP | React.MouseEvent<HTMLButtonElement, MouseEvent>) => navigate(`register${processDocTypeQs(documentType)}`);
  const closeDialogHandler = () => setModalDocumentType(null);
  const modalItem = documents.find(d=>d.documentType === modalDocumentType);
  const modalDesc = DocumentDescriptions[modalDocumentType];
  const modalName = (isXs ? modalDesc?.mobileTitle : null) ?? modalDesc?.title ?? modalItem?.name;
  return <DefaultPageLayout
    title='Purchaser portal'
    afterTitle={[<Button key='login' variant='outline-secondary' onClick={loginHandler}>Login</Button>]}
    subtitle={[<h5 key='subtitle'>{data.headline}</h5>]}
    agencyCssOverrides={data.cssOverrides}
  >
    <Modal show={!!modalDocumentType} onHide={closeDialogHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Registration required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Accessing this {modalName} requires you to login.
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant='outline-secondary' className='me-2' onClick={()=>loginHandler(modalDocumentType)}>Login</Button>
          <Button variant='primary' onClick={()=>registerHandler(modalDocumentType)}>{registerButtonText}</Button>
        </div>
      </Modal.Footer>
    </Modal>
    <ContentCard agencyHighlights={true}>
      <Card.Header>
        <h4>Welcome to the Purchaser portal</h4>
      </Card.Header>
      <Card.Body>
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>

              <th style={{ width: '16px', maxWidth: '16px' }}></th>
            </tr>
          </thead>
          <tbody>

            {documents.map((item, idx) => {
              const desc = DocumentDescriptions[item.documentType];
              const name = (isXs ? desc?.mobileTitle : null) ?? desc?.title ?? item.name;
              const summary = (isXs ? desc?.mobileSummary : null) ?? desc?.summary;
              return <tr key={`doc-${idx}`} className='cursor-pointer'>
                <td className='border-0' onClick={() => {
                  if (item.loginRequired) {
                    setModalDocumentType(item.documentType);
                    return;
                  }
                  navigate(`preview/${ShortId.fromUuid(item.documentId)}`);
                }}>
                  <b>{name}</b>
                  <div className='text-secondary'><small>{summary}</small></div>
                </td>

                <td style={{ borderBottom: '0' }}>
                  {(item.loginRequired)
                    ? <div className='d-flex justify-content-center pt-2' title="Requires registration" ><Icon name='lock'/></div>
                    : <Dropdown align='end'>
                      <Dropdown.Toggle className='clear-after' variant='white'>
                        <Icon name='more_vert'/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate(`preview/${ShortId.fromUuid(item.documentId)}`)}>View</Dropdown.Item>
                        <Dropdown.Item onClick={() => download(item.url, item.fileName)}>Download</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                </td>
              </tr>;
            })}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className='d-flex flex-column align-items-end gap-2'>
        <div>
          <Button onClick={registerHandler}>
            {registerButtonText}
          </Button>
        </div>
        <div>
          Already registered? <Card.Link className='cursor-pointer' onClick={loginHandler}>Login</Card.Link>
        </div>
      </Card.Footer>
    </ContentCard>
  </DefaultPageLayout>;
}
