import React, { ReactNode } from 'react';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useMatches, useNavigate } from 'react-router-dom';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useAuth } from '~/context/auth-context';

interface DefaultPageLayoutParams {
  title: string;
  afterTitle?: React.ReactFragment;
  subtitle: React.ReactFragment;
  afterSubtitle?: React.ReactFragment;
  agencyCssOverrides?: Record<string, string>;
  noPad?: boolean,
  heroElement?: ReactNode
}

export function DefaultPageLayout({
  children,
  title,
  afterTitle,
  subtitle,
  afterSubtitle,
  agencyCssOverrides,
  noPad,
  heroElement

}: React.PropsWithChildren<DefaultPageLayoutParams>) {
  const style = applyCssVariables({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, agencyCssOverrides);

  const { sessionInfo } = useAuth();
  const noLogoutEnableAfterTitle = !sessionInfo?.authenticated;

  const matches = useMatches();
  const navigate = useNavigate();

  const handleLogout = () => {
    const purchaserRoot = matches.find(m=>m.id === 'purchaser-root');
    if (!purchaserRoot) {
      console.warn('Couldn\'t find expected navigation target');
      return;
    }
    navigate(`${purchaserRoot.pathname}/logout`);
  };

  return <div style={style}>
    <div className='d-flex flex-column justify-content-between flex-grow-0 w-100 bg-white px-2 px-sm-4 py-2'>
      <div className='d-flex flex-row align-items-center justify-content-between w-100'>
        <h1>{title}</h1>
        {noLogoutEnableAfterTitle && !!afterTitle && <div className='d-flex flex-row gap-2'>{afterTitle}</div>}
        {!noLogoutEnableAfterTitle && <div className='d-flex flex-row gap-2'><AsyncButton variant='outline-secondary' onClick={handleLogout}>Logout</AsyncButton></div>}
      </div>
      <div>
        {subtitle}
        {!!afterSubtitle && <div className='d-flex flex-row gap-2'>{afterSubtitle}</div>}
      </div>
    </div>
    <div className='overflow-auto flex-grow-1'>
      {heroElement}
      <div className={clsJn(noPad ? 'p-0' : 'py-4 px-md-5', 'gap-4 d-flex flex-column')}>
        {/*stop chrome autocomplete*/}
        <form style={{ display: 'none' }}></form>
        {children}
      </div>
    </div>
  </div>;
}

function applyCssVariables(base: React.CSSProperties, additional?: Record<string, string>): React.CSSProperties {
  return {
    ...base,
    ...additional
  } as React.CSSProperties;
}
