import { ContentCard } from '~/components/content-card';
import { Button, Card } from 'react-bootstrap';
import React, { ReactNode } from 'react';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { PurchaserParty } from '@property-folders/contract';
import { PartyAuthorityInput } from '@property-folders/components/dragged-components/form/PartyAuthorityInput';
import { purchaserTypeOptions } from '@property-folders/common/util/pdfgen/constants';
import { PurchaserPortalData } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { useForm } from '@property-folders/components/hooks/useForm';

export function OfferOtherPurchasers({ footer }: { footer: ReactNode | undefined}) {
  useForm(); // Run validation on this page. Cannot be hoisted into authenticated offer as it requires the FormContext set up for each step
  const { value: root } = useLightweightTransaction<PurchaserPortalData>({ myPath: '' });
  const { value: primaryPurchaser } = useLightweightTransaction({ myPath: 'primaryPurchaser' }) as { value: string | undefined };
  const { value: purchasers, handleInsert } = useTransactionField<PurchaserParty[]>({ myPath: 'purchasers' });
  const nonPrimaryPurchaserIds = (purchasers || []).filter(p => p.id !== primaryPurchaser).map(p => p.id);
  const gnaf = root?.saleAddrs?.[0].gnaf ? { id: root?.saleAddrs?.[0].gnaf } : undefined;

  return <ContentCard>
    <Card.Header className='d-flex flex-row justify-content-between align-items-center'>
      <h4>Other purchasers</h4>
      <Button variant='outline-secondary' onClick={() => handleInsert({ })}>Add Purchaser</Button>
    </Card.Header>
    <Card.Body>
      {!nonPrimaryPurchaserIds.length && <Card.Text>This offer is currently being made by a sole purchaser. You may add other purchasers to your offer.</Card.Text>}
      {nonPrimaryPurchaserIds.map((purchaserId, idx) => {
        return <PartyAuthorityInput
          myIndex={idx + 1}
          thisLevel={1}
          hideOnTitleField={true}
          primaryIdAbsPath={'primaryPurchaser'}
          partyLabel='Purchaser'
          typeOptions={purchaserTypeOptions}
          myPath={`purchasers.[${purchaserId}]`}
          hidePrimaryContactCheckbox={true}
          gnaf={gnaf}
        />;
      })}
    </Card.Body>
    <Card.Footer className='d-flex flex-row justify-content-end gap-2'>
      {footer}
    </Card.Footer>
  </ContentCard>;
}
