import { useEffect, useRef } from 'react';

export function useTimeout(callback: () => void, delay: number | null, currentValue: any) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }
    if (delay !== null && currentValue !== null && currentValue !== undefined) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay, currentValue]);
}
