import React from 'react';

export function SimpleCrumbs({
  children,
  flexMode
}: React.PropsWithChildren & {flexMode?: boolean}) {
  const items = React.Children
    .toArray(children)
    .filter(x => !!x);
  const last = items.at(-1);
  return <span className={flexMode ? 'd-flex flex-row gap-2' : ''}>
    {items.map((item, index) => {
      if (items.length === 1) {
        return item;
      }
      return item === last || items.length === 1
        ? <div className='d-inline-block'>{item}</div>
        : <div key={index} className='d-inline-block'>
          {item}
          <span>{!flexMode ? ' ' : ''}/{!flexMode ? ' ' : ''}</span>
        </div>;
    })}
  </span>;
}
