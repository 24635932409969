/*
    cyrb53 (c) 2018 bryc (github.com/bryc)
    A fast and simple hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
    Public domain. Attribution appreciated.
*/
import { Predicate } from './predicate';

function cyrb53(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
  h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1>>>0);
}

export function getAgentThumbnailStyle(name: string, agentId?: number) {
  const strToHash = [
    name,
    agentId?.toString()
  ].filter(Predicate.isNotNull).join('');

  // mainly using this because the author claims it has a better distribution compared to
  // the traditional <char>*31 (or (char << 5) - char) accumulated hash code function,
  // while maintaining perf
  // agent id may not be necessary
  const hue = cyrb53(strToHash) % 360;

  return {
    color: 'white',
    backgroundColor: `hsl(${hue}, 60%, 40%)`
  };
}
