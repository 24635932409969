export * from './ws';
export * from './awareness';
export * from './rest/auth';
export * from './rest/signing';
export * from './rest/clauses';
export * from './rest/portal';
export * from './property';
export * from './property/enum';
export * from './property/options';
export * from './sync';
export * from './rest/lookup';

export enum ContentType {
  Text = 'text/plain',
  Pdf = 'application/pdf',
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Zip = 'application/zip',
  Json = 'application/json',
  OctetStream = 'application/octet-stream',
  Html = 'text/html'
}

export enum ContentEncoding {
  Brotli = 'br',
  Gzip = 'gzip',
}

export interface IFileProvider {
  getFile(id: string): Promise<Uint8Array | undefined>;
  getBlob(id: string): Promise<Blob | undefined>;
}

export const DefaultTimeZone = 'Australia/Adelaide';

export type Maybe<T> = T | undefined;
