import { PendingUpdates } from './pendingUpdates';
import { StorageDexie } from './db';

export class UserPreferencesDoc {
  private static oldKey(agentId: number) {
    return `user-prefs_${agentId}`;
  }

  private static async deleteKeySafe(agentId: number, key?: string) {
    if (!key) return;
    if (await PendingUpdates.isPending(key, agentId)) return;

    await this.deleteKeyForce(key);
  }

  private static async deleteKeyForce(key?: string) {
    if (!key) return;
    await StorageDexie.delete(key);
    await PendingUpdates.unsetForId(key);
  }

  static async deleteSafe(agentId: number, agentUuid: string | undefined) {
    await this.deleteKeySafe(agentId, this.oldKey(agentId));
    await this.deleteKeySafe(agentId, agentUuid);
  }

  static async deleteForce(agentId: number, agentUuid: string | undefined) {
    await this.deleteKeyForce(this.oldKey(agentId));
    await this.deleteKeyForce(agentUuid);
  }
}
