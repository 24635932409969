import { z } from 'zod';
import { AddressPartsSchema, FileRef, FormInstance, MaterialisedPropertyData } from '../property';

export enum ManifestType {
  None = 0,
  FormInstance = 1,
  FormInstancePlusMarketingData = 2,
  SigningComplete = 3,
  FormInstancePlusMarketingAndSnapshot = 4
}

export const SigningMarketingDataSchema = z.object({
  landType: z.number(),
  transactionType: z.unknown(),
  documentTemplateName: z.string(),
  propertyAddress: z.array(AddressPartsSchema)
});

export type SigningMarketingData = z.infer<typeof SigningMarketingDataSchema>;

export interface IFormInstancePlusMarketingData {
  formInstance: FormInstance,
  marketingData: SigningMarketingData,
  bucketIngestForceRetry?: boolean
}

export type IFormInstancePlusMarketingAndSnapshotData = IFormInstancePlusMarketingData & {
  accompanying: {
    [Property in keyof AccompanyingObjects]: {
      fileId: string
    }
  }
};

export interface ISigningCompleteData {
  propertyId: string,
  signingSessionId: string,
  formCode: string,
  formId: string,
  headline: string,
}

export type AccompanyingObjectData = {
  [Property in keyof AccompanyingObjects]: any;
};

export type AccompanyingObjects = {
  propertyDataSnapshot?: MaterialisedPropertyData
  coverSheetPdfRef?: FileRef
};

export interface IManifestData {
  manifestType: ManifestType;
  data: unknown;
}

export type ManifestData =
  | { manifestType: ManifestType.None }
  | { manifestType: ManifestType.FormInstance, data: FormInstance }
  | { manifestType: ManifestType.FormInstancePlusMarketingData, data: IFormInstancePlusMarketingData }
  | { manifestType: ManifestType.SigningComplete, data: ISigningCompleteData }
  | { manifestType: ManifestType.FormInstancePlusMarketingAndSnapshot, data: IFormInstancePlusMarketingAndSnapshotData };
