import { Maybe } from '../types/Utility';
import { SessionInfo } from '@property-folders/contract';
import { PendingUpdates } from '../offline/pendingUpdates';
import { OfflineProperties } from '../offline/offlineProperties';
import { UserPreferencesDoc } from '../offline/userPreferencesDoc';
import { OfflineSession } from '../offline/offlineSession';

export type ExpiryMode = 'synced' | 'unsynced';

export function calcExpiryMode(session?: SessionInfo): Maybe<ExpiryMode> {
  const now = Date.now();
  return session?.expUnSynced && session.expUnSynced < now
    ? 'unsynced'
    : session?.expSynced && session.expSynced < now
      ? 'synced'
      : undefined;
}

export async function getUserExpiryMode(agentId: number): Promise<Maybe<ExpiryMode>> {
  const session = await OfflineSession.getAgent(agentId);
  return calcExpiryMode(session);
}

async function cleanupUserSynced(agentId: number, agentUuid: string | undefined) {
  const agentProperties = await OfflineProperties.all(agentId);
  for (const property of agentProperties) {
    if (await PendingUpdates.isPending(property.id, agentId)) {
      continue;
    }

    await OfflineProperties.delete(property.id, agentId);
  }

  await UserPreferencesDoc.deleteSafe(agentId, agentUuid);
}

async function cleanupUserUnsynced(agentId: number, agentUuid: string | undefined) {
  const agentProperties = await OfflineProperties.all(agentId);
  for (const property of agentProperties) {
    await OfflineProperties.delete(property.id, agentId);
  }

  await PendingUpdates.unsetForAgent(agentId);
  await UserPreferencesDoc.deleteForce(agentId, agentUuid);
}

export async function canDeleteUser(agentId: number) {
  const session = await OfflineSession.getAgent(agentId);
  if (!session) {
    return false;
  }

  const hasPending = await PendingUpdates.hasPending(agentId);
  const hasProperties = await OfflineProperties.count(agentId);
  return !(hasPending && hasProperties);
}

export async function cleanupUser(agentId: number, agentUuid: string | undefined, forceExpiry?: ExpiryMode) {
  const expiryModeCandidate = await getUserExpiryMode(agentId);

  // if the user is eligible for unsynced expiry then we should take that over everything else
  const expiryMode = expiryModeCandidate === 'unsynced'
    ? expiryModeCandidate
    : forceExpiry
      ? forceExpiry
      : expiryModeCandidate;

  if (!expiryMode) {
    return;
  }

  switch (expiryMode) {
    case 'synced':
      await cleanupUserSynced(agentId, agentUuid);
      if (await canDeleteUser(agentId)) {
        await OfflineSession.deleteAgent(agentId);
      }
      break;
    case 'unsynced':
      await cleanupUserUnsynced(agentId, agentUuid);
      await OfflineSession.deleteAgent(agentId);
      break;
  }
}

export async function cleanupOtherSessions() {
  const otherSessions = await OfflineSession.nonCurrentSessions();
  for (const session of otherSessions) {
    await cleanupUser(session.agentId, session.agentUuid, 'synced');
  }
}
