import { Maybe } from '../types/Utility';
import { db } from './db';

export interface ITaskLastExecution {
  id: TaskType;
  timestamp: number;
}

export enum TaskType {
  Unknown = 0,
  YManagerGarbageCollect = 1
}

export class Tasks {
  static async getLastExecution(id: TaskType): Promise<Maybe<number>> {
    return (await db.taskLastExecution.get(id))?.timestamp;
  }

  static async setLastExecution(id: TaskType, timestamp: number) {
    await db.taskLastExecution.put({
      id,
      timestamp
    });
  }
}
