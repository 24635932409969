import { LinkButton } from '../LinkButton';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FallbackProps } from '@property-folders/components/telemetry/ErrorBoundary';

export function FallbackModal(props: FallbackProps & {onClose?: ()=>void, show?: boolean}) {
  const [internalShow, setInternalShow] = useState(true);

  function handleClose () {
    setInternalShow(false);
    props.onClose?.();
    props.resetErrorBoundary();
  }

  const show = props.show ?? internalShow;
  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton><Modal.Title>Error</Modal.Title></Modal.Header>
    <Modal.Body>
      <div>Error loading dialog. <LinkButton onClick={props.resetErrorBoundary}>Retry?</LinkButton></div>

    </Modal.Body>
    <Modal.Footer><Button variant='light' onClick={handleClose}>Close</Button></Modal.Footer>
  </Modal>;
}
