import './main.scss';
import 'material-icons/iconfont/material-icons.css';
import 'material-symbols/outlined.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter, NavigateProps, Outlet, Params,
  RouterProvider
} from 'react-router-dom';
import {
  ProtectedRoute,
  Root
} from '~/routes';
import {
  purchaserAuthenticatedRootLoader,
  PurchaserAuthenticatedRootRoute,
  purchaserUnauthenticatedRootLoader,
  PurchaserUnauthenticatedRootRoute,
  PurchaserUnauthenticatedIntroductionRoute,
  PurchaserUnauthenticatedRegistrationRoute,
  PurchaserAuthenticatedViewRoute,
  purchaserUnauthenticatedRegistrationLoader
} from '~/routes/purchaser';
import { DefaultError } from '~/components/default-error';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PurchaserUnauthenticatedLoginRoute } from './routes/purchaser/unauthenticated-login';
import { store } from '@property-folders/web/src/redux/store';
import { hideNotes, REDUCER_NAME as REDUX_NOTES_NAME } from '@property-folders/common/redux-reducers/guidanceNotes';
import { notesTable } from '@property-folders/components/display/GuidanceNotes';
import { SetLinkBuilderContext } from '@property-folders/components/context/link-builder-context';
import { Offcanvas } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { ShortId } from '@property-folders/common/util/url';
import { AuthApi, PortalType } from '@property-folders/common/client-api/auth';
import { PurchaserAuthenticatedOfferRoute } from '~/routes/purchaser/authenticated-offer';
import { PortalSessionInfoResult } from '@property-folders/contract';
import { PurchaserAuthenticatedDocumentRoute } from '~/routes/purchaser/authenticated-document';
import { PurchaserUnauthenticatedDocumentRoute } from './routes/purchaser/unauthenticated-document-preview';
import { LogoutMasker } from './routes/purchaser/logout-masker';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function getUnauthenticatedDeepLinkedNavigateProps(params: Params<string>, pathname: string): NavigateProps {
  return { to: `/purchaser/${params.portalIdRaw}/login?returnPath=${pathname}` };
}

function sessionPortalIdMatches(sessionInfo: PortalSessionInfoResult, params: Params<string>) {
  return sessionInfo.portalId === ShortId.toUuid(params.portalIdRaw);
}

const invalidateSession = (portalType: PortalType) => AuthApi
  .invalidatePortalSessionInfo(queryClient, portalType)
  .catch(console.error);
const invalidatePurchaserSession = () => invalidateSession('purchaser');

const router = createBrowserRouter([{
  path: '/',
  element: <Outlet />,
  errorElement: <DefaultError/>,
  id: 'root',
  children: [
    {
      path: 'purchaser/:portalIdRaw',
      id: 'purchaser-root',
      element: (<SetLinkBuilderContext mode='purchaser-portal'>
        <Root type={'purchaser'} />
      </SetLinkBuilderContext>),
      children: [
        {
          path: 'logout',
          id: 'logout',
          element: <LogoutMasker invalidatePurchaserSession={invalidatePurchaserSession}/>
        },
        {
          path: 'view',
          id: 'authenticated-root',
          loader: purchaserAuthenticatedRootLoader,
          element: (<ProtectedRoute redirect={getUnauthenticatedDeepLinkedNavigateProps} sessionCheck={sessionPortalIdMatches}>
            <PurchaserAuthenticatedRootRoute/>
          </ProtectedRoute>),
          children: [
            {
              path: '',
              element: <PurchaserAuthenticatedViewRoute/>
            },
            {
              path: 'offer/:offerIdRaw/',
              element: <PurchaserAuthenticatedOfferRoute/>
            },
            {
              path: 'document/:documentIdRaw/',
              element: <PurchaserAuthenticatedDocumentRoute/>
            }
          ]
        },
        {
          path: '',
          loader: purchaserUnauthenticatedRootLoader,
          id: 'unauthenticated-root',
          element: <PurchaserUnauthenticatedRootRoute/>,
          children: [
            {
              path: '',
              element: <PurchaserUnauthenticatedIntroductionRoute/>
            },
            {
              path: 'login/',
              element: <PurchaserUnauthenticatedLoginRoute/>
            },
            {
              path: 'preview/:documentIdRaw/',
              element: <PurchaserUnauthenticatedDocumentRoute />
            },
            {
              path: 'register/',
              loader: (opts) => {
                return purchaserUnauthenticatedRegistrationLoader({
                  ...opts,
                  invalidateSession: invalidatePurchaserSession
                });
              },
              element: <PurchaserUnauthenticatedRegistrationRoute/>
            },
            {
              path: 'invitation/:inviteToken',
              element: <PurchaserUnauthenticatedIntroductionRoute/>
            },
            {
              path: 'invitation/:inviteToken/register',
              loader: (opts) => {
                return purchaserUnauthenticatedRegistrationLoader({
                  ...opts,
                  invalidateSession: invalidatePurchaserSession
                });
              },
              element: <PurchaserUnauthenticatedRegistrationRoute/>
            },
            {
              path: 'invitation/:inviteToken/preview/:documentIdRaw/',
              element: <PurchaserUnauthenticatedDocumentRoute />
            }
          ]
        }
      ]
    }
  ]
}], {
  basename: '/'
});

function NotesDisplay() {
  const dispatch = useDispatch();
  const { show, noteId } = useSelector((state: any) => state?.[REDUX_NOTES_NAME]) || {};
  const { title, body } = notesTable[noteId] || {};
  return <Offcanvas
    show={show}
    onHide={() => dispatch(hideNotes())}
    placement="end"
    className="guidance-notes"
    backdropClassName="guidance-notes-backdrop"
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title><span className="h4">{title}</span></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className="lead guidance-spacing">
      {body}
    </Offcanvas.Body>
  </Offcanvas>;
}

/**
 * target lastpass things and neutralise them
 * for example, lastpass appends an element to body which can screw with page size calculations
 */
function UnAliveLastPass() {
  useEffect(() => {
    const target = document.querySelector('body');
    if (!target) return;
    const observer = new MutationObserver((list, obs) => {
      for (const record of list) {
        for (const node of record.addedNodes) {
          try {
            const asHtmlElement = (node as HTMLElement);
            if ('dataset' in asHtmlElement) {
              if (asHtmlElement.dataset['lastpassRoot'] != null) {
                asHtmlElement.style.position = 'fixed';
              }
            }
          } catch { /*no-op*/ }
        }
      }
    });

    observer.observe(target, { childList: true });
    return () => {
      observer.disconnect();
    };
  }, [document]);
  return <></>;
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RouterProvider router={router}/>
      <NotesDisplay/>
      <UnAliveLastPass />
    </Provider>
  </QueryClientProvider>
);
