export type IDObject = { id: string, _removedMarker?: boolean, _restoredMarker?: boolean };
export type IDObjectDefault = { id: string, _removedMarker?: boolean, _restoredMarker?: boolean, [otherKey: string]: any};

export enum DataGeneration {
  CarriedOver,
  Removed,
  Added,
  Restored,
  Replaced
}

export type DetermineReplacedRemovedLockedResult<TListItem extends IDObject> = { id: string, data: TListItem } & (
  | { state: DataGeneration.CarriedOver, oldData: TListItem }
  | { state: DataGeneration.Replaced, oldData: TListItem, replacedBy: string }
  | { state: DataGeneration.Added | DataGeneration.Removed | DataGeneration.Restored }
);
