import { ContractPriceType, GstFreeReason, MaterialisedPropertyData } from '@property-folders/contract';
import { Predicate } from '../../../predicate';
import { fieldFocusMap, fieldsSpacing, generateCheckboxText, itemSection, itemSubsection, spaceStackLinesSideEffect } from '..';

export function calculateGstConditionsDisplay(
  { vendorGst }: Pick<MaterialisedPropertyData, 'vendorGst'>,
  { inputTaxed, taxableSupply, marginApplied, gstIncluded }: Pick<ContractPriceType, 'inputTaxed' | 'taxableSupply' | 'marginApplied' | 'gstIncluded'>
) {
  const isInputTaxed = vendorGst ? inputTaxed : undefined;
  const isTaxableSupply = vendorGst ? Predicate.boolFalse(inputTaxed) && taxableSupply : undefined;
  const isTaxableSupplyNoMarginScheme = isTaxableSupply ? Predicate.boolFalse(marginApplied) : undefined;
  const isTaxableSupplyWithMarginScheme = isTaxableSupply ? marginApplied : undefined;
  const isTaxableSupplyGstIncluded = isTaxableSupplyWithMarginScheme ? gstIncluded : undefined;
  const isGstFree = Predicate.boolFalse(isInputTaxed) && Predicate.boolFalse(isTaxableSupply);

  const onlyPrice = Predicate.boolFalse(vendorGst) || !!(isInputTaxed || isGstFree || (isTaxableSupplyWithMarginScheme && gstIncluded));
  const isGstPayableSeparate = isTaxableSupplyNoMarginScheme
    ? true
    : isTaxableSupplyWithMarginScheme && Predicate.boolFalse(gstIncluded);

  return {
    isInputTaxed,
    isTaxableSupplyNoMarginScheme,
    isTaxableSupplyWithMarginScheme,
    isTaxableSupplyGstIncluded,
    isGstFree,
    isGstPayableSeparate,
    onlyPrice
  };
}

function gstSubsection(
  {
    inputTaxed,
    taxableSupply,
    marginApplied,
    gstIncluded,
    gstFreeReason
  }: Partial<ContractPriceType>,
  vendorGst: boolean | undefined,
  isVariation?: boolean
) {
  const subsectionTitle = undefined;
  const subSectionItems = [];

  const {
    isInputTaxed,
    isTaxableSupplyNoMarginScheme,
    isTaxableSupplyWithMarginScheme,
    isTaxableSupplyGstIncluded,
    isGstFree
  } = calculateGstConditionsDisplay({ vendorGst },{
    inputTaxed,
    taxableSupply,
    marginApplied,
    gstIncluded
  });
  const mainOlContentContent = [
    { stack: spaceStackLinesSideEffect([
      { columns: [
        { text: 'Is the Vendor registered, or required to be registered, for GST?', margin: [0, fieldsSpacing, 0, 0] },
        { width: 100, margin: [0, -6, 0, 0], text: [...generateCheckboxText('Yes', vendorGst), '  ', ...generateCheckboxText('No', Predicate.boolFalse(vendorGst))] }
      ] },
      { text: 'If ‘No’, no GST is applicable, and you can proceed to Item 5.', italics: true }
    ]) }
  ];
  if (!Predicate.boolFalse(vendorGst)) {
    mainOlContentContent.push(
      { stack: spaceStackLinesSideEffect([
        { text: ['If ‘Yes’ was selected at Item 4.1, complete ', { text: 'one', bold: true }, ' of the following four options which best describes the sale:'] },
        { text: [...generateCheckboxText('Option 1: the sale is the supply of an existing ‘residential premises’, which is input taxed.', isInputTaxed)] },
        { stack: spaceStackLinesSideEffect([
          { text: ['If selected, no GST is applicable, and ', { text: 'do not', bold: true }, ' include an amount for ‘GST payable’ at Item 5'], italics: true },
          {
            text: [
              'Note: ‘residential premises’ means land or a building that is occupied as a residence or for residential accommodation, or is intended ',
              'to be occupied, and is capable of being occupied, as a residence or for residential accommodation. ‘Residential premises’ is input ',
              'taxed, unless the ‘residential premises’ are ‘commercial residential premises’ or ‘new residential premises’.'
            ],
            italics: true
          }
        ], 2) },
        { text: [...generateCheckboxText('Option 2: the sale is GST-free as it is:', isGstFree)] },
        { stack: spaceStackLinesSideEffect([
          { text: [...generateCheckboxText('(a) the supply of a going concern; or', isGstFree ? gstFreeReason === GstFreeReason.GoingConcern : undefined)] },
          { text: [...generateCheckboxText('(b) the supply of farmland supplied for a farming business.', isGstFree ? gstFreeReason === GstFreeReason.FarmlandForFarmingBusiness : undefined)] },
          { text: ['If selected, no GST is applicable, and ', { text: 'do not', bold: true }, ' include an amount for ‘GST payable’ at Item 5'], italics: true }
        ], 2) },
        { text: [...(isTaxableSupplyWithMarginScheme
          ? generateCheckboxText('Option 3: the sale is a taxable supply, and the margin scheme does apply and the GST:', true)
          : generateCheckboxText({ text: ['Option 3: the sale is a taxable supply, and the margin scheme ', { text: 'does', bold: true }, ' apply and the GST:'] }, isTaxableSupplyWithMarginScheme)
        )] },
        { stack: spaceStackLinesSideEffect([
          { text: [...generateCheckboxText('is included in the ‘Amount payable’ at Item 5; or', isTaxableSupplyGstIncluded)] },
          { stack: spaceStackLinesSideEffect([
            { text: ['If selected, ', { text: 'do not', bold: true }, ' include an amount for ‘GST payable’ at Item 5'], italics: true }
          ], 2) },
          { text: [...generateCheckboxText('will be payable in addition to the ‘Amount payable’ at Item 5 as per General Condition 21.6.', Predicate.boolFalse(isTaxableSupplyGstIncluded))] },
          { stack: spaceStackLinesSideEffect([
            { text: 'If selected, include the GST amount in the ‘GST payable’ at Item 5 once the GST amount is determined. You may fully execute this Contract prior to the ‘GST payable’ amount being determined.', italics: true }
          ], 2) }

        ], 2) },

        { text: [...(isTaxableSupplyNoMarginScheme
          ? generateCheckboxText('Option 4: the sale is a taxable supply, and the margin scheme does not apply.', true)
          : generateCheckboxText({ text: ['Option 4: the sale is a taxable supply, and the margin scheme ', { text: 'does not', bold: true }, ' apply.'] }, isTaxableSupplyNoMarginScheme)
        )] },
        { stack: spaceStackLinesSideEffect([
          { text: 'If selected, include the GST amount in the ‘GST payable’ at Item 5', italics: true }
        ], 2) }

      ]) }
    );
  }

  subSectionItems.push({
    ol: spaceStackLinesSideEffect(mainOlContentContent)
  });

  subSectionItems.push({ text: 'The Agent is not qualified to provide advice, and the Vendor and Purchaser must obtain their own independent professional advice, in relation to the appropriate GST treatment.' });

  return itemSubsection({
    subsectionTitle: subsectionTitle,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subSectionItems),
    unbreakable: true,
    bookmark: 'subsection-is-taxed',
    isVariation
  });
}

export function contractGstSection(
  itemNo: number,
  vendorGst: boolean | undefined,
  contractPrice: ContractPriceType | undefined,
  isVariation?: boolean
) {
  const sectionItems = [];
  sectionItems.push(gstSubsection(
    contractPrice || {},
    vendorGst,
    isVariation
  ));
  return itemSection({
    itemNo: itemNo, itemTitleParam: 'GST Conditions of sale'.toUpperCase(), bookmark: [
      'bookmark_contractGst',
      ...fieldFocusMap({ 'contractPrice': ['inputTaxed', 'gstIncluded', 'marginApplied'] })
    ], stackContent: sectionItems,
    isVariation
  });
}
