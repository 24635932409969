import { DocumentFieldType } from '../document-field';

export const purchaserPortalRoot: DocumentFieldType = {
  _type: 'Map',
  id: { _type: 'UUID', _required: true },
  primaryPurchaser: { _type: 'string' },
  purchasers: {
    _type: 'Array',
    _label: 'Purchasers',
    _children: {
      _type: 'Map',
      _label: 'Purchaser',
      id: { _type: 'UUID', _required: true },
      partyType: {
        _type: 'string',
        _label: 'Party Type',
        _auditRenderer: 'enum_PartyType',
        _readonlyOnLockedIdPath: ['..', 'id']
      },
      inTrust: { _type: 'boolean', _label: 'In Trust' },
      authority: { _type: 'string', _label: 'Authority', _auditRenderer: 'enum_SigningAuthorityType' },
      fullLegalName: {
        _type: 'string',
        _label: 'Name',
        _validationRequirementFailedMessage: 'Specify full legal name of Purchaser'
      },
      onBehalfOf: { _type: 'string', _label: 'On Behalf of' },
      addressSingleLine: { _type: 'string', _label: 'Address' },
      addrSameAsPrimary: { _type: 'boolean', _label: 'Postal address same as primary Purchaser' },
      primarySubcontact: { _type: 'number' },

      personName1: { _type: 'string', _label: 'Name' },
      email1: { _type: 'string', _subtype: 'email', _label: 'Email' },
      phone1: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
      personName2: { _type: 'string', _label: 'Name' },
      email2: { _type: 'string', _subtype: 'email', _label: 'Email' },
      phone2: { _type: 'string', _subtype: 'phone', _label: 'Phone' },

      abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' } // This will allow ACNs as well as ABNs, which may not be appropriate for anything other than company. However, this definition can't be easily changed, we'd have to have a second field or something, and a special component... it's not worth handling at the moment.
    }
  }
};
