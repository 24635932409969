import { isValidTimeZone } from './isValidTimeZone';
import { DefaultTimeZone } from '@property-folders/contract';

export function dayMonthStamp(timestamp: number | string | undefined | Date, timeZone: string = DefaultTimeZone) {
  if (timestamp === undefined) return '';
  try {
    // DD-MMM-yyyy HH:mm (TZ)
    // 12-Jan-2023 09:45 (ACST)
    const formatter = new Intl.DateTimeFormat('en-AU', {
      // year: 'numeric',
      month: 'short',
      day: '2-digit',
      timeZone: timeZone && isValidTimeZone(timeZone)
        ? timeZone
        : DefaultTimeZone
    });

    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);

    const parts = formatter.formatToParts(date);
    return parts.map(({ type, value }, index, array) => {
      const prev = array[index - 1]?.type;
      switch (type) {
        case 'month':
          return value.substring(0, 3);
        case 'literal':
          if (prev === 'day' || prev === 'month') {
            return '-';
          }
          if (prev === 'year') {
            return ' ';
          }
          return value;
        case 'timeZoneName':
          return `(${value})`;
        default:
          return value;
      }
    }).join('');
  } catch {
    try {
      return new Date(timestamp).toISOString();
    } catch {
      return timestamp?.toString();
    }
  }

}

export function formatTimestamp(timestamp: number | string | undefined | Date, timeZone: string = DefaultTimeZone, includeTime = true): string {
  if (timestamp === undefined) return '';
  try {
    // DD-MMM-yyyy HH:mm (TZ)
    // 12-Jan-2023 09:45 (ACST)
    const formatter = new Intl.DateTimeFormat('en-AU', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      ...includeTime && {
        hour: 'numeric',
        hourCycle: 'h23',
        minute: 'numeric',
        timeZoneName: 'short'
      },
      timeZone: timeZone && isValidTimeZone(timeZone)
        ? timeZone
        : DefaultTimeZone
    });

    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);

    const parts = formatter.formatToParts(date);
    return parts.map(({ type, value }, index, array) => {
      const prev = array[index - 1]?.type;
      switch (type) {
        case 'month':
          return value.substring(0, 3);
        case 'literal':
          if (prev === 'day' || prev === 'month') {
            return '-';
          }
          if (prev === 'year') {
            return ' ';
          }
          return value;
        case 'timeZoneName':
          return `(${value})`;
        default:
          return value;
      }
    }).join('');
  } catch {
    try {
      return new Date(timestamp).toISOString();
    } catch {
      return timestamp?.toString();
    }
  }
}
