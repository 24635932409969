import { PathType } from '@property-folders/contract/yjs-schema/model';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { YjsDocContext } from '../context/YjsDocContext';
import { FormContext } from '../context/FormContext';
import { normalisePathToStrArray } from '@property-folders/common/util/pathHandling';

export function useFocusErrorList({
  docName,
  rootKey,
  formName
}: {
  docName: string | undefined,
  rootKey: string | undefined,
  formName?: string
}): string[] {
  return useSelector((state: any) => {
    if (formName) {
      return state?.validation?.focusErrList?.[docName ?? '']?.[rootKey ?? '']?.[formName] || [] as string[];
    }

    return Object.values(state?.validation?.focusErrList?.[docName ?? '']?.[rootKey ?? ''] || {}).flat() as string[];
  });
}

export function useSpecificErrorsForPath(path: PathType, keyOverrides?: {
  docName?: string | undefined,
  rootKey?: string | undefined,
  formName?: string | undefined
}) {

  const { docName, transactionRootKey } = useContext(YjsDocContext);
  const { formName } = useContext(FormContext);
  const docId = keyOverrides?.docName || docName;
  const docKey = keyOverrides?.rootKey || transactionRootKey;
  const formCode = keyOverrides?.formName || formName;

  return useSelector((state: any) => {
    const treeTop = formCode
      ? state?.validation?.vtrees?.[docId ?? '']?.[docKey ?? '']?.[formCode] || [] as string[]
      : Object.values(state?.validation?.vtrees?.[docId ?? '']?.[docKey ?? ''] || {}).flat() as string[];

    let current = treeTop;
    for (const segment of normalisePathToStrArray(path)) {
      current = current[segment];
      if (current == null) return current;
    }
    return current._validationResult;
  });

}