import { Card } from 'react-bootstrap';
import { ContentCard } from '~/components/content-card';
import { purchaserTypeOptions } from '@property-folders/common/util/pdfgen/constants';
import { PartyAuthorityInput } from '@property-folders/components/dragged-components/form/PartyAuthorityInput';
import React, { ReactNode } from 'react';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { useForm } from '@property-folders/components/hooks/useForm';

export function OfferReviewPrimaryPurchaser({ footer }: { footer: ReactNode | undefined }) {
  useForm(); // Run validation on this page. Cannot be hoisted into authenticated offer as it requires the FormContext set up for each step
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const primaryPurchaser = property ? property.primaryPurchaser : undefined;
  const gnaf = property?.saleAddrs?.[0].gnaf ? { id: property?.saleAddrs?.[0].gnaf } : undefined;
  // todo: make email/phone read-only... how do we force that server-side???
  return <ContentCard>
    <Card.Header><h4>Review your details</h4></Card.Header>
    <Card.Body>
      <PartyAuthorityInput
        primaryId={primaryPurchaser}
        thisLevel={1}
        hideOnTitleField={true}
        primaryIdAbsPath={'primaryPurchaser'}
        partyLabel='Purchaser'
        typeOptions={purchaserTypeOptions}
        myPath={`purchasers.[${primaryPurchaser}]`}
        purchaserPortalRegistration={true}
        hideRootPrimaryContactCheckbox={true}
        hideDelete={true}
        setAutoComplete={true}
        disabledPhone={true}
        gnaf={gnaf}
      />
    </Card.Body>
    <Card.Footer className='d-flex flex-row justify-content-end gap-2'>
      {footer}
    </Card.Footer>
  </ContentCard>;
}
