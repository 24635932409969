import { ContentCard } from '~/components/content-card';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import {
  FormInstance,
  FormSigningState,
  MaterialisedPropertyData,
  SigningParty,
  SigningPartyType,
  SigningPartyVerificationConfig,
  SigningPartyVerificationType
} from '@property-folders/contract/yjs-schema/property';
import {
  FormTypes,
  getPartyDetailPaths,
  mapSigningPartySourceTypeToCategory
} from '@property-folders/common/yjs-schema/property/form';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { SigningPartyTypeOptions } from '@property-folders/components/dragged-components/signing/Common';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { Maybe } from '@property-folders/common/types/Utility';
import { useSigningValidation } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { useFocusErrorList } from '@property-folders/components/hooks/useFocusErrorList';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';

export function ConfigureSigning({
  formCode,
  formId,
  onStartSigning,
  initialErrorMessage,
  tableTitleEndButtons
}: {
  formCode: string,
  formId: string,
  onStartSigning: () => void,
  initialErrorMessage: string,
  tableTitleEndButtons?: React.ReactNode
}) {
  const family = FormTypes[formCode].formFamily;
  const { ydoc, docName: propertyId, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  useSigningValidation({
    ydoc,
    propertyId: propertyId || '',
    formCode,
    formId,
    signingStatus: FormSigningState.Configuring,
    dataRootKeys: [transactionRootKey],
    metaRootKey: transactionMetaRootKey,
    portalValidation: true
  });
  const { value: propertyData } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { value: primaryPurchaser } = useLightweightTransaction({ myPath: 'primaryPurchaser' }) as {
    value: string | undefined
  };
  const { value: formInstance, fullPath: formInstancePath } = useLightweightTransaction<FormInstance>({
    myPath: `formStates.${family}.instances.[${formId}]`,
    bindToMetaKey: true
  });
  const focusErrList = useFocusErrorList({ docName: propertyId, rootKey: transactionRootKey });
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  const otherParties = useMemo(() => {
    return (formInstance?.signing?.parties || [])
      .filter(party => party.source.id !== primaryPurchaser)
      .filter(party => mapSigningPartySourceTypeToCategory(party.source.type) === 'purchaser')
      .map(signingParty => {
        return getPartyDetailPaths(
          signingParty,
          propertyData,
          formInstancePath,
          true
        );
      })
      .filter(Predicate.isNotNull);
  }, [formInstance]);

  return <>
    <ContentCard>
      <Card.Header className='d-flex flex-row justify-content-between align-items-center'>
        <h4>Signing</h4>
        {tableTitleEndButtons && <div className='d-flex flex-row justify-content-between align-items-center children-spacing-rem'>{tableTitleEndButtons}</div>}
      </Card.Header>
      <Card.Body>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        <p>Select how the other purchasers will be invited to sign.</p>
        {otherParties.map((party, index) => {
          return <React.Fragment key={index}>
            <ConfigureSigningParty key={index} meta={party.meta} data={party.data}/>
            {index < (otherParties.length - 1) && <hr/>}
          </React.Fragment>;
        })}
      </Card.Body>
      <Card.Footer className='d-flex flex-row justify-content-end'>
        {/*don't need an AsyncButton here as the whole content will be replaced with a spinner*/}
        <Button
          disabled={!!focusErrList.length}
          onClick={onStartSigning}
        >Send links and start signing</Button>
      </Card.Footer>
    </ContentCard>
  </>;
}

function ConfigureSigningParty({
  meta,
  data
}: {
  meta: {
    base: string
  },
  data: {
    base: string,
    name: string,
    email: string,
    phone: string
  }
}) {
  const { value: party } = useTransactionField<SigningParty>({
    myPath: meta.base,
    bindToMetaKey: true
  });
  const {
    value: verificationConfig,
    handleUpdate: updateVerificationConfig,
    handleRemove: clearVerificationConfig,
    fullPath: verifyPath,
    justUpdated: verifyJustUpdated
  } = useTransactionField<Maybe<SigningPartyVerificationConfig>>({
    parentPath: meta.base,
    myPath: 'verification',
    bindToMetaKey: true
  });
  const { value: partyData } = useTransactionField({ myPath: data.base });
  const { value: metaParty } = useLightweightTransaction<SigningParty>({ myPath: meta.base, bindToMetaKey: true });
  const { updateDraft: updateSigningParty } = useYdocBinder<SigningParty>({ path: meta.base, bindToMetaKey: true }) ;

  const name = partyData[data.name] as string;
  const email = partyData[data.email] as string | undefined;
  const phoneRaw = partyData[data.phone] as string | undefined;
  const phone = phoneRaw ? canonicalisers.phone(phoneRaw).display : undefined;

  useEffect(()=>{
    // Scrub default if email isn't available... I guess?
    if (!phone && !email) {
      return;
    }
    if (!phone && metaParty?.typeHostComposite === SigningPartyType.SignOnlineSms.toString()) {
      updateSigningParty?.(upParty=>{
        upParty.typeHostComposite = SigningPartyType.SignOnline.toString();
        upParty.type = SigningPartyType.SignOnline;
      });
    }
    if (!email && metaParty?.typeHostComposite === SigningPartyType.SignOnline.toString()) {
      updateSigningParty?.(upParty=>{
        upParty.typeHostComposite = SigningPartyType.SignOnlineSms.toString();
        upParty.type = SigningPartyType.SignOnlineSms;
      });
    }
  }, [phone, email, !!metaParty]);

  const validOptions: {name: string, label: string}[] = [];
  if (phone) {
    validOptions.push({
      name: SigningPartyType.SignOnlineSms.toString(),
      label: SigningPartyTypeOptions[SigningPartyType.SignOnlineSms]
    });
  }

  if (email) {
    validOptions.push({
      name: SigningPartyType.SignOnline.toString(),
      label: SigningPartyTypeOptions[SigningPartyType.SignOnline]
    });
  }

  return <>
    <Container fluid className='px-0'>
      <Row>
        <Col lg={6}>
          <div><b>{name}</b></div>
          {phone && party.type === SigningPartyType.SignOnlineSms && <div className='text-decoration-underline'>{phone}</div>}
          {email && party.type === SigningPartyType.SignOnline && <div className='text-decoration-underline'>{email}</div>}
        </Col>
        <Col lg={6}>
          <WrField.Select
            label='Signing Method'
            options={validOptions}
            name='typeHostComposite'
            parentPath={meta.base}
            myPath='typeHostComposite'
            bindToMetaKey={true}
            forceFieldGroupFnName='splitPartySignerType'
            canClear={false}
          />
        </Col>
      </Row>
      {party.type === SigningPartyType.SignOnline && <Row className='mt-3' style={{ backgroundColor: verifyJustUpdated ? 'lightgrey' : undefined }}>
        <Col lg={6}>
          <Form.Check
            type='checkbox'
            name={verifyPath}
            id={verifyPath}
            label='Two-Factor Authentication'
            checked={!!verificationConfig}
            onChange={e => {
              if (e.target.checked) {
                updateVerificationConfig({ type: SigningPartyVerificationType.Sms }, true);
              } else {
                updateVerificationConfig(undefined, true);
                clearVerificationConfig();
              }
            }}
          />
          {verificationConfig
            ? <div>A code will be sent via SMS.</div>
            : phone
              ? <div className={'text-muted'}>A verification code can be sent to {phone}.</div>
              : <div className={'text-muted'}>A verification code can be sent.</div>}
        </Col>
        {verificationConfig && <Col lg={6}>
          <WrField.Control
            parentPath={data.base}
            myPath={data.phone}
            name={data.phone}
            label={'Mobile Number'}
          />
        </Col>}
      </Row>}
    </Container>
  </>;
}
