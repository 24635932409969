import { MaterialisedPropertyData, Maybe, VendorParty } from '@property-folders/contract';
import { ComplexValidationReturnType } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { getValueByPath } from '../../../../util/pathHandling';

export function vendorEmailRequiredIfPrimary(nodeTree: MaterialisedPropertyData, updatedPath?: string[]): ComplexValidationReturnType {
  const forceUpdatesOn = [];
  // If we have no updated path, it's a full revalidation anyway, so nothing more to worry about
  if (Array.isArray(updatedPath) && updatedPath.length === 1 && updatedPath[0] === 'primaryVendor') {
    // We call a response to any email update, because we don't know the previous state, and we need
    // to remove primaryEmailRequired from a vendor who is no longer a primary contact
    forceUpdatesOn.push(...(nodeTree?.vendors||[]).map(v=>`vendors.[${v.id}].email1`));
    forceUpdatesOn.push(...(nodeTree?.vendors||[]).map(v=>`vendors.[${v.id}].email2`));
  } else if (Array.isArray(updatedPath) && updatedPath[updatedPath.length-1] === 'primarySubcontact') {
    // Similar to above, except the scope of changes is definitely within vendor, so we only need
    // to trigger regeneration across the two email fields
    const vendorBasePath = updatedPath.slice(0,updatedPath.length-1);
    forceUpdatesOn.push([...vendorBasePath, 'email1'].join('.'), [...vendorBasePath, 'email2'].join('.'));
  }
  // else... if the update is not on which vendor is primary, then for this to be relevant, we'd
  // already be updating email1 on it so we don't need to force it. It also is not worth determining
  // whether the calculation should even be made, it's already fairly simple from here on out. Other
  // fields will just not use this result.
  let errorObj;
  if (nodeTree.primaryVendor) {
    const vendorNode: Maybe<VendorParty> = getValueByPath(`vendors.[${nodeTree.primaryVendor}]`, nodeTree, true);
    // Primary subcontact should be reset when the VendorType and then Authority are selected. +1 because it is zero
    // indexed but the names are not. 0 Default because in most cases, we want the first contact as that is what is
    // filled in all non-dual signer setups
    const primaryEmailPath = `vendors.[${nodeTree.primaryVendor}].email${(vendorNode?.primarySubcontact || 0) + 1}`;
    const primaryEmail = getValueByPath(primaryEmailPath, nodeTree, true);
    // Either the path/field doesn't exist, or it is an empty string. Either way fails required as
    // a non-existent path means we cannot determine the primary contact and we need one for
    // correspondance
    errorObj = primaryEmail ? undefined : { [primaryEmailPath]: ['primaryEmailRequired'] };
  }

  return {
    forceUpdatesOn,
    errorObj
  };
}

export default vendorEmailRequiredIfPrimary;
