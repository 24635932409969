import { Card, Spinner } from 'react-bootstrap';
import { ContentCard } from '~/components/content-card';

export function FullScreenLoader() {
  return <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center'>
    <Spinner
      variant='light'
      animation='border'
      style={{
        width: '6rem',
        height: '6rem',
        marginInline: 'auto'
      }}/>
  </div>;
}

export function LoaderCard({ title }: { title?: string }) {
  return <ContentCard>
    {title && <Card.Header><h4>{title}</h4></Card.Header>}
    <Card.Body className='d-flex flex-row justify-content-center align-items-center py-5'>
      <Spinner
        variant='dark'
        animation='border'
        style={{
          width: '6rem',
          height: '6rem',
          marginInline: 'auto'
        }}
      />
    </Card.Body>
  </ContentCard>;
}
