import {
  Await,
  defer,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
  useParams
} from 'react-router-dom';
import React from 'react';
import { useAuth } from '~/context/auth-context';
import { ShortId } from '@property-folders/common/util/url';
import { FullScreenLoader } from '~/components/loaders';
import { mergeLoaderResults } from '~/util';
import { LoaderResult } from '~/types';
import { GetPurchaserPortalResult } from '@property-folders/contract';
import { PurchaserPortalApi } from '~/api';

export async function purchaserUnauthenticatedRootLoader({ params }: LoaderFunctionArgs) {
  const { portalIdRaw, inviteToken } = params as { portalIdRaw: string, inviteToken?: string };
  const portalId = ShortId.toUuid(portalIdRaw);
  return defer({
    data: PurchaserPortalApi.getPurchaserPortal(portalId, inviteToken)
  });
}

/**
 * Responsible for loading public data based on property id
 */
export function PurchaserUnauthenticatedRootRoute() {
  const { sessionInfo } = useAuth();
  const { search } = useLocation();
  const params = useParams() as Record<string, string>;
  const resolve = mergeLoaderResults<void>([
    useLoaderData() as LoaderResult
  ]);

  const { portalIdRaw } = params;
  const portalId = ShortId.toUuid(portalIdRaw);

  if (sessionInfo?.userState === 'full' && sessionInfo.portalId === portalId && sessionInfo?.authenticated) {
    const returnPathRaw = new URLSearchParams(search).get('returnPath');
    const returnPath = returnPathRaw && returnPathRaw.startsWith('/')
      ? returnPathRaw
      : `/purchaser/${sessionInfo.portalId}/view`;
    return <Navigate to={returnPath}/>;
  }

  return <React.Suspense fallback={<FullScreenLoader/>}>
    <Await
      resolve={resolve}
      errorElement={<div>error!</div>}
    >
      {(resolved: GetPurchaserPortalResult) => {
        document.title = `${resolved.entityName} - Purchaser portal`;
        return <Outlet/>;
      }}
    </Await>
  </React.Suspense>;
}
