import { ContentCard } from '~/components/content-card';
import { Card } from 'react-bootstrap';
import { useAsyncError, useRouteError } from 'react-router-dom';

export function DefaultError() {
  const routeError = useRouteError();
  const asyncError = useAsyncError();

  const error = asyncError || routeError;

  return <div className='py-4 px-5'>
    <ContentCard>
      <Card.Header>Unexpected Error</Card.Header>
      {!!error && <Card.Body>
        <pre>{JSON.stringify(error)}</pre>
      </Card.Body>}
    </ContentCard>
  </div>;
}
