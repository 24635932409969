type SortDirection =
  | 'asc'
  | 'desc';

export function byNumericValueDesc(a?: number, b?: number) {
  return (b ?? Number.MIN_SAFE_INTEGER) - (a ?? Number.MIN_SAFE_INTEGER);
}

export function byNumericValueAsc(a?: number, b?: number) {
  return (a ?? Number.MAX_SAFE_INTEGER) - (b ?? Number.MAX_SAFE_INTEGER);
}

export function byNumericValue(direction: SortDirection = 'asc') {
  return direction === 'asc'
    ? byNumericValueAsc
    : byNumericValueDesc;
}

export function byBooleanValueDesc(a?: boolean, b?: boolean) {
  return (b ? 1 : 0) - (a ? 1 : 0);
}

export function byMapperFnDesc<T>(fn: (x: T) => number | undefined) {
  return (a: T, b: T) => byNumericValueDesc(fn(a), fn(b));
}

export function byMapperFn<T>(fn: (x: T) => number | undefined, direction: SortDirection = 'asc') {
  return direction === 'asc'
    ? (a: T, b: T) => byNumericValueAsc(fn(a), fn(b))
    : (a: T, b: T) => byNumericValueDesc(fn(a), fn(b));
}

export function byLastAccessedDesc<T extends { lastAccessed?: number; }>(a: T, b: T) {
  return byNumericValueDesc(a.lastAccessed, b.lastAccessed);
}
export function byCreatedDateDesc<T extends { created?: number; }>(a: T, b: T) {
  return byNumericValueDesc(a.created, b.created);
}

export function byScoreDesc<T extends { score?: number }>(a: T, b: T) {
  return byNumericValueDesc(a.score, b.score);
}

type SortComparisonFn<T> = (a: T, b: T) => number;
export function byManyAspects<T>(sortFuncs: SortComparisonFn<T>[]) {
  return (a: T, b: T) => {
    for (const sortFunc of sortFuncs) {
      const result = sortFunc(a, b);
      if (result !== 0) return result;
    }

    return 0;
  };
}
