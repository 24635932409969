import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getPathParentAndIndex, getValueByPath } from '../../../../util/pathHandling';
import { canonicalisers } from '../../../../util/formatting';

export const purchasePriceGst: FieldGroupFn = (fieldId, updatedPath, immerProxy) => {
  if (fieldId !== 'contractPrice.purchasePrice') {
    return;
  }
  const rawValue = canonicalisers.aud(getValueByPath(updatedPath, immerProxy)).canonical;
  const value = typeof rawValue === 'string' ? parseFloat(rawValue) : rawValue;
  const { parent } = getPathParentAndIndex(updatedPath, immerProxy);
  parent.purchaseGst = isNaN(value) ? '' : canonicalisers.aud((value*0.1).toString()).canonical;
};
