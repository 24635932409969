import { EmailEvent } from '@property-folders/contract';

export const emailSubtypeSortValues = new Map<EmailEvent, number>([
  EmailEvent.deferred,
  EmailEvent.processed,
  EmailEvent.dropped,
  EmailEvent.delivered,
  EmailEvent.spamreport,
  EmailEvent.bounce,
  EmailEvent.open,
  EmailEvent.click
].map((type, idx) => [type, idx]));

export function emailEventIsLater(current: EmailEvent | undefined, proposed: EmailEvent | undefined): boolean {
  const currentSortIdx = current ? emailSubtypeSortValues.get(current) ?? -1 : -1;
  const proposedSortIdx = proposed ? emailSubtypeSortValues.get(proposed) ?? -1 : -1;

  return proposedSortIdx >= currentSortIdx;
}
