import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { jointPartyConfig, partyFieldConfig } from '../../../../util/formatting/constants';
import { generateParentPath, getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';
import { AuthorityParty, AuthorityPartyLegalRep, PartyType, SigningAuthorityType, VendorParty, VendorPartyJoint, jointGroupingEquivalenceKey, jointGroupingPermitted, jointTypes, multiRepAuthority } from '@property-folders/contract';
import { v4 } from 'uuid';
import { pick } from 'lodash';

const defaultAuthorityMap: {[Property in PartyType]: SigningAuthorityType} = {
  [PartyType.AdministratorCompany]: SigningAuthorityType.authRep,
  [PartyType.ExecutorCompany]: SigningAuthorityType.authRep,
  [PartyType.MortgageeCompany]: SigningAuthorityType.authRep,
  [PartyType.Corporation]: SigningAuthorityType.authRep,
  [PartyType.Individual]: SigningAuthorityType.self,
  [PartyType.ExecutorNatural]: SigningAuthorityType.self,
  [PartyType.AdministratorNatural]: SigningAuthorityType.self,
  [PartyType.MortgageeNatural]: SigningAuthorityType.self
};

/**Resets default values on a vendor when a vendor changes
 *
 * Assumes vendor is at the party of the updated path. We are also assuming that this would only
 * be called on an existing path, so the base vendor should exist.
 *
 * all fields exist on sale static map
 * input only fields: partyType
 * input and output fields: authority
 * output only: inTrust, registeredOnTitle, primarySubcontact
 *
 */
export const partyResetActivity: FieldGroupFn = (
  fieldId,
  updatedPath,
  immerProxy,
  _1,
  _2,
  previousState
) => {
  const parentPath = generateParentPath(updatedPath);
  const party = getValueByPath(parentPath, immerProxy) as AuthorityParty|VendorParty|VendorPartyJoint;
  const previousParty = getValueByPath(parentPath, previousState);
  const pointPath = fieldId.split('.').slice(-1)[0]; // The specified users of this updater should not include the root path []

  let authorityAutoSelected = false;
  if (pointPath === 'partyType') {
    const pColPath = generateParentPath(normalisePathToStrArray(parentPath));
    const colPathLeaf = pColPath[pColPath.length-1];
    if (colPathLeaf !== 'namedExecutors') {

      const transferKeys = [
        'fullLegalName', 'authority', 'email1', 'email2',
        'personName1', 'personName2', 'phone1', 'phone2',
        'abn', 'legalRepresentatives', 'primarySubcontact', 'primarySubcontactId'
      ];
      if (jointTypes.includes(party.partyType) && !jointTypes.includes(previousParty.partyType)) {
        // Moving to a joint type, populate the namedExecutors array, and fill the first element
        // with mostly current values
        const p2 = party as VendorPartyJoint;
        const retain = pick(p2, transferKeys);
        if (!Array.isArray(p2.namedExecutors)) {
          p2.namedExecutors = [];
        }
        let targetIndex;

        if (p2.primaryNamedExecutor) {
          const fi = p2.namedExecutors.findIndex(e=> e.id === p2.primaryNamedExecutor);
          if (fi >= 0) {
            targetIndex = fi;
          }
        }
        if (targetIndex != null) {
          Object.assign(p2.namedExecutors[targetIndex], retain);
        } else {
          const newId = v4();
          p2.namedExecutors.splice(0,0,{ ...retain, id: newId });
          p2.primaryNamedExecutor = newId;
        }
      } else if (!jointTypes.includes(party.partyType) && jointTypes.includes(previousParty.partyType)) {
        const p2 = party as VendorPartyJoint;
        const primaryId = p2.primaryNamedExecutor ?? p2.namedExecutors?.[0]?.id;
        const backCopy = p2.namedExecutors?.find(e=>e.id === primaryId) ?? p2.namedExecutors?.[0];
        if (backCopy) {
          const remerge = pick(backCopy,transferKeys);
          Object.assign(party, remerge);
        }
      }
    }
    if (party.partyType != null) {
      party.authority = defaultAuthorityMap[party.partyType];
      authorityAutoSelected = true;
    } else {
      delete party.authority;
    }
    delete party.inTrust;
    delete party.registeredOnTitle;
    delete party.mortgageNumber;
  }

  if (pointPath === 'authority' && !jointTypes.includes(party.partyType)) {
    const p2 = party as AuthorityParty;
    if (multiRepAuthority.includes(p2.authority) && !multiRepAuthority.includes(previousParty.authority)) {
      // In this case, we're taking an existing representative and copying them to the array. This means we will
      // be copying over personName1, not full legal name, as this remains. We also will copy the ABn, as in the
      // existing representation modes the ABN is of the representative not the party.
      // It is not intended to copy both company representatives in the dual case, as those are not
      // power of attorney or guardianships, and also do not have individual ABNs
      const retain: Omit<AuthorityPartyLegalRep, 'id'> = {
        name: p2.personName1,
        email: p2.email1,
        phone: p2.phone1,
        abn: p2.abn // This could be unwanted, but also in cases it is already set, it could also be wanted. I'm not sure
      };
      if (!Array.isArray(p2.legalRepresentatives)) {
        p2.legalRepresentatives = [];
      }
      let targetIndex;
      if (p2.primarySubcontactId) {
        const fi = p2.legalRepresentatives.findIndex(e=> e.id === p2.primarySubcontactId);
        if (fi >= 0) {
          targetIndex = fi;
        }
      }
      if (targetIndex != null) {
        Object.assign(p2.legalRepresentatives[targetIndex], retain);
      } else {
        const newId = v4();
        p2.legalRepresentatives.splice(0,0,{ ...retain, id: newId });
        p2.primarySubcontactId = newId;
      }
    } else if (!multiRepAuthority.includes(p2.authority) && multiRepAuthority.includes(previousParty.authority)) {

      const primaryId = p2.primarySubcontactId ?? p2.legalRepresentatives?.[0]?.id;
      const backCopy = p2.legalRepresentatives?.find(e=>e.id === primaryId) ?? p2.legalRepresentatives?.[0];
      if (backCopy) {
        const remerge: Partial<AuthorityParty> = {
          personName1: backCopy.name,
          email1: backCopy.email,
          phone1: backCopy.phone
          // abn: backCopy.abn // I don't think we want to copy the ABN back as this may result in clobbering data
        };
        Object.assign(party, remerge);
      }

    }
  }

  if (jointTypes.includes(party.partyType)) {
    const partyJoint = party as VendorPartyJoint;
    for (const nei in Array.isArray(partyJoint.namedExecutors) ? partyJoint.namedExecutors : []) {
      const subParty = partyJoint.namedExecutors?.[nei] as AuthorityParty;
      const permitted = jointGroupingPermitted[partyJoint.partyType];
      if (subParty.partyType && !permitted.includes(subParty.partyType)) {
        const equivalentGroup = jointGroupingEquivalenceKey[subParty.partyType];
        const permittedEquivalent = (equivalentGroup??[]).filter(e=>permitted.includes(e))[0];
        if (permittedEquivalent) {
          subParty.partyType = permittedEquivalent;
        } else {
          delete subParty.partyType;
        }
      }
    }
  }

  if (pointPath === 'authority' || authorityAutoSelected) {
    const { partyType, authority } = (party || {}) as {partyType?: string, authority?: string};
    const configuration = jointTypes.includes(partyType) ? jointPartyConfig[partyType] : (partyType && authority && partyFieldConfig[partyType][authority]);

    if (configuration) {
      party.registeredOnTitle = Boolean(configuration.registeredOnTitleDefault);
      party.inTrust = false;
      party.primarySubcontact = 0;
    } else {
      console.warn('No configuration found for selected vendor combination');
    }
  }
};
