export function flattenPdfDefinitionToPlaintext(definition: any): string {
  return flattenPdfDefinitionToPlaintextInner(definition)
    .trim()
    // \s+ would replace newlines, which we want to preserve
    // @ts-ignore
    .replaceAll(/[ \t]+/gi, ' ');
}

function flattenPdfDefinitionObject(definition: object): string {
  if ('style' in definition && definition.style === 'hiddenText') return '';
  if ('text' in definition) return flattenPdfDefinitionToPlaintext(definition.text);
  if ('stack' in definition) return flattenPdfDefinitionToPlaintext(definition.stack);
  if ('columns' in definition) return flattenPdfDefinitionToPlaintext(definition.columns);
  if ('ol' in definition && Array.isArray(definition.ol)) return definition.ol.map(flattenPdfDefinitionToPlaintext).join('\n');
  console.log('flattenPdfDefinitionObject - unhandled case', { definition });
  return '';
}

function flattenPdfDefinitionToPlaintextInner(definition: any): string {
  if (!definition) return '';
  if (Array.isArray(definition)) {
    return definition.map(flattenPdfDefinitionToPlaintext).join(' ').trim();
  }

  switch (typeof definition) {
    case 'string':
      return definition;
    case 'object':
      return flattenPdfDefinitionObject(definition);
    default:
      return '';
  }
}

