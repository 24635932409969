import * as Y from 'yjs';
import { bind } from 'immer-yjs';
import { PropertyStatsMain, PropertyStatsRootKey, UserAccessTime } from '@property-folders/contract/yjs-schema/property-stats';
import { applyMigrations, Migration } from '../index';

export function getKeyFromPropertyId(propertyId: string): string {
  return `${propertyId}_stats`;
}

export function getPropertyIdFromKey(statsKey: string): string {
  return statsKey.replace('_stats', '');
}

export function materialisePropertyStats(doc: Y.Doc | undefined): PropertyStatsMain | undefined {
  if (!doc) {
    return undefined;
  }

  return doc.getMap(PropertyStatsRootKey.Main)?.toJSON() as PropertyStatsMain | undefined;
}

const migrations: Array<Migration<PropertyStatsMain>> = [
  {
    name: 'remove duplicate user access times',
    condition: state => {
      const distinct = new Set(state.userAccessTimes.map(uat => uat.id));
      return distinct.size !== state.userAccessTimes.length;
    },
    fn: s => {
      const userMaxTimes = new Map<number, UserAccessTime>();
      let dupeHit = false;

      for (const uat of s.userAccessTimes) {
        const tsExisting = userMaxTimes.get(uat.id);
        if (tsExisting) {
          dupeHit = true;
        }
        if ((tsExisting?.ts || 0) < uat.ts) {
          userMaxTimes.set(uat.id, uat);
        }
      }

      if (!dupeHit) {
        return;
      }

      for (let idx = s.userAccessTimes.length - 1; idx >= 0; idx--) {
        const record = s.userAccessTimes[idx];
        const tsMax = userMaxTimes.get(record.id);
        if (tsMax && record !== tsMax) {
          s.userAccessTimes.splice(idx, 1);
        }
      }
    }
  }
];

export function migratePropertyStats(doc: Y.Doc | undefined): Uint8Array | undefined {
  if (!doc) {
    return undefined;
  }

  const preVector = Y.encodeStateVector(doc);
  const binder = bind<PropertyStatsMain>(doc.getMap(PropertyStatsRootKey.Main));

  if (!applyMigrations('PropertyStatsMain', binder, migrations)) {
    return undefined;
  }

  return Y.encodeStateAsUpdate(doc, preVector);
}
