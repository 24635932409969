export type AdditonalProps = Omit<any, 'message' | 'source' | 'subsources'>;
import { decycle } from './decycle.js';

export class JsonFormattedLogger {
  constructor(
    private source: string,
    private subsources?: string[]) { }

  public info(message: string, props?: AdditonalProps) {
    console.info(this.prepare(message, props));
  }

  public warn(message: string, props?: AdditonalProps) {
    console.warn(this.prepare(message, props));
  }

  public error(message: string, props?: AdditonalProps) {
    console.error(this.prepare(message, props));
  }

  public getNested(subsource: string) {
    return new JsonFormattedLogger(this.source, [...this.subsources||[], subsource]);
  }

  public prepare(message: string, props?: AdditonalProps) {
    if (props && 'message' in props) {
      props._message = props.message;
      delete props.message;
    }
    const event = {
      source: this.source,
      message,
      subsources: this.subsources,
      ...props
    };

    if (!event.subsources?.length) {
      delete event.subsources;
    }

    try {
      return JSON.stringify(decycle(event));
    } catch (e) {
      return 'Error stringifying JSON: ' + e;
    }
  }
}
