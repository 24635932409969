const dbName = 'reaformsShimDb';
const objectStoreName = 'testBlob';

export async function checkIfIndexedDBShimNeeded(skipCached = false): Promise<boolean> {
  if (skipCached) {
    await tryDeleteDb(dbName);
    return await tryTestDb(dbName);
  }

  const cached = tryGetCachedResult();
  if (typeof cached === 'boolean') {
    return cached;
  }

  await tryDeleteDb(dbName);
  const result = await tryTestDb(dbName);
  trySetCachedResult(result);
  return result;
}

async function tryDeleteDb(name: string) {
  try {
    const dbs = await window.indexedDB.databases();

    if (!dbs.filter(db => db.name === name).length) {
      // nothing to delete
      return;
    }

    await new Promise<void>((resolve, reject) => {
      try {
        const reqDelete = window.indexedDB.deleteDatabase(name);
        reqDelete.onsuccess = () => {
          resolve();
        };
        reqDelete.onerror = () => {
          reject(new Error('Delete failure'));
        };
        reqDelete.onblocked = (event) => {
          console.error('delete blocked', event);
        };
      } catch (err: unknown) {
        reject(err);
      }
    });
  } catch (err: unknown) {
    console.error('could not delete', err);
  }
}

async function tryTestDb(name: string): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    let blobUrlsUnsupported = false;
    try {
      const db = window.indexedDB.open(name, 1);
      db.onupgradeneeded = function (event) {
        const result: IDBDatabase = (event.target as IDBOpenDBRequest).result;

        try {
          result.createObjectStore(objectStoreName, {
            autoIncrement: true
          }).put(new Blob());
        } catch (err: unknown) {
          const message = typeof err === 'string'
            ? err
            : err instanceof Error
              ? err.message || ''
              : '';

          if (message.includes('BlobURLs are not yet supported')) {
            blobUrlsUnsupported = true;
          }
          throw err;
        }
      };
      db.onblocked = (event => {
        console.error('blocked', event);
      });
      db.onsuccess = (event => {
        const result: IDBDatabase = (event.target as IDBOpenDBRequest).result;
        if (!result.objectStoreNames.contains(objectStoreName)) {
          blobUrlsUnsupported = true;
        }
        result.close();
        resolve(blobUrlsUnsupported);
      });
      db.onerror = (event => {
        const result: IDBDatabase | undefined = (event.target as IDBOpenDBRequest)?.result;
        if (result) {
          result.close();
        }
        resolve(blobUrlsUnsupported);
      });
    } catch (e) {
      resolve(blobUrlsUnsupported);
    }
  });
}

const cachedResultKey = 'indexedDbShimNeeded';
function tryGetCachedResult(): boolean | undefined {
  try {
    const cachedResult = localStorage.getItem( cachedResultKey);
    switch (cachedResult) {
      case 'true': return true;
      case 'false': return false;
      default:
        break;
    }
  } catch (err: unknown) {
    console.error(err);
    return undefined;
  }
}

function trySetCachedResult(value: boolean) {
  try {
    localStorage.setItem(cachedResultKey, value ? 'true' : 'false');
  } catch (err: unknown) {
    console.error(err);
  }
}
