/**
 * If you're creating a new Y doc, then load this template into it first.
 */
export const PropertyStatsSchemaTemplate = 'AQH3xq7+DAAnAQRNYWluD3VzZXJBY2Nlc3NUaW1lcwAA';

export enum PropertyStatsRootKey {
  Main = 'Main'
}

export type UserAccessTime = {
  /**
   * agent id
   */
  id: number,

  /**
   * timestamp in unix epoch ms
   */
  ts: number
};

export type PropertyStatsMain = {
  id: string;
  userAccessTimes: UserAccessTime[];
};
