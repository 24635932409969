export enum PurchaserPortalRootKey {
  Main = 'Main'
}

export enum PurchaserEditableDocumentStatus {
  Draft = 'Draft',
  Signing = 'Signing',
  Cancelled = 'Cancelled'
}

export enum PurchaserSubmittedDocumentStatus {
  Submitted = 'Submitted',
  Withdrawn = 'Withdrawn',
  Declined = 'Declined',
  Accepted = 'Accepted'
}

export enum SettingsOfferType {
  none = 'none',
  offer = 'offer',
  contract = 'contract'
}
