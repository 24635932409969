import { createSlice } from '@reduxjs/toolkit';
import { NotesTable } from '../types/NotesTable';
import { notesTable } from '@property-folders/components/display/GuidanceNotes';

export type notesState = {
  show: boolean,
  noteId: keyof NotesTable
};

export const REDUCER_NAME = 'guidanceNotes';

const thisReducer = createSlice({
  name: REDUCER_NAME,
  initialState: {
    show: false,
    noteId: 'inputTaxedPremises'
  } as notesState,
  reducers: {
    showNotes(state, action: {payload: {noteId: keyof typeof notesTable}}) {
      state.show = true;
      state.noteId = action.payload.noteId;
    },
    hideNotes(state) {
      state.show = false;
    }
  }
});

export const { showNotes, hideNotes } = thisReducer.actions;
export default thisReducer.reducer;
