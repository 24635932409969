import { ArrayUtil } from '@property-folders/common/util/array';
import clsJn from '~/../../common/util/classNameJoin';

export function ProgressSteps({ current, steps }: { current: number, steps: number }) {
  return <div className='d-flex flex-row gap-2 justify-content-end'>
    {ArrayUtil.range(steps, 1).map(x => (
      <div
        key={x}
        style={{
          width: '4rem',
          height: '0.8rem'
        }}
        className={clsJn('rounded', x <= current ? 'bg-success' : 'bg-secondary')}/>
    ))}
  </div>;
}
