import { AddressParts, Agent, AuthorityParty, PartyType, VendorParty, VendorPartyJoint, jointTypes } from '@property-folders/contract';
import { IAddressSearchResponseParts } from '@property-folders/contract/rest/address-search';
import { jointPartyConfig, partyFieldConfig } from './constants';
import { canonicalisers } from './canonicalisers';
import { Predicate } from '../../predicate';

export function composeStreetAddressFromParts(parts: IAddressSearchResponseParts | AddressParts | undefined) {
  let address = '';

  if (!parts) {
    return '';
  }

  if (parts.LotNumber) {
    address += `Lot ${parts.LotNumber} `;
  }

  // Because EPF has behaviour of including unit number in street number, we'll account for
  // equivalent behaviour
  const streetNumberIncludesUnit = parts.StreetNumber?.includes('/');
  if (parts.UnitNumber && !streetNumberIncludesUnit) {
    const unitType = parts.UnitNumber.match('/unit/i')
      ? ''
      : parts.UnitType ?? 'Unit';

    address += `${unitType.trim()} ${parts.UnitNumber}/`;
  }

  if (parts.LevelNumber && !streetNumberIncludesUnit) {
    // note: according to aus post, solidus (/) is inappropriate for level numbers, but an extra space is correct.
    address += `${parts.LevelNumber.match('/level/i')?'':'Level '}${parts.LevelNumber} `;
  }
  address += `${parts.StreetNumber ?? ''} ${parts.StreetName}`.trim();

  // Capitalise the first letter in case we get a 'unit'/'lot' from the user
  if (address.length) {
    address = address[0].toUpperCase() + address.slice(1);
  }

  return address.trim();
}

export function composeSubStatePostFromParts(parts: IAddressSearchResponseParts | AddressParts) {
  return `${parts.Suburb} ${parts.State} ${parts.Postcode}`;
}

export function composeFullAddressFromParts(parts: IAddressSearchResponseParts | AddressParts) {
  return composeStreetAddressFromParts(parts) + ', ' + composeSubStatePostFromParts(parts);
}

export function shortAgent(agent: Agent | undefined) {
  if (!agent) {
    return '' ;
  }
  const showId = agent.rla || agent.abn;
  return agent.company??'' + (showId ? ` (${showId})` : '');
}

export function shortAgentAndSales(agent: Agent) {
  const salespNames = (agent.salesp??[]).map(s=>s.name).filter(Predicate.isTruthy);

  const agencyDescription = shortAgent(agent);

  if (salespNames.length > 0) {
    return `${salespNames.join(',')} of ${agencyDescription||'____________________________'}`;
  } else {
    return shortAgent(agent);
  }
}

export function populateSigningPhrase(party: AuthorityParty, opts?: {overrides: {[fieldName:string]: string}}) {
  const partyType = party.partyType;
  const authority = party.authority;
  const configuration = jointTypes.includes(partyType) ? jointPartyConfig[partyType] : (partyType && authority && partyFieldConfig[partyType][party.authority]);
  let signingPhrase;
  if (configuration && configuration.signingTemplate !== '[fullLegalName]') {
    const { signingTemplate } = configuration;
    const fieldMatches = (signingTemplate||'').matchAll(/\[([\w0-9]+)\]/g);
    signingPhrase = signingTemplate;
    for (const matcher of fieldMatches) {
      if (Array.isArray(matcher) && matcher.length === 2) {
        const matchedSegment = matcher[0];
        const fieldName = matcher[1];
        let fieldValue = opts?.overrides?.[fieldName] ?? party?.[fieldName];
        if (fieldName === 'abn') {
          fieldValue = canonicalisers.abnacn(fieldValue).display;
        }
        signingPhrase = signingPhrase?.replace(matchedSegment, fieldValue || '[Unknown]');
      }
    }
  }
  return signingPhrase;
}

export function vendorDescription(vendorParam: VendorParty | VendorPartyJoint) {
  if (jointTypes.includes(vendorParam.partyType??PartyType.Error)) {
    const vendor = vendorParam as VendorPartyJoint;
    const { partyType } = (vendor || {});
    const { trustOrAppointedName, appointedAlwaysApplicable } = partyType && jointPartyConfig[partyType]||{};
    const isBehalf = vendor?.inTrust || appointedAlwaysApplicable;
    const primarySubExec = vendor.namedExecutors;
    const vendorJoins = (primarySubExec??[])
      .map(v=>{
        const hasAbn = !!v.abn;
        const abnCanon = canonicalisers.abnacn(v?.abn||'');
        return `${v.fullLegalName}${hasAbn ? ` ${(abnCanon.components?.type??'abn').toUpperCase()} ${abnCanon?.display}` : ''}`;
      })
      .join(' and ');
    return `${vendorJoins}${isBehalf ? ` ${trustOrAppointedName} ${vendor?.onBehalfOf}` : ''}`;
  }
  const vendor = vendorParam as VendorParty;
  const { partyType, authority } = (vendor || {});
  const { trustOrAppointedName, appointedAlwaysApplicable } = partyType && authority && partyFieldConfig[partyType]?.[authority]||{};
  const isBehalf = vendor?.inTrust || appointedAlwaysApplicable;
  const hasAbn = !!vendor.abn;
  const abnCanon = canonicalisers.abnacn(vendor?.abn||'');
  return `${vendor.fullLegalName}${hasAbn ? ` ${(abnCanon.components?.type??'abn').toUpperCase()} ${abnCanon?.display}` : ''}${isBehalf ? ` ${trustOrAppointedName} ${vendor?.onBehalfOf??''}` : ''}`;
}

export const commaAndReducer = (acc: string, cv: string, ci: number, ca: string[]) => acc ? [acc,cv].join(ci+1 === ca.length ? ' and ' : ', ') : cv;
