import { Predicate } from '../../../predicate';

export function companyTradingAs(companyName: string | undefined = '', tradeName: string | undefined = '', opts?: {long: boolean}) {
  const { long } = opts??{};
  const tradingAsText = long ? 'trading as' : 'T/A';
  let rVal = companyName;

  if (Predicate.strEmptyOrWhitespace(rVal)) {
    rVal = tradeName;
  } else if (companyName !== tradeName && !Predicate.strEmptyOrWhitespace(tradeName)) {
    rVal = rVal + ` ${tradingAsText} ${tradeName}`;
  }
  return rVal || '';
}
