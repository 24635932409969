import { useEffect } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { PurchaserPortalApi } from '~/api';
import { ContentCard } from '~/components/content-card';

export function LogoutMasker({ invalidatePurchaserSession }: {invalidatePurchaserSession: ()=>Promise<void>}) {
  const navigate = useNavigate();
  const matches = useMatches();

  useEffect(()=>{
    const purchaserRoot = matches.find(m=>m.id === 'purchaser-root');
    if (!purchaserRoot) {
      console.warn('Couldn\'t find expected navigation target');
      return;
    }

    PurchaserPortalApi.logout(purchaserRoot.params.portalIdRaw)
      .then(()=>{
        return invalidatePurchaserSession();
      }).then(()=>{
        navigate(purchaserRoot.pathname);
      });

  }, []);

  return <div style={{ paddingTop: '3rem' }}>
    <ContentCard agencyHighlights={true}>
      <div className='text-center m-5'>Logging out...</div>
    </ContentCard>
  </div>
  ;

}