const tzCache: Record<string, boolean> = {};
export function isValidTimeZone(timeZone: string) {
  if (timeZone in tzCache) {
    return tzCache[timeZone];
  }

  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw new Error('Time zones are not available in this environment');
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: timeZone });
    tzCache[timeZone] = true;
    return true;
  }
  catch (ex) {
    tzCache[timeZone] = false;
    return false;
  }
}
