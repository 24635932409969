import { FileRef } from '../../property';

/**
 * If you're creating a new Y doc, then load this template into it first.
 */
export const UserPreferencesSchemaTemplate = 'AgG30PGfDgAhAQRNYWluC3Byb3BlcnR5SWRzAQG76PXQBwCnt9Dxnw4AAQG30PGfDgEAAQ==';

export enum UserPreferencesRootKey {
  /**
   * Root key for shape defined by {@link UserPreferencesMain}
   */
  Main = 'Main'
}

export type UserPreferencesImage = {
  ref: FileRef,
  setAtMs: number
};

export type UserPreferencesMain = {
  agentId: number;
  /**
   * @deprecated use starredPropertyIds array instead
   */
  propertyIds?: {
    [key: string]: boolean
  };
  starredPropertyIds: string[];
  images?: {
    signature?: UserPreferencesImage;
    initials?: UserPreferencesImage;
  },
  lastNotifyOnRemoteSign?: boolean;
};
