import { DefaultPageLayout } from '~/components/default-page-layout';
import { ContentCard } from '~/components/content-card';
import { useAsyncValue, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GetPurchaserPortalResult } from '@property-folders/contract';
import React, { useState } from 'react';
import { Alert, Button, Card, FloatingLabel, Form } from 'react-bootstrap';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { noBubble, swallow } from '~/util';
import { useAuth } from '~/context/auth-context';
import { StatusCodeError } from '@property-folders/common/client-api/statusCodeError';
import { PurchaserPortalApi } from '~/api';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { ResendButton } from '~/components/resend-button';

enum PageSteps {
  UsernamePassword = 1,
  SmsVerification = 2
}

export function PurchaserUnauthenticatedLoginRoute() {
  const navigate = useNavigate();
  const data = useAsyncValue() as GetPurchaserPortalResult;
  const { search, state } = useLocation();
  const { emailHint } = state??{};
  const previewPassthrough = new URLSearchParams(search).get('doctype');
  const returnPathRaw = new URLSearchParams(search).get('returnPath');
  const returnPathDecision = returnPathRaw && returnPathRaw.startsWith('/')
    ? returnPathRaw
    : `/purchaser/${data.portalId}/view`;

  const previewPassthroughQs = previewPassthrough ? `?doctype=${previewPassthrough}` : '';
  const returnPath = `${returnPathDecision}${previewPassthroughQs}`;
  const { invalidate: reloadSession } = useAuth();
  const [step, setStep] = useState<PageSteps>(PageSteps.UsernamePassword);
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState(emailHint||'');
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [verificationPhoneSuffix, setVerificationPhoneSuffix] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeChanged, setVerificationCodeChanged] = useState(false);

  const loginHandler = async () => {
    setSubmitting(true);
    setErrorMessage('');
    const canonicalPhone = canonicalisers.phone(username);

    try {
      if (!canonicalPhone.valid) {
        setErrorMessage('Provided phone number is not valid. Please check it and try again.');
        return;
      }
      const loginResult = await PurchaserPortalApi.login(data.portalId, { username: canonicalPhone.canonical.toString() });
      switch (loginResult?.type) {
        case 'success':
          await reloadSession();
          navigate(returnPath);
          return;
        case 'sms_verification_required':
          setStep(PageSteps.SmsVerification);
          setVerificationPhoneSuffix(loginResult.phoneSuffix);
          return;
        default:
          setErrorMessage('Login failed!');
          return;
      }
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage('Login failed!');
      return;
    } finally {
      setSubmitting(false);
    }
  };

  const verificationHandler = async () => {
    setSubmitting(true);
    setErrorMessage('');

    try {
      await PurchaserPortalApi.loginVerify(data.portalId, { code: verificationCode });
    } catch (err: unknown) {
      console.error(err);
      if (err instanceof StatusCodeError && err.status === 403) {
        setErrorMessage('Too many verification attempts. User login is temporarily locked.');
        return;
      }
      setErrorMessage('Invalid verification code');
      return;
    } finally {
      setSubmitting(false);
    }

    await reloadSession();
    navigate(returnPath);
  };

  return <DefaultPageLayout
    title='Purchaser portal'
    subtitle={[<h5 key='subtitle'>{data.headline}</h5>]}
    agencyCssOverrides={data.cssOverrides}
  >
    {step === PageSteps.UsernamePassword && <form onSubmit={noBubble(swallow(loginHandler))}>
      <ContentCard agencyHighlights={true}>
        <Card.Header>
          <h4>Welcome to the Purchaser portal</h4>
        </Card.Header>
        <Card.Body className='d-flex flex-column gap-2'>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
          <Card.Text>Enter your phone number below:</Card.Text>
          <FloatingLabel label={'Phone'}>
            <Form.Control
              autoComplete='tel'
              inputMode='tel'
              onChange={e => {
                setUsername(e.target.value);
                setUsernameChanged(true);
              }}
              isInvalid={!username && usernameChanged}
              autoFocus
              defaultValue={emailHint}
              tabIndex={1}
            />
            {usernameChanged && <Form.Control.Feedback type={'invalid'}>Phone number is required.</Form.Control.Feedback>}
          </FloatingLabel>

        </Card.Body>
        <Card.Footer className='d-flex flex-column gap-2'>
          <div className='d-flex flex-row justify-content-end gap-2'>
            <Button variant='outline-secondary' onClick={() => navigate('..', { relative: 'route' })} tabIndex={3}>Cancel</Button>
            <SpinnerButton
              type='submit'
              processing={submitting}
              disabled={!(username)}
              onClick={noBubble(swallow(loginHandler))}
              tabIndex={2}
            >Login</SpinnerButton>
          </div>

        </Card.Footer>
      </ContentCard>
    </form>}
    {step === PageSteps.SmsVerification && <form onSubmit={noBubble(swallow(verificationHandler))}><ContentCard>
      <Card.Header>
        <h4>Verification</h4>
      </Card.Header>
      <Card.Body>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        <Card.Text>We have sent a verification code to <b>{canonicalisers.phone(username).display}</b>.</Card.Text>
        <Card.Text>Enter the code below to verify this phone number:</Card.Text>
        <FloatingLabel label={'Phone verification code'}>
          <Form.Control
            type='text'
            autoComplete='one-time-code'
            inputMode='numeric'
            onChange={e => {
              const beforeNotEmpty = e.target.value.length > 0;
              e.target.value = e.target.value.replace(/[^\d]/, '');
              if (beforeNotEmpty && e.target.value.length === 0) {
                e.preventDefault();
                return false;
              }
              setVerificationCode(e.target.value);
              setVerificationCodeChanged(true);
            }}
            isInvalid={!verificationCode && verificationCodeChanged}
            autoFocus
            tabIndex={1}
          />
        </FloatingLabel>
      </Card.Body>
      <Card.Footer>
        <div className='d-flex flex-row justify-content-end gap-2'>
          <Button
            variant='outline-secondary'
            onClick={() => navigate('..', { relative: 'route' })}
            tabIndex={4}>Cancel</Button>
          <ResendButton
            initialSent={!!verificationPhoneSuffix}
            phone={canonicalisers.phone(username).canonical}
            portalId={data.portalId}
            resendSource='login'
            tabIndex={3}
          />
          <SpinnerButton
            type='submit'
            processing={submitting}
            disabled={!verificationCode}
            onClick={noBubble(swallow(verificationHandler))}
            tabIndex={2}
          >Verify</SpinnerButton>
        </div>
      </Card.Footer>
    </ContentCard></form>}
  </DefaultPageLayout>;
}
