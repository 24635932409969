import { InstanceHistory, MaterialisedPropertyData } from '@property-folders/contract';

import { buildSigningTimelines } from '../../../../util/dataExtract';

export function preventChangeToAuctionifVendorPrice (proposedUpdate: any, data: MaterialisedPropertyData, latestSnapshot: MaterialisedPropertyData, snapshotHistory: InstanceHistory) {
  if (typeof proposedUpdate !== 'string' || proposedUpdate !== 'auction') {
    // We are only going to pop up this warning if the user is changing to an auction.
    return false;
  }

  const instanceGroupings = buildSigningTimelines(snapshotHistory).instanceSets??[];
  const currentInstanceSnapshots = instanceGroupings[instanceGroupings.length-1].map(inst => snapshotHistory.data[inst.signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'']);

  const priceHistory = currentInstanceSnapshots.map(s=>Number(s.sale?.vendorPrc)).filter(a=>a>0);
  const minPrice = priceHistory.length > 0 ? Math.min(...priceHistory) : null;
  return !!minPrice && Number(data.sale?.vendorPrc) > minPrice;
}