import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { PurchaserPortalApi } from '~/api';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { ButtonProps } from 'react-bootstrap';

enum ResendStatus {
  Initial,
  RequestingCode,
  WaitingForResendTimeout,
  Ready
}

const resendTime = 5 * 60 * 1000; //5 minutes being the dedupe lifetime

function secondsToTimerText(seconds: number) {
  if (seconds <= 0) {
    return '';
  }
  if (seconds < 60) {
    return ` ${seconds}s`;
  }
  return ` ${Math.floor(seconds/60)}:${(seconds%60).toString().padStart(2,'0')}`;
}

export function ResendButton({ initialSent, phone, portalId, resendSource, ...restProps }: {initialSent: boolean, portalId: string, phone: string, resendSource: string} & ButtonProps) {
  const [resendState, setResendState] = useState(ResendStatus.Initial);
  const reenableTimer = useRef<NodeJS.Timeout>();
  const resendButtonRef = useRef<HTMLButtonElement|null>(null);
  const timerInterval = useRef<NodeJS.Timer>();
  const timerAcc = useRef<number>(0);

  useEffect(() => {
    if (initialSent) {
      handleWaitSet();
    }
  }, [initialSent]);

  useEffect(()=>{
    return () => {
      clearTimeout(reenableTimer.current);
      clearInterval(timerInterval.current);
    };
  }, []);

  function setReady() {
    setResendState(ResendStatus.Ready);
    clearInterval(timerInterval.current);
    timerAcc.current = 0;
    const timerTextField = resendButtonRef.current?.querySelector('#timer');
    if (timerTextField) {
      timerTextField.textContent = '';
    }

  }

  function handleWaitSet() {
    setResendState(ResendStatus.WaitingForResendTimeout);
    reenableTimer.current = setTimeout(()=>{
      setReady();
    },resendTime);
    timerAcc.current = resendTime/1000;
    const timerTextField = resendButtonRef.current?.querySelector('.timer');

    if (timerTextField) {
      timerTextField.textContent = secondsToTimerText(timerAcc.current);
    }
    clearInterval(timerInterval.current);
    timerInterval.current = setInterval(()=>{
      timerAcc.current -= 1;
      const timerTextField = resendButtonRef.current?.querySelector('.timer');

      if (timerTextField) {
        timerTextField.textContent = secondsToTimerText(timerAcc.current);
      }

    }, 1000);
  }

  function handleResendOtp() {
    if (!phone) return;
    setResendState(ResendStatus.RequestingCode);
    PurchaserPortalApi.resendOtp(portalId, canonicalisers.phone(phone).canonical, resendSource).then(handleWaitSet);
  }

  const buttonDisabled = resendState !== ResendStatus.Ready;

  return useMemo(()=><SpinnerButton
    {...restProps}
    ref={resendButtonRef}
    type='button'
    variant='outline-secondary'
    processing={resendState === ResendStatus.RequestingCode}
    onClick={handleResendOtp}
    disabled={buttonDisabled}
  >{resendState === ResendStatus.Ready
      ? 'Resend Code'
      : resendState === ResendStatus.RequestingCode
        ? 'Sending'
        : resendState === ResendStatus.WaitingForResendTimeout
          ? 'Resend in '
          : 'Resend Code'

    }{resendState === ResendStatus.WaitingForResendTimeout && <span className='timer'>{secondsToTimerText(timerAcc.current)}</span>}
  </SpinnerButton>, [resendState  ]);
}
