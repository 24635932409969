import { Outlet } from 'react-router-dom';
import { AuthProvider } from '~/context/auth-context';
import { PortalType } from '@property-folders/common/client-api/auth';

export function Root({ type }: { type: PortalType}) {
  return (
    <AuthProvider type={type}>
      <Outlet />
    </AuthProvider>
  );
}
