export const friendlyTimeFormatter = Intl.DateTimeFormat('en-AU', { hour12: true, hour: 'numeric', minute: 'numeric' }).format;
export const friendlyHourFormatter = Intl.DateTimeFormat('en-AU', { hour12: true, hour: 'numeric' }).format;
export const friendlyDateFormatterShort = Intl.DateTimeFormat('en-AU', { month: 'short', day: 'numeric' }).format;

export const friendlyDateFormatter = Intl.DateTimeFormat('en-AU', { dateStyle: 'medium' }).format;
export const friendlyDateFormatterFull = Intl.DateTimeFormat('en-AU', { dateStyle: 'long' }).format;
export const localDate = (isoDateString: string | number | Date | undefined) => {
  if (!isoDateString) {
    return isoDateString;
  }
  return friendlyDateFormatter(new Date(isoDateString));
};

export function friendlyMinimalDateFormatter(date: string | Date) {
  return friendlyDateFormatter(new Date(date));
}

export function friendlyMinimalTimeFormatter(time: string) {
  const resetTime = new Date();
  const [hR,mR] = time.split(':');
  const h = parseInt(hR);
  const m = parseInt(mR);
  resetTime.setHours(h);
  resetTime.setMinutes(m);
  if (m === 0) return friendlyHourFormatter(resetTime).replace(' ','');
  return friendlyTimeFormatter(resetTime).replace(' ','');
}