import { isStringifiedBlob, StringifiedBlob } from './StringifiedBlob';
import { RecursiveObject } from './IRecursiveObject';

function b64toBlob(b64Data: StringifiedBlob): Blob {
  const blobStr = b64Data._blob.startsWith('data:') ? b64Data._blob.split(',')[1] : b64Data._blob;
  const byteCharacters = atob(blobStr);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: b64Data._contentType });
}

export function FetchObjectContainingBlobs(
  obj: RecursiveObject | Blob | undefined
): any {
  if (isStringifiedBlob(obj)) {
    return b64toBlob(obj);
  }

  if (typeof obj !== 'object' || obj == null) {
    return obj;
  }

  for (const key of Object.keys(obj)) {
    const data = obj[key];

    if (typeof data !== 'object' || data == null) {
      continue;
    }

    if (isStringifiedBlob(data)) {
      obj[key] = b64toBlob(data);
    }

    if (typeof data === 'object') {
      obj[key] = FetchObjectContainingBlobs(obj[key]);
    }
  }

  return obj;
}
