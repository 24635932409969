import { DefaultPageLayout } from '~/components/default-page-layout';
import { SimpleCrumbs } from '~/components/simple-crumbs';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { PurchaserPortalSharedDocument } from '@property-folders/contract';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { Button } from 'react-bootstrap';

export function ViewDocumentPage({
  headline,
  agencyCssOverrides,
  document,
  returnPath
}: {
  headline: string,
  agencyCssOverrides?: Record<string, string>,
  document: PurchaserPortalSharedDocument
  returnPath?: string
}) {
  const navigate = useNavigate();

  return <DefaultPageLayout
    title={'Review document'}
    subtitle={[<SimpleCrumbs key='crumbs'>
      <Link key='backlink' to={returnPath ?? '..'} relative={returnPath ? 'path' : 'route'}>{headline}</Link>
      <span key='crumb'>{document.name}</span>
    </SimpleCrumbs>]}
    agencyCssOverrides={agencyCssOverrides}
    noPad={true}>
    <div style={{ position: 'absolute', top: '96px', left: '0', width: '100%', height: 'calc(100% - 96px)' }}>
      <SetupPdfLoadStateContext>
        <PDFViewer
          bookmark=''
          pdfUrl={document.url}
          filename={document.fileName}
          renderTextLayer={false}
          standalonePreview={true}
          useLoadSuccessForCompletion={true}
          activeViews={1}
          toolbarRight={<Button
            variant="secondary"
            onClick={()=>{
              navigate(returnPath??'..', { relative: returnPath ? 'path' : 'route' });
            }}
          >
            <Icon name='close'/>
          </Button>}
        />
      </SetupPdfLoadStateContext>
    </div>
  </DefaultPageLayout>;
}
