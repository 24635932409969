import React, { useContext, useMemo } from 'react';
import { AuthApi, PortalType } from '@property-folders/common/client-api/auth';
import { useQueryClient } from '@tanstack/react-query';
import { PortalSessionInfoResult } from '@property-folders/contract';
import { LinkBuilderContext } from '@property-folders/components/context/link-builder-context';
import { useParams } from 'react-router-dom';

export interface AuthContextValue {
  loaded: boolean,
  sessionInfo?: PortalSessionInfoResult;
  logout: () => void;
  invalidate: () => Promise<void>;
}

function noop() { /**/ }
export const AuthContext = React.createContext<AuthContextValue>({
  loaded: false,
  logout: noop,
  invalidate: () => Promise.resolve()
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children, type }: React.PropsWithChildren<{ type: PortalType }>) {
  const queryClient = useQueryClient();
  const linkBuilder = useContext(LinkBuilderContext);
  const params = useParams();
  const { data, isLoading } = AuthApi.usePortalSessionInfo(type, path => linkBuilder.restApi(path), params.inviteToken);
  const loaded = !isLoading;
  const value = useMemo<AuthContextValue>(() => ({
    sessionInfo: data?.session?.type === 'portal'
      ? data.session
      : undefined,
    loaded,
    logout: () => {
      // future api call
    },
    invalidate: () => AuthApi.invalidatePortalSessionInfo(queryClient, type)
  }), [data, loaded]);

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
}
