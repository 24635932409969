import { SaleAddress } from '@property-folders/contract';

function addressJoin(street: string, subStatePost: string) {
  return [street, subStatePost].join(', ');
}

export function contactAddressDerivation(partyAddress: string, useSaleAddr: boolean, saleAddrs: SaleAddress[]) {
  let useAllowed = false;
  let contactAddress = partyAddress;
  let isUsed = false;
  if (Array.isArray(saleAddrs) && saleAddrs.length === 1 && saleAddrs[0].streetAddr && saleAddrs[0].subStateAndPost) {
    useAllowed = true;
  }
  if (useAllowed && useSaleAddr) {
    contactAddress = addressJoin(saleAddrs[0].streetAddr, saleAddrs[0].subStateAndPost);
    isUsed = true;
  }
  return { useAllowed, isUsed, contactAddress };
}

